import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectArtistFollowers } from 'src/selectors/inv-common';
import { pluralizeText } from 'src/utils/common/helpers';

import { FollowUnfollowArtistButton } from './follow-unfollow-artist-button';

function ArtistCard({ name, artistRef, imageURL, redirectURL, userArtistData }) {
  const followers = useSelector(state => selectArtistFollowers(state, artistRef));

  const { upcomingItems } = userArtistData;

  const upcomingItemsString = `${upcomingItems.toLocaleString()} lot`;
  const followersString = `${followers.toLocaleString()} follower`;

  return (
    <div className="artist-card">
      { imageURL
        ? (
          <a className="thumbnail-img" href={redirectURL}>
            <img alt="Artist Thumbnail" src={imageURL} />
          </a>
        )
        : (
          <div className="no-image">
            No Image
          </div>
        )}
      <div className="detail">
        <div className="align-container">
          <a className="artist-name text-ellipsis" href={redirectURL}>
            {name}
          </a>
          <div className="items-and-followers">
            {upcomingItems > 0 && pluralizeText(upcomingItems, upcomingItemsString)}
            {followers > 0 && `${upcomingItems > 0 ? ' | ' : ''}${pluralizeText(followers, followersString)}` }
          </div>
        </div>
        <FollowUnfollowArtistButton artistRef={artistRef} />
      </div>
    </div>
  );
}

ArtistCard.propTypes = {
  name: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
  artistRef: PropTypes.string.isRequired,
  userArtistData: PropTypes.shape({
    followers: PropTypes.number,
    upcomingItems: PropTypes.number,
  })
};

ArtistCard.defaultProps = {
  userArtistData: {
    upcomingItems: 0,
  }
};

export default ArtistCard;
