import { get } from 'lodash';

import logger from 'routes/middleware/logging/logger';
import { HTTP_STATUS_CODES } from 'routes/constants';
import PDPService from '../services/pdp';

import {
  FETCH_PDP_LOT_SUCCESS,
  FETCH_PDP_LOT_ERROR,
  FETCH_INITIAL_LOT_DATA_SUCCESS,
  FETCH_INITIAL_LOT_DATA_ERROR,
  FETCH_PDP_BIDDER_STATUS_SUCCESS,
  FETCH_PDP_BIDDER_STATUS_ERROR,
  FETCH_PDP_LOT_LIVE_INFO_SUCCESS,
  FETCH_PDP_LOT_LIVE_INFO_ERROR,
  POST_PDP_ADD_TO_WATCH_LIST_SUCCESS,
  POST_PDP_ADD_TO_WATCH_LIST_ERROR,
  POST_PDP_REMOVE_FROM_WATCH_LIST_SUCCESS,
  POST_PDP_REMOVE_FROM_WATCH_LIST_ERROR,
  FETCH_PDP_WATCHER_COUNT_SUCCESS,
  FETCH_PDP_WATCHER_COUNT_ERROR,
  FETCH_PDP_SELLER_INFO_SUCCESS,
  FETCH_PDP_SELLER_INFO_ERROR,
  FETCH_PDP_USER_CONTACT_INFO_SUCCESS,
  FETCH_PDP_USER_CONTACT_INFO_ERROR,
  FETCH_PDP_STATES_SUCCESS,
  FETCH_PDP_STATES_ERROR,
  PDP_UPDATE_PLACE_BID_FORM_DATA,
  POST_PDP_FOLLOW_UN_FOLLOW_SELLER_SUCCESS,
  POST_PDP_FOLLOW_UN_FOLLOW_SELLER_ERROR,
  FETCH_PDP_IS_SELLER_FOLLOWED_SUCCESS,
  FETCH_PDP_IS_SELLER_FOLLOWED_ERROR,
  SET_PDP_ASYNC_STATUS,
  FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_SUCCESS,
  FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_ERROR,
  POST_PDP_UPDATE_BID_TOTAL_SUCCESS,
  POST_PDP_UPDATE_BID_TOTAL_ERROR,
  SET_PDP_SELECTED_BID_INCREMENT,
  SET_PDP_IS_LOADING,
  POST_PDP_NOTIFY_ME_SUCCESS,
  POST_PDP_NOTIFY_ME_ERROR,
  PDP_UPDATE_PLACE_BID_FORM_ERRORS,
  FETCH_PDP_REQUEST_FOR_APPROVAL_SUCCESS,
  FETCH_PDP_REQUEST_FOR_APPROVAL_ERROR,
  RESET_PDP_ERRORS,
  RESET_PDP_PLACE_BID_FORM_ERRORS,
  FETCH_PDP_SELLER_FOLLOW_COUNT_SUCCESS,
  FETCH_PDP_SELLER_FOLLOW_COUNT_ERROR,
  FETCH_PDP_BILLING_CARDS_ERROR,
  FETCH_PDP_BILLING_CARDS_SUCCESS,
  PDP_UPDATE_NEW_CARD_DETAILS,
  PDP_UPDATE_SELECTED_CARD_DETAILS,
  FETCH_PDP_SUBSCRIPTION_INFO_SUCCESS,
  FETCH_PDP_SUBSCRIPTION_INFO_ERROR,
  FETCH_PDP_ARTIST_INFO_SUCCESS,
  FETCH_PDP_ARTIST_INFO_ERROR,
  PDP_SET_BID_SUBMIT_RESPONSE,
  PDP_SET_BID_SUBMIT_ERROR_MESSAGE,
  PDP_SET_BID_SUBMIT_ERRORS_DATA,
  PDP_SET_SELECTED_BID_AMOUNT,
  PDP_SET_ARE_BILLING_SHIPPING_ADDRESS_SAME,
  PDP_USE_NEW_CREDIT_CARD,
  PDP_SET_ALGOLIA_PARAMS,
  PDP_SET_CURRENCY_COVERSION_RATE,
  PDP_SET_IS_PROD_ENV,
  PDP_FETCH_VIP_STATUS_SUCCESS,
  PDP_FETCH_VIP_STATUS_ERROR,
  PDP_SET_BID_CONCIERGE_STATUS,
  SET_PDP_CONCIERGE_MODAL_PROPS,
  SET_PDP_STATUS_CODE,
} from './constants';

import { ASYNC_ACTION_STATUS_TYPE, ASYNC_ACTION, BID_SUBMIT_FE_ERROR_MSG } from '../utils/pdp/constants';
import { REST_RESPONSE_STATUS_CODES } from '../services/rest-client/constants';

const fetchPDPLotSuccess = PDPLot => ({
  type: FETCH_PDP_LOT_SUCCESS,
  payload: PDPLot,
});

const fetchInitialLotDataSuccess = PDPLot => ({
  type: FETCH_INITIAL_LOT_DATA_SUCCESS,
  payload: PDPLot,
});

const fetchBidderStatusSuccess = userApprovalData => ({
  type: FETCH_PDP_BIDDER_STATUS_SUCCESS,
  payload: userApprovalData,
});

export const pdpSetAlgoliaParams = algoliaParams => ({
  type: PDP_SET_ALGOLIA_PARAMS,
  payload: algoliaParams,
});

const fetchLotLiveInfoSuccess = liveInfo => ({
  type: FETCH_PDP_LOT_LIVE_INFO_SUCCESS,
  payload: liveInfo,
});

const fetchWatcherCountSuccess = watcherCount => ({
  type: FETCH_PDP_WATCHER_COUNT_SUCCESS,
  payload: watcherCount,
});

const fetchSellerInfoSuccess = info => ({
  type: FETCH_PDP_SELLER_INFO_SUCCESS,
  payload: info,
});

export const setAsyncStatus = status => ({
  type: SET_PDP_ASYNC_STATUS,
  payload: status,
});

export const fetchUserContactInfoSuccess = contactInfo => ({
  type: FETCH_PDP_USER_CONTACT_INFO_SUCCESS,
  payload: contactInfo,
});

export const fetchStatesSuccess = (statesData, formType) => ({
  type: FETCH_PDP_STATES_SUCCESS,
  payload: { statesData, formType },
});

export const updatePlaceBidFormData = (key, updatedValue, nestedForm) => ({
  type: PDP_UPDATE_PLACE_BID_FORM_DATA,
  payload: { key, updatedValue, nestedForm },
});

/**
 * Add/Update errors for a form in place bid modal
 * @param errors Object of shape { fieldName: errorMessage }
 * @param errorType form name
 * @param overwrite If overwrite is true, errors are overwritten with `errors` object
 * else we'll add the new error fields
 * @return {{payload: {errorType, overwrite: boolean, errors}, type: string}}
 */
export const updatePlaceBidFormErrors = (errors, errorType, overwrite = true) => ({
  type: PDP_UPDATE_PLACE_BID_FORM_ERRORS,
  payload: { errors, errorType, overwrite },
});

export const resetPlaceBidFromErrors = errorType => ({
  type: RESET_PDP_PLACE_BID_FORM_ERRORS,
  payload: errorType,
});

export const followUnFollowSellerSuccess = isFollowed => ({
  type: POST_PDP_FOLLOW_UN_FOLLOW_SELLER_SUCCESS,
  payload: isFollowed,
});

export const fetchIsSellerFollowedSuccess = isFollowed => ({
  type: FETCH_PDP_IS_SELLER_FOLLOWED_SUCCESS,
  payload: isFollowed,
});

export const postNotifyMeSuccess = isNotify => ({
  type: POST_PDP_NOTIFY_ME_SUCCESS,
  payload: isNotify,
});

export const setSelectBidIncrement = bidInc => ({
  type: SET_PDP_SELECTED_BID_INCREMENT,
  payload: bidInc,
});

export const setSelectedBidAmount = bidAmount => ({
  type: PDP_SET_SELECTED_BID_AMOUNT,
  payload: bidAmount,
});

export const setPDPIsLoading = isLoading => ({
  type: SET_PDP_IS_LOADING,
  payload: isLoading !== false,
});

export const pdpSetIsProdEnv = isProd => ({
  type: PDP_SET_IS_PROD_ENV,
  payload: isProd,
});

const fetchPDPRequestForApprovalInitSuccess = approvalData => ({
  type: FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_SUCCESS,
  payload: approvalData,
});

const fetchPDPRequestForApprovalSuccess = ccInfo => ({
  type: FETCH_PDP_REQUEST_FOR_APPROVAL_SUCCESS,
  payload: ccInfo,
});

const fetchPDPRequestBillingCardsSuccess = memberCards => ({
  type: FETCH_PDP_BILLING_CARDS_SUCCESS,
  payload: memberCards,
});

const postPDPUpdateBidTotalSuccess = bidData => ({
  type: POST_PDP_UPDATE_BID_TOTAL_SUCCESS,
  payload: bidData,
});

const fetchSubscriptionInfoSuccess = subscriptionInfo => ({
  type: FETCH_PDP_SUBSCRIPTION_INFO_SUCCESS,
  payload: subscriptionInfo,
});

export const fetchSellerFollowCountSuccess = count => ({
  type: FETCH_PDP_SELLER_FOLLOW_COUNT_SUCCESS,
  payload: count,
});

export const updateNewCardDetails = cardDetails => ({
  type: PDP_UPDATE_NEW_CARD_DETAILS,
  payload: cardDetails,
});

export const setIsUseNewCard = isUseNewCard => ({
  type: PDP_USE_NEW_CREDIT_CARD,
  payload: isUseNewCard,
});

export const updateSelectedCardDetails = cardDetails => ({
  type: PDP_UPDATE_SELECTED_CARD_DETAILS,
  payload: cardDetails,
});

export const fetchArtistInfoSuccess = artistInfo => ({
  type: FETCH_PDP_ARTIST_INFO_SUCCESS,
  payload: artistInfo,
});

export const pdpSetBidSubmitResponse = response => ({
  type: PDP_SET_BID_SUBMIT_RESPONSE,
  payload: response,
});

export const pdpSetBidSubmitErrorMessage = errors => ({
  type: PDP_SET_BID_SUBMIT_ERROR_MESSAGE,
  payload: errors,
});

export const pdpSetBidSubmitErrorsData = data => ({
  type: PDP_SET_BID_SUBMIT_ERRORS_DATA,
  payload: data,
});

export const pdpSetAreBillingShippingAddressSame = areSame => ({
  type: PDP_SET_ARE_BILLING_SHIPPING_ADDRESS_SAME,
  payload: areSame,
});

export const setCurrencyConversionRate = currencyConversion => ({
  type: PDP_SET_CURRENCY_COVERSION_RATE,
  payload: currencyConversion,
});

export const setBidConciergeStatus = (conciergeStatus) => ({
  type: PDP_SET_BID_CONCIERGE_STATUS,
  payload: conciergeStatus,
});

const postAddToWatchListSuccess = {
  type: POST_PDP_ADD_TO_WATCH_LIST_SUCCESS,
};

const postRemoveFromWatchListSuccess = {
  type: POST_PDP_REMOVE_FROM_WATCH_LIST_SUCCESS,
};

const fetchVIPStatusSuccess = VIPStatus => ({
  type: PDP_FETCH_VIP_STATUS_SUCCESS,
  payload: VIPStatus,
});

export const setConciergeModalProps = data => ({
  type: SET_PDP_CONCIERGE_MODAL_PROPS,
  payload: data,
});

const fetchVIPStatusError = {
  type: PDP_FETCH_VIP_STATUS_ERROR,
};

const fetchPDPLotError = {
  type: FETCH_PDP_LOT_ERROR,
};

const fetchInitialLotDataError = {
  type: FETCH_INITIAL_LOT_DATA_ERROR,
};

const setPDPDataCallStatus = statusCode => ({
  type: SET_PDP_STATUS_CODE,
  payload: statusCode,
});

const fetchBidderStatusError = {
  type: FETCH_PDP_BIDDER_STATUS_ERROR,
};

const fetchLotLiveInfoError = {
  type: FETCH_PDP_LOT_LIVE_INFO_ERROR,
};

const postAddToWatchListError = {
  type: POST_PDP_ADD_TO_WATCH_LIST_ERROR,
};

const postRemoveFromWatchListError = {
  type: POST_PDP_REMOVE_FROM_WATCH_LIST_ERROR,
};

const fetchWatcherCountError = {
  type: FETCH_PDP_WATCHER_COUNT_ERROR,
};

const fetchSellerInfoError = {
  type: FETCH_PDP_SELLER_INFO_ERROR,
};

const fetchPDPRequestForApprovalInitError = {
  type: FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_ERROR,
};

const fetchPDPRequestBillingCardsError = {
  type: FETCH_PDP_BILLING_CARDS_ERROR,
};

const fetchPDPRequestForApprovalError = {
  type: FETCH_PDP_REQUEST_FOR_APPROVAL_ERROR,
};

const postPDPUpdateBidTotalError = {
  type: POST_PDP_UPDATE_BID_TOTAL_ERROR,
};

const fetchSellerFollowCountError = {
  type: FETCH_PDP_SELLER_FOLLOW_COUNT_ERROR,
};

const fetchSubscriptionInfoError = {
  type: FETCH_PDP_SUBSCRIPTION_INFO_ERROR,
};

export const fetchArtistInfoError = {
  type: FETCH_PDP_ARTIST_INFO_ERROR,
};

export const fetchUserContactInfoError = {
  type: FETCH_PDP_USER_CONTACT_INFO_ERROR,
};

export const fetchStatesError = {
  type: FETCH_PDP_STATES_ERROR,
};

export const followUnFollowSellerError = {
  type: POST_PDP_FOLLOW_UN_FOLLOW_SELLER_ERROR,
};

export const fetchIsSellerFollowedError = {
  type: FETCH_PDP_IS_SELLER_FOLLOWED_ERROR,
};

export const postNotifyMeError = {
  type: POST_PDP_NOTIFY_ME_ERROR,
};

export const resetPDPErrors = {
  type: RESET_PDP_ERRORS,
};

export const fetchPDPLot = (lotId, memberId, hostname, xAuthToken) => async (dispatch) => {
  try {
    const {
      success,
      data: PDPLotData,
      statusCode,
      statusMessage
    } = await PDPService.getPDPLot(lotId, memberId, hostname, xAuthToken);

    if (success) {
      dispatch(fetchPDPLotSuccess(PDPLotData));
      return PDPLotData;
    }

    logger.log(
      (statusCode === 404 ? 'info' : 'error'),
      'PDP: Bad response fetching lot',
      { lotId, memberId, statusCode, statusMessage }
    );
    dispatch(fetchPDPLotError);
    return {};
  } catch (error) {
    logger.error(`PDP: Error fetching lot [lotId=${lotId}, memberId=${memberId}]`, error);
    dispatch(fetchPDPLotError);
    return {};
  }
};

export const fetchInitialLotData = (lotId, hostname, xAuthToken) => async (dispatch) => {
  try {
    const {
      success,
      data: PDPLotData,
      statusCode,
      statusMessage
    } = await PDPService.getInitialLotData(lotId, hostname, xAuthToken);

    if (success) {
      dispatch(fetchInitialLotDataSuccess(PDPLotData));
      return PDPLotData;
    }

    logger.log(
      (statusCode === 404 ? 'info' : 'error'),
      'PDP: Bad response fetching lot',
      { lotId, statusCode, statusMessage }
    );
    if (statusCode >= HTTP_STATUS_CODES.BAD_REQUEST) {
      dispatch(setPDPDataCallStatus(statusCode));
      dispatch(fetchInitialLotDataError);
    }
    return {};
  } catch (error) {
    logger.error(`PDP: Error fetching lot [lotId=${lotId}`, error);
    dispatch(fetchInitialLotDataError);
    return {};
  }
};

export const fetchBidderStatus = (hostname, catalogRef, memberId) => async (dispatch) => {
  try {
    const {
      success,
      data: bidderStatus,
      statusCode,
      statusMessage
    } = await PDPService.getBidderStatus(hostname, catalogRef, memberId);

    if (success) {
      dispatch(fetchBidderStatusSuccess(bidderStatus));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching bidder status',
        { catalogRef, memberId, statusCode, statusMessage }
      );
      dispatch(fetchBidderStatusError);
    }
  } catch (error) {
    logger.error(`PDP: Error fetching bidder status [catalogRef=${catalogRef}, memberId=${memberId}]`, error);
    dispatch(fetchBidderStatusError);
  }
};

export const fetchLotLiveInfo = (lotRef, memberId) => async (dispatch) => {
  try {
    const { success, data: lotLiveInfo, statusCode, statusMessage } = await PDPService.getLotLiveInfo(lotRef, memberId);

    if (success) {
      dispatch(fetchLotLiveInfoSuccess(lotLiveInfo));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching live lot info',
        { lotRef, memberId, statusCode, statusMessage }
      );
      dispatch(fetchLotLiveInfoError);
    }
  } catch (error) {
    logger.error(`PDP: Error fetching live lot info [lotRef=${lotRef}, memberId=${memberId}]`, error);
    dispatch(fetchLotLiveInfoError);
  }
};

export const addToWatchList = lotID => async (dispatch) => {
  const statusType = ASYNC_ACTION.ADD_TO_WATCHLIST_STATUS;
  try {
    dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.LOADING, type: statusType }));
    const { success, statusCode, statusMessage } = await PDPService.postAddToWatchlist(lotID);

    if (success) {
      dispatch(postAddToWatchListSuccess);
      dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.COMPLETE, type: statusType }));
    } else {
      logger.error('PDP: Bad response adding to watch list', { lotID, statusCode, statusMessage });
      dispatch(postAddToWatchListError);
      dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.FAILED, type: statusType }));
    }
  } catch (error) {
    logger.error(`PDP: Error adding to watch list [lotID=${lotID}]`, error);
    dispatch(postAddToWatchListError);
    dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.FAILED, type: statusType }));
  }
};

export const removeFromWatchList = lotID => async (dispatch) => {
  const statusType = ASYNC_ACTION.REMOVE_TO_WATCHLIST_STATUS;
  try {
    dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.LOADING, type: statusType }));
    const { success, statusCode, statusMessage } = await PDPService.postRemoveFromWatchlist(lotID);

    if (success) {
      dispatch(postRemoveFromWatchListSuccess);
      dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.COMPLETE, type: statusType }));
    } else {
      logger.error('PDP: Bad response removing from watch list', { lotID, statusCode, statusMessage });
      dispatch(postRemoveFromWatchListError);
      dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.FAILED, type: statusType }));
    }
  } catch (error) {
    logger.error(`PDP: Error removing from watch list [lotID=${lotID}]`, error);
    dispatch(postRemoveFromWatchListError);
    dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.FAILED, type: statusType }));
  }
};

export const fetchSellerInfo = (lotId, cookie, hostname) => async (dispatch) => {
  try {
    const {
      success,
      data: sellerInfo,
      statusCode,
      statusMessage
    } = await PDPService.getSellerInfo(lotId, cookie, hostname);

    if (success) {
      dispatch(fetchSellerInfoSuccess(sellerInfo));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching seller info',
        { lotId, statusCode, statusMessage }
      );
      dispatch(fetchSellerInfoError);
    }
  } catch (error) {
    logger.error(`PDP: Error fetching seller info [lotId=${lotId}]`, error);
    dispatch(fetchSellerInfoError);
  }
};

export const fetchSubscriptionInfo = (hostname, oasHeaders, memberId, catalogId) => async (dispatch) => {
  try {
    const headers = {
      ...oasHeaders,
      'member-id': memberId,
    };
    const {
      success,
      data: subscriptionInfo,
      statusCode,
      statusMessage
    } = await PDPService.getSubscriptionInfo(hostname, headers, catalogId);

    if (success) {
      dispatch(fetchSubscriptionInfoSuccess(subscriptionInfo));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching subscription info',
        { memberId, statusCode, statusMessage }
      );
      dispatch(fetchSubscriptionInfoError);
    }
  } catch (error) {
    logger.error(`PDP: Error fetching subscription info [memberId=${memberId}]`, error);
    dispatch(fetchSubscriptionInfoError);
  }
};

export const sendSellerMessage = (data, memberId) => async (dispatch) => {
  const statusType = ASYNC_ACTION.CONTACT_SELLER_STATUS;
  try {
    dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.LOADING, type: statusType }));
    const { success, statusCode, statusMessage } = await PDPService.postSellerMessage(data, memberId);

    if (success) {
      dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.COMPLETE, type: statusType }));
    } else {
      logger.error('PDP: Bad response sending seller message', { memberId, statusCode, statusMessage });
      dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.FAILED, type: statusType }));
    }
  } catch (error) {
    logger.error(`PDP: Error sending seller message [memberId=${memberId}]`, error);
    dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.FAILED, type: statusType }));
  }
};

export const fetchWatcherCount = catalogRef => async (dispatch) => {
  try {
    const { success, data: watcherCount, statusCode, statusMessage } = await PDPService.getWatcherCount(catalogRef);

    if (success) {
      dispatch(fetchWatcherCountSuccess(watcherCount));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching watcher count',
        { catalogRef, statusCode, statusMessage }
      );
      dispatch(fetchWatcherCountError);
    }
  } catch (error) {
    logger.error(`PDP: Error sending fetching watcher count [catalogRef=${catalogRef}]`, error);
    dispatch(fetchWatcherCountError);
  }
};

export const fetchUserContactInfo = async (dispatch) => {
  try {
    const { success, data: { data: contactInfo }, statusCode, statusMessage } = await PDPService.getUserContactInfo();

    if (success) {
      dispatch(fetchUserContactInfoSuccess(contactInfo));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching user contact info',
        { statusCode, statusMessage }
      );
      dispatch(fetchUserContactInfoError);
    }
  } catch (error) {
    logger.error('PDP: Error sending fetching user contact info', error);
    dispatch(fetchUserContactInfoError);
  }
};

export const fetchStatesForCountry = (countryCode, formType) => async (dispatch) => {
  try {
    const {
      success,
      data: { data: statesData },
      statusCode,
      statusMessage
    } = await PDPService.getStatesForTheGivenCountry(countryCode);

    if (success) {
      dispatch(fetchStatesSuccess(statesData, formType));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching states for country',
        { countryCode, statusCode, statusMessage }
      );
      dispatch(fetchStatesError);
    }
  } catch (error) {
    logger.error(`PDP: Error sending fetching states for country [countryCode=${countryCode}]`, error);
    dispatch(fetchStatesError);
  }
};

export const followUnFollowSeller = (houseRef, follow, authToken) => async (dispatch) => {
  const statusType = ASYNC_ACTION.FOLLOW_SELLER_STATUS;
  try {
    dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.LOADING, type: statusType }));
    const {
      success,
      statusCode,
      statusMessage
    } = await PDPService.postFollowUnFollowSeller(houseRef, follow, authToken);

    if (success) {
      dispatch(followUnFollowSellerSuccess(follow));
      dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.COMPLETE, type: statusType }));
    } else {
      logger.error('PDP: Bad response (un-)following seller', { houseRef, follow, statusCode, statusMessage });
      dispatch(followUnFollowSellerError);
      dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.FAILED, type: statusType }));
    }
  } catch (error) {
    logger.error(`PDP: Error (un-)following seller [houseRef=${houseRef}, follow=${follow}]`, error);
    dispatch(followUnFollowSellerError);
    dispatch(setAsyncStatus({ status: ASYNC_ACTION_STATUS_TYPE.FAILED, type: statusType }));
  }
};

export const fetchSellerFollowCount = houseRef => async (dispatch) => {
  try {
    const { data: followCount, success, statusCode, statusMessage } = await PDPService.getFollowSellerCount(houseRef);

    if (success) {
      dispatch(fetchSellerFollowCountSuccess(followCount));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching follow count',
        { houseRef, statusCode, statusMessage }
      );
      dispatch(fetchSellerFollowCountError);
    }
  } catch (error) {
    logger.error(`PDP: Error fetching follow count [houseRef=${houseRef}]`, error);
    dispatch(fetchSellerFollowCountError);
  }
};

export const fetchIsSellerFollowed = (houseRef, authToken) => async (dispatch) => {
  try {
    const {
      data: { sellerFollowed },
      success,
      statusCode,
      statusMessage
    } = await PDPService.getIsSellerFollowed(houseRef, authToken);

    if (success) {
      dispatch(fetchIsSellerFollowedSuccess(sellerFollowed));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching is seller followed',
        { houseRef, statusCode, statusMessage }
      );
      dispatch(fetchIsSellerFollowedError);
    }
  } catch (error) {
    logger.error(`PDP: Error fetching is seller followed [houseRef=${houseRef}]`, error);
    dispatch(fetchIsSellerFollowedError);
  }
};

export const fetchMemberBillingCards = () => async (dispatch) => {
  try {
    const {
      success,
      data: { data: memberBillingCardsData },
      statusCode,
      statusMessage
    } = await PDPService.getMemberBillingCards();

    if (success) {
      const { pageData: memberCards } = memberBillingCardsData;
      dispatch(fetchPDPRequestBillingCardsSuccess(memberCards));
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching member billing cards',
        { statusCode, statusMessage }
      );
      dispatch(fetchPDPRequestBillingCardsError);
    }
  } catch (error) {
    logger.error('PDP: Error fetching member billing cards', error);
    dispatch(fetchPDPRequestBillingCardsError);
  }
};

export const fetchRequestForApproval = ({ catalogRef, lotRef, bidAmount }) => async (dispatch) => {
  try {
    const {
      success,
      data: { data: ccInfo },
      statusCode,
      statusMessage
    } = await PDPService.getRequestForApproval(catalogRef, lotRef, bidAmount);

    if (success) {
      dispatch(fetchPDPRequestForApprovalSuccess(ccInfo));
      if (ccInfo.pageData.showCreditCardSection || ccInfo.pageData.catalog.hasPaymentProcessing) {
        await dispatch(fetchMemberBillingCards());
      }
    } else {
      logger.log(
        (statusCode === 404 ? 'info' : 'error'),
        'PDP: Bad response fetching request for approval',
        { catalogRef, lotRef, bidAmount, statusCode, statusMessage }
      );
      dispatch(fetchPDPRequestForApprovalError);
    }
  } catch (error) {
    logger.error('PDP: Error fetching request for approval '
      + `[catalogRef=${catalogRef}, lotRef=${lotRef}, bidAmount=${bidAmount}]`, error);
    dispatch(fetchPDPRequestForApprovalError);
  }
};

export const fetchRequestForApprovalInit = ({ catalogRef, lotRef, bidAmount }) => async (dispatch) => {
  try {
    dispatch(setPDPIsLoading());
    const {
      success,
      data: { data: approvalData },
      statusCode,
      statusMessage
    } = await PDPService.getRequestForApprovalInit(catalogRef, lotRef);

    if (success) {
      if (approvalData.pageData.showRFA) {
        await dispatch(fetchRequestForApproval({ catalogRef, lotRef, bidAmount }));
      }
      dispatch(fetchPDPRequestForApprovalInitSuccess(approvalData));
    } else {
      logger.error(
        'PDP: Bad response fetching request for approval init',
        { catalogRef, lotRef, bidAmount, statusCode, statusMessage }
      );
      dispatch(fetchPDPRequestForApprovalInitError);
    }
  } catch (error) {
    logger.error('PDP: Error fetching request for approval init '
      + `[catalogRef=${catalogRef}, lotRef=${lotRef}, bidAmount=${bidAmount}]`, error);
    dispatch(fetchPDPRequestForApprovalInitError);
  }
};

export const updateBidTotal = ({ lotRef, bidAmount, conciergeBid = false }) => async (dispatch) => {
  try {
    const {
      success,
      data: { data: bidData },
      statusCode,
      statusMessage
    } = await PDPService.postUpdateBidTotal(lotRef, bidAmount, conciergeBid);

    if (success) {
      dispatch(postPDPUpdateBidTotalSuccess(bidData));
    } else {
      logger.error('PDP: Bad response calling update bid total', { lotRef, bidAmount, statusCode, statusMessage });
      dispatch(postPDPUpdateBidTotalError);
    }
  } catch (error) {
    logger.error(`PDP: Error calling update bid total [lotRef=${lotRef}, bidAmount=${bidAmount}]`, error);
    dispatch(postPDPUpdateBidTotalError);
  }
};

export const notifyMe = (lotId, authToken) => async (dispatch) => {
  try {
    const { success, statusCode, statusMessage } = await PDPService.postNotifyMe(lotId, authToken);

    if (success) {
      dispatch(postNotifyMeSuccess(success));
      // handle successful follow heap event
      try {
        // eslint-disable-next-line no-undef
        heap.track('Past Lot Alert - Sign-up - Success Event');
      } catch (error) {
        logger.warn('Past Lot alert heap tracking error', error);
      }
    } else {
      logger.error('Unsuccessful response from past lot alert sign-up', { lotId, statusCode, statusMessage });
      dispatch(postNotifyMeError);
    }
  } catch (error) {
    logger.error(`Error during past lot alert sign-up [lotId=${lotId}]`, error);
    dispatch(postNotifyMeError);
  }
};

export const fetchArtistInfo = (hostname, artistRef) => async (dispatch) => {
  try {
    const {
      success,
      data: artistInfo,
      statusCode,
      statusMessage
    } = await PDPService.getArtistInfo(hostname, artistRef);

    if (success) {
      dispatch(fetchArtistInfoSuccess(artistInfo));
    } else {
      logger.error('PDP: Unsuccessful response fetching artist info', { artistRef, statusCode, statusMessage });
      dispatch(fetchArtistInfoError);
    }
  } catch (error) {
    logger.error(`PDP: Error fetching artist info [artistRef=${artistRef}]`, error);
    dispatch(fetchArtistInfoError);
  }
};

export const fetchBidInput = (lotRef, bidAmount, conciergeBid) => async (dispatch) => {
  try {
    await PDPService.getBidInput(lotRef, bidAmount);
    const {
      success,
      data: { data: orderBidResponse },
      statusCode,
      statusMessage
    } = await PDPService.postOrderBid(lotRef, bidAmount, conciergeBid);

    if (success) {
      const { success: bidSubmitSuccessful, errorMessage } = get(orderBidResponse, 'orderBidResponse', {});
      if (bidSubmitSuccessful) {
        dispatch(pdpSetBidSubmitErrorMessage(''));
      } else {
        logger.error('PDP: orderBidResponse response contained errors', { lotRef, bidAmount, errorMessage });
      }
      return { bidSubmitSuccessful, errorMessage };
    }

    logger.error('PDP: Unsuccessful response from postOrderBid', { lotRef, bidAmount, statusCode, statusMessage });
    return { bidSubmitSuccessful: false, errorMessage: BID_SUBMIT_FE_ERROR_MSG.UNEXPECTED_ERROR };
  } catch (error) {
    logger.error(`PDP: Error fetching or placing bid [lotRef=${lotRef}, bidAmount=${bidAmount}]`, error);
    return { bidSubmitSuccessful: false, errorMessage: BID_SUBMIT_FE_ERROR_MSG.UNEXPECTED_ERROR };
  }
};

export const submitRFA = rfaData => async (dispatch) => {
  try {
    const rfaResponse = await PDPService.postSubmitRFA(rfaData);
    const { success, statusCode, statusMessage, data: { data: submitRFAData } } = rfaResponse;

    if (success) {
      const { pageData: { success: bidSubmitSuccessful, errorMessage, rfaApprovalStatus } } = submitRFAData;
      if (bidSubmitSuccessful) {
        dispatch(pdpSetBidSubmitErrorMessage(''));
      } else {
        logger.error('PDP: submitRFA response contained errors', { rfaData, errorMessage });
      }

      return { bidSubmitSuccessful, rfaApprovalStatus, errorMessage };
    }

    let errorMessage = '';
    if (statusCode === REST_RESPONSE_STATUS_CODES.UNPROCESSABLE && get(rfaResponse, 'data.errors.length', 0)) {
      const { pageData: { KYCBidLimited, bidLimit, kycBidLimit, kycStatus } } = submitRFAData;
      dispatch(pdpSetBidSubmitErrorsData({ KYCBidLimited, bidLimit, kycBidLimit, kycStatus }));
      errorMessage = get(rfaResponse, 'data.errors[0].validationErrors.orderBidError', BID_SUBMIT_FE_ERROR_MSG.UNEXPECTED_ERROR);
    } else {
      const fieldErrorMessage = get(submitRFAData, 'oasError.errorFields[0].fieldErrorMessage', '');
      errorMessage = fieldErrorMessage || get(
        submitRFAData,
        'oasError.message',
        BID_SUBMIT_FE_ERROR_MSG.UNEXPECTED_ERROR
      );
    }

    logger.error('PDP: Unsuccessful response from submitRFA', { rfaData, statusCode, statusMessage, errorMessage });
    return { bidSubmitSuccessful: false, errorMessage };
  } catch (error) {
    logger.error(`PDP: Error submitting RFA [rfaData=${JSON.stringify(rfaData)}]`, error);
    return { bidSubmitSuccessful: false, errorMessage: '' };
  }
};

export const fetchVIPStatus = (hostname, authToken) => async (dispatch) => {
  try {
    const { success, data, statusCode, statusMessage } = await PDPService.getVIPBadge(hostname, authToken);
    if (success) {
      dispatch(fetchVIPStatusSuccess(data));
    } else {
      logger.error('PDP: Unsuccessful response fetching VIP status', { statusCode, statusMessage });
      dispatch(fetchVIPStatusError);
    }
  } catch (error) {
    logger.error('PDP: Error fetching VIP status', error);
    dispatch(fetchVIPStatusError);
  }
};

export const lotSMSOptIn = async (lotRef, countryCode, phoneNo) => {
  await PDPService.postLotSMSOptIn(lotRef, countryCode, phoneNo);
};

export const catalogSMSOptIn = async (catalogRef, countryCode, phoneNo) => {
  await PDPService.postCatalogSMSOptIn(catalogRef, countryCode, phoneNo);
};
