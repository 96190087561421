import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import platform from 'platform';
import { AndroidBanner } from 'inv-common-components';

// Elements
import LoggedInMenu from 'src/components/header/loggedInMenu';
import LoggedOutMenu from 'src/components/header/LoggedOutMenu';
import AlgoliaSearchBar from 'src/components/header/searchBar/algoliaSearchBar/algolia-search-bar';
import AlgoliaSearchBarV1 from 'src/components/header/searchBar/algoliaSearchBarV1/algoliaSearchBarV1';
import AlgoliaSearchBarV2 from 'src/components/header/searchBar/algoliaSearchBarV2/algoliaSearchBarV2';
import { sendABToHeap } from 'src/components/header/searchBar/config';
import { getImageBaseDomain } from 'src/utils/common/helpers';
import NavBar from 'src/components/header/NavBar';
import CategoryModal from 'src/components/header/modals/CategoryModal';
import { setIsMobileUserAgent, setWindowWidth } from 'src/actions/inv-common.actions';
import SEOCategoryModal from 'src/components/header/modals/SEOCategoryModal';
import OnboardingEyebrow from 'src/components/header/OnBoardingEyeBrow';
import { LBCAnnouncement } from 'src/components/header/LBCAnnouncement';
import {
  DesktopContainer,
  LogoContainer,
  SearchContainer,
  MobileSearchContainer,
  LoginContainer,
  NavBarContainer,
  DesktopContainerV1
} from './Header.styled';
import { pageLoadHeapEvents } from './PageLoad';

/**
 * Header component.
 *
 * @returns {JSX.Element} The rendered header component.
 */
function Header() {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => get(state, 'invReactCommon.isMobile', false));
  const searchTypeAheadVersion = useSelector((state) => get(state, 'invReactCommon.searchTypeAheadCookie', false));
  const isLoggedIn = useSelector((state) => get(state, 'invReactCommon.sessionInfo.isLoggedIn', false));
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  const isVIP = useSelector((state) => get(state, 'invReactCommon.isVIP', false));
  const headers = useSelector((state) => get(state, 'invReactCommon.reqHeaders', {}));
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [showStalePwdMsg, setShowStalePwdMsg] = useState(false);
  const [showOnboardingEyebrow, setShowOnboardingEyebrow] = useState(false);
  const [showAndroidBanner, setShowAndroidBanner] = useState(false);
  const HeaderDesktopContainer = ['v1', 'v2'].includes(searchTypeAheadVersion) ? DesktopContainerV1 : DesktopContainer;
  const searchContainerId = searchTypeAheadVersion === 'v1' ? 'searchBarContainerV1' : 'searchBarContainer';

  useEffect(() => {
    sendABToHeap('Search Type Ahead', 0);
  }, []);

  useEffect(() => {
    //choose the screen size
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(setIsMobileUserAgent(true));
      } else {
        dispatch(setIsMobileUserAgent(false));
      }
      dispatch(setWindowWidth(window.innerWidth));
    };
    window.addEventListener('resize', handleResize);
    dispatch(setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    // Read the value from session storage. If true, show the warning message.
    // Reset the value to make sure only showing once per session.
    const isStalePwd = get(window, 'sessionStorage.suggestPasswordUpdate', 'false');
    if (isStalePwd === 'true') {
      setShowStalePwdMsg(true);
    }
    window.sessionStorage.setItem('suggestPasswordUpdate', false);
  }, []);

  useEffect(() => {
    pageLoadHeapEvents(isLoggedIn, headers);
  }, []);

  useEffect(() => {
    window.addEventListener('DOMContentLoaded', () => {
      if (document.getElementById('onboarding-eyebrow')) return;
      setShowOnboardingEyebrow(true);
    });

    if (String(platform.os).toLowerCase().match('android')) {
      setShowAndroidBanner(true);
    }
  }, []);

  const getSearchBarComponent = () => {
    if (searchTypeAheadVersion === 'v1') {
      return <AlgoliaSearchBarV1 isMobile={isMobile} />;
    } if (searchTypeAheadVersion === 'v2') {
      return <AlgoliaSearchBarV2 isMobile={isMobile} />;
    }
    return <AlgoliaSearchBar isMobile={isMobile} />;
  };

  return (
    <>
      {showAndroidBanner && <AndroidBanner />}
      { isVIP && <LBCAnnouncement />}
      { showOnboardingEyebrow && <OnboardingEyebrow /> }
      <header id="headerV4">
        {isMobile ? (
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-7 col-md-3">
                  <div className="navbar-header d-flex align-items-center justify-content-start">
                    <button
                      id="taxonomyOptionsBtn"
                      type="button"
                      className="navbar-toggler btn-modal-trigger d-block d-md-none mt-2"
                      data-direction="left"
                      data-modal-trigger="taxonomy-options"
                      aria-expanded="false"
                      aria-label="Navigation Menu"
                      aria-hidden="true"
                      onClick={() => setIsCategoryModalOpen(true)}
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="fa fa-bars fa-2x" />
                    </button>
                    <a href="/" className="logo mt-1 mt-md-4" itemProp="url">
                      <img className="img-fluid" src="https://image.invaluable.com/static/header/IN_Red32.svg" alt="Invaluable" itemProp="logo" />
                    </a>
                  </div>
                  <CategoryModal isModalOpen={isCategoryModalOpen} closeModal={() => setIsCategoryModalOpen(false)} />
                </div>
                {isLoggedIn ? (
                  <LoggedInMenu />
                ) : (
                  <LoggedOutMenu />
                )}
              </div>
            </div>
            <div className="search-xs-container container-md">
              <MobileSearchContainer id="mobileSearchContainer">
                {getSearchBarComponent()}
              </MobileSearchContainer>
            </div>
          </>
        ) : (
          <>
            <HeaderDesktopContainer className="container-fluid">
              <LogoContainer>
                <a href="/" className="logo" itemProp="url">
                  <img
                    className="img-fluid"
                    src={`${getImageBaseDomain(isProd)}/static/header/IN_Red32.svg`}
                    alt="Invaluable"
                    itemProp="logo"
                  />
                </a>
              </LogoContainer>
              {/* Search Container placeholder */}
              <SearchContainer id={searchContainerId}>
                {getSearchBarComponent()}
              </SearchContainer>
              {/* User menu/login/signup/favorites placeholder */}
              <LoginContainer className="log-signup-container">
                {isLoggedIn ? (
                  <LoggedInMenu />
                ) : (
                  <LoggedOutMenu />
                )}
              </LoginContainer>
              {/* container for search type ahead dropdown */}
              <div id="suggestion-dropdown-container" />
            </HeaderDesktopContainer>
            {/* Lower Categoreis Navbar placeholder */}
            <NavBarContainer>
              <NavBar />
            </NavBarContainer>
          </>
        )}
        {/*  Update Password */}
        {showStalePwdMsg && (
        <div className="container">
          <div id="stalePwdMsg" className="alert alert-warning alert-dismissible space-top-wide mt-4" role="alert">
            <button className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowStalePwdMsg(false)}>
              <span aria-hidden="true">×</span>
            </button>
            <i className="fa fa-warning" />
            <span className="alert-content">Your password has not been updated in a while. To improve the security of your account, please update your password now. <a className="text-nowrap" href="/my-account/profile">Update Password</a>.</span>
          </div>
        </div>
        )}
        <SEOCategoryModal />
      </header>
    </>
  );
}

export default Header;
