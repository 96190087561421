import React, { useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, BreadcrumbItem } from 'reactstrap';

import { Breadcrumbs } from 'src/components/artist-detail';
import ArtistDetailTabsContainer from 'src/components/artist-detail/artist-detail-tabs-container/ArtistDetailTabsContainer';
import ArtistDetailWrapper from 'src/components/common/ArtistDetailWrapper';
import { algoliaSearchClientNormalized } from 'src/utils/algolia-helper';
import { selectArtistBioInfo } from 'src/selectors/artist-detail';
import { getArtistSeoFriendlyPath } from 'src/utils/artist-detail';
import { heapTrack } from 'src/utils/common/helpers';
import { NEW_ARTIST_PAGE_VIEW_HEAP_EVENT } from 'src/utils/constants';

function ArtistUpcomingLots(
  {
    searchState: algoliaSearchState,
    artistRef,
    resultsState,
    location,
    indexName,
    isBot,
  }
) {
  const { displayName: artistDisplayName, firstName, lastName } = useSelector(selectArtistBioInfo);
  const history = useHistory();
  const artistUrl = getArtistSeoFriendlyPath({ firstName, lastName, artistRef });

  useEffect(() => {
    heapTrack(NEW_ARTIST_PAGE_VIEW_HEAP_EVENT.UPCOMING_PAGE);
  }, []);

  return (
    <Fragment>
      <Container fluid>
        <Breadcrumbs pathName={history.location.pathname}>
          <BreadcrumbItem key={artistDisplayName}><a href={artistUrl}>{artistDisplayName}</a></BreadcrumbItem>
          <BreadcrumbItem key="For Sale at Auction" active>For Sale at Auction</BreadcrumbItem>
        </Breadcrumbs>
      </Container>
      <ArtistDetailWrapper>
        <ArtistDetailTabsContainer
          {...{
            searchClient: algoliaSearchClientNormalized(isBot),
            resultsState,
            artistRef,
            location,
            algoliaSearchState,
            indexName,
          }}
        />
      </ArtistDetailWrapper>
    </Fragment>
  );
}

ArtistUpcomingLots.propTypes = {
  artistRef: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  searchState: PropTypes.objectOf(PropTypes.any),
  resultsState: PropTypes.objectOf(PropTypes.any),
};

ArtistUpcomingLots.defaultProps = {
  searchState: {},
  resultsState: {},
};

export default ArtistUpcomingLots;
