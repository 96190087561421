import React from 'react';

function Cross({ onClick }) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 2L2 14M2 2L14 14"
        stroke="#28292A"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default Cross;
