import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getImageBaseDomain } from 'src/utils/common/helpers';
import { trackClick } from '../utils';

function FollowedArtists({ followedArtists }) {
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  return (
    <div className="col-md-12 col-lg-12 col-xl-3 side-border">
      <div className="my-invaluable-section-header">
        <a
          id="heap-tag-id"
          className="following-artist-tag"
          href="/my-account/artists"
          onClick={() => trackClick('Artist')}
        >Following <i className="fa fa-chevron-right" />
        </a>
      </div>
      {
        followedArtists.length > 0 ? followedArtists.map(artist => (
          <div className="saved-searches" key={artist.artistRef}>
            <div className="circled-thumb">
              <img
                src={artist.thumbFileUrl ? artist.thumbFileUrl
                  : `${getImageBaseDomain(isProd)}/static/noimage_noborder.png`}
                alt="artist"
              />
            </div>
            <div className="saved-search-details flex-center">
              <a
                className="artist-href following-artist-tag"
                href={`/artist/${artist.artistRef}`}
                onClick={() => trackClick('Artist')}
              >{artist.displayName}
              </a>
              {artist.countItems > 0 && <span className="new-tag">{artist.countItems} new</span>}
            </div>
          </div>
        )) : <p>You are not following any artists.</p>
      }
      {
        followedArtists.length > 0 && (
          <a
            className="following-artist-tag following-link"
            href="/my-account/artists"
            onClick={() => trackClick('Artist')}
          >View all new items
          </a>
        )
      }
    </div>
  );
}

export default FollowedArtists;
