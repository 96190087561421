import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { getImageBaseDomain } from 'src/utils/common/helpers';
import { FaBars } from 'src/containers/Header/Header.styled';
import CategoryPopover from './popovers/CategoryPopover';
import { categoryData } from './CategoryData';

function NavBar() {
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));

  return (
    <Fragment>
      <nav className="navbar d-md-block container-fluid">
        <div className="navbar-nav-links">
          <a href="/auctions/" className="artists-link">Auctions</a>
          <a href="/auction-house" className="artists-link">Auction Houses</a>
          <a href="/artists/" className="artists-link notranslate">Artists</a>
          <span className="divider" />

          <a
            href="/search?queryContext=PremiumLots_all&sort=bidsDesc&query=%2520&keyword=%2520"
            className="view-all invaluable-picks-link d-none"
          >
            <img
              src={`${getImageBaseDomain(isProd)}/static/invaluable_picks.svg`}
              alt="Invaluable picks logo"
            />
            <span>
              INVALUABLE PICKS
            </span>
          </a>
          {categoryData.map((category) => {
            if (category.targetId !== 'superCategory') {
              return (
                <>
                  <a
                    popover-placement="bottom"
                    data-toggle="popover"
                    data-container="nav"
                    className={category.className}
                    data-popover="fine-art-link"
                    data-num-cols="1"
                    href={category.titleHref}
                    aria-expanded="false"
                    id={category.targetId}
                  >
                    {category.title}
                  </a>
                  <CategoryPopover
                    targetId={category.targetId}
                    linkData={category.linkData}
                    viewAllTitle={category.title}
                    titleHref={category.titleHref}
                  />
                </>
              );
            }
            return (
              <>
                <button
                  aria-label="More Categories"
                  className="btn more-link notranslate"
                  aria-expanded="false"
                  id="superCategory"
                >
                  <FaBars className="fa fa-bars" />
                </button>
                <CategoryPopover
                  targetId={category.targetId}
                  linkData={category.linkData}
                />
              </>
            );
          })}
        </div>
      </nav>

      <div className="container" />
    </Fragment>
  );
}

export default NavBar;
