import { useSelector } from 'react-redux';
import aa from 'search-insights';

import { ALGOLIA_METHODS } from '../utils/pdp/constants';
import { selectAlgoliaParams } from '../selectors/pdp';
import { selectMemberId } from '../selectors/inv-common';

const useInitializeAlgolia = () => {
  const memberId = useSelector(selectMemberId);
  const { oasNodeSid } = useSelector(selectAlgoliaParams);

  aa('getUserToken', null, (_, userToken) => {
    if (userToken) return;
    aa(ALGOLIA_METHODS.INIT, {
      appId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
      apiKey: process.env.REACT_APP_ALGOLIA_KEY,
    });
    aa(ALGOLIA_METHODS.SET_USER_TOKEN, memberId === 0 ? oasNodeSid : memberId.toString());
  });
};

export default useInitializeAlgolia;
