import React from 'react';
import { get } from 'lodash';

export function MyAccountItem({ link, userCounts }) {
  const { label, link: { linkTo }, countKey } = link || {};
  const count = get(userCounts, countKey, 0);
  return (
    <div className="item mb-3">
      <a className="user-menu-tag" href={linkTo}>
        {label} {count > 0 && ` (${count})`}
      </a>
      <i className="fa fa-chevron-right" aria-hidden="true" />
    </div>
  );
}
