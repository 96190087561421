import React from 'react';
import PropType from 'prop-types';
import { connectSortBy } from 'react-instantsearch-dom';

import Select from 'src/components/common/Select';

function SortByFilter({ items: sortByFilterOptions, refine, currentRefinement, optionValueKey, optionDisplayKey }) {
  return (
    <div className="sort-by-filter">
      <span className="sort-by-text">Sort By: </span>
      <Select
        options={sortByFilterOptions}
        optionValueKey={optionValueKey}
        optionDisplayKey={optionDisplayKey}
        value={currentRefinement}
        onChange={(sortValue) => refine(sortValue)}
      />
    </div>
  );
}

SortByFilter.propTypes = {
  items: PropType.array.isRequired,
  refine: PropType.func.isRequired,
  currentRefinement: PropType.string.isRequired,
  optionValueKey: PropType.string.isRequired,
  optionDisplayKey: PropType.string.isRequired,
};

export default connectSortBy(SortByFilter);
