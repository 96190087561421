export const ALGOLIA_LOTS_COMMON_CONFIG = {
  highlightPostTag: '</ais-highlight-0000000000>',
  highlightPreTag: '<ais-highlight-0000000000>',
  hitsPerPage: 12,
  maxValuesPerFacet: 50,
  page: 0,
  attributesToRetrieve: [
    'lotTitle', 'currencyCode', 'currencySymbol', 'lotNumber', 'lotRef', 'bidCount',
    'photoPath', 'estimateLow', 'estimateHigh', 'dateTimeLocal', 'endTimeUTCUnix',
    'houseName',
  ],
  facets: [
    'hasImage',
    'Jewelry',
    'supercategoryName',
    'dateTimeUTCUnix',
    'houseName',
    'countryName',
    'currencyCode',
  ]
};

export const SEARCH_TYPE_AHEAD = 'SEARCH_TYPE_AHEAD';
export const MAXIMUM_RETRIES_TO_FETCH_POPULAR_ARTIST = 2;
export const MY_INVALUABLE = 'my_inv_version';
export const IS_PPR_VIEW = 'isPPRView';
