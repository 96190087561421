import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { selectArtistNotableSoldLots } from 'src/selectors/artist-detail';
import { getAuctionLotSeoFriendlyURL } from 'src/components/artist-detail/helpers';
import { formatCurrency } from 'src/utils/algolia-helper';

function NotableSoldLots() {
  const notableSoldLots = useSelector(selectArtistNotableSoldLots);

  const hasNotableSoldLots = !!notableSoldLots.length;

  if (!hasNotableSoldLots) return null;

  return (
    <div className="notable-sold-container">
      <h3>Notable Sold Lots</h3>
      <div className="notable-sold-card-holder">
        <Row>
          { notableSoldLots
            .map(({ lotID, largePhotoUrl, lotTitle, currencyCode, lotRefPrice, lotPriceNative, lotNum, lotRef }) => {
              const lotSoldAtPrice = lotPriceNative || lotRefPrice;
              const auctionLotSeoFriendlyURL = getAuctionLotSeoFriendlyURL(lotTitle, lotNum, lotRef);

              return (
                <Col
                  xs={6}
                  md={3}
                  key={lotID}
                >
                  <div className="notable-sold-card card-xs-mb">
                    <div className="thumb-container">
                      <a target="_blank" rel="noreferrer" href={auctionLotSeoFriendlyURL}>
                        <img alt={lotTitle} src={largePhotoUrl.href} />
                      </a>
                    </div>
                    <h4>
                      <a target="_blank" rel="noreferrer" href={auctionLotSeoFriendlyURL}>
                        {lotTitle}
                      </a>
                    </h4>
                    <strong>Sold: {`${currencyCode} ${formatCurrency(lotSoldAtPrice)} `}</strong>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
}

export default NotableSoldLots;
