import { combineReducers } from 'redux';

import artist from './artist.reducer';
import auctions from './auctions.reducer';
import categories from './categories.reducer';
import homepage from './homepage.reducer';
import search from './search.reducer';
import catalog from './catalog.reducer';
import auctionHouse from './auctionHouse.reducer';
import artistDirectoryPage from './artist-directory-page.reducer';
import artistDetail from './artist-detail.reducer';
import invReactCommon from './inv-react/inv-react.reducer';
import pdp from './pdp/pdp.reducer';
import rfa from './rfa.reducer';

const reducers = {
  artist,
  auctions,
  categories,
  search,
  homepage,
  catalog,
  pdp,
  auctionHouse,
  artistDirectoryPage,
  artistDetail,
  invReactCommon,
  rfa,
};

export default combineReducers(reducers);
