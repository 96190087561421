import bugsnag from '@bugsnag/js';

const pjson = require('../package.json');

const bugsnagClient = bugsnag('09bae844fe748d7ef4df187e937b1e7f');
if (process.env.NODE_ENV === 'development' && window) {
  window.bugsnagClient = bugsnagClient;
}
bugsnagClient.app.releaseStage = process.env.NODE_ENV;
bugsnagClient.app.version = pjson.version;

export default bugsnagClient;
