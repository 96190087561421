import React, { Fragment, useMemo } from 'react';
import moment from 'moment';
import {
  connectCurrentRefinements,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

import { metersToMiles, mapFacetName } from 'src/utils/algolia-helper';
import ClearRefinements from '../clear-refinements';

const operatorType = {
  min: '≥',
  max: '≤',
};

function CurrentRefinements({
  items,
  rangeInput,
  refine,
  searchParams,
  searchState,
  setRangeInput,
  subCategories,
  onDeleteRefinementItem,
  onSearchStateChange,
}) {
  const searchLocationParams = useMemo(() => {
    const { aroundRadius, locationName } = searchParams;

    return {
      aroundRadius,
      locationName
    };
  }, [searchParams]);

  const shouldDisplayRefinements = useMemo(
    () => (items && items.length)
      || (subCategories && subCategories.length)
       || (searchLocationParams.locationName),
    [items, searchLocationParams, subCategories]
  );

  const handleRemoveSubCategoryClick = (subCat) => (event) => {
    event.preventDefault();
    const subCatStart = window.location.href.indexOf('subcategoryRef=');
    const subCatEnd = window.location.href.indexOf('&', subCatStart);
    const firstPart = window.location.href.slice(0, subCatStart);
    const secondPart = subCatEnd === -1 ? '' : window.location.href.slice(subCatEnd);
    let subCatString = subCatEnd === -1
      ? window.location.href.slice(subCatStart)
      : window.location.href.slice(subCatStart, subCatEnd);
    const filterString = `${subCat.subCatRef}%2B${encodeURIComponent(subCat.subCatTitle)}`;
    subCatString = subCatString.replace(filterString, '');

    if (subCatString.charAt(15) === '_') {
      subCatString = subCatString.replace('_', '');
    }

    subCatString = subCatString.replace(/_$/, '');
    subCatString = subCatString.replace('__', '_');

    if (subCatString === 'subcategoryRef=') {
      subCatString = '';
    }

    window.location = `${firstPart}${subCatString}${secondPart}`;
  };

  function handleRemove(type, attribute) {
    onSearchStateChange({
      ...searchState,
      range: {
        ...searchState.range,
        [attribute]: {
          ...searchState.range[attribute],
          [type]: undefined,
        },
      },
    });
    setRangeInput({ ...rangeInput, [type]: undefined });
  }

  function renderRefinement(item, label, value) {
    return (
      <span className="ais-CurrentRefinements-category">
        {(typeof value === 'boolean')
          ? <span className="ais-CurrentRefinements-categoryLabel">{label}</span>
          : (
            <span className="ais-CurrentRefinements-categoryLabel">
              {`${label}: ${value}` }
            </span>
          )}
        <button
          type="button"
          className="ais-CurrentRefinements-delete"
          onClick={(event) => {
            event.preventDefault();
            refine(item.value);
          }}
        >✕
        </button>
      </span>
    );
  }

  function renderRangeFilter(item, label, value, operator, type) {
    return (
      <span className="ais-CurrentRefinements-category">
        <span className="ais-CurrentRefinements-categoryLabel">
          {`${label}: ${operator} ${value}` }
        </span>
        <button
          type="button"
          className="ais-CurrentRefinements-delete"
          onClick={() => handleRemove(type, item.attribute)}
        >✕
        </button>
      </span>
    );
  }

  function renderCurrentRefinement(item) {
    if (item.items) {
      return null;
    }

    if (item.attribute === 'priceResult' || item.attribute === 'currentBid') {
      const inputRangeValues = [
        ...(searchState.range[item.attribute].min
          ? [{ label: 'min', value: searchState.range[item.attribute].min }]
          : []
        ),
        ...(searchState.range[item.attribute].max
          ? [{ label: 'max', value: searchState.range[item.attribute].max }]
          : []
        ),
      ];

      return inputRangeValues.map(ele => (
        <span className="ais-CurrentRefinements-item">
          { renderRangeFilter(item, mapFacetName(item.attribute), ele.value, operatorType[ele.label], ele.label) }
        </span>
      ));
    }

    if (item.attribute === 'dateTimeUTCUnix') {
      const min = item.currentRefinement.min || moment().unix();
      const max = item.currentRefinement.max || moment().unix();
      const value = `${moment.unix(min).format('MM/DD/YY')} - ${moment.unix(max).format('MM/DD/YY')}`;

      return (
        <span className="ais-CurrentRefinements-item">
          { renderRefinement(item, 'Date', value) }
        </span>
      );
    }

    return (
      <span className="ais-CurrentRefinements-item">
        { renderRefinement(item, mapFacetName(item.attribute), item.currentRefinement) }
      </span>
    );
  }

  function renderLocationRefinement() {
    if (!searchLocationParams.locationName) {
      return null;
    }

    const { aroundRadius, locationName } = searchLocationParams;
    const aroundDistance = aroundRadius > 0 ? `${metersToMiles(aroundRadius)} miles` : 'Any distance';

    return (
      <ul
        className="ais-CurrentRefinements-list"
      >
        <li className="ais-CurrentRefinements-item">
          <span
            className="ais-CurrentRefinements-label"
            style={{ textTransform: 'capitalize' }}
          >
            Location
          </span>
          <span className="ais-CurrentRefinements-category">
            <span className="ais-CurrentRefinements-categoryLabel">
              {aroundDistance} of {locationName}
            </span>
            <button
              type="button"
              className="ais-CurrentRefinements-delete"
              onClick={onDeleteRefinementItem}
            >✕
            </button>
          </span>
        </li>
      </ul>
    );
  }

  return (
    <div className="current-refinements">
      {shouldDisplayRefinements
        && (
        <Fragment>
          <div className="clear-filters-holder">
            <h3>Your Selection</h3>
            <ClearRefinements
              rangeInput={rangeInput}
              setRangeInput={setRangeInput}
              onClear={onDeleteRefinementItem}
            />
          </div>
          <div className="ais-CurrentRefinements">
            {
              items && items.map((item) => {
                const name = mapFacetName(item.attribute);
                return (
                  <Fragment key={item.label}>
                    {item.items && item.items.map(nested => (
                      <ul
                        key={nested.label}
                        className="ais-CurrentRefinements-list"
                      >
                        <li className="ais-CurrentRefinements-item">
                          <span
                            className="ais-CurrentRefinements-label"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {name}
                          </span>
                          <span className="ais-CurrentRefinements-category">
                            <span className="ais-CurrentRefinements-categoryLabel">
                              { decodeURIComponent(nested.label) }
                            </span>
                            <button
                              type="button"
                              className="ais-CurrentRefinements-delete"
                              onClick={(event) => {
                                event.preventDefault();
                                refine(nested.value);
                              }}
                            >✕
                            </button>
                          </span>
                        </li>
                      </ul>
                    ))}
                    {renderCurrentRefinement(item)}
                  </Fragment>
                );
              })
}
            {renderLocationRefinement()}
            {
              subCategories.length > 0 && subCategories.map(item => (
                <Fragment key={item.subCatTitle}>
                  {item
                  && (
                  <ul
                    key={item.subCatTitle}
                    className="ais-CurrentRefinements-list"
                  >
                    <li className="ais-CurrentRefinements-item">
                      <span
                        className="ais-CurrentRefinements-label"
                        style={{ textTransform: 'capitalize' }}
                      >
                        Sub Category
                      </span>
                      <span className="ais-CurrentRefinements-category">
                        <span className="ais-CurrentRefinements-categoryLabel">
                          { decodeURIComponent(item.subCatTitle) }
                        </span>
                        <button
                          type="button"
                          className="ais-CurrentRefinements-delete"
                          onClick={handleRemoveSubCategoryClick(item)}
                        >✕
                        </button>
                      </span>
                    </li>
                  </ul>
                  )}
                </Fragment>
              ))
}
          </div>
        </Fragment>
        )}
    </div>
  );
}

CurrentRefinements.defaultProps = {
  rangeInput: null,
  searchParams: {},
  setRangeInput: null,
  subCategories: [],
  onDeleteRefinementItem: Function.prototype,
};

CurrentRefinements.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  refine: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({}),
  searchState: PropTypes.shape({
    range: PropTypes.shape({}),
  }).isRequired,
  rangeInput: PropTypes.shape({}),
  setRangeInput: PropTypes.func,
  onSearchStateChange: PropTypes.func.isRequired,
  onDeleteRefinementItem: PropTypes.func,
  subCategories: PropTypes.arrayOf(PropTypes.shape({
    subCatRef: PropTypes.string,
    subCatTitle: PropTypes.string,
  })),

};

export default connectCurrentRefinements(CurrentRefinements);
