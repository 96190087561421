import { STATIC_POPULAR_ARTISTS } from 'src/components/common/constants';
import {
  FETCH_SESSION_INFO_SUCCESS,
  FETCH_SESSION_INFO_ERROR,
  FETCH_FOLLOW_THIS_ARTIST_SUCCESS,
  FETCH_FOLLOW_THIS_ARTIST_ERROR,
  SET_FOLLOW_THIS_ARTIST_SUCCESS,
  SET_FOLLOW_THIS_ARTIST_ERROR,
  SET_USER_POPULAR_ARTISTS_DATA,
  SET_IS_PROD_ENV,
  SET_HOST_NAME,
  SET_USER_COUNTRY_CODE,
  ADD_CONVERSION_RATE,
  FETCH_SIMILAR_ARTISTS_DATA_SUCCESS,
  FETCH_SIMILAR_ARTISTS_DATA_ERROR,
  FETCH_USER_ARTIST_FOLLOW_STATUS_SUCCESS,
  FETCH_USER_ARTIST_FOLLOW_STATUS_ERROR,
  SET_IS_MOBILE_USER_AGENT,
  FETCH_HEADER_LOGIN_ERROR,
  FETCH_HEADER_SIGNUP_ERROR,
  FETCH_IS_VIP_STATUS_SUCCESS,
  FETCH_IS_VIP_STATUS_ERROR,
  FETCH_USER_COUNTS_SUCCESS,
  FETCH_USER_COUNTS_ERROR,
  SET_SEARCH_TYPE_AHEAD_COOKIE,
  SET_MY_INVALUABLE_COOKIE,
  SET_MODAL_STATUS,
  SET_POST_LOGIN_REDIRECT_URL,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  SET_WINDOW_WIDTH,
} from 'src/actions/constants';

import logger from 'routes/middleware/logging/logger';
import { MODAL_NAMES } from 'src/utils/constants';
import {
  getPopularArtistsFollowersCount,
  getSimilarArtistsFollowersCount,
} from './helpers';

const initialState = {
  isProd: false,
  isMobile: false,
  isVIP: false,
  windowWidth: 1326,
  counts: {
    ARTIST_ALERTS: 0,
    REGISTERED_AUCTIONS: 0,
    ACTIVE_BIDS: 0,
    CATEGORY_ALERTS: 0,
    KEYWORD_ARTIST_ALERTS: 0,
    KEYWORD_ALERTS: 0,
    UNREAD_MESSAGES_COUNT: 0,
    FOLLOWING_SELLERS_COUNT: 0,
    WATCHED_LOTS: 0,
    WON_LOTS: 0,
  },
  userCountryCode: '',
  currencyConversionRates: {},
  hostname: '',
  sessionInfo: {
    oasHeaders: null,
    memberId: 0,
    authToken: null,
    isLoggedIn: false,
  },
  reqHeaders: {
    Accept: 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json; charset=utf-8',
  },
  followedUnfollowedArtistsStatus: {},
  userPopularArtistsData: {},
  userSimilarArtists: [],
  artistFollowers: {},
  searchTypeAheadCookie: null,
  categoryData: {
    superCategories: [],
    fineArt: [],
    decorativeArt: [],
    jewelry: [],
    collectibles: [],
    furniture: [],
    asianArtAndAntiques: [],
  },
  notifications: [],
  notificationsCount: 0,
  myInvaluableCookie: null,
  errors: {
    isFetchFollowThisArtistCallFailed: false,
    isSetFollowThisArtistCallFailed: false,
    isUserSimilarArtistsCallFailed: false,
    isUserFollowedArtistsStatusCallFailed: false,
    loginErrors: false,
    signupErrors: false,
  },
  modals: {
    [MODAL_NAMES.emailOnly]: { isOpen: false, type: 'default', data: {} },
    [MODAL_NAMES.signup]: { isOpen: false },
    [MODAL_NAMES.login]: { isOpen: false }
  },
  postLoginRedirectUrl: null,
};

function invReactCommon(state = initialState, action) {
  try {
    switch (action.type) {
      case FETCH_SESSION_INFO_SUCCESS: {
        const { oasHeaders, memberId, isLoggedIn, firstName } = action.payload;
        return {
          ...state,
          sessionInfo: {
            memberId,
            isLoggedIn,
            authToken: oasHeaders?.['x-auth-token'],
            oasHeaders,
            firstName,
          },
          reqHeaders: {
            ...state.reqHeaders,
            ...oasHeaders,
            'member-id': memberId,
          }
        };
      }

      case FETCH_FOLLOW_THIS_ARTIST_SUCCESS: {
        const { isFollowed, artistRef } = action.payload;

        return {
          ...state,
          followedUnfollowedArtistsStatus: {
            ...state.followedUnfollowedArtistsStatus,
            [artistRef]: isFollowed,
          },
        };
      }

      case SET_FOLLOW_THIS_ARTIST_SUCCESS: {
        const { isFollowed, artistRef } = action.payload;
        const currentArtistFollowers = state.artistFollowers[artistRef] || 0;

        return {
          ...state,
          followedUnfollowedArtistsStatus: {
            ...state.followedUnfollowedArtistsStatus,
            [artistRef]: isFollowed,
          },
          artistFollowers: {
            ...state.artistFollowers,
            [artistRef]: isFollowed ? currentArtistFollowers + 1 : currentArtistFollowers - 1,
          }
        };
      }

      case SET_USER_POPULAR_ARTISTS_DATA: {
        const userPopularArtistsData = action.payload;

        const popularArtistsFound = STATIC_POPULAR_ARTISTS.filter(
          ({ artistRef }) => artistRef in userPopularArtistsData
        );
        const popularArtistFollowersCount = getPopularArtistsFollowersCount(popularArtistsFound, userPopularArtistsData);

        return {
          ...state,
          userPopularArtistsData,
          artistFollowers: {
            ...state.artistFollowers,
            ...popularArtistFollowersCount,
          }
        };
      }

      case FETCH_SIMILAR_ARTISTS_DATA_SUCCESS: {
        const userSimilarArtists = action.payload;
        const similarArtistsFollowersCount = getSimilarArtistsFollowersCount(userSimilarArtists);

        return {
          ...state,
          userSimilarArtists,
          artistFollowers: {
            ...state.artistFollowers,
            ...similarArtistsFollowersCount,
          }
        };
      }

      case FETCH_USER_ARTIST_FOLLOW_STATUS_SUCCESS: {
        const { userArtistFollowStatus } = action.payload;
        const userFollowedArtists = {
          ...state.followedUnfollowedArtistsStatus,
          ...userArtistFollowStatus
        };

        return {
          ...state,
          followedUnfollowedArtistsStatus: userFollowedArtists,
        };
      }

      case FETCH_USER_ARTIST_FOLLOW_STATUS_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            isUserFollowedArtistsStatusCallFailed: false,
          }
        };
      }

      case FETCH_SIMILAR_ARTISTS_DATA_ERROR: {
        return {
          ...state,
          error: {
            ...state.errors,
            isUserSimilarArtistsCallFailed: true,
          }
        };
      }

      case SET_IS_PROD_ENV: {
        return {
          ...state,
          isProd: action.payload,
        };
      }

      case SET_HOST_NAME: {
        return {
          ...state,
          hostname: action.payload,
        };
      }

      case SET_WINDOW_WIDTH: {
        return {
          ...state,
          windowWidth: action.payload,
        };
      }

      case SET_USER_COUNTRY_CODE: {
        return {
          ...state,
          userCountryCode: action.payload,
        };
      }

      case SET_IS_MOBILE_USER_AGENT: {
        return {
          ...state,
          isMobile: action.payload,
        };
      }

      case ADD_CONVERSION_RATE: {
        return {
          ...state,
          currencyConversionRates: {
            ...state.currencyConversionRates,
            [action.payload.currencyCode]: action.payload.conversionRate
          }
        };
      }

      case FETCH_FOLLOW_THIS_ARTIST_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            isFetchFollowThisArtistCallFailed: true,
          },
        };
      }

      case SET_FOLLOW_THIS_ARTIST_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            isSetFollowThisArtistCallFailed: true,
          },
        };
      }

      case FETCH_IS_VIP_STATUS_SUCCESS: {
        return {
          ...state,
          isVIP: action.payload,
        };
      }

      case FETCH_IS_VIP_STATUS_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            isVIPError: action.payload,
          },
        };
      }

      case FETCH_USER_COUNTS_SUCCESS: {
        return {
          ...state,
          counts: action.payload,
        };
      }

      case FETCH_USER_COUNTS_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            countsError: action.payload,
          },
        };
      }

      case FETCH_NOTIFICATIONS_SUCCESS: {
        return {
          ...state,
          notifications: action.payload.notifications,
          notificationsCount: action.payload.notificationsCount,
        };
      }

      case FETCH_NOTIFICATIONS_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            notificationsError: action.payload,
          },
        };
      }

      case FETCH_SESSION_INFO_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            isSessionInfoCallFailed: true,
          },
        };
      }

      case FETCH_HEADER_LOGIN_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            loginErrors: true,
          },
        };
      }

      case FETCH_HEADER_SIGNUP_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            signupErrors: true,
          },
        };
      }

      case SET_SEARCH_TYPE_AHEAD_COOKIE: {
        return {
          ...state,
          searchTypeAheadCookie: action.payload
        };
      }

      case SET_MY_INVALUABLE_COOKIE: {
        return {
          ...state,
          myInvaluableCookie: action.payload
        };
      }

      case SET_MODAL_STATUS: {
        const { name, isOpen, ...other } = action.payload;

        return {
          ...state,
          modals: {
            ...state.modals,
            [name]: {
              ...state.modals[name],
              ...other,
              isOpen,
            }
          }
        };
      }

      case SET_POST_LOGIN_REDIRECT_URL: {
        return {
          ...state,
          postLoginRedirectUrl: action.payload
        };
      }

      default: {
        return state;
      }
    }
  } catch (error) {
    logger.error('Error in the inv common Reducer', error);
    return state;
  }
}

export default invReactCommon;
