import get from 'lodash/get';
import axios from 'axios';
import {
  addConversionRate,
  fetchUserSimilarArtistsDataSuccess,
  fetchUserSimilarArtistsDataError,
  fetchUserArtistFollowStatusSuccess,
  fetchUserArtistFollowStatusError
} from 'src/actions/inv-common.actions';
import logger from 'routes/middleware/logging/logger';
import { selectArtistGenres } from 'src/selectors/artist-detail';
import { selectIsProd } from 'src/selectors/inv-common';
import { fetchCurrencyConvertedRates } from 'src/utils/common/helpers';
import { routes } from 'config';
import INVCommonService from '../../services/common';
import {
  fetchSessionInfoSuccess,
  fetchSessionInfoError,
  fetchFollowThisArtistSuccess,
  fetchFollowThisArtistError,
  setFollowThisArtistSuccess,
  setFollowThisArtistError,
} from '../../actions';
import {
  augmentSimilarArtistDataWithLotImages,
  fetchSimilarArtistLotsPhoto,
  fetchSimilarArtistsInfoByGenre
} from './helpers';

export const fetchSessionInfo = ({ cookies, hostname }) => async (dispatch) => {
  try {
    const { success, data: sessionInfo } = await INVCommonService.getSessionInfo(cookies, hostname);
    if (success) {
      const memberId = get(sessionInfo, 'user.userID', 0);
      const oasHeaders = get(sessionInfo, 'oasHeaders');
      const firstName = get(sessionInfo, 'user.firstName', '');
      const sessionData = {
        oasHeaders,
        memberId,
        isLoggedIn: memberId !== 0,
        firstName,
        authToken: oasHeaders?.['x-auth-token']
      };
      dispatch(fetchSessionInfoSuccess(sessionData));
      return sessionData;
    }
    dispatch(fetchSessionInfoError);
  } catch (error) {
    dispatch(fetchSessionInfoError);
  }
};

export const fetchFollowThisArtistStatus = (artistRef, cookies, hostname) => async (dispatch) => {
  try {
    const { success, data: { followed: isFollowed } } = await INVCommonService.getFollowThisArtistStatus(artistRef, cookies, hostname);

    if (success) {
      dispatch(fetchFollowThisArtistSuccess({ isFollowed, artistRef }));
    } else {
      dispatch(fetchFollowThisArtistError);
    }
  } catch (error) {
    dispatch(fetchFollowThisArtistError);
  }
};

export const setFollowThisArtistStatus = (artistRef, hostname) => async (dispatch) => {
  try {
    const { success, data: { followed: isFollowed } } = await INVCommonService.setFollowThisArtistStatus(artistRef, hostname);

    if (success) {
      dispatch(setFollowThisArtistSuccess({ isFollowed, artistRef }));
    } else {
      dispatch(setFollowThisArtistError);
    }
  } catch (error) {
    dispatch(setFollowThisArtistError);
  }
};

export const fetchUserArtistFollowStatus = (cookies, hostname, authToken, artistRefs) => async (dispatch) => {
  try {
    const { success, data: userArtistFollowStatus } = await INVCommonService.getUserFollowedArtistsStatus(cookies, hostname, authToken, artistRefs);

    if (success) {
      dispatch(fetchUserArtistFollowStatusSuccess(userArtistFollowStatus));
    } else {
      dispatch(fetchUserArtistFollowStatusError);
    }
  } catch (error) {
    dispatch(fetchUserArtistFollowStatusError);
  }
};

export const addCurrencyConversionRate = (userCountryCountry, currencyCode, hostname) => async (dispatch) => {
  const currencyConversions = await fetchCurrencyConvertedRates(userCountryCountry, currencyCode, hostname);
  if (currencyConversions && currencyConversions.length) {
    dispatch(addConversionRate(Object.assign({}, ...currencyConversions)));
  }
};

export const fetchUserSimilarArtistsData = (artistRef, algoliaClient) => async (dispatch, getState) => {
  try {
    const rootState = getState();
    const isProduction = selectIsProd(rootState);
    const genres = selectArtistGenres(rootState);

    if (!genres?.length) {
      return;
    }

    const similarArtistsData = await fetchSimilarArtistsInfoByGenre(algoliaClient, isProduction, genres, artistRef);

    let artistWithLotImages = [];

    if (similarArtistsData?.length) {
      artistWithLotImages = await fetchSimilarArtistLotsPhoto(algoliaClient, isProduction, similarArtistsData);
    }

    const formattedSimilarArtistsData = augmentSimilarArtistDataWithLotImages(similarArtistsData, artistWithLotImages, isProduction);

    dispatch(fetchUserSimilarArtistsDataSuccess(formattedSimilarArtistsData));
  } catch (error) {
    logger.error('ARTIST DETAILS: Algolia SSR fetch error for [fetchUserSimilarArtistsData]', error);

    dispatch(fetchUserSimilarArtistsDataError);
  }
};

export const fetchSessionInfoClient = () => async (dispatch) => {
  try {
    const response = await axios.get(`${routes.api.SESSION_INFO}?t=${new Date().getTime()}`);
    const memberId = get(response, 'data.user.userID', 0);
    const oasHeaders = get(response, 'data.oasHeaders');
    const firstName = get(response, 'data.user.firstName', '');
    dispatch(fetchSessionInfoSuccess({ oasHeaders, memberId, isLoggedIn: memberId !== 0, firstName }));
  } catch (error) {
    dispatch(fetchSessionInfoError);
  }
};
