import React from 'react';

import EmailOnlyModal from './EmailOnlyModal';
import LoginModal from './LoginModal';
import SignUpModal from './SignUpModal';

function ModalManager() {
  return (
    <>
      <LoginModal />
      <SignUpModal />
      <EmailOnlyModal />
    </>
  );
}

export default ModalManager;
