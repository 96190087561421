import React from 'react';
import { useSelector } from 'react-redux';

import { selectArtistBioInfo, selectArtistRef } from 'src/selectors/artist-detail';
import { FollowUnfollowArtistButton } from '../../common/follow-unfollow-artist-button';
import { ARTIST_DETAIL_INFO_ELEMENT_ID } from '../constants';

function ArtistIntro() {
  const { displayName } = useSelector(selectArtistBioInfo);
  const artistRef = useSelector(selectArtistRef);

  return (
    <div className="artist-intro" id={ARTIST_DETAIL_INFO_ELEMENT_ID}>
      <div className="artist-intro-head">
        <h2>{`About ${displayName}`}</h2>
        <FollowUnfollowArtistButton
          isSecondaryBtn
          artistRef={artistRef}
        />
      </div>
    </div>
  );
}

export default ArtistIntro;
