// Modules
import Styled from 'styled-components';

const mediaQuerySizes = {
  sm: '(min-width: 540px)',
  md: '(min-width: 767px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1140px)',
  xxl: '(min-width: 1341px)',
};

export const DesktopContainer = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0;
  .ais-search-list {
    overflow: auto;
  }
`;

export const DesktopContainerV1 = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0;
  position: relative;
  .ais-search-list {
    overflow: auto;
  }

  .fa-search {
    color: #E4002B !important; 
  }

  .suggestions-dropdown {
    top: 100%;
    margin-top: 17px;
  }

  #searchBarContainerV1 {
    max-width: inherit !important;
  }

  #suggestionDropdown {
    box-shadow: 1px 5px 14px 0px rgba(0,0,0,0.32);
    -webkit-box-shadow: 1px 5px 14px 0px rgba(0,0,0,0.32);
    -moz-box-shadow: 1px 5px 14px 0px rgba(0,0,0,0.32);
  }

  .logo {

    img {
      width: 170px;
      
      @media ${mediaQuerySizes.lg} {
        width: 200px
      }
    }
  }
`;

export const LogoContainer = Styled.div`
  margin-right: auto;
  margin-top: 21px;
`;

export const SearchContainer = Styled.div`
  flex: auto !important;
  margin: 0 10px 0 20px;
  position: relative;
  max-width: 35%;
  @media ${mediaQuerySizes.lg} {
    margin: 0 20px 0 40px;
  }
`;

export const MobileSearchContainer = Styled.div`
  .suggestions-dropdown {
      top: 130px !important;
    }
  .ais-search-list {
      overflow: scroll;
    }
`;

export const LoginContainer = Styled.div`
  margin-left: auto;
  display: flex;
  margin-top: 21px;

  .hello-container {
    
    .user-name {
      max-width: 50px;

      @media ${mediaQuerySizes.xxl} {
        max-width: 100px;
      }
    }
  }
`;

export const NavBarContainer = Styled.div`
flex: 0 0 100%;
margin-top: 1rem;
`;

export const HideMd = Styled.span`
  display: none;

  @media ${mediaQuerySizes.xl} {
  display: inline-block;  
  }
`;

export const HideSm = Styled.span`
  display: none;

  @media ${mediaQuerySizes.md} {
  display: inline-block;  
  }
`;

export const ShowMobile = Styled.span`
  display: inline-block;

  @media ${mediaQuerySizes.md} {
  display: none;  
  }
`;

export const SSOContainer = Styled.div`
display: flex;
justify-content: center;
text-align: center
`;

export const SmallText = Styled.span`
font-size: 12px;
color: #222;
text-align: center 
`;

export const FaBars = Styled.span`
font-size: 16px !important;
`;

export const HeaderBell = Styled.span`
left: 0 !important; 
margin-left: 0 !important;
`;

export const HeaderGearMobile = Styled.span`
    color: #ffffff;
    text-shadow: 0 1px 0 #333333;
    font-size: 2em;
    opacity: 0.8;
    margin-top: -7px;
    margin-left: auto;
    background: transparent;
    border: 0;
      `;

export const NotifModalHeader = Styled.div`
      justify-content: flex-end !important;
      `;
