import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import { fetchConsoleLink, openOnNewWindow } from 'inv-common-components';
import { sendABToHeap } from '../../config';
import useFetchAuctions from '../../hooks/useFetchAuctions';

function Auctions({ hits }) {
  const [auctions] = useFetchAuctions(hits);
  const xAuthToken = useSelector((state) => get(state, 'invReactCommon.sessionInfo.authToken', false));

  const openLiveConsole = async (catalogRef) => {
    const channelID = 1;

    try {
      const consoleUrlResponse = await fetchConsoleLink({ catalogRef, xAuthToken, channelID });
      const liveConsoleUrl = get(consoleUrlResponse, 'data.consoleUrl', '');
      openOnNewWindow(liveConsoleUrl);
    } catch (error) {
      console.error('error fetching searchbar live console url', error);
    }
  };

  const renderAuctionButton = (live, timed, inProgress, catalogRef) => {
    const catalogURL = `/catalog/${catalogRef}`;
    if (live && inProgress) {
      return (
        <button
          onClick={() => openLiveConsole(catalogRef)}
          className="btn btn-live full-width"
          type="button"
        >
          Enter Live Auction
        </button>
      );
    }

    if (timed && inProgress) {
      return (
        <a
          href={catalogURL}
          className="btn btn-live full-width"
        >
          Happening Now
        </a>
      );
    }

    return null;
  };

  const getTimeToAuction = (dateTimeUTCUnix, unit = 'days') => {
    const today = moment();
    const date = moment(dateTimeUTCUnix);
    if (unit === 'days') return Math.ceil(moment.duration(date.diff(today)).asDays());
    return Math.ceil(moment.duration(date.diff(today)).asHours());
  };

  const renderAuctionDateTime = (live, timed, inProgress, dateTimeLocal, eventDate) => {
    const daysToAution = getTimeToAuction(eventDate);
    if (live) {
      if (inProgress) {
        return <p className="auction-status">Live Now</p>;
      } if (daysToAution <= 7 && daysToAution >= 1) {
        return <p className="auction-status">{`${daysToAution} ${daysToAution === 1 ? 'Day' : 'Days'} Left`}</p>;
      } if (daysToAution === 0) {
        return <p className="auction-status">Live Today</p>;
      }
      return (
        <Fragment>
          <p className="date-time non-localized-date">{dateTimeLocal}</p>
          <p className="localized-date d-none hide">{dateTimeLocal}</p>
        </Fragment>
      );
    }

    if (inProgress) {
      return <span className="timed-badge">accepting bids</span>;
    }
    if (daysToAution >= 1 && daysToAution <= 7) {
      return <p className="auction-status">{`${daysToAution} ${daysToAution === 1 ? 'Day' : 'Days'} Left`}</p>;
    }
    if (daysToAution === 0) {
      const hours = getTimeToAuction(eventDate, 'hours');
      if (hours >= 1) { return <p className="auction-status">{`${hours} ${hours > 1 ? 'Hours' : 'Hour'} Left`}</p>; }
      return <p className="auction-status">Closing Soon</p>;
    }
    return (
      <Fragment>
        <p className="date-time non-localized-date">{dateTimeLocal}</p>
        <p className="date-time localized-date d-none hide">{dateTimeLocal}</p>
      </Fragment>
    );
  };

  if (!auctions.length) {
    return (
      <Fragment>
        <h4>Auctions</h4>
        <span className="empty-results">No suggested auctions</span>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <h4>Auctions</h4>
      {auctions.map((catalog, index) => {
        const { catalogRef, auctionTitle, houseName, live, inProgress,
          timed, userApprovalStatus, dateTimeLocal, eventDate } = catalog;
        return (
          <div className={`auctions ${index > 0 ? 'space-top' : ''}`}>
            <div>
              <div>
                {renderAuctionDateTime(live, timed, inProgress, dateTimeLocal, eventDate)}
              </div>
              {
                userApprovalStatus === 'p' && (
                  <p className="approval-status pending">
                    <i className="fa fa-refresh" />
                    Pending
                  </p>
                )
              }
              {
                userApprovalStatus === 'a' && (
                  <p className="approval-status approved">
                    <i className="fa fa-check" />
                    Approved
                  </p>
                )
              }
            </div>
            <a
              href={`/catalog/${catalogRef}`}
              className="title"
              onClick={() => sendABToHeap('Search Type Ahead', { clickEvent: 'Auctions' }, 0)}
            >{auctionTitle}
            </a>
            <p className="auctioneer">by {houseName}</p>
            {renderAuctionButton(live, timed, inProgress, catalogRef)}
            {!userApprovalStatus && (
              <a
                href={`/bidNow/reqForApproval?catalogRef=${catalogRef}`}
                className="register-to-bid"
              >Register to bid
              </a>
            )}
          </div>
        );
      })}
    </Fragment>
  );
}

export default memo(Auctions);
