import React from 'react';

const style = { fontWeight: 500 };

function HighlightedText({ text, searchString, linkTo, onClick }) {
  const parts = React.useMemo(() => {
    const regex = new RegExp(`(${searchString})`, 'gi');
    return text.split(regex);
  }, [text, searchString]);

  return (
    <li
      role="option"
      aria-selected="false"
      aria-label={text}
      className="text-truncate w-100"
    >
      <a
        href={linkTo}
        onClick={onClick}
      >
        {parts.map((part) => (part.toLowerCase() === searchString.toLowerCase() ? (
          <span
            style={style}
            key={`matched-${part.replace(' ', '-')}`}
          >
            {part}
          </span>
        ) : (
          <span key={part.replace(' ', '-')}>{part}</span>
        )))}
      </a>
    </li>
  );
}

export default HighlightedText;
