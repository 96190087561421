import logger from 'routes/middleware/logging/logger';

const initialState = {};
function artistDirectoryPage(state = initialState, action) {
  try {
    switch (action.type) {
      default:
        return state;
    }
  } catch (error) {
    logger.error('Error in Artist Directory Page Reducer', error);
    return state;
  }
}

export default artistDirectoryPage;
