import React, { createContext, useEffect, useContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const AlgoliaContext = createContext();

const initialState = {
  count: 0,
  facets: null,
  searchParams: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_COUNT':
      return { ...state, count: action.payload };
    case 'SET_FACETS':
      return { ...state, facets: action.payload };
    case 'SET_SEARCH_PARAMS':
      return { ...state, searchParams: { ...state.searchParams, ...action.payload } };
    case 'REPLACE_SEARCH_PARAMS':
      return { ...state, searchParams: action.payload };
    default:
      return state;
  }
}

export function AlgoliaProvider({ searchParams, children }) {
  const [state, dispatch] = useReducer(reducer, { ...initialState, searchParams });

  useEffect(() => {
    dispatch({ type: 'SET_SEARCH_PARAMS', payload: searchParams });
  }, [searchParams]);

  const setCount = (count) => {
    dispatch({ type: 'SET_COUNT', payload: count });
  };

  const setFacets = (facets) => {
    dispatch({ type: 'SET_FACETS', payload: facets });
  };

  const setSearchParams = (params, replace = false) => {
    if (replace) {
      dispatch({ type: 'REPLACE_SEARCH_PARAMS', payload: params });
    } else {
      dispatch({ type: 'SET_SEARCH_PARAMS', payload: params });
    }
  };

  const value = useMemo(() => ({
    state,
    setCount,
    setFacets,
    setSearchParams,
  }), [state, setCount, setFacets, setSearchParams]);

  return (
    <AlgoliaContext.Provider value={value}>
      {children}
    </AlgoliaContext.Provider>
  );
}

export function useAlgolia() {
  const { state, setCount, setFacets, setSearchParams } = useContext(AlgoliaContext);

  if (typeof state === 'undefined') {
    throw new Error('useAlgolia must be used within a AlgoliaProvider');
  }

  return {
    ...state,
    setCount,
    setFacets,
    setSearchParams,
  };
}

AlgoliaProvider.propTypes = {
  searchParams: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

AlgoliaProvider.defaultProps = {
  searchParams: {},
};
