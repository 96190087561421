import React from 'react';
import HighlightedText from './HighlightText';
import { sendABToHeap } from '../../config';

function Auctions({ catalogDetails, value }) {
  return (
    <div className="searches-block">
      {catalogDetails.map((catalog) => {
        const { catalogRef, auctionTitle } = catalog;
        return (
          <HighlightedText
            linkTo={`/catalog/${catalogRef}`}
            key={`suggested-auction-${catalogRef}`}
            text={auctionTitle}
            searchString={value}
            onClick={() => sendABToHeap('Search Type Ahead', { clickEvent: 'Auctions' }, 0)}
          />
        );
      })}
    </div>
  );
}

export default Auctions;
