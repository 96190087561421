import algoliasearch from 'algoliasearch';
import { CallEnum } from '@algolia/transporter';
import { SEARCH_TYPE_AHEAD } from 'routes/utils/constants';
import { getCookie } from '../../../../routes/utils/cookiesUtils';

export const UPCOMING_LOTS_INDEX = 'upcoming_lots_prod_query_suggestions';
export const HOUSE_INDEX = {
  prod: 'houses_default_prod',
  dev: 'houses_default_stage'
};
export const CATEGORIES_INDEX = 'categories_prod';
export const RECENT_SEARCH = 'RECENT_SEARCH';
export const RECENT_SEARCH_INDEX = 'upcoming_lots_prod';
export const ARTISTS_INDEX = {
  prod: 'artists_default_prod',
  dev: 'artists_default_stage'
};
export const UPCOMING_LOTS_ARTIST_INDEX = {
  prod: 'artists_default_prod',
  dev: 'artists_default_stage'
};
export const UPCOMING_LOTS_SUGGESTIONS_INDEX = {
  prod: 'upcoming_lots_prod',
  dev: 'upcoming_lots_stage'
};

export const NO_OF_RESULTS = 7;
export const NO_OF_RESULTS_MOBILE = 4;
export const NO_OF_RESULTS_SMALL = 3;
export const NO_OF_RESULTS_LONG = 10;

export const sectionTitle = {
  upcoming_lots_prod_query_suggestions: 'SUGGESTED SEARCHES',
  houses_default_stage: 'SUGGESTED HOUSES',
  houses_default_prod: 'SUGGESTED HOUSES',
  categories_prod: 'SUGGESTED CATEGORIES',
};

export const theme = {
  container: {
    padding: '0',
    listStyle: 'none',
    position: 'relative',
  },
  suggestionsList: {
    listStyle: 'none',
    padding: '0',
    borderTop: '1px solid #bac4c9',
  },
  suggestionsContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    zIndex: 2000,
    maxHeight: '630px',
    float: 'left',
    minWidth: '160px',
    padding: '20px 0px 10px',
    margin: '0',
    fontSize: '16px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #bac4c9',
    borderRadius: 0,
    boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
  },
  sectionTitle: {
    lineHeight: '20px',
    display: 'block',
    fontSize: '13px',
    padding: '4px 10px',
    textTransform: 'uppercase',
  },
  suggestion: {
    color: '#333',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
};

export const saveRecentItem = (saveValue) => {
  const getItem = localStorage.getItem(RECENT_SEARCH);
  if (getItem) {
    const recentSearch = JSON.parse(getItem);
    if (recentSearch.length) {
      if (recentSearch.length >= 7) {
        recentSearch.length = 6;
      }
      localStorage.setItem(RECENT_SEARCH, JSON.stringify([saveValue, ...recentSearch]));
      return;
    }
  }
  localStorage.setItem(RECENT_SEARCH, JSON.stringify([saveValue]));
};

export function sendABToHeap(testName, eventData = {}, timeLimit, cookieName = SEARCH_TYPE_AHEAD) {
  let limit = timeLimit;
  if (limit > 7600) {
    return;
  }
  let variant = getCookie(cookieName, document.cookie);

  if (!variant || variant === 'old') {
    variant = 'control';
  }

  if (window.heap) {
    window.heap.removeEventProperty(testName);
    const eventProps = {};
    eventProps[testName] = variant;
    window.heap.addEventProperties(eventProps);
    window.heap.track(testName, { variant, ...eventData });
  } else {
    setTimeout(() => {
      limit += 500;
      sendABToHeap(testName, eventData, limit, cookieName);
    }, 1000);
  }
}

export const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, 'NO_KEY', {
  hosts: [
    {
      url: process.env.REACT_APP_ALGOLIA_API_URL,
      protocol: 'https',
      accept: CallEnum.Read,
    },
  ],
});

export const agoliaSearchClient = {
  search(requests) {
    const shouldSearch = requests.some(({ params: { query } }) => query !== '');
    if (shouldSearch) {
      return algoliaClient.search(requests);
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
};
