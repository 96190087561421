import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connectRange } from 'react-instantsearch-dom';

function RangeInput({ currentRefinement, min, max, refine, rangeInput, setRangeInput }) {
  const [error, setError] = useState(false);

  function validate(type, value) {
    if (value && (value > max || value < min)) {
      setError(`Value must be between ${min} and ${max}`);
    } else {
      setError(false);
    }
  }

  function handleChange(event) {
    event.preventDefault();
    const { value, name } = event.target;
    validate(name, value);
    setRangeInput({ ...rangeInput, [name]: value });
  }

  function handleSubmit(event) {
    event.preventDefault();
    refine({
      min: rangeInput.min > min ? rangeInput.min : min,
      max: rangeInput.max < max ? rangeInput.max : max,
    });
  }

  return (
    <div className="range-input filter-wrapper">
      <div className="ais-RangeInput">
        <form
          className="ais-RangeInput-form"
          onSubmit={handleSubmit}
        >
          <div className="ais-RangeInput-wrraper">
            <label
              className="ais-RangeInput-label form-group"
              htmlFor="range-input-min"
            >
              <input
                type="number"
                className="form-control ais-RangeInput-input--min"
                id="range-input-min"
                placeholder={currentRefinement.min}
                name="min"
                min={min}
                max={max}
                value={rangeInput.min || ''}
                onChange={handleChange}
              />
            </label>
            <span className="ais-RangeInput-separator">to</span>
            <label
              className="ais-RangeInput-label"
              htmlFor="range-input-max"
            >
              <input
                type="number"
                className="form-control ais-RangeInput-input--min"
                id="range-input-max"
                placeholder={currentRefinement.max}
                name="max"
                min={min}
                max={max}
                value={rangeInput.max || ''}
                onChange={handleChange}
              />
            </label>
          </div>
          {error && <div className="text-danger">{error}</div>}
          <div>
            <button
              type="button"
              onClick={handleSubmit}
              className="range-input-submit-btn btn btn-link"
            >Apply price range
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

RangeInput.propTypes = {
  currentRefinement: PropTypes.objectOf({}).isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  refine: PropTypes.func.isRequired,
  rangeInput: PropTypes.objectOf({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  setRangeInput: PropTypes.func.isRequired,
};

export default connectRange(RangeInput);
