import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
} from 'reactstrap';
import { setModalStatus } from 'src/actions/inv-common.actions';
import { get } from 'lodash';
import { selectModalStatus } from 'src/selectors/inv-common';

import axios from 'axios';
import { MODAL_NAMES } from 'src/utils/constants';
import { fullPageLoadingSpinnerToggle } from 'src/utils/common/helpers';
import styled from 'styled-components';
import useEmailOnlyData from 'src/hooks/useEmailOnlyData';
import {
  SSOContainer,
  SmallText
} from '../../../containers/Header/Header.styled';
import { GET_ACCOUNT_TYPE_ENDPOINT, followItem } from './emailOnlyUtils';

const types = {
  default: {
    title: 'Enter Your Email',
    subtitle: 'Sign in or create an account with your email',
    goToNextStep: true
  },
  lot: {
    title: 'Enter your email and be the first to know when similar items are listed!'
  },
  artist: {
    title: 'Get notified when new items by this artist are listed',
  },
  category: {
    title: 'Follow this category and get notified when new items are listed!',
    goToNextStep: true
  },
  house: {
    title: 'Get notified when new items from this Auction House are listed'
  },
  keyword: {
    title: 'Get notified when new items that match this search are listed'
  }
};

const Label = styled.label`
position: relative;
  float: left;
  padding-left: 20px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 15px;
`;

const Checkbox = styled.input`
  position: absolute;
  margin: 5px;
  margin-left: -20px;
  margin-top: 5px !important;
`;

function EmailOnlyModal() {
  const dispatch = useDispatch();
  const { email } = useEmailOnlyData();
  const [emailAddress, setEmailAddress] = useState(email);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const loginErrors = useSelector((state) => get(state, 'invReactCommon.errors.loginErrors', false));
  const { isOpen: isModalOpen, type, data: modalData = {} } = useSelector(state => selectModalStatus(state, MODAL_NAMES.emailOnly));
  const { title, subtitle, goToNextStep } = types[type] || types.default;
  const closeModal = () => dispatch(setModalStatus({
    name: MODAL_NAMES.emailOnly,
    isOpen: false,
    type: 'default',
    data: {}
  }));

  const isValid = Boolean(/[^\s]*@[a-z0-9.-]*/i.test(emailAddress)) && (goToNextStep ? true : checkedTerms);

  const handleEmailAddressChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const goToTheNextModal = async () => {
    fullPageLoadingSpinnerToggle(true);
    const { data: { data } } = await axios.get(GET_ACCOUNT_TYPE_ENDPOINT, {
      headers: {
        email: emailAddress,
      },
    });
    fullPageLoadingSpinnerToggle(false);

    const { accountType, firstName } = data;

    closeModal();
    dispatch(setModalStatus({
      isOpen: true,
      userName: firstName,
      email: emailAddress,
      name: ['emailOnly', 'noAccount'].includes(accountType) ? MODAL_NAMES.signup : MODAL_NAMES.login,
    }));
  };

  const handleSubmitLogin = async () => {
    if (goToNextStep) {
      goToTheNextModal();
    } else {
      await followItem({
        email: email || emailAddress,
        signup: true,
        ...modalData
      });
      closeModal();
    }
  };

  const handleDefaultSubmit = (e) => {
    e.preventDefault();
    handleSubmitLogin();
  };

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        window.google.accounts.id.renderButton(
          document.getElementById('ssoGoogleButtonEmailOnly'),
          {
            theme: 'outline',
            size: 'large',
            text: 'continue_with',
            logo_alignment: 'center',
            width: '270px',
          } // customization attributes
        );
      }, 1);
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      className="modal-dialog modal-dialog-centered"
      size="md"
      fade
    >
      <div className="modal-header login-modal-header">
        <button type="button" className="close" data-dismiss="modal" onClick={() => closeModal()}>
          <span>×</span><span className="sr-only">Close</span>
        </button>
      </div>

      <div className="modal-title modal-title-text text-overflow text-center">{title}</div>

      {subtitle && <div className="text-center">{subtitle}</div>}

      <ModalBody>
        <Form className={`login-form ${loginErrors ? 'has-error' : ''}`} onSubmit={handleDefaultSubmit}>
          <fieldset className="login-inputs" id="loginFields">
            <FormGroup>
              <Input
                type="text"
                name="email"
                placeholder="Enter email address"
                maxLength={250}
                value={emailAddress}
                onChange={(e) => handleEmailAddressChange(e)}
              />
            </FormGroup>

            {!goToNextStep && (
              <div className="clearfix space-bottom">
                <Label className="checkbox-inline pull-left">
                  <Checkbox type="checkbox" className="termsCheck" value={checkedTerms} onChange={e => setCheckedTerms(e.target.checked)} />
                  <span className="text-muted rememberMeCheck dark-gray-text">I have read and accept Invaluable's  <a href="/agreements/userTerms.cfm" target="_blank">terms of service</a> and <a href="/agreements/privacy.cfm" target="_blank">privacy policy</a>.</span>
                </Label>
              </div>
            )}

            <div className="clearfix mb-2">
              <Button
                role="button"
                className="btn btn-login-modal modal-login-btn space-top log-in-memory"
                onClick={() => handleSubmitLogin()}
                disabled={!isValid}
              >Continue
              </Button>
            </div>
          </fieldset>

          {goToNextStep && (
            <>
              <div className="social-separator">
                <div className="social-line" />
                <span>OR</span>
                <div className="social-line" />
              </div>
              <SSOContainer className="mb-4">
                <div id="ssoGoogleButtonEmailOnly" allow="identity-credentials-get" className="space-bottom col-sm-6 text-center" />
              </SSOContainer>

              <SmallText className="dark-gray-text">
                By continuing with Google, you agree to Invaluable's&nbsp;
                <a href="/agreements/userTerms.cfm" target="_blank">terms of service</a> and&nbsp;
                <a href="/agreements/privacy.cfm" target="_blank">privacy policy</a>.
                Invaluable may send you communications; you can set your preferences in your account.
              </SmallText>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default EmailOnlyModal;
