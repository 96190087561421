import {
  SET_ARTIST_DATE_FILTER,
  FETCH_ARTIST_BIO_INFO_SUCCESS,
  FETCH_ARTIST_BIO_INFO_ERROR,
  FETCH_ARTIST_NOTABLE_SOLD_LOTS_SUCCESS,
  FETCH_ARTIST_NOTABLE_SOLD_LOTS_ERROR,
  SET_FILTERED_LOTS_COUNT,
  SET_LOTS_DISPLAY_MODE,
  SET_NUMBER_OF_LOTS_PER_PAGE,
  SET_CURRENT_PAGE_NUMBER,
  SET_ARTIST_PRICE_RANGE_FILTER,
  SET_HOUSES_SELLING_WORK_BY_ARTIST_SUCCESS,
} from './constants';

export const fetchArtistBioInfoSuccess = artistBio => ({
  type: FETCH_ARTIST_BIO_INFO_SUCCESS,
  payload: artistBio,
});

export const fetchArtistNotableSoldLotsSuccess = notableSoldLots => ({
  type: FETCH_ARTIST_NOTABLE_SOLD_LOTS_SUCCESS,
  payload: notableSoldLots,
});

export const fetchArtistBioInfoError = {
  type: FETCH_ARTIST_BIO_INFO_ERROR,
};

export const fetchArtistNotableSoldLotsError = {
  type: FETCH_ARTIST_NOTABLE_SOLD_LOTS_ERROR,
};

export const setArtistDateFilter = dateFilter => ({
  type: SET_ARTIST_DATE_FILTER,
  payload: dateFilter,
});

export const setArtistPriceRangeFilter = priceRangeFilter => ({
  type: SET_ARTIST_PRICE_RANGE_FILTER,
  payload: priceRangeFilter,
});

export const setFilteredLotsCount = filteredLotsCount => ({
  type: SET_FILTERED_LOTS_COUNT,
  payload: filteredLotsCount,
});

export const setLotsDisplayMode = lotsDisplayMode => ({
  type: SET_LOTS_DISPLAY_MODE,
  payload: lotsDisplayMode,
});

export const setNumberOfLotsPerPage = lotPerPage => ({
  type: SET_NUMBER_OF_LOTS_PER_PAGE,
  payload: lotPerPage,
});

export const setCurrentPageNumber = page => ({
  type: SET_CURRENT_PAGE_NUMBER,
  payload: page,
});

export const setAuctionHousesSellingWorkByArtist = houses => ({
  type: SET_HOUSES_SELLING_WORK_BY_ARTIST_SUCCESS,
  payload: houses,
});
