import React from 'react';
import { Provider } from 'react-redux';
import { renderToString } from 'react-dom/server';
import thunk from 'redux-thunk';
import { getUnixTime } from 'date-fns';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { StaticRouter, Route } from 'react-router-dom';

import {
  setArtistDateFilter,
  setHostName,
  setIsProdEnv,
  setUserCountryCode,
  setCurrentPageNumber,
  setNumberOfLotsPerPage,
  setArtistPriceRangeFilter,
  setLotsDisplayMode,
} from 'src/actions';
import { setIsMobileUserAgent, setUserPopularArtistsData, setSearchTypeAheadCookie, setmyInvaluableCookie } from 'src/actions/inv-common.actions';
import reducers from 'src/reducers';
import { FiltersAndResultsWrapper } from 'src/components/artist-detail';
import { STATIC_POPULAR_ARTISTS } from 'src/components/common/constants';
import ArtistDetailSeo from 'src/components/seo/ArtistDetailSeo';
import {
  selectTotalFilteredLots,
  selectLotsDisplayMode,
  selectArtistBioInfo,
} from 'src/selectors/artist-detail';
import { selectSessionInfo, selectSimilarArtistsRefs } from 'src/selectors/inv-common';
import {
  fetchArtistBioInfo,
  fetchArtistNotableSoldLots,
  fetchFrmtAndSetHousesSellingArtistWork,
} from 'src/thunks/artist-detail.thunk';
import {
  fetchSessionInfo,
  fetchFollowThisArtistStatus,
  fetchUserSimilarArtistsData,
  fetchUserArtistFollowStatus,
} from 'src/thunks/inv-common';
import {
  getDefaultNumberOfLotsPerPage,
  artistURLToSearchState,
  getPriceRangeBySelectedTab,
} from 'src/utils/artist-detail';
import { searchClient } from 'src/utils/algolia-helper';
import { getIsProductionEnv, getIsBot } from 'src/utils/common/helpers';
import { DEFAULT_PAGINATION_PAGE_NUMBER } from 'src/utils/constants';
import { ALGOLIA_INDEX, ARTISTS_DEFAULT_INDEX } from 'src/utils/algolia-helper/constants';
import { DEFAULT_DISPLAY_MODE } from 'src/utils/artist-detail/constants';
import { Footer } from 'inv-common-components';
import Header from 'src/containers/Header/Header';
import {
  PAGE_LAYOUTS,
  ALGOLIA_STYLE_SHEETS,
  REACT_SLICK_STYLES,
  POPULAR_ARTISTS_ALGOLIA_ATTRIBUTES_TO_RETRIVE,
} from '../constants';
import logger from '../middleware/logging/logger';
import {
  fetchAlgoliaResultsStateServerSide,
  parseLocationFromURL,
  getIsMobileUserAgent,
} from '../routeUtils';
import { getCookie } from './cookiesUtils';
import {
  ALGOLIA_LOTS_COMMON_CONFIG,
  MAXIMUM_RETRIES_TO_FETCH_POPULAR_ARTIST,
  SEARCH_TYPE_AHEAD,
  MY_INVALUABLE
} from './constants';

/**
 * Extract artist Ref from the URL. Artist ref is in the URL separated by a -
 * after firstname and lastname of the artist
 * Also removes the last slash/# from the URL if present.
 * @param {string} url: e.g. aalto-alvar-exvu4h58e9
 * @returns artist Ref
 */
export const extractArtistRefFromURL = (url) => {
  const lastURLPart = url.split('-').reverse()[0].replace('/', '');
  return lastURLPart.includes('#') ? lastURLPart.split('#')[0] : lastURLPart;
};

/**
 * Gets the page number based on the current page number and the number of total filtered lots.
 *
 * @param {number} totalFilteredLots - The total number of lots in the filtered list.
 * @param {number} page - The current page number.
 * @param {number} lotsResultsPerPage - Number of lots per page
 * @returns {string} The page number to display in the title.
 */
const getPageNumber = (totalFilteredLots, page, lotsResultsPerPage) => {
  let pageNumber = '';

  if (totalFilteredLots > lotsResultsPerPage) {
    pageNumber = page.toString();
  }

  return pageNumber;
};

/**
 * Fetches popular artists Refs from Static Popular Artists.
 * @returns {Array} - An array containing popular artists refs.
 */
export const getPopularArtistsRefs = () => STATIC_POPULAR_ARTISTS.map(artist => artist.artistRef);

/**
 * Fetches popular artists data from Algolia based on the popular artist references.
 * algoliaClient is mendatory to fetch from algolia
 * @param {Object} algoliaClient - algolia client to call algolia
 * @param {Bool} isProd - boolean to check is the env is of prod
 * @param {number} retries - retires to do before logging error
 * @returns {Promise<object>} A Promise that resolves to an object containing data with popular artists refs as keys.
 */
export const getPopularArtistsData = async (algoliaClient, isProd, retries = 0) => {
  const filterParams = STATIC_POPULAR_ARTISTS.map(artist => `artistRef:${artist.artistRef}`);
  try {
    const algoliaResults = await algoliaClient.search([{
      indexName: isProd ? ARTISTS_DEFAULT_INDEX.PROD : ARTISTS_DEFAULT_INDEX.STAGE,
      params: {
        attributesToRetrieve: POPULAR_ARTISTS_ALGOLIA_ATTRIBUTES_TO_RETRIVE,
        facetFilters: [filterParams],
      },
    }]);

    const popularArtistsData = algoliaResults.results[0].hits.reduce((obj, hit) => ({
      ...obj,
      [hit.artistRef]: {
        upcomingItems: hit.upcomingCount,
        followerCount: hit.followerCount,
      },
    }), {});

    return popularArtistsData;
  } catch (error) {
    if (retries < 1) {
      logger.error('POPULAR ARTIST: Algolia fetch error for PopularArtistUpcomingCount '
      + `[filterParams=${filterParams}, `
      + `attributesToRetrieve=${POPULAR_ARTISTS_ALGOLIA_ATTRIBUTES_TO_RETRIVE}]`, error);
      return {};
    }
  }
};

/**
    Get the popular artist data with multiple retries when api fails or update it in redux store.
    @param {function} dispatch - redux dispatch function.
    @param {Object} algoliaClient - algolia client to call algolia
    @param {Bool} isProd - boolean to check is the env is of prod
    @param {number} maxRetries - maximum retries to do when api fails.
*/
export const fetchPopularArtistsWithRetries = async (
  dispatch,
  algoliaClient,
  isProd,
  maxRetries = MAXIMUM_RETRIES_TO_FETCH_POPULAR_ARTIST
) => {
  let retriesLeft = maxRetries;

  const getPopularArtists = async () => {
    if (retriesLeft < 0) return;
    const popularArtistData = await getPopularArtistsData(algoliaClient, isProd, retriesLeft);
    if (popularArtistData) dispatch(setUserPopularArtistsData(popularArtistData));
    else {
      retriesLeft -= 1;
      getPopularArtists();
    }
  };
  getPopularArtists();
};

/**
 * Retrieves markup data for a given URL, path, page, store, headers, state, and layout
 * @param {string} url - The URL for which to fetch the markup data
 * @param {string} path - The path for which to fetch the markup data
 * @param {number} page - The page number for which to fetch the markup data
 * @param {Object} store - The Redux store to use for rendering the markup
 * @param {Object} headers - The headers to use for the request
 * @param {Function} getState - A function that retrieves the current state of the Redux store
 * @param {string} hostname - The hostname for which to fetch the markup data
 * @param {string} [layout=PAGE_LAYOUTS.V4] - The layout for the markup data
 * @param {React.Component} Component - The React component to render
 * @param {Object} initialState - The initial state for the Redux store
 * @param {boolean} [isPageNumberRequired=true] - A flag indicating whether the page number is required for the SEO
 * @param {boolean} [omitRobotTags=true] - A flag indicating whether to omit no-index no-follow robot tags

 * @returns {Object} - An object containing the header, headerNav, mainContent, scripts, footer, seoDom, layout, head, preloadedState, and pageStyles
*/
export const getArtistRoutesMarkupData = async ({
  url,
  path,
  page,
  store,
  context,
  sheet,
  getState,
  hostname,
  layout = PAGE_LAYOUTS.V4,
  Component,
  initialState,
  selectedTab,
  isPageNumberRequired = true,
}) => {
  const artist = selectArtistBioInfo(getState());

  const headerContent = renderToString(sheet.collectStyles(
    <Provider store={store}>
      <StaticRouter
        location={url}
        context={context}
      >
        <Header />
      </StaticRouter>
    </Provider>
  ));

  const styleTags = sheet.getStyleTags();

  const mainContent = renderToString(
    <Provider store={store}>
      <StaticRouter
        location={url}
      >
        <Route
          path={path}
          component={props => (
            <Component {...initialState} {...props} />
          )}
        />
      </StaticRouter>
      <Footer />
    </Provider>
  );

  let pageNumber = '';
  if (isPageNumberRequired) {
    const totalFilteredLots = selectTotalFilteredLots(getState());
    const lotsDisplayMode = selectLotsDisplayMode(getState());
    const lotsResultsPerPage = getDefaultNumberOfLotsPerPage(lotsDisplayMode);

    pageNumber = getPageNumber(
      totalFilteredLots,
      page,
      lotsResultsPerPage
    );
  }

  const seoDom = renderToString(
    <ArtistDetailSeo page={pageNumber} artist={artist} hostName={hostname} selectedTab={selectedTab} />
  );

  return ({
    mainContent: headerContent + mainContent,
    seoDom,
    layout,
    preloadedState: getState(),
    pageStyles: styleTags + ALGOLIA_STYLE_SHEETS + REACT_SLICK_STYLES,
  });
};

/**
 * Get the Algolia configuration for retrieving upcoming lots.
 * @param {boolean} [isRetrieveAttributes=true] - Whether to retrieve attributes.
 * @param {boolean} [isGetHitsPerPage=true] - Whether to retrieve hits per page.
 * @returns {Object} The Algolia configuration object.
 */
export const getUpcomingLotsAlgoliaConfig = (isRetrieveAttributes = true, isGetHitsPerPage = true) => {
  const indexName = getIsProductionEnv() ? ALGOLIA_INDEX.UPCOMING_PROD : ALGOLIA_INDEX.UPCOMING_NON_PROD;
  const hitsPerPage = isGetHitsPerPage ? ALGOLIA_LOTS_COMMON_CONFIG.hitsPerPage : 0;
  const attributesToRetrieve = isRetrieveAttributes
    ? ['currentBid', 'saleType', 'dateTimeUTCUnix', ...ALGOLIA_LOTS_COMMON_CONFIG.attributesToRetrieve]
    : [];
  const facets = [...ALGOLIA_LOTS_COMMON_CONFIG.facets, 'currentBid'];

  return {
    indexName,
    params: {
      ...ALGOLIA_LOTS_COMMON_CONFIG,
      hitsPerPage,
      attributesToRetrieve,
      facets
    }
  };
};

/**
 * Get the Algolia configuration for retrieving past lots.
 * @param {boolean} [isRetrieveAttributes=true] - Whether to retrieve attributes.
 * @param {boolean} [isGetHitsPerPage=true] - Whether to retrieve hits per page.
 * @returns {Object} The Algolia configuration object.
 */
export const getPastLotsAlgoliaConfig = (isRetrieveAttributes = true, isGetHitsPerPage = true) => {
  const indexName = ALGOLIA_INDEX.PAST_PROD;
  const hitsPerPage = isGetHitsPerPage ? ALGOLIA_LOTS_COMMON_CONFIG.hitsPerPage : 0;
  const attributesToRetrieve = isRetrieveAttributes
    ? ['priceResult', ...ALGOLIA_LOTS_COMMON_CONFIG.attributesToRetrieve]
    : [];

  const facets = [
    ...ALGOLIA_LOTS_COMMON_CONFIG.facets,
    'Fine Art',
    'Decorative Art',
    'Collectibles',
    'Furniture',
    'Asian Art & Antiques',
    'Dolls%2C Bears & Toys',
    'Automobiles%2C Boats & Airplanes',
    'Wines & Spirits',
    'Firearms',
    'priceResult'
  ];

  return {
    indexName,
    params: {
      ...ALGOLIA_LOTS_COMMON_CONFIG,
      hitsPerPage,
      attributesToRetrieve,
      facets
    }
  };
};

/**
 * Generates Algolia search parameters for retrieving upcoming and past lots associated with a particular artist.
 *
 * @param {string} artistRef - The reference of the artist.
 * @param {boolean} [isRetrieveAttributes=true] - Indicates whether to retrieve attributes of the lots.
 * @param {boolean} [isGetHitsPerPage=true] - Indicates whether to get the hits per page configuration.
 * @returns {Array} - An array containing Algolia search parameters for upcoming and past lots.
 */
export const getArtistUpcomingPastLotsAlgoliaParams = (
  artistRef,
  isRetrieveAttributes = true,
  isGetHitsPerPage = true,
) => {
  const currentTimeInUnix = getUnixTime(new Date());
  const pastLotsAlgoliaConfig = getPastLotsAlgoliaConfig(isRetrieveAttributes, isGetHitsPerPage);
  const upcomingLotsAlgoliaConfig = getUpcomingLotsAlgoliaConfig(isRetrieveAttributes, isGetHitsPerPage);

  const pastLotsAlgoliaParams = {
    ...pastLotsAlgoliaConfig,
    params: {
      ...pastLotsAlgoliaConfig.params,
      filters: `artistRef:${artistRef} AND onlineOnly:false AND banned:false AND hasImage:true AND dateTimeUTCUnix<${currentTimeInUnix}`,
    }
  };

  const upcomingLotsAlgoliaParams = {
    ...upcomingLotsAlgoliaConfig,
    params: {
      ...upcomingLotsAlgoliaConfig.params,
      filters: `artistRef:${artistRef} AND onlineOnly:false AND banned:false AND unlotted:false AND closed:false AND hasImage:true`,
    }
  };

  return [upcomingLotsAlgoliaParams, pastLotsAlgoliaParams];
};

/**
 * Fetches common data for an artist's upcoming and past routes pages.
 * @param {object} req - The HTTP request object.
 * @param {boolean} isProd - Indicates whether the function is running in production mode.
 * @param {string} path - The path of the current request.
 * @returns {Object} An object containing the url, page, store, headers, hostname, getState and initialState.
 */
export const getArtistUpcomingPastRoutesCommonData = async ({ req, isProd, indexName, selectedTab, path }) => {
  const { hostname, headers, cookies, params, query, url } = req;
  const isBot = getIsBot(req);

  const searchState = artistURLToSearchState(query, isProd, selectedTab);
  const { page = DEFAULT_PAGINATION_PAGE_NUMBER, dateType } = searchState;
  const artistRef = extractArtistRefFromURL(params.id);
  const location = parseLocationFromURL(url, hostname);
  const priceRange = getPriceRangeBySelectedTab(searchState, selectedTab);
  const isMobile = getIsMobileUserAgent(req);

  const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
  const { dispatch, getState } = store;

  await dispatch(fetchSessionInfo({ cookies, hostname }));
  await dispatch(setIsMobileUserAgent(isMobile));
  await dispatch(setSearchTypeAheadCookie(getCookie(SEARCH_TYPE_AHEAD, req.cookies)));
  await dispatch(setmyInvaluableCookie(getCookie(MY_INVALUABLE, req.cookies)));
  await dispatch(setLotsDisplayMode(DEFAULT_DISPLAY_MODE[selectedTab]));
  await dispatch(setNumberOfLotsPerPage(getDefaultNumberOfLotsPerPage(DEFAULT_DISPLAY_MODE[selectedTab])));

  const { authToken } = selectSessionInfo(getState());

  await Promise.all([
    fetchArtistBioInfo(artistRef, hostname),
    fetchArtistNotableSoldLots(artistRef, hostname),
    fetchFollowThisArtistStatus(artistRef, cookies, hostname),
    setIsProdEnv(isProd),
    setHostName(hostname),
    setUserCountryCode(req.headers['cf-ipcountry']),
    setArtistDateFilter({ date: dateType }),
    setArtistPriceRangeFilter(priceRange),
    setCurrentPageNumber(page),
    fetchFrmtAndSetHousesSellingArtistWork(artistRef),
  ].map(request => dispatch(request)));

  await dispatch(fetchUserSimilarArtistsData(artistRef, searchClient(isBot)));

  const popularArtistsRefs = getPopularArtistsRefs();
  const similarArtistsRefs = selectSimilarArtistsRefs(getState());

  await dispatch(
    fetchUserArtistFollowStatus(cookies, hostname, authToken, [...popularArtistsRefs, ...similarArtistsRefs])
  );

  const resultsState = await fetchAlgoliaResultsStateServerSide(FiltersAndResultsWrapper, store, {
    algoliaSearchState: searchState,
    indexName,
    artistRef,
    searchClient: searchClient(isBot),
    store,
    location,
    url,
    path,
  });

  const initialState = {
    resultsState,
    searchState,
    artistRef,
    location,
    indexName,
    isBot,
  };

  return {
    url,
    page,
    store,
    headers,
    hostname,
    getState,
    initialState,
  };
};
