import React from 'react';
import PropTypes from 'prop-types';

import { ARROW_DIRECTIONS } from './constants';

export function Arrow({ className, style, onClick, direction }) {
  return (
    <button className={className} style={style} onClick={onClick}>
      <i className={`fa fa-angle-${direction}`} />
    </button>
  );
}

Arrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(Object.values(ARROW_DIRECTIONS)).isRequired,
};

Arrow.defaultProps = {
  className: '',
  style: {},
  onClick: () => null,
};
