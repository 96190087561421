import React, { useEffect, useState } from 'react';

import { LBCAnnouncementBanner } from 'inv-common-components';

export function LBCAnnouncement() {
  const [isAnnouncementBannerVisible, setIsAnnouncementBannerVisible] = useState(true);

  useEffect(() => {
    localStorage.setItem('LBCBanner', 'true');
  }, []);

  if (localStorage.getItem('LBCBanner') !== 'false') {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div
        id="lbc-announcement-banner"
        className="d-none"
      >
        <LBCAnnouncementBanner
          isBannerOpen={isAnnouncementBannerVisible}
          setIsBannerOpen={setIsAnnouncementBannerVisible}
          onBannerClose={() => localStorage.setItem('LBCBanner', 'false')}
        />
      </div>
    );
  }

  return null;
}

export default LBCAnnouncement;
