import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { MY_ACCOUNT_LINKS } from '../utils';
import { MyAccountItem } from './myAccountItem';

function MyAccount({ userCounts }) {
  const isVip = useSelector((state) => get(state, 'invReactCommon.isVIP', false));
  return (
    <div id="loggedInFlyoutDesktop" className="col-sm-4 col-md-4 col-lg-4 col-xl-2 my-profile-section-v3">
      <div className="inner-div">
        <div className="inner-block">
          {
            MY_ACCOUNT_LINKS.My_ACCOUNT_SECTION.map((link) => (
              <MyAccountItem link={link} userCounts={userCounts} key={`account-link-${link.label}`} />
            ))
          }
          {
            isVip && (
            <div className="item mb-3">
              <a className="user-menu-tag" href="/inv/vip">
                <img
                  width="20"
                  className="ml-2"
                  src="https://www.invaluable.com/content/images/VIPBidder_Paddle.svg"
                  alt="VIP Badge"
                />&nbsp;VIP Benefits
              </a>
            </div>
            )
          }
        </div>
        <div className="inner-block pl-4">
          <span className="mb-4">Following</span>
          {
            MY_ACCOUNT_LINKS.MY_ACCOUNT_FOLLOWING.map((link) => (
              <MyAccountItem link={link} userCounts={userCounts} key={`account-link-${link.label}`} />
            ))
          }
        </div>
        <div className="inner-block">
          <MyAccountItem link={MY_ACCOUNT_LINKS.MY_DIGITAL_GALLARY} />
        </div>
        <div className="inner-block">
          {
            MY_ACCOUNT_LINKS.MY_ACCOUNT_SETTINGS.map((link) => (
              <MyAccountItem link={link} userCounts={userCounts} key={`account-link-${link.label}`} />
            ))
          }
        </div>
        <div className="inner-block">
          <div className="item mb-0">
            <a id="heap-tag-id" className="user-menu-tag" href="/logout.cfm">Sign Out</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
