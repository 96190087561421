import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropType from 'prop-types';

import { MODAL_TRANSITION_TIME_OUT_MS } from 'src/components/common/constants';
import Filters from 'src/components/artist-detail/filters-and-lots-view/Filters';
import ClearRefinements from 'src/components/common/algolia/clear-refinements';
import { FilteredLotsCount } from 'src/components/artist-detail/filters-results-wrapper/FilteredLotsCount';
import { SortAndViewControls } from 'src/components/artist-detail/filters-results-wrapper/SortAndViewControls';

function MobileFiltersModal({ closeModal, isModalOpen }) {
  return (
    <Modal
      className="custom-modals-global filter-mobile-view-modal"
      isOpen={isModalOpen}
      toggle={closeModal}
      modalTransition={{ timeout: MODAL_TRANSITION_TIME_OUT_MS }}
      size="md"
      centered
      fade
      unmountOnClose={false}
    >
      <ModalHeader
        toggle={closeModal}
        tag="div"
      >
        <h4>Filter & Sort</h4>
        <FilteredLotsCount />
      </ModalHeader>
      <ModalBody>
        <div className="filter-mobile-view-container">
          <SortAndViewControls />
          <Filters />
          <div className="apply-and-clear-btns">
            <button className="btn btn-primary" onClick={closeModal}>Apply Changes</button>
            <ClearRefinements />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

MobileFiltersModal.propTypes = {
  closeModal: PropType.func.isRequired,
  isModalOpen: PropType.bool.isRequired,
};

export default MobileFiltersModal;
