import React from 'react';

function MobileFilterIcon() {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="4" width="6" height="3" rx="1.5" />
      <rect x="11" y="4" width="19" height="3" rx="1.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 8C9.88071 8 11 6.88071 11 5.5C11 4.11929 9.88071 3 8.5 3C7.11929 3 6 4.11929 6 5.5C6 6.88071 7.11929 8 8.5 8ZM8.5 11C11.5376 11 14 8.53757 14 5.5C14 2.46243 11.5376 0 8.5 0C5.46243 0 3 2.46243 3 5.5C3 8.53757 5.46243 11 8.5 11Z" />
      <rect x="24" y="14" width="6" height="3" rx="1.5" />
      <rect y="14" width="19" height="3" rx="1.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.5 18C22.8807 18 24 16.8807 24 15.5C24 14.1193 22.8807 13 21.5 13C20.1193 13 19 14.1193 19 15.5C19 16.8807 20.1193 18 21.5 18ZM21.5 21C24.5376 21 27 18.5376 27 15.5C27 12.4624 24.5376 10 21.5 10C18.4624 10 16 12.4624 16 15.5C16 18.5376 18.4624 21 21.5 21Z" />
      <rect y="24" width="6" height="3" rx="1.5" />
      <rect x="11" y="24" width="19" height="3" rx="1.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 28C9.88071 28 11 26.8807 11 25.5C11 24.1193 9.88071 23 8.5 23C7.11929 23 6 24.1193 6 25.5C6 26.8807 7.11929 28 8.5 28ZM8.5 31C11.5376 31 14 28.5376 14 25.5C14 22.4624 11.5376 20 8.5 20C5.46243 20 3 22.4624 3 25.5C3 28.5376 5.46243 31 8.5 31Z" />
    </svg>
  );
}

export default MobileFilterIcon;
