import React, { useEffect } from 'react';
import SuggestionSearch from './components/SuggestionSearch';
import PopularSearches from './components/PopularSearches';
import RecentlyViewed from './components/recentlyViewed';
import RecentSearches from './components/RecentSearches';
import ArtistsAndHouses from './components/artistsAndHouses';
import Auctions from './components/auctions';

function MobileSuggestionDropdown(props) {
  const handleClickOutside = (event) => {
    const element = document.getElementById('suggestionDropdown');
    const btnIds = ['search-go-btn', 'arrow-right'];
    if (element && !element.contains(event.target) && !btnIds.includes(event.target.id)) {
      props.onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div
      id="suggestionDropdown"
      role="listbox"
      className="suggestions-dropdown show-on-mobile"
    >
      <div className="row">
        {props.value ? (
          <div className="col-md-4 section-divider">
            <SuggestionSearch
              key="suggested-searches"
              value={props.value}
              hits={props.hits}
            />
          </div>
        ) : (
          <div className="col-md-4 section-divider">
            <RecentSearches />
          </div>
        )}
        {props.value ? (
          <div className="col-md-4 section-divider">
            <ArtistsAndHouses
              value={props.value}
              hits={props.hits}
            />
          </div>
        ) : (
          <div className="col-md-4 section-divider">
            <PopularSearches />
          </div>
        )}
        <div className="col-md-4 section-divider">
          {
            props.value ? <Auctions hits={props.hits} /> : <RecentlyViewed isMobile={props.isMobile} />
          }
        </div>
      </div>
    </div>
  );
}

export default MobileSuggestionDropdown;
