import axios from 'axios';
import browserDetect from 'browser-detect';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { get } from 'lodash';
import { isClientProdEnv } from 'src/utils/common/helpers';

export const initFirebase = (isProd) => {
  firebase.initializeApp({
    apiKey: isProd ? 'AIzaSyA8S2e16po15jI5F8Dk4J0NiHgEUhdosjY' : 'AIzaSyCcTfjcBnUh C11zEJ-jXu2D45-TyR5sahk',
    authDomain: isProd ? 'web-push-notifications-prod.firebaseapp.com' : 'web-push-notifications-stage.firebaseapp.com',
    databaseURL: isProd ? 'https://web-push-notifications-prod.firebaseio.com' : 'https://web-push-notifications-stage.firebaseio.com',
    projectId: isProd ? 'web-push-notifications-prod' : 'web-push-notifications-stage',
    storageBucket: isProd ? 'web-push-notifications-prod.appspot.com' : 'web-push-notifications-stage.appspot.com',
    messagingSenderId: isProd ? '109409413375' : '239007205203',
    appId: isProd ? '1:109409413375:web:79ad4b27aee1d3335f04ca' : '1:239007205203:web:c546b83e03d7f5779cc3a6',
  });
};

// Handler for getting FCM token and connecting push notifications permissions with firebase
async function firebaseCall(memberId) {
  // We need for ServiceWorkerRegisteration instance
  const registration = await navigator.serviceWorker.ready;
  const messaging = firebase.messaging();

  // Wrap statement in try catch to prevent bugsnag logging false errors
  try {
  // retrieve firebase cloud messaging token generated for this browser instance
    const token = await messaging.getToken({
      serviceWorkerRegistration: registration,
      vapidKey: '',
    });
    const urlEnv = window.location.hostname.split('.')[0];
    console.log('url env is ===>>>>', urlEnv);
    // Pass current user's memberID, token to register to firebase
    await axios.post(
      `https://${urlEnv}.invaluable.com/push/deviceRegistration`,
      {
        memberID: memberId,
        deviceToken: token,
        deviceType: 'web',
      },
      { headers:
    { Accept: 'application/json',
      'Content-Type': 'application/json' },
      }
    )
      .then(() => {}, (error) => {
        console.error(error);
      });
  } catch (err) {
    console.error(err);
  }
}

export function handleBlockNotifications() {
  const numWeeks = 2;
  const now = new Date();
  localStorage.setItem('pushRetryPopUp', now.setDate(now.getDate() + numWeeks * 7));
}

const checkRemotePermission = async function (permissionData, memberId) {
  if (permissionData.permission === 'default') {
    const isProd = isClientProdEnv();
    // This is a new web service URL and its validity is unknown.
    window.safari.pushNotification.requestPermission(
      `https://${isProd ? 'www' : 'stage'}.invaluable.com/push`, // The web service URL.
      'web.com.invaluable.webpush', // The Website Push ID.
      { memberID: String(memberId) }, // Data that you choose to send to your server to help you identify the user.
      (permData) => checkRemotePermission(permData, memberId) // The callback function.
    );
  } else if (permissionData.permission === 'denied') {
    handleBlockNotifications();
  } else if (permissionData.permission === 'granted') {
    // The web service URL is a valid push provider, and the user said yes.
    // permissionData.deviceToken is now available to use.
    // Pass current user's memberID, token to register to firebase
    try {
      await axios.post(
        '/push/deviceRegistration',
        {
          memberID: memberId,
          deviceToken: permissionData.deviceToken,
          deviceType: 'safari',
        },
        {
          headers:
          {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
};

function registerSafariNotifs(memberId) {
  // Ensure that the user can receive Safari Push Notifications.
  if ('safari' in window && 'pushNotification' in window.safari) {
    const permissionData = window.safari.pushNotification.permission('web.com.invaluable.webpush');
    checkRemotePermission(permissionData, memberId);
  }
}

export function allowNotifications(memberId) {
  const browser = browserDetect();
  const notification = get(window, 'Notification', 'none');
  const notificationPermission = get(notification, 'permission', 'none');

  // We need to check our browser for browser type, service will not be registered properly without this
  if (notificationPermission === 'default') {
    if (browser && browser.name === 'safari') {
      registerSafariNotifs(memberId);
    } else if (browser && browser.name === 'firefox') {
      // Since firefox has disabled the box popup like google chrome we have to watch
      // the notification permissions status in order to properly register firebase and its token
      notification.requestPermission(() => {
        if ('permissions' in navigator) {
          navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
            if (notificationPerm.state === 'granted') {
              firebaseCall(memberId);
            }
            notificationPerm.onchange = () => {
              if (notificationPerm.state === 'granted') {
                firebaseCall(memberId);
              }
            };
          });
        }
      });
    } else {
      firebaseCall(memberId);
    }
  }
}

export async function registerNotifications(callback, memberId) {
  const browser = browserDetect();
  const notification = get(window, 'Notification', 'none');
  const notificationPermission = get(notification, 'permission', 'none');
  const openModal = window.location.search;
  if (browser.name === 'firefox' || browser.name === 'chrome' || browser.name === 'safari') {
    if (notificationPermission === 'default') {
      if ((localStorage.getItem('pushRetryPopUp') < new Date().getTime() || localStorage.getItem('pushRetryPopUp') === null) && openModal.indexOf('modalOpen') === -1) {
        callback(true);
        localStorage.removeItem('pushRetryPopUp');
      }
    } else {
    // We need to check our browser for browser type, service will not be registered properly without this
    // eslint-disable-next-line no-lonely-if
      if (browser && browser.name === 'safari') {
        registerSafariNotifs(memberId);
      } else if (browser && browser.name === 'firefox') {
      // Since firefox has disabled the box popup like google chrome we have to watch
        // the notification permissions status in order to properly register firebase and its token
        notification.requestPermission(() => {
          if ('permissions' in navigator) {
            navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
              if (notificationPerm.state === 'granted') {
                firebaseCall(memberId);
              }
              notificationPerm.onchange = () => {
                if (notificationPerm.state === 'granted') {
                  firebaseCall(memberId);
                }
              };
            });
          }
        });
      } else {
        firebaseCall(memberId);
      }
    }
  }
}
