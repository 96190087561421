import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { MOBILE_MEDIA_QUERY } from 'src/components/common/constants';
import { selectIsMobile } from 'src/selectors/inv-common';

const useMedia = (query, defaultState = false) => {
  const [state, setState] = useState(defaultState);
  const isMobileScreen = useSelector(selectIsMobile);

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    const mql = window.matchMedia(query);
    const onChange = () => {
      if (!isMountedRef.current) return;
      setState(!!mql.matches);
    };

    mql.addListener(onChange);
    setState(mql.matches);

    return () => {
      isMountedRef.current = false;
      mql.removeListener(onChange);
    };
  }, [query]);

  // isMobileScreen represents, if it is mobile device on the server side
  if (!isMountedRef.current && query === MOBILE_MEDIA_QUERY) {
    return isMobileScreen;
  }

  return state;
};

export default useMedia;
