import Styled from 'styled-components';

const mediaQuerySizes = {
  xl: '(min-width: 1140px)'
};

export const SearchBarWrapper = Styled.div`
    position: relative;

    &.expended {
        width: 90%;

        @media ${mediaQuerySizes.xl} {
            width: 100%;
        }
    }
`;

export const CloseButton = Styled.button`
    position: absolute;
    padding: 0;
    margin: 0;
    width: 50px;
    height: 100%;
    left: 100%;
    background: transparent !important;
    border: none !important;
`;
