import React from 'react';
import { useSelector } from 'react-redux';

import { selectArtistGenres } from 'src/selectors/artist-detail';

import Aliases from './Aliases';
import Biography from './Biography';

function ArtistDetailedInfo() {
  const artistGenres = useSelector(selectArtistGenres);

  const artistRelatedStylesAndMovements = artistGenres.map((genre) => genre.genreName).join(', ');

  return (
    <div
      className="artist-detailed-info"
    >
      {artistRelatedStylesAndMovements && (
      <div className="detailed-info-widget">
        <h3>Related Styles/Movements</h3>
        <p>{artistRelatedStylesAndMovements}</p>
      </div>
      )}
      <Aliases />
      <Biography />
    </div>
  );
}

export default ArtistDetailedInfo;
