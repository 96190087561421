/**
 * Returns an object containing number of followers
 * for each similar artist in the input array.
 *
 * @param {Array} userSimilarArtists
 * An array of objects representing similar artists. Each object should have the following properties:
 * - artistRef: A reference to the artist.
 * - followerCount: An integer indicating the number of followers the artist has.
 *
 * @returns {Object} An object mapping artist references to their number of followers.
 */
export const getSimilarArtistsFollowersCount = (userSimilarArtists) => (
  userSimilarArtists.reduce((accumulator, { artistRef, followerCount }) => ({
    ...accumulator,
    [artistRef]: followerCount
  }), {})
);

/**
 * Returns an object containing the number of followers
 * for each popular artist in the input array, based on the user's data.
 *
 * @param {Array} popularArtists
 * An array of objects representing popular artists. Each object should have the following property:
 * - artistRef: A reference to the artist.
 *
 * @param {Object} userPopularArtistsData
 * An object mapping artist references to an object with properties:
 * - followerCount: An integer indicating the number of followers the artist has.
 *
 * @returns {Object} An object mapping artist references to their number of followers.
 */
export const getPopularArtistsFollowersCount = (popularArtists, userPopularArtistsData) => (
  popularArtists.reduce((accumulator, { artistRef }) => {
    const { followerCount } = userPopularArtistsData[artistRef];

    return { ...accumulator, [artistRef]: followerCount };
  }, {})
);
