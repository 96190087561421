import axios from 'axios';
import moment from 'moment-timezone';

import { routes } from '../../../config';
import {
  FIXED_DECIMAL_PLACES_CONVERSION,
  PDP_GA_DATA_ATTRIBUTE_ID,
  PDP_GA_DATA_ELEMENT_ID,
  REGEX_TO_REMOVE_ANY_EXTRA_SPACE,
  REGEX_TO_REMOVE_ANY_LINKS_WITH_ANCHOR_TAG,
  REGEX_TO_REMOVE_ANY_URL_WITH_HTTP_OR_HTTPS,
  SESSION_STORAGE_KEY_TO_HIDE_NOTIFICATION_POPUP,
} from './constants';

/**
 * A helper to transform the given image URL to high resolution image URL
 * @param imageURL {string}
 * @returns {string}
 */
export const getHighResolutionImageUrl = imageURL => imageURL && imageURL.replace('.jpg', '_original.jpg');

/**
 * Returns the RFA link that can be used to register the bidder for bidding.
 * If the bidder is not logged in, the log in pop up is shown.
 * @param catalogRef
 * @param lotRef
 * @returns RFA link
 */
export const getRFALink = (catalogRef, lotRef) => (
  `/bidNow/reqForApproval?catalogRef=${catalogRef}&lotRef=${lotRef}`
);

/**
 * Formats the auction date given the required format.
 * @param date iso format date
 * @param timeZoneRegion time zone region name
 * @param format moment compatible date/time format
 * @param timeZone time zone name
 * @returns formatted date
 */
export const formatAuctionDate = ({ date, timeZoneRegion, format, timeZone }) => {
  const auctionDate = moment(date).tz(timeZoneRegion);

  return timeZone ? `${auctionDate.format(format)} ${timeZone}` : auctionDate.format(format);
};

/**
 * A helper function to take the auction house name and reference and convert it to a valid auction house URL
 * @param name
 * @param ref
 * @returns string: Link to the auction house
 */
export const getAuctionHouseURL = (name, ref) => {
  const spacesRegex = /\s/g;
  const nameLower = name.toLowerCase().replace(spacesRegex, '-');
  const refLower = ref.toLowerCase();
  return `auction-house/${nameLower}-${refLower}`;
};

/**
 * A helper function to fetch oas headers
 * @returns object: OASHeaders
 */
export const fetchOASHeaders = async () => {
  const headersResponse = await axios.get(`${routes.api.SESSION_INFO}?t=${new Date().getTime()}`);
  return headersResponse.data;
};

/**
 * A helper function to remove any occurrence of links with anchor tag, URL and remove the extra spaces from the text
 * @examples <a href='http://www.google.com'>Click me</a> or https://www.google.com or http://www.google.com
 * @param text
 * @returns remove any occurrence of links from the text
 */
export const removeLinksFromText = text => text.replace(REGEX_TO_REMOVE_ANY_LINKS_WITH_ANCHOR_TAG, '')
  .replace(REGEX_TO_REMOVE_ANY_URL_WITH_HTTP_OR_HTTPS, '')
  .replace(REGEX_TO_REMOVE_ANY_EXTRA_SPACE, ' ')
  .trim();

export const removeHtmlTags = html => html.replace(/(<([^>]+)>)/ig, '');

export const getPDPGoogleAnalyticsData = () => {
  try {
    const gaAnalyticsEle = document.getElementById(PDP_GA_DATA_ELEMENT_ID);
    const gaAnalyticsAttr = gaAnalyticsEle.getAttribute(PDP_GA_DATA_ATTRIBUTE_ID);
    return JSON.parse(gaAnalyticsAttr);
  } catch (err) {
    return {};
  }
};

/**
 * A helper function to hide notification popup which is invoked after a successfull login
 */
export const hideNotificationPopup = () => {
  sessionStorage.setItem(SESSION_STORAGE_KEY_TO_HIDE_NOTIFICATION_POPUP, true);
};

/**
 * A helper function to format amount with currency and its symbol
 */
export const appendCurrencyAndSymbolWithAmount = (amount, currency, symbol) => (symbol ? `${symbol}${amount} ${currency}` : `${currency} ${amount}`);

/**
 * A helper function to convert an amount upto fixed decimal places
 * @param {number} amount - value which needs to be converted
 * @returns {string} - converted amount
 */
export const convertNumberInFixedDecimalPlaces = (amount) => {
  const convertedNumber = parseFloat(amount).toLocaleString(
    undefined, // it is necessary to use the user's system locale as we're doing in other places
    {
      minimumFractionDigits: FIXED_DECIMAL_PLACES_CONVERSION,
      maximumFractionDigits: FIXED_DECIMAL_PLACES_CONVERSION,
    }
  );
  return convertedNumber;
};

export const sendABToHeap = (testName, variantName, timeLimit) => {
  let limit = timeLimit;
  if (limit > 7600) {
    return;
  }

  if (window.heap) {
    window.heap.removeEventProperty(testName);
    const eventProps = {};
    eventProps[testName] = variantName;
    window.heap.addEventProperties(eventProps);
    window.heap.track(testName, { variant: variantName });
  } else {
    setTimeout(() => {
      limit += 500;
      sendABToHeap(testName, variantName, limit);
    }, 1000);
  }
};
