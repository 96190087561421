import {
  FETCH_CATALOG_ERROR,
  FETCH_CATALOG_LOADING,
  FETCH_CATALOG_SUCCESS,
  FETCH_CURRENCY_ARR,
  UPDATE_TAB_VISIBILITY,
  SYNC_LOT_VISIBILTY,
  UPDATE_LOT_INFO_PROPERTIES,
} from '../actions/constants';

const initialState = {
  catalogInfo: {},
  isLoadingCatalog: false,
  upcomingAuctions: null,
  type: null,
  isLoggedIn: false,
  IsTabVisible: true,
  visibleLots: [],
  lotInfoProperties: [],
};

function catalog(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATALOG_SUCCESS: {
    /*
     ** Set postLoginAction value to the state if the cookie exist
     ** then immediately delete the cookie by expiring it.
     */
      let postLoginAction;

      if (action.payload.cookie.includes('postLoginAction')) {
        postLoginAction = action.payload.cookie.split(';')
          .find(cookie => cookie.includes('postLoginAction'))
          .split('=')[1];
      }
      return {
        ...state,
        catalogInfo: action.payload.catalogRes,
        isLoadingCatalog: false,
        memberID: action.payload.memberID,
        xAuthToken: action.payload.xAuthToken,
        isLoggedIn: action.payload.isLoggedIn,
        postLoginAction,
        type: action.type,
      };
    }

    case FETCH_CATALOG_ERROR:
      return {
        ...state,
        type: action.type,
        errorMessage: action.payload,
        isLoadingCatalog: false,
      };

    case FETCH_CATALOG_LOADING:
      return {
        ...state,
        type: action.type,
        isLoadingCatalog: true,
      };

    case FETCH_CURRENCY_ARR:
      return {
        ...state,
        type: action.type,
        currencyCode: action.payload[0].currencyCode,
        conversionRate: action.payload[0].conversionRate,
      };

    case UPDATE_TAB_VISIBILITY:
      return {
        ...state,
        IsTabVisible: action.payload,
      };

    case SYNC_LOT_VISIBILTY:
      return {
        ...state,
        visibleLots: action.payload,
      };

    case UPDATE_LOT_INFO_PROPERTIES:
      return {
        ...state,
        lotInfoProperties: action.payload,
      };

    default:
      return state;
  }
}

export default catalog;
