import React from 'react';
import { urls } from 'invaluable-solitaire';
import { InstantSearch, Index, Configure } from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { UPCOMING_LOTS_INDEX, HOUSE_INDEX, NO_OF_RESULTS, agoliaSearchClient } from '../config';
import AutoComplete from './autocomplete';

function AlgoliaSearchBar({ isMobile }) {
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));

  const redirectTo = (hit) => {
    if (hit.query) {
      window.location.href = `/search?keyword=${hit.query}`;
      return;
    }
    if (hit.houseName) {
      window.location.href = `/auction-house/${urls.seoFriendlyUrl(hit.houseName)}-${urls.seoFriendlyUrl(hit.houseRef)}`;
    }
    if (hit.name) {
      window.location.href = hit.url;
    }
  };

  return (
    <InstantSearch
      indexName={UPCOMING_LOTS_INDEX}
      searchClient={agoliaSearchClient}
    >
      <Configure
        hitsPerPage={NO_OF_RESULTS}
        clickAnalytics
      />
      <AutoComplete
        isMobile={isMobile}
        onSuggestionSelected={(event, { suggestion }) => {
          redirectTo(suggestion);
        }}
      />

      <Index indexName={UPCOMING_LOTS_INDEX} />
      <Index indexName={isProd ? HOUSE_INDEX.prod : HOUSE_INDEX.dev} />
    </InstantSearch>
  );
}

export default AlgoliaSearchBar;
