import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsProd, selectUserPopularArtistsData } from 'src/selectors/inv-common';
import { getImageBaseDomain } from 'src/utils/common/helpers';

import ArtistCard from './ArtistCard';
import { Carousel } from './carousel';
import { IMAGE_CDN_ARTIST_RELATIVE_URL, STATIC_POPULAR_ARTISTS } from './constants';

function PopularArtists() {
  const userPopularArtistsData = useSelector(selectUserPopularArtistsData);
  const isProduction = useSelector(selectIsProd);

  return (
    <div className="artist-carousel-container">
      <h2>Popular Artists</h2>
      <Carousel>
        {STATIC_POPULAR_ARTISTS.map(({ artistRef, name, imageURL, redirectURL }) => (
          <ArtistCard
            key={artistRef}
            name={name}
            imageURL={`${getImageBaseDomain(isProduction)}/${IMAGE_CDN_ARTIST_RELATIVE_URL}${imageURL}`}
            redirectURL={redirectURL}
            artistRef={artistRef}
            userArtistData={userPopularArtistsData[artistRef]}
          />
        ))}
      </Carousel>
    </div>
  );
}

export default PopularArtists;
