import React, { useEffect, useState } from 'react';
import { PopoverBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import axios from 'axios';
import classNames from 'classnames';
import { MY_INVALUABLE } from 'routes/utils/constants';
import { MyInvaluablePopover } from './styles';
import { formatBidsAndFav, getArtistHits, trackClick } from './utils';
import FollowedArtists from './components/followedArtists';
import Auctions from './components/auctions';
import BidsAndFavorites from './components/bidsAndFavorites';
import MyAccount from './components/myAccount';
import { MY_INVALUABLE_VERSIONS, REQUEST_URLS } from './constants';
import { sendABToHeap } from '../searchBar/config';

export default function MyInvaluable({ userCounts, version }) {
  const userId = useSelector((state) => get(state, 'invReactCommon.sessionInfo.memberId', 'false'));
  const xAuthToken = useSelector((state) => get(state, 'invReactCommon.sessionInfo.authToken', 'false'));
  const [myInvaluableData, setMyInvaluableData] = useState(null);
  const columnClasses = classNames(
    'sections-row',
    { 'col-md-8 col-lg-8 col-xl-10': version === MY_INVALUABLE_VERSIONS.version1 },
    { 'col-md-10 col-lg-10 col-xl-12': version === MY_INVALUABLE_VERSIONS.version2 }
  );

  const fetchData = async () => {
    try {
      const headers = {
        Accept: 'application/json',
        'Access-Control-Allow-Headers': '*',
        'x-auth-token': xAuthToken,
        'Content-Type': 'application/json; charset=utf-8',
        'member-id': userId,
      };
      const promises = REQUEST_URLS.map(url => axios.get(url, { headers }));
      const myInvData = await Promise.all(promises);
      const followedArtists = await getArtistHits(get(myInvData, '[0].data.content'), []);
      const bidsAndFavorites = await formatBidsAndFav(
        false,
        get(myInvData, '[2].data._embedded.auctionLotUserItemViewList', []),
        get(myInvData, '[3].data._embedded.savedItemViewList', []),
      );
      const auctions = get(myInvData, '[1].data._embedded.registeredAuctionsViewList', []);
      setMyInvaluableData({
        followedArtists,
        auctions,
        bidsAndFavorites
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
    sendABToHeap('My Invaluable Hover Unit', {}, 0, MY_INVALUABLE);
  }, []);

  return (
    <>
      {
        version === MY_INVALUABLE_VERSIONS.version2 && (
          <a
            id="myInvaluablePopover"
            href="/my-account/home"
            className="logged-in-link btn"
            aria-label="My Account Flyout"
            aria-expanded="false"
            onClick={() => trackClick('My Invaluable')}
          >
            <span id="headerUserIcon" className="fa fa-user" />
            <span className="ml-1 user-name notranslate">&nbsp; My Invaluable</span>
          </a>
        )
      }
      {
        myInvaluableData && (
        <MyInvaluablePopover
          placement="top"
          target="myInvaluablePopover"
          trigger="focus hover"
          className="showPopoverV3 popover nav-popover fade top in top-0"
        >
          <PopoverBody className="popover-content my-invaluable-version-v1">
            <div className="row row-styles in-v4 my-invaluable-popover">
              <div className={columnClasses}>
                <div className="row header">
                  <h1>
                    My Invaluable
                  </h1>
                  <a href="/my-account/home" className="outline-button">View All</a>
                </div>
                <div className="row blocks-ctn">
                  <FollowedArtists followedArtists={myInvaluableData.followedArtists} />
                  <Auctions auctions={myInvaluableData.auctions} />
                  <BidsAndFavorites savedItems={myInvaluableData.bidsAndFavorites || []} />
                </div>
              </div>
              {version === MY_INVALUABLE_VERSIONS.version1 && <MyAccount userCounts={userCounts} />}
            </div>
          </PopoverBody>
        </MyInvaluablePopover>
        )
      }
    </>
  );
}
