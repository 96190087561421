import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  connectMenu,
} from 'react-instantsearch-dom';

function CustomMenuSelect({ items, currentRefinement, refine, title, label }) {
  return (
    <Fragment>
      {title && <h3>{ title }</h3>}
      <div className="ais-MenuSelect filter-wrapper filter-no-bottom-border">
        <select
          value={currentRefinement || ''}
          onChange={event => refine(event.currentTarget.value)}
          className="algolia-select"
        >
          <option value="">{label}</option>
          {items.map(item => (
            <option
              key={item.label}
              value={item.isRefined ? currentRefinement : item.value}
              className="ais-MenuSelect-option"
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </Fragment>
  );
}

CustomMenuSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentRefinement: PropTypes.number.isRequired,
  refine: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default connectMenu(CustomMenuSelect);
