/* eslint-disable no-unused-vars */
import React from 'react';
import { PropTypes, bool, number, string, oneOfType } from 'prop-types';
import { connectHitsPerPage } from 'react-instantsearch-dom';

function HitsPerPage({ items, refine, setPageSize, currentRefinement }) {
  const handleChange = (e) => {
    const { value } = e.target;
    refine(value);
    setPageSize(value);
  };

  return (
    <div className="ais-HitsPerPage">
      <select
        className="algolia-select"
        name="hitsPerPage"
        onChange={handleChange}
        value={currentRefinement}
      >
        {items.map(item => (
          <option
            key={item.label}
            className="ais-HitsPerPage-option"
            value={item.value}
          >{item.label}
          </option>
        ))}
      </select>
    </div>
  );
}

HitsPerPage.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isRefined: bool.isRequired,
      value: number.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  currentRefinement: oneOfType([
    string,
    number,
  ]).isRequired,
  refine: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
};

export default connectHitsPerPage(HitsPerPage);
