import { UncontrolledPopover } from 'reactstrap';
import Styled from 'styled-components';

export const MyInvaluablePopover = Styled(UncontrolledPopover)`
    width: 100%;
    max-width: none !important;
    .popover {
        top: 18px !important;
        max-width: none !important;
        width: 100%;
        z-index: 1049;

        .popover-body {
            margin: 0 !important;
            padding: 0 !important;
        }
        .row-styles {
            padding-left: 15px !important;
            margin: 0 !important;
            @media only screen and (min-width: 992px) {
                padding-left: 60px !important;
            } 
        }
    }
`;

export const AddToCalendarMenuPopover = Styled(UncontrolledPopover)`
    .popover {
        width: 140px;

        ul {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            margin: 0;
        }
    }
`;

export const StatusWrapper = Styled.p`
    margin-bottom: 0 !important;
`;
