import { urls as solitaireUrls } from 'invaluable-solitaire';
import { getArtistSeoFriendlyPath } from 'src/utils/artist-detail';

import { routes } from '../../../config';
import { PAST_UPCOMING_LOTS_PATH } from './constants';

/**
 * Formats the given birth death dates
 * @param {String} birthAndDeathDates e,g. 1552-1615
 * @returns formatted date string, b. 1552 - d. 1615
 */
const getFormattedBirthAndDeathDates = (birthAndDeathDates) => {
  if (!birthAndDeathDates) return '';

  const [birth = '', death = ''] = birthAndDeathDates.split('-');
  const formattedBirth = birth && `b. ${birth}`;
  const formattedDeath = death && `d. ${death}`;

  return `${formattedBirth} - ${formattedDeath}`;
};

/**
 * Uses professions and birthAndDeathDates to generate formatted professions and era
 * @param {List} professions
 * @param {String} birthAndDeathDates e,g. 1552-1615
 * @returns string, formatted profession and era to show
 */
// eslint-disable-next-line import/prefer-default-export
export const getFormattedProfessionsAndEra = (professions = [], birthAndDeathDates = '') => {
  const formattedProfessions = professions.join(', ');
  const formattedDates = getFormattedBirthAndDeathDates(birthAndDeathDates);
  const showProfessionDateSeparator = formattedProfessions && formattedDates;

  return `${formattedProfessions}${showProfessionDateSeparator && ', '}${formattedDates}`;
};

/**
 * Returns the Auction house's SEO-friendly URL.
 * @param {string} houseName - The name of the auction house.
 * @param {string} houseRef - The reference of the auction house.
 * @returns {string} The SEO-friendly URL of the auction house.
 */
export const getAuctionHouseSeoFriendlyURL = (houseName, houseRef) => `${routes.AUCTION_HOUSE}/${solitaireUrls.seoFriendlyUrl(houseName)}-${solitaireUrls.seoFriendlyUrl(houseRef)}`;

/**
 * Returns the Auction lot's SEO-friendly URL.
 * @param {string} lotTitle - The title of the auction lot.
 * @param {number} lotNumber - The auction lot number.
 * @param {string} lotRef - The reference of the auction lot.
 * @returns {string} The SEO-friendly URL of the auction lot.
 */
export const getAuctionLotSeoFriendlyURL = (lotTitle, lotNumber, lotRef) => {
  const { seoFriendlyUrl } = solitaireUrls;

  return `/auction-lot/${seoFriendlyUrl(lotTitle)}-${seoFriendlyUrl(lotNumber)}-c-${seoFriendlyUrl(lotRef)}`;
};

/**
 * Returns the URL for the artist's detail page for past/upcoming auctions
 * @param {Object} artist - The artist object
 * @param {string} hostname - The hostname for the URL
 * @param {boolean} isUpcoming - Flag to indicate whether the link is for upcoming auctions or not
 * @returns {string} The URL for the artist's detail page for past/upcoming auctions
 */
export const createArtistDetailURLForPastUpcomingPages = (artist, hostname, isUpcoming) => {
  const { lastName, firstName, artistRef } = artist;
  const auctionType = isUpcoming ? PAST_UPCOMING_LOTS_PATH.UPCOMING : PAST_UPCOMING_LOTS_PATH.PAST;
  const artistPath = getArtistSeoFriendlyPath({ firstName, lastName, artistRef });

  return `https://${hostname}${artistPath}${auctionType}`;
};
