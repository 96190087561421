import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrencyConversionRates, selectHostname, selectUserCountryCode } from '../selectors/inv-common';
import countryCurrencies from '../utils/algolia-helper/currencies';
import { getAmountFormatter } from '../utils/common/helpers';
import { addCurrencyConversionRate } from '../thunks/inv-common';

/**
 * A hook to localise any amount based on the given currency.
 * @param amount Amount to convert
 * @param currencyCode Original currency code
 * @return {string} Returns the converted amount with local currency symbol if applicable else returns an empty string
 */
export const useLocalisedAmount = ({ amount, currencyCode }) => {
  const dispatch = useDispatch();

  const hostname = useSelector(selectHostname);
  const userCountryCountry = useSelector(selectUserCountryCode);
  const currencyConversionRates = useSelector(selectCurrencyConversionRates);

  const localCurrencyCode = countryCurrencies[userCountryCountry];

  useEffect(() => {
    if (!currencyConversionRates[localCurrencyCode]) {
      dispatch(addCurrencyConversionRate(userCountryCountry, currencyCode, hostname));
    }
  }, []);

  const isConvertAmount = !!currencyConversionRates[localCurrencyCode] && localCurrencyCode !== currencyCode;

  return isConvertAmount ? getAmountFormatter(localCurrencyCode)
    .format(Math.ceil(amount * currencyConversionRates[localCurrencyCode])) : '';
};
