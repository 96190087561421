export const gaArtistView = (artistName, artistRef, upcoming = false) => {
  global.dataLayer.push(
    {
      event: 'artist page views',
      ArtistRef: artistRef,
      ArtistName: artistName,
      category: 'artist page',
      action: artistName,
      label: upcoming ? 'view upcoming items' : 'view items sold at auction',
    }
  );
};
