import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { urls } from 'invaluable-solitaire';
import { get } from 'lodash';
import {
  UncontrolledPopover,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { markAllNotifsRead, deleteAllNotifications, markNotifRead } from 'src/actions/inv-common.actions';
import NotificationsDropDown from './NotificationsDropDown';
import { formatNotifDate } from '../myInvaluable/utils';

function NotifiicationsPopover() {
  const dispatch = useDispatch();
  const notificationsArr = useSelector((state) => get(state, 'invReactCommon.notifications', []));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const headers = useSelector((state) => get(state, 'invReactCommon.reqHeaders', {}));

  return (
    <UncontrolledPopover
      placement="bottom"
      target="notifLoginBtn"
      trigger="focus hover"
      popperClassName="nav-popover notif-content fade"
      innerClassName="popover-body clearfix"
    /*
    * This is a hack to get the popover to show up on the bottom of the container
    known bug with reactstrap https://github.com/reactstrap/reactstrap/issues/1613
    */
      flip={false}
      boundariesElement="window"
    >
      <div className="my-acct-nav menu-options menu-user-options notif-menu">
        <div className="border-bottom space-top row d-none d-sm-flex pb-2">
          <div className="col-10">
            <span id="myInvHomePartial"><h2 className="no-margin notif-h2">Notifications</h2></span>
          </div>
          <div className={`col-1 ${dropdownOpen ? 'show' : ''}`}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
              <DropdownToggle
                caret
                className="fa fa-gear fa-gear-header"
                tag="span"
              />
              <DropdownMenu
                className="dropdown-menu notif-cont-dropdown notif-gray dropdown-menu-right"
              >
                <DropdownItem
                  id="markAllRead"
                  tag="a"
                  onClick={() => dispatch(markAllNotifsRead({ headers }))}
                >
                  Mark all as read
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  id="deleteAllNotifs"
                  onClick={() => dispatch(deleteAllNotifications({ headers }))}
                >
                  Delete all
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  className="notif-link"
                  href="/my-account/email-preferences"
                >
                  Notification Settings
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <ul id="notifsPopUpList" className="media-list">
          {
                notificationsArr.length < 1 && (
                <li className="row pl-2" key="zeronotifs">
                  <div className="media-body row mt-3">
                    <div className="col-10">
                      <h4
                        className="media-heading mark-notif-read-link my-0"
                        data-link="/my-account/email-preferences"
                        data-id=""
                      >You do not have any notifications.
                      </h4>
                      <span
                        className="notif-span mark-notif-read-link"
                        data-link="/my-account/email-preferences"
                        data-id=""
                      >You can manage your desktop notification settings from your account.
                      </span>
                      <div
                        className="notif-date-text mark-notif-read-link"
                        data-link="/my-account/email-preferences"
                        data-id=""
                      />
                    </div>
                  </div>
                </li>
                )
        }
          {notificationsArr.length >= 1 && notificationsArr.map((notif) => {
            let lotTitle = notif.lotTitle ? `${notif.lotTitle.split(' ').join('-').replace(/,/g, '')}-` : '';
            let pageType = notif.redirectType;
            let lotRef = notif.ref?.toLowerCase();

            if (pageType === 'lot') {
              pageType = 'auction-lot';
              lotTitle = `${urls.seoFriendlyUrl(notif.title)}-`;
            }

            if (pageType === 'messages') {
              pageType = 'my-account/message-center';
              lotRef = '';
              lotTitle = '';
            }

            const notificationURL = `/${pageType}/${lotTitle?.toLowerCase()}${lotRef}`;

            return (
              <li key={`${notif.notificationID}pop`} className="media row mt-3 pb-2 border-bottom">
                <div className="notif-col">
                  {!notif.read
                  && <span className="fa fa-circle red-font" />}
                </div>
                <div className="media-left col-2 zero-left-padding">
                  <a
                    href={notificationURL}
                    className=" mark-notif-read-link"
                    onClick={() => dispatch(markNotifRead({ notificationID: notif.notificationID, headers }))}
                  >
                    <img
                      className="media-object notif-img"
                      src={notif.thumbPhotoLink ? notif.thumbPhotoLink : '/img/invaluable/INVBadge_256x256.png'}
                      alt="..."
                    />
                  </a>
                </div>
                <div className="media-body row ">
                  <div
                    className="col-10"
                    onClick={() => dispatch(markNotifRead({
                      notificationID: notif.notificationID, headers, notificationURL
                    }))}
                    aria-hidden="true"
                  >
                    <h4
                      className="media-heading mark-notif-read-link "
                    >{notif.title}
                    </h4>
                    <span
                      className="notif-span mark-notif-read-link"

                    >{notif.body}
                    </span>
                    <div
                      className="notif-date-text mark-notif-read-link"
                    >{formatNotifDate(notif.timeStamp)}
                    </div>
                  </div>
                  <NotificationsDropDown notifData={notif} headers={headers} />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </UncontrolledPopover>
  );
}

export default NotifiicationsPopover;
