import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import { selectArtistBioInfo } from 'src/selectors/artist-detail';

function Biography() {
  const { bio } = useSelector(selectArtistBioInfo);

  const parsedBiography = ReactHtmlParser(bio);

  return (
    bio && (
      <div className="detailed-info-widget">
        <h3>Biography</h3>
        <p>{ parsedBiography }</p>
      </div>
    )
  );
}

export default Biography;
