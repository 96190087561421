import React from 'react';
import { bool, number, string, arrayOf, shape, func } from 'prop-types';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

function ClearRefinements({ items, refine, rangeInput, setRangeInput, onClear }) {
  return (
    <button
      onClick={() => {
        refine(items);
        // Work around for subcategories INV-8445
        if (window.location.search.includes('subcategoryRef')) {
          const url = new URL(window.location.href);
          url.search = '';
          window.location = url.toString();
        }
        onClear();
        setRangeInput({ ...rangeInput, ...{ min: '', max: '' } });
      }}
      className="btn btn-link"
    >
      Clear all filters
    </button>
  );
}

ClearRefinements.defaultProps = {
  rangeInput: null,
  setRangeInput: null,
  items: [],
};

ClearRefinements.propTypes = {
  items: arrayOf(
    shape({
      label: string,
      isRefined: bool,
      count: number,
      value: func,
    })
  ),
  refine: func.isRequired,
  rangeInput: shape({}),
  setRangeInput: func,
};

export default connectCurrentRefinements(ClearRefinements);
