/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import React, { Fragment } from 'react';
import { urls } from 'invaluable-solitaire';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { getImageBaseDomain } from 'src/utils/common/helpers';
import { HOUSE_INDEX,
  NO_OF_RESULTS_MOBILE,
  NO_OF_RESULTS_SMALL,
  UPCOMING_LOTS_ARTIST_INDEX,
  sendABToHeap } from '../../config';
import HighlightedText from './HightlightText';

function ArtistsAndHouses({ value, hits }) {
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  const isMobile = useSelector((state) => get(state, 'invReactCommon.isMobile', false));
  const imageServer = getImageBaseDomain(isProd);
  const length = isMobile ? NO_OF_RESULTS_MOBILE : NO_OF_RESULTS_SMALL;
  const upcomingLotsArtistsIndex = isProd ? UPCOMING_LOTS_ARTIST_INDEX.prod : UPCOMING_LOTS_ARTIST_INDEX.dev;
  const houseIndex = isProd ? HOUSE_INDEX.prod : HOUSE_INDEX.dev;

  const getLink = (index, name, ref) => {
    if (index === houseIndex) {
      return `/auction-house/${urls.seoFriendlyUrl(name)}-${urls.seoFriendlyUrl(ref)}`;
    }
    if (index === upcomingLotsArtistsIndex) {
      return `/artist/${urls.seoFriendlyUrl(name)}-${urls.seoFriendlyUrl(ref)}`;
    }
  };

  const onClickTag = (type) => {
    if (type === 'artists') {
      sendABToHeap('Search Type Ahead', { clickEvent: 'Artists' }, 0);
    } else {
      sendABToHeap('Search Type Ahead', { clickEvent: 'Auction Houses' }, 0);
    }
  };

  return (
    <Fragment>
      <h4>Artists</h4>
      {
      hits.map(({ index, hits }) => {
        if (index === upcomingLotsArtistsIndex) {
          return (
            <div className="searches-block">
              {hits.length ? hits.slice(0, length).map(({ displayName, artistRef, photoPath }) => (
                <div
                  className="artists-and-houses"
                  key={`artist-${artistRef}`}
                >
                  <img
                    alt={displayName}
                    className="photo"
                    src={photoPath || `${imageServer}/static/noimage_noborder.png`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `${imageServer}/static/noimage_noborder.png`;
                    }}
                  />
                  <HighlightedText
                    linkTo={getLink(index, displayName, artistRef)}
                    key={`${artistRef}-house`}
                    text={displayName}
                    searchString={value}
                    onClick={() => onClickTag('artists')}
                  />
                </div>
              )) : <span className="none-hover">No relevant artists found</span>}
            </div>
          );
        }
        if (index === houseIndex) {
          return (
            <div className="mobile-only-section">
              <h4>Auction Houses</h4>
              <div className="searches-block">
                {hits.length ? hits.slice(0, length).map(({ houseName, houseRef, houseLogoUrl }) => (
                  <div className="artists-and-houses">
                    <img
                      className="photo"
                      src={houseLogoUrl}
                      alt={houseName}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `${imageServer}/static/noimage_noborder.png`;
                      }}
                    />
                    <HighlightedText
                      linkTo={getLink(index, houseName, houseRef)}
                      key={`${houseRef}-house`}
                      text={houseName}
                      searchString={value}
                      onClick={() => onClickTag('auctionHouses')}
                    />
                  </div>
                )) : <span className="none-hover">No relevant houses found</span>}
              </div>
            </div>
          );
        }
      })
    }
    </Fragment>
  );
}

export default ArtistsAndHouses;
