import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Container, BreadcrumbItem } from 'reactstrap';
import { JSONLD, Generic } from 'react-structured-data';

import {
  ArtistDetailSection,
  Breadcrumbs,
} from 'src/components/artist-detail';
import PastUpcomingLotsCarousel from 'src/components/artist-detail/past-upcoming-lots-carousel/PastUpcomingLotsCarousel';
import ArtistDetailWrapper from 'src/components/common/ArtistDetailWrapper';
import { selectArtistBioInfo } from 'src/selectors/artist-detail';
import { selectIsProd } from 'src/selectors/inv-common';
import { algoliaSearchClient } from 'src/utils/algolia-helper';
import { getImageBaseDomain, heapTrack } from 'src/utils/common/helpers';
import { getPastAndUpcomingLotsCarouselsOrder } from 'src/utils/artist-detail';
import { NEW_ARTIST_PAGE_VIEW_HEAP_EVENT } from 'src/utils/constants';
import { fetchPopularArtistsWithRetries } from 'routes/utils/artistsRoutesUtils';
import { gaArtistView } from 'src/ga/artist.ga';

function ArtistDetail({ history, carouselLotsAlgoliaData }) {
  const dispatch = useDispatch();

  const { displayName, artistRef } = useSelector(selectArtistBioInfo);
  const isProd = useSelector(selectIsProd);

  const { upcomingCarouselData, pastCarouselData } = carouselLotsAlgoliaData;
  const carouselsOrder = getPastAndUpcomingLotsCarouselsOrder(upcomingCarouselData, pastCarouselData);

  useEffect(() => fetchPopularArtistsWithRetries(dispatch, algoliaSearchClient, isProd), []);

  useEffect(() => {
    heapTrack(NEW_ARTIST_PAGE_VIEW_HEAP_EVENT.MAIN_PAGE);

    global.dataLayer = global.dataLayer || [];
    global.dataLayer.push({
      artistRef,
      artistName: displayName
    });

    gaArtistView(displayName, artistRef);
  }, []);

  const makeProductSchemaJson = (item) => {
    const {
      lotTitle,
      lotRef,
      photoPath,
      estimateLow,
      currencyCode,
      _highlightResult: { lotDescription: { value } }
    } = item;
    const lotPhotoUrl = photoPath ? `${getImageBaseDomain(isProd)}/housePhotos/${photoPath}` : '';

    return (
      <JSONLD key={lotRef} dangerouslyExposeHtml>
        <Generic
          type="product"
          jsonldtype="Product"
          schema={{
            description: value,
            name: lotTitle,
            image: lotPhotoUrl,
            offers: { price: estimateLow, priceCurrency: currencyCode },
          }}
        />
      </JSONLD>
    );
  };

  return (
    <Fragment>
      <Container fluid>
        <Breadcrumbs pathName={history.location.pathname}>
          <BreadcrumbItem active>{displayName}</BreadcrumbItem>
        </Breadcrumbs>
      </Container>
      <ArtistDetailWrapper>
        {carouselsOrder.map(({ key, carouselData, isUpcoming }) => (
          <PastUpcomingLotsCarousel {...{ key, ...carouselData, isUpcoming }} />
        ))}
        <ArtistDetailSection />
      </ArtistDetailWrapper>
      {carouselLotsAlgoliaData.pastCarouselData.lotsToDisplay.map(item => makeProductSchemaJson(item))}
      {carouselLotsAlgoliaData.upcomingCarouselData.lotsToDisplay.map(item => makeProductSchemaJson(item))}
    </Fragment>
  );
}

ArtistDetail.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  carouselLotsAlgoliaData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ArtistDetail.defaultProps = {
  history: {},
};

export default ArtistDetail;
