import React from 'react';
import MyAuction from './myAuction';
import { trackClick } from '../utils';

function Auctions({ auctions }) {
  return (
    <div className="col-md-12 col-lg-12 col-xl-5 side-border">
      <div className="my-invaluable-section-header">
        <a
          className="my-auction-tag"
          href="/my-account/auctions"
          onClick={() => trackClick('My Auction')}
        >My Auctions <i className="fa fa-chevron-right" />
        </a>
      </div>
      {
        auctions.length > 0
          ? auctions.slice(0, 4).map((auction, index) => (
            <MyAuction auction={auction} notFirst={index > 0} key={auction.ref} />
          )) : <p>You do not have any upcoming auctions saved.</p>
      }
    </div>
  );
}

export default Auctions;
