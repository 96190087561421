import React from 'react';
import { Col, Row } from 'reactstrap';
import PropType from 'prop-types';

import CurrentRefinements from 'src/components/common/algolia/current-refinment';
import FilteredLotsView from 'src/components/artist-detail/filters-and-lots-view/FilteredLotsView';
import Filters from 'src/components/artist-detail/filters-and-lots-view/Filters';

export function WebFilterResultsView({ searchState, onSearchStateChange }) {
  return (
    <Row>
      <Col md={4} lg={4} xl={3}>
        <Filters />
      </Col>
      <Col md={8} lg={8} xl={9}>
        <div className="current-refinements-container">
          <CurrentRefinements
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
          />
        </div>
        <FilteredLotsView />
      </Col>
    </Row>
  );
}

WebFilterResultsView.propTypes = {
  searchState: PropType.shape({}).isRequired,
  onSearchStateChange: PropType.func.isRequired,
};
