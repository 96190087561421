import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { getImageBaseDomain } from 'src/utils/common/helpers';
import { RECENT_SEARCH, RECENT_SEARCH_INDEX, algoliaClient, sendABToHeap } from '../../config';
import Search from './icons/Search';

function RecentSearches() {
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  const [recentItems, setRecentItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getItem = localStorage.getItem(RECENT_SEARCH);
    if (getItem) {
      setLoading(true);
      const recentSearch = JSON.parse(getItem);
      const query = recentSearch.map(item => ({
        indexName: RECENT_SEARCH_INDEX,
        query: item,
        params: {
          hitsPerPage: 1,
        },
      }));

      algoliaClient.multipleQueries(query).then(({ results }) => {
        if (results.length) {
          const items = [];
          results.forEach(({ hits, query: searchedKeyword }) => {
            if (hits.length) {
              const { hasImage = false, photoPath = '' } = hits[0];
              items.push({
                linkTo: `/search?query=${searchedKeyword}&keyword=${searchedKeyword}`,
                title: searchedKeyword,
                image: hasImage ? `${getImageBaseDomain(isProd)}/housePhotos/${photoPath}`
                  : 'https://image.invaluable.com/static/noimage_noborder.png',
              });
            }
          });
          setRecentItems(items);
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <Fragment>
      <h4>
        <Search />
        Recent Searches
      </h4>
      <div className="recent-block">
        {recentItems ? recentItems.map(({ title, image, linkTo }, idx) => (
          <div
          // eslint-disable-next-line react/no-array-index-key
            key={`${title}-${idx}`}
            className="recent-items"
          >
            <img
              src={image}
              alt="recent-search"
            />
            <a
              onClick={() => {
                sendABToHeap('Search Type Ahead', { clickEvent: 'Recent Searches' }, 0);
              }}
              href={linkTo}
              className="text-truncate"
            >
              {title}
            </a>
          </div>
        )) : null}
        {!loading && !recentItems.length ? <span className="none-hover">No recent searches</span> : null}
      </div>
    </Fragment>
  );
}

export default RecentSearches;
