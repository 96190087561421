import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useDebounce from '@rooks/use-debounce';

import { setLotsDisplayMode, setNumberOfLotsPerPage } from 'src/actions';
import { InvTabs } from 'src/components/common/inv-tabs';
import { FiltersAndResultsWrapper } from 'src/components/artist-detail';
import { getDefaultNumberOfLotsPerPage } from 'src/utils/artist-detail';
import { DEFAULT_DISPLAY_MODE } from 'src/utils/artist-detail/constants';
import { URL_UPDATE_DELAY_MS } from 'src/components/artist-detail/filters-results-wrapper/constants';
import NoSSR from 'src/hoc/NoSSR';

import { getIsUrlSameAsPrevious } from 'src/utils/common/helpers';
import { useSelectedArtistDetailTab } from 'src/hooks';

import { selectIsProd, selectHostname } from 'src/selectors/inv-common';
import { selectArtistBioInfo } from 'src/selectors/artist-detail';
import { fetchPopularArtistsWithRetries } from 'routes/utils/artistsRoutesUtils';
import { gaArtistView } from 'src/ga';
import { getArtistDetailLotsTabsMeta } from './helpers';
import UpcomingTooltip from './UpcomingTooltip';

function ArtistDetailTabsContainer({
  algoliaSearchState,
  artistRef,
  resultsState,
  searchClient,
  location,
  widgetsCollector,
  indexName,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedTab = useSelectedArtistDetailTab();
  const isProd = useSelector(selectIsProd);
  const hostname = useSelector(selectHostname);
  const artistBio = useSelector(selectArtistBioInfo);

  const updateUrl = useCallback(
    useDebounce((url, updatedState) => {
      const isUrlSameAsPrevious = getIsUrlSameAsPrevious(url, history);

      if (url && !isUrlSameAsPrevious) history.push(url, updatedState);
    }, URL_UPDATE_DELAY_MS),
    [history]
  );

  useEffect(() => {
    const displayModeForUpdatedTab = DEFAULT_DISPLAY_MODE[selectedTab];
    dispatch(setLotsDisplayMode(displayModeForUpdatedTab));
    dispatch(setNumberOfLotsPerPage(getDefaultNumberOfLotsPerPage(displayModeForUpdatedTab)));
  }, [selectedTab]);

  useEffect(() => fetchPopularArtistsWithRetries(dispatch, searchClient, isProd), []);

  useEffect(() => {
    const { displayName } = artistBio;

    global.dataLayer = global.dataLayer || [];
    global.dataLayer.push({
      artistRef,
      artistName: displayName
    });

    gaArtistView(displayName, artistRef, selectedTab === 'upcoming');
  }, [selectedTab]);

  return (
    <Fragment>
      <InvTabs
        selectedTabId={selectedTab || ''}
        tabsMeta={getArtistDetailLotsTabsMeta(artistBio, hostname)}
        className="no-scroll"
      />
      <FiltersAndResultsWrapper
        {...{
          searchClient,
          resultsState,
          artistRef,
          history,
          location,
          algoliaSearchState,
          indexName,
          widgetsCollector,
          updateUrl,
        }}
      />
      <NoSSR>
        <UpcomingTooltip />
      </NoSSR>
    </Fragment>
  );
}

ArtistDetailTabsContainer.propTypes = {
  artistRef: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  algoliaSearchState: PropTypes.objectOf(PropTypes.any),
  resultsState: PropTypes.objectOf(PropTypes.any),
  widgetsCollector: PropTypes.func,
};

ArtistDetailTabsContainer.defaultProps = {
  algoliaSearchState: {},
  resultsState: new Proxy({}, { get: () => [] }),
  widgetsCollector: () => { },
};

export default ArtistDetailTabsContainer;
