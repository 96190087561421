import React from 'react';
import { useSelector } from 'react-redux';
import { selectTotalFilteredLots } from 'src/selectors/artist-detail';

export function FilteredLotsCount() {
  const totalFilteredLots = useSelector(selectTotalFilteredLots);

  const lotsCountFormatted = `${totalFilteredLots.toLocaleString()} Lot${totalFilteredLots.length === 1 ? '' : 's'}`;

  return (
    <h3 className="lots-count">{lotsCountFormatted}</h3>
  );
}
