import get from 'lodash/get';

import { ARTMYN_ASSET_DEFAULT_ATTRBS } from '../../utils/pdp/constants';

/**
 * A helper function to extract date and timezone region from the iso8601 dateTime (2022-09-30T12:00-05:00[America/Chicago]).
 * If timeZoneRegion([America/Chicago]) is in the iso8601 dateTime extract using regex and returns that("America/Chicago")
 * otherwise returns the default time zone("CST") for the auction.
 * @param iso8601LocalDate e.g., 2022-09-30T12:00-05:00[America/Chicago]
 * @param timeZone e.g., CST
 * @returns [dateTimePart, timeZoneRegionPart]
 */
export const extractAuctionDateAndTimeZoneRegion = (iso8601LocalDate, timeZone) => {
  const date = get(/^(.*?)(?=\[)/.exec(iso8601LocalDate), '0', iso8601LocalDate);
  const timeZoneRegion = get(/\[(.*?)]/.exec(iso8601LocalDate), '1', timeZone);

  return [date, timeZoneRegion];
};

/**
 * A helper function to format lot photos and add Artmyn asset if available.
 * @param lotPhotos An array of object containing lot photos.
 * @param artmyns An array of obejct containing artmyn assets.
 * @returns An array of photos with formatted data, with added Artmyn asset if available.
 */

export const formatLotPhotosWithArtmyn = (lotPhotos, artmyns) => {
  const photos = lotPhotos.map(photo => ({
    ref: get(photo, 'ref', ''),
    medium: get(photo, '_links.medium.href', ''),
    large: get(photo, '_links.large.href', ''),
  }));

  const [selectedArtmynItem] = artmyns;
  const artmynAsset = selectedArtmynItem ? [{ ...ARTMYN_ASSET_DEFAULT_ATTRBS, artmynUrl: selectedArtmynItem.tidenURL || selectedArtmynItem.uuIDURL }] : [];

  return [...artmynAsset, ...photos];
};
