import { getImageEnv } from '../../config';

export const DEFAULT_PAGINATION_PAGE_NUMBER = 1;
export const DECIMAL_RADIX = 10;

export const DEFAULT_PRICE_RANGE_FILTER = {
  min: '',
  max: ''
};

export const NEW_ARTIST_PAGE_VIEW_HEAP_EVENT = {
  MAIN_PAGE: 'Main Artist Page (Test) - View - Page',
  UPCOMING_PAGE: 'Upcoming Artist Page (Test) - View - Page',
  PAST_PAGE: 'Past Artist Page (Test) - View - Page',
};

export const MODAL_NAMES = {
  emailOnly: 'emailOnly',
  signup: 'signup',
  login: 'login'
};

export const EMAIL_MODAL_TYPES = {
  default: 'default',
  lot: 'lot',
  artist: 'artist',
  category: 'category',
  house: 'house',
  keyword: 'keyword'
};

export const SIMILAR_ITEMS_MIN_CONFIDENCE_SCORE = 39.9;

export const VIP_BADGE_URL = `https://${getImageEnv()}.invaluable.com/static/invaluable/pdp/VIPBidder_Paddle.svg`;

export const LBC_CALENDAR_URL = `https://${getImageEnv()}.invaluable.com/lbc-calendar.svg`;
