import endOfDayDateFns from 'date-fns/endOfDay';
import { fromUnixTime, getUnixTime } from 'date-fns';

/**
 * Returns a JS Date object with its time set to the end of the day
 * @param {Date} date The date to convert.
 * @return {Date}
 */
export function endOfDay(date) {
  return endOfDayDateFns(date);
}

/**
 * Converts a unix timestamp into a JS Date Object
 * @param {number} timestamp The unix timestamp to convert.
 * @return {Date}
 */
export function dateFromUnixTime(timestamp) {
  return fromUnixTime(timestamp);
}

/**
 * Converts a JS Date object into a unix timestamp
 * @param {Date} date The date to convert.
 * @return {number}
 */
export function unixTimeFromDate(date) {
  return getUnixTime(date);
}
