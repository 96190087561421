import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';

export const HOME_REDIRECT_URL = '/';
export const ARTIST_DIRECTORY_REDIRECT_URL = '/artists/';

export const ARTIST_DETAIL_INFO_ELEMENT_ID = 'ARTIST_DETAIL_INFO';

export const NO_LOTS_TO_DISPLAY_MESSAGE = {
  [ARTIST_LOTS_TABS.PAST]: 'There are no past lots available from this artist.',
};

export const PAST_UPCOMING_LOTS_PATH = {
  PAST: 'sold-at-auction-prices/',
  UPCOMING: 'lots-at-auction/',
};
