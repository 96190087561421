import axios from 'axios';
import { get } from 'lodash';
import logger from 'routes/middleware/logging/logger';
import { fetchAuctionsWithFeaturedLots } from 'src/utils/featuredLots';
import {
  FETCH_AUCTIONS_ERROR,
  FETCH_AUCTIONS_LOADING,
  FETCH_AUCTIONS_SUCCESS,
} from './constants';

// Action creators
export const fetchAuctionsLoading = () => ({
  type: FETCH_AUCTIONS_LOADING,
});

export const fetchAuctionsSuccess = payload => ({
  type: FETCH_AUCTIONS_SUCCESS,
  payload,
});

export const fetchAuctionsError = error => ({
  type: FETCH_AUCTIONS_ERROR,
  payload: error,
});

export const fetchAuctions = ({ reqHostname, queryParams = {} }) => async (dispatch, getState) => {
  dispatch(fetchAuctionsLoading());
  try {
    let headers = {
      'Content-Type': 'application/json',
      channel: 'invaluable',
    };
    const sessionInfo = get(getState(), 'invReactCommon.sessionInfo', {});

    const { memberId, oasHeaders } = sessionInfo;
    const xAuthToken = oasHeaders?.['x-auth-token'] || '';

    // Add member-id to headers for remaining requests
    headers = {
      ...headers,
      'member-id': memberId,
      'x-auth-token': xAuthToken,
    };

    let upcomingAuctionsUrl = `https://${reqHostname}/api/catalog/upcoming?facetOn=false&loadParityBannerInfo=true`;

    // Make request with size = 0, and remove checkbox filters to get clean slate facet information
    let facetCountUrl = `https://${reqHostname}/api/catalog/upcoming/facets?facetLimit=500`;

    // Default size to 50
    if (!queryParams.size) {
      upcomingAuctionsUrl += '&size=50';
    }

    if (Object.keys(queryParams).length > 0) {
      Object.keys(queryParams).forEach((param) => {
        const paramString = `&${param}=${queryParams[param]}`;
        upcomingAuctionsUrl += paramString;

        // The facet request should be "pure" and ignore all params except for country filters, date filters, and auction type filters
        const ignoreParamArray = ['category', 'house', 'page', 'size', 'state'];
        if (!ignoreParamArray.includes(param)) {
          if (facetCountUrl.indexOf('?') === -1) {
            facetCountUrl += paramString.replace('&', '?');
          } else {
            facetCountUrl += paramString;
          }
        }
      });
    }
    const [facetCountResponse, upcomingAuctionsResponse] = await Promise.all([
      axios(facetCountUrl, { headers }),
      axios(upcomingAuctionsUrl, { headers }),
    ]);

    const updatedResponse = await fetchAuctionsWithFeaturedLots({
      auctionResponse: upcomingAuctionsResponse, reqHostname });

    const payload = {
      auctions: updatedResponse,
      // States may not come back from the facet request, so default it to an empty array if not
      facets: {
        ...facetCountResponse.data,
        state: facetCountResponse.data.state || [],
      },
      memberId,
      reqHostname,
      sort: upcomingAuctionsResponse.data.sort,
      totalElements: upcomingAuctionsResponse.data.totalElements,
      pagination: {
        isFirst: upcomingAuctionsResponse.data.first,
        isLast: upcomingAuctionsResponse.data.last,
        itemsOnPage: upcomingAuctionsResponse.data.numberOfElements,
        pageNumber: upcomingAuctionsResponse.data.number,
        pageSize: upcomingAuctionsResponse.data.size,
        totalPages: upcomingAuctionsResponse.data.totalPages,
      },
    };

    dispatch(fetchAuctionsSuccess(payload));
  } catch (err) {
    logger.error(`Error fetching auctions [queryParams=${JSON.stringify(queryParams)}]`, err);
    dispatch(fetchAuctionsError(err));
  }
};
