import React, { useEffect, useState } from 'react';
import { Tooltip } from 'reactstrap';

import { useSelectedArtistDetailTab } from 'src/hooks';
import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';
import { getIsUpcomingTooltipClosed, setIsUpcomingTooltipClosed } from './helpers';

function UpcomingTooltip() {
  const selectedTab = useSelectedArtistDetailTab();
  const [isTooltipClosed, setIsTooltipClosed] = useState(getIsUpcomingTooltipClosed());

  useEffect(() => {
    if (selectedTab === ARTIST_LOTS_TABS.UPCOMING) {
      setIsUpcomingTooltipClosed(true);
    }
  }, [selectedTab]);

  const closeTooltip = () => {
    setIsTooltipClosed(true);
    setIsUpcomingTooltipClosed(true);
  };

  if (selectedTab === ARTIST_LOTS_TABS.UPCOMING || isTooltipClosed) return null;

  return (
    <Tooltip
      target={ARTIST_LOTS_TABS.UPCOMING}
      placement="bottom"
      className="custom-block-tooltip tab-popover"
      isOpen
    >
      <span
        role="button"
        className="btn-close"
        onClick={closeTooltip}
      />
      <div>
        <strong>View Available Lots</strong>
        <p>See upcoming lots that are currently available for bidding!</p>
      </div>
    </Tooltip>
  );
}

export default UpcomingTooltip;
