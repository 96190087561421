import Styled from 'styled-components';

export const SuggestionDropdown = Styled.button`
    top: 100% !important;
    left: 0 !important;
    width: calc(100% - 37px) !important;
    text-align: justify;
    margin-top: 17px;
    border: none;
`;

export const SuggestionDropdownMobile = Styled.button`
    top: 130px !important;
    left: 0 !important;
    width: 100% !important;
    text-align: justify;
    border: none;
`;
