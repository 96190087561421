import React, { memo, useCallback, useEffect, useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

import RefinementListItem from './RefinementListItem';

function RefinementList({
  hide,
  isFromSearch,
  items,
  refine,
  searchable,
  searchForItems,
  showMore,
  title,
  translations,
}) {
  const [state, setState] = useState('');

  useEffect(() => () => {
    setState('');
  }, []);

  const handleOnChange = useCallback(
    (event) => {
      const value = event.currentTarget.value;
      setState(value);
      searchForItems(value);
    },
    [searchForItems]
  );

  const handleOnRefineItem = useCallback((value) => {
    refine(value);
    setState('');
  }, [refine]);

  const renderSearchable = useCallback(() => {
    if (!searchable) {
      return null;
    }

    if (typeof searchable === 'function') {
      const Searchable = searchable;

      return <Searchable />;
    }

    return (
      <input
        type="search"
        className="form-control"
        value={state}
        placeholder={translations && translations.placeholder}
        onChange={handleOnChange}
        aria-label={translations && translations.ariaLabel}
        aria-describedby={translations && translations.ariaDescribedby}
      />
    );
  }, [searchable, state, handleOnChange]);

  if (hide || ((!items.length || state !== '') && state === '')) {
    return null;
  }

  return (
    <>
      <h3>{title}</h3>
      <div className="filter-wrapper refinment-list">
        <div className="ais-RefinementList">
          {renderSearchable()}
          <ul className="ais-RefinementList-list">
            {items.map((item) => (
              <RefinementListItem
                key={item.label}
                item={item}
                isFromSearch={isFromSearch}
                onRefineItem={handleOnRefineItem}
              />
            ))}
          </ul>
          {!items.length && <div>No Results</div>}
          {showMore && (
            <button
              type="button"
              className="ais-RefinementList-showMore"
            >
              Show more
            </button>
          )}
        </div>
      </div>
    </>
  );
}

RefinementList.defaultProps = {
  hide: false,
  searchable: false,
};

RefinementList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  isFromSearch: PropTypes.bool.isRequired,
  refine: PropTypes.func.isRequired,
  showMore: PropTypes.bool.isRequired,
  searchable: PropTypes.oneOfType([PropTypes.bool, PropTypes.node, PropTypes.func]),
  searchForItems: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  hide: PropTypes.bool,
};

export default connectRefinementList(memo(RefinementList));
