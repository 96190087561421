import { getUnixTime } from 'date-fns';

import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';

import { SORT_BY_FILTER_OPTIONS, DEFAULT_SORT_BY_OPTION } from './constants';

/**
 * Generates a filter string based on the artist reference and selected tab.
 *
 * @param {string} artistRef - The artist reference.
 * @param {string} selectedTab - The selected tab (e.g., 'upcoming', 'past').
 * @returns {string} The generated filter string.
 */
export const getFilterStr = (artistRef, selectedTab) => {
  const filterStr = `artistRef:${artistRef} AND onlineOnly:false AND banned:false AND hasImage:true`;
  if (selectedTab === ARTIST_LOTS_TABS.UPCOMING) {
    return `${filterStr} AND unlotted:false AND closed:false`;
  }

  if (selectedTab === ARTIST_LOTS_TABS.PAST) {
    const currentTimeInUnix = getUnixTime(new Date());

    return `${filterStr} AND dateTimeUTCUnix<${currentTimeInUnix}`;
  }
};

/**
 * Returns sort by options according to the display filter and the current env
 * @param {boolean} isProd
 * @param {boolean} isUpcoming
 * @returns list, sort by of options
 */
export const getSortOptions = (isProd, isUpcoming) => {
  if (!isUpcoming) return SORT_BY_FILTER_OPTIONS.PAST;

  return isProd ? SORT_BY_FILTER_OPTIONS.UPCOMING_PROD : SORT_BY_FILTER_OPTIONS.UPCOMING_STAGE;
};

/**
 * Returns default sort by option according to the display filter and the current env
 * @param {boolean} isProd
 * @param {boolean} isUpcoming
 * @returns string, default sort by option
 */
export const getDefaultSortByOption = (isProd, isUpcoming) => {
  if (!isUpcoming) return DEFAULT_SORT_BY_OPTION.PAST;

  return isProd ? DEFAULT_SORT_BY_OPTION.UPCOMING_PROD : DEFAULT_SORT_BY_OPTION.UPCOMING_STAGE;
};
