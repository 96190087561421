export const FOLLOW_UNFOLLOW_ARTIST_BTN_TEXT = {
  PRIMARY: {
    FOLLOW: 'Follow This Artist',
    FOLLOWING: 'Following This Artist',
  },
  SECONDARY: {
    FOLLOW: 'Follow',
    FOLLOWING: 'Following',
  },
};

export const FOLLOW_UNFOLLOW_ARTIST_BTN_ICON = {
  PLUS: 'fa-plus',
  CHECK: 'fa-check',
};
