import React from 'react';
import { InstantSearch, Index, Configure } from 'react-instantsearch-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  UPCOMING_LOTS_SUGGESTIONS_INDEX,
  UPCOMING_LOTS_INDEX,
  HOUSE_INDEX,
  NO_OF_RESULTS,
  UPCOMING_LOTS_ARTIST_INDEX,
  algoliaClient,
} from '../config';

import AutocompleteSearchBar from './autocompleteSearchBar';

function AlgoliaSearchBar({ isMobile }) {
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  return (
    <InstantSearch
      indexName={UPCOMING_LOTS_INDEX}
      searchClient={algoliaClient}
    >
      <Configure
        hitsPerPage={NO_OF_RESULTS}
        clickAnalytics
      />
      <AutocompleteSearchBar isMobile={isMobile} />
      <Index indexName={UPCOMING_LOTS_INDEX} />
      <Index indexName={isProd ? UPCOMING_LOTS_ARTIST_INDEX.prod : UPCOMING_LOTS_ARTIST_INDEX.dev} />
      <Index indexName={isProd ? HOUSE_INDEX.prod : HOUSE_INDEX.dev} />
      <Index
        indexName={isProd ? UPCOMING_LOTS_SUGGESTIONS_INDEX.prod : UPCOMING_LOTS_SUGGESTIONS_INDEX.dev}
      >
        <Configure filters="banned:false AND channelIDs:1 AND unlotted:false AND onlineOnly:false AND closed:false" />
      </Index>
    </InstantSearch>
  );
}

export default AlgoliaSearchBar;
