import React from 'react';
import { useSelector } from 'react-redux';

import { getFormattedProfessionsAndEra } from 'src/components/artist-detail/helpers';
import { selectArtistBioInfo } from 'src/selectors/artist-detail';

import ArtistDetailedInfo from './ArtistDetailedInfo';
import ArtistIntro from './ArtistIntro';
import NotableSoldLots from './NotableSoldLots';

function ArtistDetailSection() {
  const { aliases, bio, genres, birthAndDeathDates, professions } = useSelector(selectArtistBioInfo);

  const shouldRenderArtistDetailInfo = !!(aliases?.length || genres?.length || bio);
  const professionsAndEra = getFormattedProfessionsAndEra(professions, birthAndDeathDates);

  return (
    <div className="artist-detailed-section">
      <ArtistIntro />
      <div className="artist-detail-inner-container">
        <h3 className="profession-names">{professionsAndEra}</h3>
        {shouldRenderArtistDetailInfo && <ArtistDetailedInfo />}
        <NotableSoldLots />
      </div>
    </div>
  );
}

export default ArtistDetailSection;
