import { useSelector } from 'react-redux';

import { selectLotData } from '../selectors/pdp';

const useLocalCurrencyConversion = () => {
  const {
    currentBid,
    localCurrencyConversionRate,
    localCurrencyCode,
  } = useSelector(selectLotData);

  const isShowLocalizedBidAmount = localCurrencyConversionRate && localCurrencyCode;
  const localizedBidAmount = isShowLocalizedBidAmount && Math.ceil(currentBid * localCurrencyConversionRate);
  const localizedBidAmountText = isShowLocalizedBidAmount && `${localizedBidAmount.toLocaleString()} ${localCurrencyCode}`;

  return { isShowLocalizedBidAmount, localizedBidAmountText };
};

export default useLocalCurrencyConversion;
