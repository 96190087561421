import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
} from 'reactstrap';
import { loginUser, setModalStatus, setPostLoginRedirectUrl } from 'src/actions/inv-common.actions';
import { get } from 'lodash';
import { selectModalStatus } from 'src/selectors/inv-common';
import { MODAL_NAMES } from 'src/utils/constants';
import { errorMessages } from './errorUtils';

import {
  SSOContainer,
  SmallText
} from '../../../containers/Header/Header.styled';

function LoginModal() {
  const { isOpen: isLoginModalOpen, userName, email } = useSelector(state => selectModalStatus(state, MODAL_NAMES.login));
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState(email || '');
  const [password, setPassword] = useState('');
  const [keepLoggedInChecked, setKeepLoggedInChecked] = useState(true);
  const loginErrors = useSelector((state) => get(state, 'invReactCommon.errors.loginErrors', false));

  const closeModal = () => {
    dispatch(setModalStatus({
      name: MODAL_NAMES.login,
      isOpen: false
    }));
    // in case user opens modal multiple times. We set postLoginRedirectUrl
    // to null to prevent false redirect after login
    dispatch(setPostLoginRedirectUrl(null));
  };

  const setSignUpModalOpen = () => dispatch(setModalStatus({
    name: MODAL_NAMES.signup,
    isOpen: true,
  }));

  const handleEmailAddressChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleToggleSignIn = () => {
    closeModal();
    setSignUpModalOpen();
  };

  const handleSubmitLogin = () => {
    const userData = {
      userName: emailAddress,
      password,
      keepLoggedIn: keepLoggedInChecked,
    };

    dispatch(loginUser(userData));
  };

  useEffect(() => {
    if (isLoginModalOpen) {
      setTimeout(() => {
        window.google.accounts.id.renderButton(
          document.getElementById('ssoGoogleButtonLogin'),
          {
            theme: 'outline',
            size: 'large',
            text: 'continue_with',
            logo_alignment: 'center',
            width: '280px',
          } // customization attributes
        );
      }, 1);
    }
  }, [isLoginModalOpen]);

  useEffect(() => {
    if (email && !emailAddress) {
      setEmailAddress(email);
    }
  }, [email, emailAddress]);

  return (
    <Modal
      isOpen={isLoginModalOpen}
      className="modal-dialog modal-dialog-centered"
      size="md"
      fade
      id="signInModal"
    >
      <div className="modal-header login-modal-header">
        <button type="button" className="close" data-dismiss="modal" onClick={() => closeModal()}>
          <span>×</span><span className="sr-only">Close</span>
        </button>
      </div>
      <div className="modal-title modal-title-text text-overflow text-center">Welcome Back{userName ? `, ${userName}. Please enter your password to continue` : ''}</div>
      {!userName && <div className="text-center">Log in to view your account and personalized recommendations.</div>}
      <ModalBody>
        <Form className={`login-form ${loginErrors ? 'has-error' : ''}`}>
          <fieldset className="login-inputs" id="loginFields">
            <FormGroup>
              <Input
                type="text"
                name="email"
                placeholder="Email"
                maxLength={250}
                value={emailAddress}
                onChange={(e) => handleEmailAddressChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                name="password"
                id="passwordLoginModal"
                placeholder="Password"
                maxLength={250}
                value={password}
                onChange={(e) => handlePasswordChange(e)}
              />
              {loginErrors && (
                <div className="login-error-message control-label loginError" id="loginError">
                  {errorMessages.loginIncorrect}
                </div>
              )}
            </FormGroup>

            <div className="clearfix mb-2">
              <label
                className="checkbox-inline pull-left ml-3"
                htmlFor="rememberMe"
                id="rememberMeLabel"
                onClick={() => setKeepLoggedInChecked(!keepLoggedInChecked)}
              >
                <Input type="checkbox" checked={`${keepLoggedInChecked ? 'checked' : ''}`} id="rememberMe" />
                <span className="text-muted dark-gray-text rememberMeCheck">Keep me logged in</span>
              </label>

              <a href="/forgot-password" className="pull-right"> Forgot Password? </a>

              <Button
                role="button"
                className="btn btn-login-modal modal-login-btn space-top log-in-memory"
                onClick={() => handleSubmitLogin()}
                disabled={(!password.length || !emailAddress.length)}
              >Log In
              </Button>
            </div>
          </fieldset>
          <div className="social-separator">
            <div className="social-line" />
            <span>OR</span>
            <div className="social-line" />
          </div>
          <SSOContainer>
            <div id="ssoGoogleButtonLogin" allow="identity-credentials-get" className="space-bottom text-center" />
          </SSOContainer>
          {!userName && (
            <div className="clearfix mb-2 text-center mt-3">
              <span className="dark-gray-text">Don't have an account? </span>
              <a
                role="button"
                className="create-acct-link"
                onClick={() => handleToggleSignIn()}
              >Sign up
              </a>
            </div>
          )}
          <SmallText className="dark-gray-text">
            By continuing with Google, you agree to Invaluable's&nbsp;
            <a href="/agreements/userTerms.cfm" target="_blank">terms of service</a> and&nbsp;
            <a href="/agreements/privacy.cfm" target="_blank">privacy policy</a>.
            Invaluable may send you communications; you can set your preferences in your account.
          </SmallText>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default LoginModal;
