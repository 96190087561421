/* *************** ARTIST BIO *************** */
export const FETCH_ARTIST_BIO_ERROR = 'FETCH_ARTIST_BIO_ERROR';
export const FETCH_ARTIST_BIO_SUCCESS = 'FETCH_ARTIST_BIO_SUCCESS';

/* *************** AUCTIONS *************** */
export const FETCH_AUCTIONS_ERROR = 'FETCH_AUCTIONS_ERROR';
export const FETCH_AUCTIONS_LOADING = 'FETCH_AUCTIONS_LOADING';
export const FETCH_AUCTIONS_SUCCESS = 'FETCH_AUCTIONS_SUCCESS';

/* *************** CATEGORIES *************** */
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';
export const FETCH_CATEGORIES_LOADING = 'FETCH_CATEGORIES_LOADING';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORY_FOLLOW_LOADING = 'FETCH_CATEGORY_FOLLOW_LOADING';
export const FETCH_CATEGORY_FOLLOW_ERROR = 'FETCH_CATEGORY_FOLLOW_ERROR';
export const FETCH_CATEGORY_FOLLOW_SUCCESS = 'FETCH_CATEGORY_FOLLOW_SUCCESS';
export const FETCH_CATEGORY_UNFOLLOW_ERROR = 'FETCH_CATEGORY_UNFOLLOW_ERROR';
export const FETCH_CATEGORY_UNFOLLOW_SUCCESS = 'FETCH_CATEGORY_UNFOLLOW_SUCCESS';

/* *************** SEARCH RESULTS *************** */
export const FETCH_SEARCH_RESULTS_LOADING = 'FETCH_SEARCH_RESULTS_LOADING';
export const FETCH_SEARCH_RESULTS_SUCCESS = 'FETCH_SEARCH_RESULTS_SUCCESS';
export const FETCH_SEARCH_RESULTS_ERROR = 'FETCH_SEARCH_RESULTS_ERROR';

/* *************** COUNTRY CODE RESULT *************** */
export const FETCH_CURRENCY_CODE = 'FETCH_CURRENCY_CODE';
export const FETCH_CURRENCY_ARR = 'FETCH_CURRENCY_ARR';

/* *************** TOGGLE KEYWORD *************** */
export const TOGGLE_SAVED_SEARCH_KEYWORD = 'TOGGLE_SAVED_SEARCH_KEYWORD';
export const TOGGLE_SAVED_ARTIST_KEYWORD = 'TOGGLE_SAVED_ARTIST_KEYWORD';

/* *************** FETCH KEYWORDS FOLLOWED *************** */
export const FETCH_KEYWORD_FOLLOWED_SUCCESS = 'FETCH_KEYWORD_FOLLOWED_SUCCESS';
export const FETCH_KEYWORD_FOLLOWED_ERROR = 'FETCH_KEYWORD_FOLLOWED_ERROR';
export const FETCH_KEYWORD_ID = 'FETCH_KEYWORD_ID';

/* *************** UPCOMING AUCTIONS *************** */
export const FETCH_UPCOMING_AUCTIONS_ERROR = 'FETCH_UPCOMING_AUCTIONS_ERROR';
export const FETCH_UPCOMING_AUCTIONS_SUCCESS = 'FETCH_UPCOMING_AUCTIONS_SUCCESS';
export const FETCH_UPCOMING_AUCTIONS_LOADING = 'FETCH_UPCOMING_AUCTIONS_LOADING';

/* *************** HOMEPAGE MY AUCTIONS *************** */
export const FETCH_MY_AUCTIONS_ERROR = 'FETCH_MY_AUCTIONS_ERROR';
export const FETCH_MY_AUCTIONS_SUCCESS = 'FETCH_MY_AUCTIONS_SUCCESS';

/* *************** RELATED HOUSES ***************** */
export const FETCH_HOUSES_LOADING = 'FETCH_HOUSES_LOADING';
export const FETCH_HOUSES_ERROR = 'FETCH_HOUSES_ERROR';
export const FETCH_HOUSES_SUCCESS = 'FETCH_HOUSES_SUCCESS';

/* *************** RELATED GENRES ***************** */
export const FETCH_GENRES_LOADING = 'FETCH_GENRES_LOADING';
export const FETCH_GENRES_ERROR = 'FETCH_GENRES_ERROR';
export const FETCH_GENRES_SUCCESS = 'FETCH_GENRES_SUCCESS';

/* *************** FEATURED LOTS ***************** */
export const FETCH_FEATURED_LOTS_LOADING = 'FETCH_FEATURED_LOTS_LOADING';
export const FETCH_FEATURED_LOTS_ERROR = 'FETCH_FEATURED_LOTS_ERROR';
export const FETCH_FEATURED_LOTS_SUCCESS = 'FETCH_FEATURED_LOTS_SUCCESS';

/* *************** CATALOG RESULTS ***************** */
export const FETCH_CATALOG = 'FETCH_CATALOG';
export const FETCH_CATALOG_ERROR = 'FETCH_CATALOG_ERROR';
export const FETCH_CATALOG_SUCCESS = 'FETCH_CATALOG_SUCCESS';
export const FETCH_CATALOG_LOADING = 'FETCH_CATALOG_LOADING';
export const UPDATE_TAB_VISIBILITY = 'UPDATE_TAB_VISIBILITY';
export const SYNC_LOT_VISIBILTY = 'SYNC_LOT_VISIBILTY';
export const UPDATE_LOT_INFO_PROPERTIES = 'UPDATE_LOT_INFO_PROPERTIES';

/* *************** MemberInfo ***************** */
export const FETCH_MEMBER_INFO = 'FETCH_MEMBER_INFO';
export const FETCH_MEMBER_INFO_SUCCESS = 'FETCH_MEMBER_INFO_SUCCESS';
export const FETCH_MEMBER_INFO_ERROR = 'FETCH_MEMBER_INFO_ERROR';

/* *************** PDP ***************** */
export const FETCH_PDP_LOT_SUCCESS = 'FETCH_PDP_LOT_SUCCESS';
export const FETCH_PDP_LOT_ERROR = 'FETCH_PDP_LOT_ERROR';
export const FETCH_INITIAL_LOT_DATA_SUCCESS = 'FETCH_INITIAL_LOT_DATA_SUCCESS';
export const FETCH_INITIAL_LOT_DATA_ERROR = 'FETCH_INITIAL_LOT_DATA_ERROR';
export const FETCH_PDP_BIDDER_STATUS_SUCCESS = 'FETCH_PDP_BIDDER_STATUS_SUCCESS';
export const FETCH_PDP_BIDDER_STATUS_ERROR = 'FETCH_PDP_BIDDER_STATUS_ERROR';
export const FETCH_PDP_LOT_LIVE_INFO_SUCCESS = 'FETCH_PDP_LOT_LIVE_INFO_SUCCESS';
export const FETCH_PDP_LOT_LIVE_INFO_ERROR = 'FETCH_PDP_LOT_LIVE_INFO_ERROR';
export const POST_PDP_ADD_TO_WATCH_LIST_SUCCESS = 'POST_PDP_ADD_TO_WATCH_LIST_SUCCESS';
export const POST_PDP_ADD_TO_WATCH_LIST_ERROR = 'POST_PDP_ADD_TO_WATCH_LIST_ERROR';
export const POST_PDP_REMOVE_FROM_WATCH_LIST_SUCCESS = 'POST_PDP_REMOVE_TO_WATCH_LIST_SUCCESS';
export const POST_PDP_REMOVE_FROM_WATCH_LIST_ERROR = 'POST_PDP_REMOVE_TO_WATCH_LIST_ERROR';
export const FETCH_PDP_WATCHER_COUNT_SUCCESS = 'FETCH_PDP_WATCHER_COUNT_SUCCESS';
export const FETCH_PDP_WATCHER_COUNT_ERROR = 'FETCH_PDP_WATCHER_COUNT_ERROR';
export const FETCH_PDP_SELLER_INFO_SUCCESS = 'FETCH_PDP_SELLER_INFO_SUCCESS';
export const FETCH_PDP_SELLER_INFO_ERROR = 'FETCH_PDP_SELLER_INFO_ERROR';
export const FETCH_PDP_USER_CONTACT_INFO_SUCCESS = 'FETCH_PDP_USER_CONTACT_INFO_SUCCESS';
export const FETCH_PDP_USER_CONTACT_INFO_ERROR = 'FETCH_PDP_USER_CONTACT_INFO_ERROR';
export const FETCH_PDP_STATES_SUCCESS = 'FETCH_PDP_STATES_SUCCESS';
export const FETCH_PDP_STATES_ERROR = 'FETCH_PDP_STATES_ERROR';
export const FETCH_PDP_IS_SELLER_FOLLOWED_SUCCESS = 'FETCH_PDP_IS_SELLER_FOLLOWED_SUCCESS';
export const FETCH_PDP_IS_SELLER_FOLLOWED_ERROR = 'FETCH_PDP_IS_SELLER_FOLLOWED_ERROR';
export const POST_PDP_FOLLOW_UN_FOLLOW_SELLER_SUCCESS = 'POST_PDP_FOLLOW_UN_FOLLOW_SELLER_SUCCESS';
export const POST_PDP_FOLLOW_UN_FOLLOW_SELLER_ERROR = 'POST_PDP_FOLLOW_UN_FOLLOW_SELLER_ERROR';
export const SET_PDP_ASYNC_STATUS = 'SET_PDP_ASYNC_STATUS';
export const FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_SUCCESS = 'FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_SUCCESS';
export const FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_ERROR = 'FETCH_PDP_REQUEST_FOR_APPROVAL_INIT_ERROR';
export const FETCH_PDP_BILLING_CARDS_SUCCESS = 'FETCH_PDP_BILLING_CARDS_SUCCESS';
export const FETCH_PDP_BILLING_CARDS_ERROR = 'FETCH_PDP_BILLING_CARDS_ERROR';
export const POST_PDP_UPDATE_BID_TOTAL_SUCCESS = 'POST_PDP_UPDATE_BID_TOTAL_SUCCESS';
export const POST_PDP_UPDATE_BID_TOTAL_ERROR = 'POST_PDP_UPDATE_BID_TOTAL_ERROR';
export const SET_PDP_SELECTED_BID_INCREMENT = 'SET_PDP_SELECTED_BID_INCREMENT';
export const PDP_SET_SELECTED_BID_AMOUNT = 'PDP_SET_SELECTED_BID_AMOUNT';
export const SET_PDP_IS_LOADING = 'SET_PDP_IS_LOADING';
export const POST_PDP_NOTIFY_ME_SUCCESS = 'POST_PDP_NOTIFY_ME_SUCCESS';
export const POST_PDP_NOTIFY_ME_ERROR = 'POST_PDP_NOTIFY_ME_ERROR';
export const PDP_UPDATE_PLACE_BID_FORM_DATA = 'PDP_UPDATE_PLACE_BID_FORM_DATA';
export const PDP_UPDATE_PLACE_BID_FORM_ERRORS = 'PDP_UPDATE_PLACE_BID_FORM_ERRORS';
export const FETCH_PDP_REQUEST_FOR_APPROVAL_SUCCESS = 'FETCH_PDP_REQUEST_FOR_APPROVAL_SUCCESS';
export const FETCH_PDP_REQUEST_FOR_APPROVAL_ERROR = 'FETCH_PDP_REQUEST_FOR_APPROVAL_ERROR';
export const RESET_PDP_ERRORS = 'RESET_PDP_ERRORS';
export const RESET_PDP_PLACE_BID_FORM_ERRORS = 'RESET_PDP_PLACE_BID_FORM_ERRORS';
export const FETCH_PDP_SELLER_FOLLOW_COUNT_SUCCESS = 'FETCH_PDP_SELLER_FOLLOW_COUNT_SUCCESS';
export const FETCH_PDP_SELLER_FOLLOW_COUNT_ERROR = 'FETCH_PDP_SELLER_FOLLOW_COUNT_ERROR';
export const PDP_UPDATE_NEW_CARD_DETAILS = 'PDP_UPDATE_NEW_CARD_DETAILS';
export const PDP_USE_NEW_CREDIT_CARD = 'PDP_USE_NEW_CREDIT_CARD';
export const PDP_UPDATE_SELECTED_CARD_DETAILS = 'PDP_UPDATE_SELECTED_CARD_DETAILS';
export const FETCH_PDP_SUBSCRIPTION_INFO_SUCCESS = 'FETCH_PDP_SUBSCRIPTION_INFO_SUCCESS';
export const FETCH_PDP_SUBSCRIPTION_INFO_ERROR = 'FETCH_PDP_SUBSCRIPTION_INFO_ERROR';
export const FETCH_PDP_ARTIST_INFO_SUCCESS = 'FETCH_PDP_ARTIST_INFO_SUCCESS';
export const FETCH_PDP_ARTIST_INFO_ERROR = 'FETCH_PDP_ARTIST_INFO_ERROR';
export const PDP_SET_BID_SUBMIT_RESPONSE = 'PDP_SET_BID_SUBMIT_RESPONSE';
export const PDP_SET_BID_SUBMIT_ERROR_MESSAGE = 'PDP_SET_BID_SUBMIT_ERROR_MESSAGE';
export const PDP_SET_BID_SUBMIT_ERRORS_DATA = 'PDP_SET_BID_SUBMIT_ERRORS_DATA';
export const PDP_SET_ARE_BILLING_SHIPPING_ADDRESS_SAME = 'PDP_SET_ARE_BILLING_SHIPPING_ADDRESS_SAME';
export const PDP_SET_ALGOLIA_PARAMS = 'PDP_SET_ALGOLIA_PARAMS';
export const PDP_SET_CURRENCY_COVERSION_RATE = 'PDP_SET_CURRENCY_COVERSION_RATE';
export const PDP_SET_IS_PROD_ENV = 'PDP_SET_IS_PROD_ENV';
export const PDP_SET_BID_CONCIERGE_STATUS = 'PDP_SET_BID_CONCIERGE_STATUS';
export const SET_PDP_STATUS_CODE = 'SET_PDP_STATUS_CODE';

/* *************** AUCTION HOUSE ***************** */
export const SET_AUCTION_HOUSE_ARTISTS = 'SET_AUCTION_HOUSE_ARTISTS';
export const SET_AUCTION_HOUSE_ARTISTS_LOADING = 'SET_AUCTION_HOUSE_ARTISTS_LOADING';
export const FETCH_AUCTION_HOUSE_DETAIL_SUCCESS = 'FETCH_AUCTION_HOUSE_DETAIL_SUCCESS';
export const FETCH_AUCTION_HOUSE_DETAIL_ERROR = 'FETCH_AUCTION_HOUSE_DETAIL_ERROR';
export const FETCH_AH_IS_SELLER_FOLLOWED = 'FETCH_AH_IS_SELLER_FOLLOWED';
export const AH_FOLLOW_UNFOLLOW_SUCCESS = 'AH_FOLLOW_UNFOLLOW_SUCCESS';
export const AH_FOLLOW_UNFOLLOW_ERROR = 'AH_FOLLOW_UNFOLLOW_ERROR';
export const AH_FETCH_FOLLOW_COUNT_SUCCESS = 'AH_FETCH_FOLLOW_COUNT_SUCCESS';
export const AH_FETCH_UPCOMING_AUCTIONS_SUCCESS = 'AH_FETCH_UPCOMING_AUCTIONS_SUCCESS';
export const FETCH_AUCTION_HOUSE_PAST_AUCTIONS_SUCCESS = 'FETCH_AUCTION_HOUSE_PAST_AUCTIONS_SUCCESS';
export const FETCH_AUCTION_HOUSE_PAST_AUCTIONS_ERROR = 'FETCH_AUCTION_HOUSE_PAST_AUCTIONS_ERROR';
export const FETCH_AUCTION_HOUSE_PAST_AUCTIONS_LOADING = 'FETCH_AUCTION_HOUSE_PAST_AUCTIONS_LOADING';
export const FETCH_AUCTION_HOUSE_POPULAR_ITEMS_SUCCESS = 'FETCH_AUCTION_HOUSE_POPULAR_ITEMS_SUCCESS';
export const FETCH_AUCTION_HOUSE_POPULAR_ITEMS_ERROR = 'FETCH_AUCTION_HOUSE_POPULAR_ITEMS_ERROR';
export const FETCH_AUCTION_HOUSE_POPULAR_ITEMS_LOADING = 'FETCH_AUCTION_HOUSE_POPULAR_ITEMS_LOADING';
export const FETCH_AUCTION_HOUSE_CONTACT_INFO_SUCCESS = 'FETCH_AUCTION_HOUSE_CONTACT_INFO_SUCCESS';
export const SET_AUCTION_HOUSE_NOTABLE_LOTS = 'SET_AUCTION_HOUSE_NOTABLE_LOTS';
export const SET_AUCTION_HOUSE_NOTABLE_LOTS_LOADING = 'SET_AUCTION_HOUSE_NOTABLE_LOTS_LOADING';
export const PDP_FETCH_VIP_STATUS_SUCCESS = 'PDP_FETCH_VIP_STATUS_SUCCESS';
export const PDP_FETCH_VIP_STATUS_ERROR = 'PDP_FETCH_VIP_STATUS_ERROR';
export const SET_CF_IP_COUNTRY = 'SET_CF_IP_COUNTRY';
export const SEND_SELLER_MESSAGE_ERROR = 'SEND_SELLER_MESSAGE_ERROR';
export const SEND_SELLER_MESSAGE_LOADING = 'SEND_SELLER_MESSAGE_LOADING';
export const SEND_SELLER_MESSAGE_SUCCESS = 'SEND_SELLER_MESSAGE_SUCCESS';
export const FETCH_VIP_STATUS_ERROR = 'FETCH_VIP_STATUS_ERROR';
export const FETCH_VIP_STATUS_SUCCESS = 'FETCH_VIP_STATUS_SUCCESS';
export const FETCH_SUBSCRIBED_USER_STATUS_SUCCESS = 'FETCH_SUBSCRIBED_USER_STATUS_SUCCESS';
export const FETCH_AUCTION_HOUSE_CATEGORIES_ERROR = 'FETCH_AUCTION_HOUSE_CATEGORIES_ERROR';
export const FETCH_AUCTION_HOUSE_CATEGORIES_LOADING = 'FETCH_AUCTION_HOUSE_CATEGORIES_LOADING';
export const FETCH_AUCTION_HOUSE_CATEGORIES_SUCCESS = 'FETCH_AUCTION_HOUSE_CATEGORIES_SUCCESS';
export const FETCH_AGGREGATED_RATING_SUCCESS = 'FETCH_AGGREGATED_RATING_SUCCESS';
export const REFETCH_UPCOMING_LOTS_SUCCESS = 'REFETCH_UPCOMING_LOTS_SUCCESS';
export const LOAD_MORE_UPCOMING_LOTS_SUCCESS = 'LOAD_MORE_UPCOMING_LOTS_SUCCESS';
export const SET_PDP_CONCIERGE_MODAL_PROPS = 'SET_PDP_CONCIERGE_MODAL_PROPS';

/* *************** ArtistDetail ***************** */
export const FETCH_ARTIST_BIO_INFO_SUCCESS = 'FETCH_ARTIST_BIO_INFO_SUCCESS';
export const FETCH_ARTIST_BIO_INFO_ERROR = 'FETCH_ARTIST_BIO_INFO_ERROR';
export const FETCH_ARTIST_NOTABLE_SOLD_LOTS_SUCCESS = 'FETCH_ARTIST_NOTABLE_SOLD_LOTS_SUCCESS';
export const FETCH_ARTIST_NOTABLE_SOLD_LOTS_ERROR = 'FETCH_ARTIST_NOTABLE_SOLD_LOTS_ERROR';
export const SET_ARTIST_DATE_FILTER = 'SET_ARTIST_DATE_FILTER';
export const SET_FILTERED_LOTS_COUNT = 'SET_FILTERED_LOTS_COUNT';
export const SET_LOTS_DISPLAY_MODE = 'SET_LOTS_DISPLAY_MODE';
export const SET_NUMBER_OF_LOTS_PER_PAGE = 'SET_NUMBER_OF_LOTS_PER_PAGE';
export const SET_CURRENT_PAGE_NUMBER = 'SET_CURRENT_PAGE_NUMBER';
export const SET_ARTIST_PRICE_RANGE_FILTER = 'SET_ARTIST_PRICE_RANGE_FILTER';
export const SET_HOUSES_SELLING_WORK_BY_ARTIST_SUCCESS = 'SET_HOUSES_SELLING_WORK_BY_ARTIST_SUCCESS';

/* *************** inv-common ***************** */
export const FETCH_SESSION_INFO_SUCCESS = 'FETCH_SESSION_INFO_SUCCESS';
export const FETCH_SESSION_INFO_ERROR = 'FETCH_SESSION_INFO_ERROR';
export const FETCH_FOLLOW_THIS_ARTIST_SUCCESS = 'FETCH_FOLLOW_THIS_ARTIST_SUCCESS';
export const FETCH_FOLLOW_THIS_ARTIST_ERROR = 'FETCH_FOLLOW_THIS_ARTIST_ERROR';
export const SET_FOLLOW_THIS_ARTIST_SUCCESS = 'SET_FOLLOW_THIS_ARTIST_SUCCESS';
export const SET_FOLLOW_THIS_ARTIST_ERROR = 'SET_FOLLOW_THIS_ARTIST_ERROR';
export const SET_USER_POPULAR_ARTISTS_DATA = 'SET_USER_POPULAR_ARTISTS_DATA';
export const SET_IS_PROD_ENV = 'SET_IS_PROD_ENV';
export const SET_HOST_NAME = 'SET_HOST_NAME';
export const SET_USER_COUNTRY_CODE = 'SET_USER_COUNTRY_CODE';
export const ADD_CONVERSION_RATE = 'ADD_CONVERSION_RATE';
export const FETCH_SIMILAR_ARTISTS_DATA_SUCCESS = 'FETCH_SIMILAR_ARTISTS_DATA_SUCCESS';
export const FETCH_SIMILAR_ARTISTS_DATA_ERROR = 'FETCH_SIMILAR_ARTISTS_DATA_ERROR';
export const FETCH_USER_ARTIST_FOLLOW_STATUS_SUCCESS = 'FETCH_USER_ARTIST_FOLLOW_STATUS_SUCCESS';
export const FETCH_USER_ARTIST_FOLLOW_STATUS_ERROR = 'FETCH_USER_ARTIST_FOLLOW_STATUS_ERROR';
export const SET_IS_MOBILE_USER_AGENT = 'SET_IS_MOBILE_USER_AGENT';
export const FETCH_USER_COUNTS_SUCCESS = 'FETCH_USER_COUNTS_SUCCESS';
export const FETCH_USER_COUNTS_ERROR = 'FETCH_USER_COUNTS_ERROR';
export const SET_SEARCH_TYPE_AHEAD_COOKIE = 'SET_SEARCH_TYPE_AHEAD_COOKIE';
export const SET_MY_INVALUABLE_COOKIE = 'SET_MY_INVALUABLE_COOKIE';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const SET_POST_LOGIN_REDIRECT_URL = 'SET_POST_LOGIN_REDIRECT_URL';

/* *************** Header ***************** */
export const FETCH_HEADER_LOGIN_SUCCESS = 'FETCH_HEADER_LOGIN_SUCCESS';
export const FETCH_HEADER_LOGIN_LOADING = 'FETCH_HEADER_LOGIN_LOADING';
export const FETCH_HEADER_LOGIN_ERROR = 'FETCH_HEADER_LOGIN_ERROR';
export const FETCH_HEADER_SIGNUP_SUCCESS = 'FETCH_HEADER_SIGNUP_SUCCESS';
export const FETCH_HEADER_SIGNUP_LOADING = 'FETCH_HEADER_SIGNUP_LOADING';
export const FETCH_HEADER_SIGNUP_ERROR = 'FETCH_HEADER_SIGNUP_ERROR';
export const FETCH_IS_VIP_STATUS_SUCCESS = 'FETCH_IS_VIP_STATUS_SUCCESS';
export const FETCH_IS_VIP_STATUS_ERROR = 'FETCH_IS_VIP_STATUS_ERROR';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';

/* *************** RFA ***************** */
export const FETCH_RFA_PAGE_DATA_SUCCESS = 'FETCH_RFA_PAGE_DATA_SUCCESS';
export const FETCH_RFA_PAGE_DATA_ERROR = 'FETCH_RFA_PAGE_DATA_ERROR';
export const FETCH_RFA_USER_DATA_SUCCESS = 'FETCH_RFA_USER_DATA_SUCCESS';
export const FETCH_RFA_USER_DATA_ERROR = 'FETCH_RFA_USER_DATA_ERROR';
export const SET_SMS_BANNED_STATUS = 'SET_SMS_BANNED_STATUS';

/* *************** Modals ***************** */
export const SET_MODAL_STATUS = 'SET_MODAL_STATUS';
