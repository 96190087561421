import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { selectArtistDisplayName } from '../../selectors/artist-detail';

import BreadcrumbListSchema from './BreadcrumbListSchema';
import { ARTIST_DIRECTORY_REDIRECT_URL, HOME_REDIRECT_URL } from './constants';

function Breadcrumbs({ pathName, children }) {
  const artistDisplayName = useSelector(selectArtistDisplayName);

  return (
    <div className="breadcrumb-container mb-4 mt-3">
      <BreadcrumbListSchema artistName={artistDisplayName} pathName={pathName} />
      <Breadcrumb>
        <BreadcrumbItem><a href={HOME_REDIRECT_URL}>Home</a></BreadcrumbItem>
        <BreadcrumbItem><a href={ARTIST_DIRECTORY_REDIRECT_URL}>Artists</a></BreadcrumbItem>
        { children }
      </Breadcrumb>
    </div>
  );
}

Breadcrumbs.propTypes = {
  pathName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Breadcrumbs;
