/**
 * A helper to return slider config based on the view port size.
 * @param {object} config slick slider config object https://react-slick.neostack.com/docs/api/
 * @param {boolean} isMobile is mobile device
 * @param {boolean} isTablet is tablet device
 * @returns {object} slick slider config object
 */
export const getDeviceSliderConfig = (config, isMobile, isTablet, isSmallMobile) => {
  if (isSmallMobile) return config.MOBILE_SM_CONFIG;
  if (isMobile) return config.MOBILE_CONFIG;
  if (isTablet) return config.TABLET_CONFIG;
  return config.DESKTOP_CONFIG;
};
