import React from 'react';
import PropType from 'prop-types';

import CurrentRefinements from 'src/components/common/algolia/current-refinment';
import FilteredLotsView from 'src/components/artist-detail/filters-and-lots-view/FilteredLotsView';

export function MobileFilterResultsView({
  searchState,
  onSearchStateChange,
}) {
  return (
    <div className="current-refinements-container">
      <div className="clear-filters-holder">
        <CurrentRefinements
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
        />
      </div>
      <FilteredLotsView />
    </div>
  );
}

MobileFilterResultsView.propTypes = {
  searchState: PropType.shape({}).isRequired,
  onSearchStateChange: PropType.func.isRequired,
};
