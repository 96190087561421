import algoliasearch from 'algoliasearch';
import { get } from 'lodash';
import moment from 'moment';
import { getImageBaseDomain } from 'src/utils/common/helpers';
import { MY_INVALUABLE } from 'routes/utils/constants';
import { sendABToHeap } from '../searchBar/config';

const { urls: { seoFriendlyUrl } } = require('invaluable-solitaire');

const { office365, google, yahoo, ics } = require('calendar-link');

const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, 'NO_KEY', {
  hosts: [
    {
      url: process.env.REACT_APP_ALGOLIA_API_URL,
      protocol: 'https',
      accept: 1,
    },
  ],
});

export async function getArtistHits(artist = []) {
  const sevenDaysAgo = moment().subtract(7, 'days').unix();
  const query = artist.slice(0, 8).map(({ artistRef, lastSearchedDate }) => ({
    indexName: 'upcoming_lots_alerts_prod',
    filters: `postDateTimeUTCUnix>=${lastSearchedDate && lastSearchedDate != null
      ? moment(lastSearchedDate).unix()
      : sevenDaysAgo} AND artistRef:${artistRef}`,
    params: {
      hitsPerPage: 1,
    },
  }));
  const { results } = await algoliaClient.multipleQueries(query);
  if (results.length) {
    const countItems = [];
    results.forEach(({ hits, nbHits }) => {
      if (hits.length) {
        countItems.push({
          countItems: nbHits,
          artistRef: hits[0].artistRef
        });
      }
    });
    const updatedArtist = [];
    artist.forEach((item) => {
      const isCount = countItems.find(({ artistRef }) => artistRef === item.artistRef);
      updatedArtist.push({
        ...item,
        countItems: isCount ? isCount.countItems : 0,
      });
    });
    return updatedArtist;
  }
  return artist.slice(0, 8);
}

export function getCalendarLinks(event, catalogRef) {
  const calendarLinks = [
    {
      calendarType: 'Outlook',
      calendarURL: office365(event),
      catalogRef,
      asUrl: true,
    },
    {
      calendarType: 'Google',
      calendarURL: google(event),
      catalogRef,
      asUrl: true,
    },
    {
      calendarType: 'Yahoo',
      calendarURL: yahoo(event),
      catalogRef,
      asUrl: true,
    },
    {
      calendarType: 'iCal',
      calendarURL: ics(event),
      catalogRef,
      asUrl: false,
    },
  ];

  return calendarLinks;
}

function sortWithFilters(array = []) {
  const liveOutBid = array.filter((item, idx) => {
    const { isLive, isOutBid } = item;
    if (isLive && isOutBid) {
      array.splice(idx, 1);
      return item;
    }
    return null;
  });
  const liveHighBid = array.filter((item, idx) => {
    const { isLive, highBidder } = item;
    if (isLive && highBidder) {
      array.splice(idx, 1);
      return item;
    }
    return null;
  });
  const liveFav = array.filter((item) => {
    const { isLive, isFav } = item;
    if (isLive && isFav) {
      return true;
    }
    return false;
  });
  const notLiveOutBid = array.filter((item, idx) => {
    const { isLive, isOutBid } = item;
    if (!isLive && isOutBid) {
      array.splice(idx, 1);
      return item;
    }
    return null;
  });
  const notLiveHighBid = array.filter((item, idx) => {
    const { isLive, highBidder } = item;
    if (!isLive && highBidder) {
      array.splice(idx, 1);
      return item;
    }
    return null;
  });
  const notLiveFav = array.filter((item, idx) => {
    const { isLive, isFav } = item;
    if (!isLive && isFav) {
      array.splice(idx, 1);
      return item;
    }
    return null;
  });
  return [...liveOutBid, ...liveHighBid, ...liveFav, ...notLiveOutBid, ...notLiveHighBid, ...notLiveFav, ...array];
}

function formatFavDate(lotDate, timezoneCode = '') {
  const currentDate = moment.utc(new Date());
  const eventDate = moment(lotDate);
  const daysRemaing = eventDate.diff(currentDate, 'days');
  if (daysRemaing === 0) {
    return {
      dateOrDays: 'Live Today',
      showDate: false,
    };
  }
  if (daysRemaing < 7) {
    return {
      dateOrDays: `${daysRemaing} day${daysRemaing === 1 ? '' : 's'} left`,
      showDate: false,
    };
  }
  return {
    dateOrDays: `${moment.utc(lotDate).locale('en-us').format('LLL')} ${timezoneCode}`,
    showDate: true,
  };
}

export async function formatBidsAndFav(isProd, bids = [], favs = []) {
  const formattedBids = bids.map(({
    itemView: { ref = '',
      title = '', bidCount = 0, currency = '', photos = '', timeZone = '', isLive = false, eventLocalDate = '' },
    userItemProperties: { hasBid = false, highBidder = false,
      bidStatus = '', pendingBid = false, winningBidder = false, maxBidAmount = 0 },
  }) => {
    const dateStamp = formatFavDate(eventLocalDate || new Date(), timeZone);
    return {
      bidCount,
      date: dateStamp.dateOrDays,
      showDate: dateStamp.showDate,
      hasBid,
      highBidder,
      img: get(
        photos,
        '[0]._links.thumbnail.href',
        `${getImageBaseDomain(isProd)}/static/noimage_noborder.png`
      ),
      isFav: false,
      isLive,
      isOutBid: bidStatus === 'outbid',
      linkTo: `/auction-lot/${seoFriendlyUrl(title)}-${seoFriendlyUrl(ref)}`,
      pendingBid,
      price: `${currency} ${maxBidAmount}`,
      ref,
      title,
      winningBidder,
    };
  }) || [];

  const formattedFavs = favs.map(({ ref = '', title = '', price = 0,
    isLive = false, bidCount = 0, currencyCode = '', photos = '', eventDateLocalized = '',
    timezoneCode = '',
  }) => {
    const dateStamp = formatFavDate(eventDateLocalized, timezoneCode);
    return {
      bidCount,
      date: dateStamp.dateOrDays,
      showDate: dateStamp.showDate,
      hasBid: false,
      highBidder: false,
      img: get(
        photos,
        '[0]._links.thumbnail.href',
        `${getImageBaseDomain(isProd)}/static/noimage_noborder.png`
      ),
      isFav: true,
      isLive,
      isOutBid: false,
      linkTo: `/auction-lot/${seoFriendlyUrl(title)}-${seoFriendlyUrl(ref)}`,
      pendingBid: false,
      price: `${currencyCode} ${price}`,
      ref,
      title,
      winningBidder: false,
    };
  }) || [];
  if (formattedBids.length && formattedFavs.length) {
    const mergeFavAndBids = formattedBids.map((item) => {
      const isFav = formattedFavs.findIndex(({ ref }) => ref === item.ref);
      if (isFav !== -1) {
        formattedFavs.splice(isFav, 1);
        return {
          ...item,
          isFav: true,
        };
      }
      return item;
    });
    const bidAndFavItems = sortWithFilters([...mergeFavAndBids, ...formattedFavs]);
    if (bidAndFavItems.length > 4) {
      bidAndFavItems.length = 4;
    }
    return bidAndFavItems;
  }
  if (!formattedBids.length && formattedFavs.length) {
    if (formattedFavs.length > 4) {
      formattedFavs.length = 4;
    }
    return formattedFavs;
  }
  if (formattedBids.length && !formattedFavs.length) {
    if (formattedBids.length > 4) {
      formattedBids.length = 4;
    }
    return formattedBids;
  }
  return [];
}

export const trackClick = (eventName) => {
  sendABToHeap('My Inv-hover unit', { clickEvent: eventName }, 0, MY_INVALUABLE);
};

export const formatNotifDate = (timeStamp) => {
  // Neet to account for UTC time how date is stored in DB
  const timeOffset = new Date(Date.now()).getTimezoneOffset() * 60000;
  const justNowDate = Date.now() - 300000;
  const lastSixHoursDate = Date.now() - 6 * 60 * 60000;
  const lastWeekDate = Date.now() - 7 * 24 * 60 * 60000;
  const compareDate = new Date(timeStamp) - timeOffset;
  const correctDate = new Date(compareDate);
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let timeOptions;

  // Convert our timestamps to text if they fall within benchmarks of time
  if (compareDate >= justNowDate) {
    return 'Just Now';
  } if (compareDate >= lastSixHoursDate) {
    const minutes = Math.round((Date.now() - compareDate) / 60000);
    const hours = Math.round((Date.now() - compareDate) / (60 * 60000));
    if (compareDate >= Date.now() - 59 * 60000) {
      return `${minutes} minutes ago`;
    } if (hours < 2) {
      return `${hours} hour ago`;
    }
    return `${hours} hours ago`;
  } if (compareDate >= lastWeekDate) {
    timeOptions = {
      weekday: 'long',
      timeZone: currentTimeZone,
      hour: 'numeric',
      minute: '2-digit',
    };
  } else {
    timeOptions = {
      month: 'long',
      day: 'numeric',
      timeZone: currentTimeZone,
      hour: 'numeric',
      minute: '2-digit',
    };
  }

  // Return Formatted Date String
  const showDate = new Date(correctDate).toLocaleTimeString('en-us', timeOptions);
  return showDate;
};

const MY_ACCOUNT_URL_PREFIX = '/my-account/';
export const MY_ACCOUNT_LINKS = {
  My_ACCOUNT_SECTION: [
    {
      label: 'My Bids',
      link: `${MY_ACCOUNT_URL_PREFIX}bids`,
      countKey: 'ACTIVE_BIDS'
    },
    {
      label: 'My Auctions',
      link: `${MY_ACCOUNT_URL_PREFIX}auctions`,
      countKey: 'REGISTERED_AUCTIONS'
    },
    {
      label: 'My Purchases',
      link: `${MY_ACCOUNT_URL_PREFIX}purchases`,
      countKey: 'REGISTERED_AUCTIONS'
    },
    {
      label: 'My Saved Items',
      link: `${MY_ACCOUNT_URL_PREFIX}/saved-items`,
      countKey: 'WATCHED_LOTS'
    }
  ],
  MY_ACCOUNT_FOLLOWING: [
    {
      label: 'Keywords',
      link: `${MY_ACCOUNT_URL_PREFIX}keywords`,
      countKey: 'KEYWORD_ALERTS',
    },
    {
      label: 'Artists',
      link: `${MY_ACCOUNT_URL_PREFIX}artists`,
      countKey: 'KEYWORD_ARTIST_ALERTS',
    },
    {
      label: 'Sellers',
      link: `${MY_ACCOUNT_URL_PREFIX}sellers`,
      countKey: 'FOLLOWING_SELLERS_COUNT',
    },
    {
      label: 'Categories',
      link: `${MY_ACCOUNT_URL_PREFIX}categories`,
      countKey: 'CATEGORY_ALERTS',
    }
  ],
  MY_DIGITAL_GALLARY: {
    label: 'My Digital Gallery',
    link: `${MY_ACCOUNT_URL_PREFIX}digital-gallery`,
    countKey: ''
  },
  MY_ACCOUNT_SETTINGS: [
    {
      link: `${MY_ACCOUNT_URL_PREFIX}payment-options`,
      label: 'Payment Options',
    },
    {
      link: '/myInvaluable/mySubscriptions.cfm',
      label: 'Subscriptions',
    },
    {
      link: `${MY_ACCOUNT_URL_PREFIX}message-center`,
      label: 'Messages',
      countKey: 'UNREAD_MESSAGES_COUNT'
    },
    {
      link: '/inv/help/',
      label: 'Help',
    },
    {
      label: 'Notification Settings',
      link: `${MY_ACCOUNT_URL_PREFIX}email-preferences`,
    },
  ]
};
