import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useMedia } from '../../../hooks';
import { MOBILE_MEDIA_QUERY, MOBILE_SM_MEDIA_QUERY, TABLET_MEDIA_QUERY } from '../constants';

import { DEFAULT_CAROUSEL_CONFIG } from './config';
import { getDeviceSliderConfig } from './helpers';

function Carousel({ customConfig, children }) {
  const [isCarouselMounted, setIsCarouselMounted] = useState(false);
  const isTablet = useMedia(TABLET_MEDIA_QUERY);
  const isMobile = useMedia(MOBILE_MEDIA_QUERY);
  const isSmallMobile = useMedia(MOBILE_SM_MEDIA_QUERY);

  const carouselConfig = isEmpty(customConfig) ? DEFAULT_CAROUSEL_CONFIG : customConfig;
  const deviceSliderConfig = getDeviceSliderConfig(carouselConfig, isMobile, isTablet, isSmallMobile);
  const sliderConfig = {
    ...deviceSliderConfig,
    infinite: deviceSliderConfig.infinite && children.length > deviceSliderConfig.slidesToShow,
  };
  const carouselClasses = classNames('artist-carousel-container', { hidden: !isCarouselMounted });

  useEffect(() => {
    setIsCarouselMounted(true);
  }, []);

  return (
    <div className={carouselClasses}>
      <Slider {...sliderConfig}>
        {children}
      </Slider>
    </div>
  );
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  customConfig: PropTypes.shape({}),
};

Carousel.defaultProps = {
  customConfig: {},
};

export default Carousel;
