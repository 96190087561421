import { endOfDay, differenceInMilliseconds } from 'date-fns';

import { SALE_TYPE, LOT_COUNT_DOWN_STATUS } from './constants';

/* LIVE LOGIC
*   If occuring same day output Live Today
*   if auction is inProgress display Live Now
*   if auction starting in next week display the # of days left
* Timed Logic:
*   ending same day: display # of hours left
*   ending within an hour display # Closing Now
*   ending 1-7 days from current date display # of days left
*   8 or more days no change to existing display
*/

const millisInOneMinute = 60000;
const millisInHour = millisInOneMinute * 60;
const millisInDay = millisInHour * 24;
const millisInWeek = millisInDay * 7;

const useTimedCountdown = (hit) => {
  try {
    const saleType = hit.saleType;
    const currentDate = new Date().getTime();
    const millisUntilEndOfDate = differenceInMilliseconds(endOfDay(new Date()), new Date());
    const eightDaysInMillis = millisInWeek + millisInDay;

    // return date info according to sale type
    if (saleType === SALE_TYPE.LIVE) {
      const dateTimeUTCUnix = hit.dateTimeUTCUnix;
      //  Multiply unix timestamp by 1000 to be able to compare to date object in milliseconds
      const dateTimeMillis = dateTimeUTCUnix * 1000;
      const liveTimeRemaining = dateTimeMillis - currentDate;
      const daysRemaining = Math.ceil((liveTimeRemaining - (millisUntilEndOfDate)) / millisInDay);
      //   If sale is currently live and open
      if (currentDate > dateTimeMillis) {
        return LOT_COUNT_DOWN_STATUS.LIVE_NOW;
        //   If sale is not currently scheduled to be open but is the same date as users locald date
      } if (millisUntilEndOfDate > liveTimeRemaining) {
        return LOT_COUNT_DOWN_STATUS.LIVE_TODAY;
        //   If sale is less than 8 days away but not the day of
      } if (liveTimeRemaining < eightDaysInMillis && daysRemaining <= 7) {
        return `${daysRemaining} ${daysRemaining > 1 ? LOT_COUNT_DOWN_STATUS.DAYS_LEFT
          : LOT_COUNT_DOWN_STATUS.DAY_LEFT}`;
      }
    } else if (saleType === SALE_TYPE.TIMED) {
      // Timed
      const endTimeUTCUnix = hit.endTimeUTCUnix;
      //  Multiply unix timestamp by 1000 to be able to compare to date object in milliseconds
      const endTimeMillis = endTimeUTCUnix * 1000;
      const timedTimeRemaining = endTimeMillis - currentDate;
      const timedDaysRemaining = Math.ceil((timedTimeRemaining - millisUntilEndOfDate) / millisInDay);
      const hoursRemainingTimed = timedTimeRemaining / millisInHour;
      //   If sale is same date and there is time remaining for timed item return hours left until sale
      if (millisUntilEndOfDate > timedTimeRemaining) {
        const hoursLeft = Math.floor(hoursRemainingTimed);
        if (hoursLeft < 1) {
          return LOT_COUNT_DOWN_STATUS.CLOSING_NOW;
        }
        return `${hoursLeft} ${hoursLeft > 1 ? LOT_COUNT_DOWN_STATUS.HOURS_LEFT : LOT_COUNT_DOWN_STATUS.HOUR_LEFT}`;

      //   If sale is less than 8 days away and occurs the day after the current date or greater
      } if (timedTimeRemaining < eightDaysInMillis && timedDaysRemaining <= 7) {
        return `${timedDaysRemaining} ${timedDaysRemaining > 1 ? LOT_COUNT_DOWN_STATUS.DAYS_LEFT
          : LOT_COUNT_DOWN_STATUS.DAY_LEFT}`;
      }
    }
  } catch (error) {
    console.error('timedcountdown error', error);
  }
  //   Leave date as it is currently displayed
  return false;
};

export default useTimedCountdown;
