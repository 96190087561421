import { get } from 'lodash';
import axios from 'axios';

// send whale and vip status to heap if not done previously in session
export async function sendWhaleEvent(memberID, headers) {
  const sessionMemberId = sessionStorage.getItem('memberId');
  if (memberID !== sessionMemberId) {
    const config = {
      headers,
    };
    try {
      const isWhaleRes = await axios.get('/api/users/heap-info', config);
      const isWhale = get(isWhaleRes, 'data.whale', false);
      const isVip = get(isWhaleRes, 'data.vip', false);
      sessionStorage.setItem('isWhale', isWhale);
      sessionStorage.setItem('isVip', isVip);
      sessionStorage.setItem('memberId', memberID);
      window.heap.addEventProperties({ whaleStatus: isWhale.toString() });
      window.heap.addEventProperties({ isVip: isVip.toString() });
    } catch (error) {
      console.error('heap info call error ', error);
    }
  }
}

// Heap tracking events on pageload
export function pageLoadHeapEvents(isLoggedIn, headers) {
  const memberID = get(headers, 'member-id', '');
  if (get(window, 'heap.loaded', false)) {
    try {
      if (isLoggedIn) {
        window.heap.addEventProperties({ authState: 'authenticated' });
        window.heap.identify(memberID.toString());
        sendWhaleEvent(memberID.toString(), headers);
      } else {
        window.heap.addEventProperties({ authState: 'logged-out' });
        window.heap.addEventProperties({ whaleStatus: 'false' });
      }
    } catch (error) {
      console.error('heap pageload error', error);
    }
  } else {
    setTimeout(() => {
      pageLoadHeapEvents();
    }, 1000);
  }
}
