/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  ModalBody,
  Collapse
} from 'reactstrap';
import { urls as solitaireUrls } from 'invaluable-solitaire';
import { categoryData } from '../CategoryData';

// Created this modal to appease our SEO and mitigate risk of losing organic traffic INV-10858
function SEOCategoryModal() {
  const [superCategoriesOpen, setSuperCategoriesOpen] = useState(false);
  const [fineArtOpen, setFineArtOpen] = useState(false);
  const [decorativeArtOpen, setDecorativeArtOpen] = useState(false);
  const [jewelryOpen, setJewelryOpen] = useState(false);
  const [collectiblesOpen, setCollectiblesOpen] = useState(false);
  const [furnitureOpen, setFurnitureOpen] = useState(false);
  const [asianArtAndAntiquesOpen, setAsianArtAndAntiquesOpen] = useState(false);

  return (
    <div
      className="modal-sliding fade left d-none hidden show"
      id="taxonomyOptionsModal"
    >
      <div className="modal-header">
        <h4 className="modal-title">Shop the World's Premier</h4>
        <button type="button" className="close" data-dismiss="modal">
          <span>×</span><span className="sr-only">Close</span>
        </button>
      </div>
      <ModalBody>
        <div className="menu-options taxonomy-options">
          <div className="card">
            <div className="card-heading">
              <a href="/auctions/" className="font-weight-bold text-secondary">
                <span className="taxonomy-title notranslate">Upcoming Auctions</span>
              </a>
            </div>
          </div>
          <div className="card">
            <div className="card-heading">
              <a href="/auction-house" className="font-weight-bold text-secondary">
                <span className="taxonomy-title notranslate">Auction Houses</span>
              </a>
            </div>
          </div>
          <div className="card">
            <div className="card-heading">
              <a href="/artists/" className="font-weight-bold text-secondary">
                <span className="taxonomy-title notranslate">Artists</span>
              </a>
            </div>
          </div>
          <div className="card invaluable-picks-link d-none">
            <div className="panel-heading">
              <a
                href="/search?queryContext=PremiumLots_all&amp;sort=bidsDesc&amp;query=%2520&amp;keyword=%2520"
                className="bold"
              >
                <img src="https://image.invaluable.com/static/invaluable_picks.svg" alt="Invaluable picks logo" />
                <span className="taxonomy-title notranslate">INVALUABLE PICKS</span>
              </a>
            </div>
          </div>
          {categoryData.map((category) => {
            const clickEvent = () => {
              switch (category.title) {
                case 'Fine Art':
                  return setFineArtOpen(!fineArtOpen);
                case 'Decorative Art':
                  return setDecorativeArtOpen(!decorativeArtOpen);
                case 'Jewelry':
                  return setJewelryOpen(!jewelryOpen);
                case 'Collectibles':
                  return setCollectiblesOpen(!collectiblesOpen);
                case 'Furniture':
                  return setFurnitureOpen(!furnitureOpen);
                case 'Asian Art':
                  return setAsianArtAndAntiquesOpen(!asianArtAndAntiquesOpen);
                default:
                  return setSuperCategoriesOpen(!superCategoriesOpen);
              }
            };

            const modalOpen = () => {
              switch (category.title) {
                case 'Fine Art':
                  return fineArtOpen;
                case 'Decorative Art':
                  return decorativeArtOpen;
                case 'Jewelry':
                  return jewelryOpen;
                case 'Collectibles':
                  return collectiblesOpen;
                case 'Furniture':
                  return furnitureOpen;
                case 'Asian Art':
                  return asianArtAndAntiquesOpen;
                default:
                  return superCategoriesOpen;
              }
            };

            const isOpen = modalOpen();

            const categoryTitle = category.title ? category.title : 'More';

            return (
              <div className="card" key={category.targetId}>
                <div
                  role="button"
                  className="card-heading"
                  onClick={() => clickEvent()}
                >
                  <span className="font-weight-bold collapsed">
                    <span className="taxonomy-title notranslate">{categoryTitle}</span>
                    {isOpen ? <span className="fa fa-angle-up" /> : <span className="fa fa-angle-down" />}
                  </span>
                </div>
                <Collapse isOpen={isOpen}>
                  <ul className="menu-options-list list-unstyled">
                    { category.title
                    && <li className="view-all"><a href={category.titleHref}>View All {category.title}</a></li>}
                    {
                    category.title
                      ? category.linkData.map((linkData) => (
                        <li key={linkData.categoryRef}>
                          <a
                            href={`/${solitaireUrls.seoFriendlyUrl(linkData.categoryName)}${linkData.isParent ? '/pc' : '/cc'}-${linkData.categoryRef}/`}
                          >
                            {linkData.categoryName}
                          </a>
                        </li>
                      ))
                      : category.linkData.filter((linkObj) => {
                        switch (linkObj.categoryName) {
                          case 'Asian Art & Antiques':
                          case 'Collectibles':
                          case 'Decorative Art':
                          case 'Fine Art':
                          case 'Furniture':
                          case 'Jewelry':
                            return false;
                          default:
                            return true;
                        }
                      }).map((linkData) => (
                        <li key={linkData.categoryRef}>
                          <a
                            href={`/${solitaireUrls.seoFriendlyUrl(linkData.categoryName)}${linkData.isParent ? '/pc' : '/cc'}-${linkData.categoryRef}/`}
                          >
                            {linkData.categoryName}
                          </a>
                        </li>
                      ))
                  }
                  </ul>
                </Collapse>
              </div>
            );
          })}
        </div>
      </ModalBody>
    </div>
  );
}

export default SEOCategoryModal;
