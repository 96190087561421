import { omit, get } from 'lodash';
import queryString from 'query-string';

import {
  FETCH_SEARCH_RESULTS_ERROR,
  FETCH_SEARCH_RESULTS_LOADING,
  FETCH_SEARCH_RESULTS_SUCCESS,
  TOGGLE_SAVED_SEARCH_KEYWORD,
  FETCH_KEYWORD_FOLLOWED_ERROR,
  FETCH_KEYWORD_FOLLOWED_SUCCESS,
  FETCH_CURRENCY_CODE,
} from '../actions/constants';

const initialState = {
  items: null,
  isLoadingSearchResults: false,
  searchType: null,
  followed: false,
  followedKeyword: false,
};

function search(state = initialState, action) {
  switch (action.type) {
    case FETCH_SEARCH_RESULTS_LOADING:
      return {
        ...state,
        searchType: action.type,
        items: null,
        links: null,
        page: null,
        postLoginAction: null,
        isLoadingSearchResults: true,
        previousNumBids: (state.page) ? state.page.totalElements : 0,
      };

    case FETCH_SEARCH_RESULTS_SUCCESS: {
      const { keywordsFollowed, sessionInfo } = action.payload;
      const paramsObj = (window.location.search) ? queryString.parse(window.location.search) : {};
      const urlKeyword = paramsObj.keyword;
      const userObject = get(sessionInfo, 'user', false);
      const isLoggedIn = userObject !== false;
      /*
     ** Set postLoginAction value to the state if the cookie exist
     ** then immediately delete the cookie by expiring it.
     */
      let postLoginAction;
      if (document.cookie.includes('postLoginAction')) {
        postLoginAction = document.cookie.split(';')
          .find(cookie => cookie.includes('postLoginAction'))
          .split('=')[1];

        document.cookie = `postLoginAction=; expires=${new Date()}`;
      }

      let followed = false;
      if (keywordsFollowed && urlKeyword) {
      /*
      ** Making the values lowercase solves for the problem of forms
      ** in mobile capitalizing terms by default.
      */
        const newKeywordsFollowed = {};
        keywordsFollowed.keywords.forEach((term) => {
        // eslint-disable-next-line no-param-reassign
          if (typeof term === 'number') term = term.toString();

          newKeywordsFollowed[term.toLowerCase()] = 1;
        });

        followed = !!newKeywordsFollowed[urlKeyword.toLowerCase()];
      }

      return {
        ...state,
        keyword: urlKeyword,
        searchType: action.type,
        isLoadingSearchResults: false,
        isLoggedIn,
        status: action.payload.status || 200,
        keywordsFollowed,
        followed,
        postLoginAction,
        sessionInfo,
      };
    }

    case TOGGLE_SAVED_SEARCH_KEYWORD: {
      const followed = !state.followed;
      const followedKeyword = !state.followedKeyword;
      let keywordsFollowed;
      if (action.payload.type === 'saveKeyWord') {
        const keywordId = action.payload.keywordId;
        keywordsFollowed = followed ? { ...state.keywordsFollowed, [action.payload.keyword.toLowerCase()]: 1 } : omit(state.keywordsFollowed, state.keyword);
        return {
          ...state,
          followed,
          followedKeyword,
          keywordsFollowed,
          keywordId,
        };
      }
      keywordsFollowed = followed ? { ...state.keywordsFollowed, [state.keyword.toLowerCase()]: 1 } : omit(state.keywordsFollowed, state.keyword);
      return {
        ...state,
        followed,
        followedKeyword,
        keywordsFollowed,
      };
    }

    case FETCH_SEARCH_RESULTS_ERROR:
      return {
        ...state,
        keyword: null,
        searchType: action.type,
        items: null,
        errorMessage: action.payload,
        isLoadingSearchResults: false,
        page: { totalElements: 0, totalPages: 0 },
        previousNumBids: null,
      };

    case FETCH_KEYWORD_FOLLOWED_SUCCESS: {
      let keywordId = action.payload.keywordId;
      const followedKeyword = !!keywordId;

      if (keywordId === undefined) {
        keywordId = '';
      }

      return {
        ...state,
        keywordId,
        followedKeyword,
      };
    }

    case FETCH_KEYWORD_FOLLOWED_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        keywordId: '',
        followedKeyword: false,
      };

    case FETCH_CURRENCY_CODE:
      return {
        ...state,
        localCurrencyCode: action.payload,
      };

    default:
      return state;
  }
}

export default search;
