/**
 * Get cookie values by name from request.
 * @param {string} [cName] - Name of cookie to be retreived
 * @param {string} [cookies] - Cookies received in request
 * @returns {string} Value set against the cookie.
 */
export function getCookie(cName, cookies) {
  const name = `${cName}=`;
  const cDecoded = decodeURIComponent(cookies); // to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}
