import React, { Fragment, useEffect, useState } from 'react';
import Search from './icons/Search';
import { RECENT_SEARCH, sendABToHeap } from '../../config';

function RecentSearches() {
  const [recentItems, setRecentItems] = useState([]);

  useEffect(() => {
    const getItem = localStorage.getItem(RECENT_SEARCH);
    if (getItem) {
      const recentSearch = JSON.parse(getItem);
      setRecentItems(recentSearch);
    }
  }, []);

  if (!recentItems.length) {
    return (
      <Fragment>
        <h4>
          <Search />
          Recent Searches
        </h4>
        <p>No recent searches</p>
      </Fragment>
    );
  }

  return (recentItems.length
    ? (
      <Fragment>
        <h4>
          <Search />
          Recent Searches
        </h4>
        <div className="recent-block">
          {recentItems.slice(0, 4).map((title, idx) => (
            <div
          // eslint-disable-next-line react/no-array-index-key
              key={`${title}-${idx}`}
              className="recent-items"
            >
              <a
                onClick={() => {
                  sendABToHeap('Search Type Ahead', { clickEvent: 'Recent Searches' }, 0);
                }}
                href={`/search?query=${title}&keyword=${title}`}
                className="text-truncate"
              >
                {title}
              </a>
            </div>
          ))}
        </div>
      </Fragment>
    ) : null
  );
}

export default RecentSearches;
