import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { setLotsDisplayMode } from 'src/actions';
import { LOTS_DISPLAY_MODES } from 'src/utils/artist-detail/constants';
import { selectLotsDisplayMode } from 'src/selectors/artist-detail';

function GridViewListViewControls() {
  const dispatch = useDispatch();
  const lotsDisplayMode = useSelector(selectLotsDisplayMode);

  const btnClasses = 'btn border-btn';
  const gridViewBtnClasses = classNames(btnClasses, { selected: lotsDisplayMode === LOTS_DISPLAY_MODES.GRID });
  const listViewBtnClasses = classNames(btnClasses, { selected: lotsDisplayMode === LOTS_DISPLAY_MODES.LIST });

  const handleLotsDisplayModeChange = (updatedDisplayMode) => {
    dispatch(setLotsDisplayMode(updatedDisplayMode));
  };

  return (
    <div className="filter-views-btns">
      <button
        className={gridViewBtnClasses}
        onClick={() => handleLotsDisplayModeChange(LOTS_DISPLAY_MODES.GRID)}
      >
        <i className="fa fa-th-large" />
      </button>
      <button
        className={listViewBtnClasses}
        onClick={() => handleLotsDisplayModeChange(LOTS_DISPLAY_MODES.LIST)}
      >
        <i className="fa fa-th-list" />
      </button>
    </div>
  );
}

export default GridViewListViewControls;
