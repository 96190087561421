/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
import { sectionTitle, theme } from '../config';

function AutoComplete({ currentRefinement, refine, hits, onSuggestionSelected, isMobile }) {
  const [value, setValue] = useState(currentRefinement);

  useEffect(() => {
    const queryString = window.location.search && new URLSearchParams(window.location.search);
    let keyword = queryString && queryString.get('keyword');
    if (keyword === null) {
      keyword = '';
    }

    setValue(decodeURIComponent(keyword));
  }, []);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      return window.location.href = `/search?keyword=${currentRefinement}`;
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const inputLength = value.length;

    if (inputLength === 0) {
      return [];
    }

    if (inputLength >= 3) {
      refine(value);
    }
  };

  const onSuggestionsClearRequested = () => {
    refine();
  };

  function getSuggestionValue(hit) {
    if (hit.query) {
      return hit.query;
    }
    if (hit.name) {
      return hit.name;
    }
    if (hit.houseName) {
      return hit.houseName;
    }
  }

  function renderSuggestion(hit) {
    let display = '';

    if (hit.query) {
      display = hit.query;
    }

    if (hit.name) {
      display = hit.name;
    }
    if (hit.houseName) {
      display = hit.houseName;
    }

    return (
      <div className="ais-suggestion">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="eac-item text-overflow"
        >
          {display}
        </a>
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function InputComponent(inputProps) {
    return (
      <div
        className="form-group form-tooltip-group form-group-special"
        id={(isMobile) ? 'searchBar_m' : 'searchBar'}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={(isMobile) ? 'searchItemsNew_m' : 'searchItemsNew'}
          className="form-special-label hidden-xs d-none d-md-block"
        >
          <a
            href="/catalog/advancedSearch.cfm?srchScope=u"
            className="notranslate"
          >Advanced
          </a>
        </label>
        <div className="dropdown btn-group">
          <div
            className="input-group"
            role="combobox"
            aria-controls={(isMobile) ? 'termsList_m' : 'termsList'}
            aria-expanded={hits.length > 0}
          >
            <div className="input-group-addon">
              <i className="fa fa-search text-complimentary" />
            </div>
            <input
              {...inputProps}
              type="text"
              aria-label="Search items & sellers"
              placeholder="Search items & sellers"
              id={(isMobile) ? 'searchItemsNew_m' : 'searchItemsNew'}
              className="search-items form-control"
            />
          </div>
        </div>
      </div>
    );
  }

  function renderSuggestionsContainer({ containerProps, children }) {
    return (
      <div
        {...containerProps}
        className="ais-search-list"
        style={children && { ...theme.suggestionsContainer, display: 'block' }}
      >
        {children && (
        <div
          id={(isMobile) ? 'egageSearch_m' : 'egageSearch'}
          style={{ paddingLeft: '10px' }}
        />
        )}
        {children}
      </div>
    );
  }

  function renderSectionTitle(section) {
    if (section.hits.length > 0) {
      return sectionTitle[section.index];
    }
  }

  function getSectionSuggestions(section) {
    return section.hits;
  }

  const inputProps = {
    placeholder: 'Search for a product...',
    onChange,
    onKeyDown: handleKeyDown,
    value,
  };

  return (
    <AutoSuggest
      suggestions={hits}
      multiSection
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      renderInputComponent={InputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      theme={theme}
    />
  );
}

export default connectAutoComplete(AutoComplete);
