/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { urls } from 'invaluable-solitaire';
import {
  Modal,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { get } from 'lodash';
import { markAllNotifsRead, deleteAllNotifications, markNotifRead } from 'src/actions/inv-common.actions';
import { NotifModalHeader } from 'src/containers/Header/Header.styled';
import NotificationsDropDown from '../popovers/NotificationsDropDown';
import { formatNotifDate } from '../myInvaluable/utils';

function NotificationsModal({ isModalOpen, closeModal }) {
  const dispatch = useDispatch();
  const notificationsArr = useSelector((state) => get(state, 'invReactCommon.notifications', []));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const headers = useSelector((state) => get(state, 'invReactCommon.reqHeaders', {}));

  return (
    <Modal
      isOpen={isModalOpen}
      modalClassName="modal-sliding fade right show"
      className="modal-dialog"
      size="sm"
    >
      <NotifModalHeader className="modal-header">
        <h4
          className="modal-title text-truncate"
          style={{
            width: '70%',
          }}
        >Notifications
        </h4>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          direction="down"
          style={{
            width: '15%',
          }}
        >
          <DropdownToggle
            caret
            tag="button"
            className="close dropdown"
          >
            <span
              className="fa fa-gear"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                position: 'relative',
                top: '0px',
                left: '19px',
                fontSize: '.8em'
              }}

            />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu mob-dropdown"
          >
            <DropdownItem
              id="markAllRead"
              tag="a"
              onClick={() => dispatch(markAllNotifsRead({ headers }))}
            >
              Mark all as read
            </DropdownItem>
            <DropdownItem
              tag="a"
              id="deleteAllNotifs"
              onClick={() => dispatch(deleteAllNotifications({ headers }))}
            >
              Delete all
            </DropdownItem>
            <DropdownItem
              tag="a"
              className="notif-link"
              href="/my-account/email-preferences"
            >
              Notification Settings
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <button
          type="button"
          className="close ml-0"
          data-dismiss="modal"
          onClick={() => closeModal()}
          style={{
            width: '15%',
          }}
        >
          <span aria-hidden="true">×</span><span className="sr-only">Close</span>
        </button>
      </NotifModalHeader>
      <ModalBody>
        <div className="my-acct-nav menu-options menu-user-options notif-menu">
          <ul id="notifsPopUpList" className="media-list">
            {
                notificationsArr.length < 1 && (
                <li className="row pl-2" key="noNotifs">
                  <div className="media-body row mt-3">
                    <div className="col-10">
                      <h4 className="media-heading mark-notif-read-link my-0" data-link="/my-account/email-preferences" data-id="">You do not have any notifications.</h4>
                      <span className="notif-span mark-notif-read-link" data-link="/my-account/email-preferences" data-id="">You can manage your desktop notification settings from your account.</span>
                      <div className="notif-date-text mark-notif-read-link" data-link="/my-account/email-preferences" data-id="" />
                    </div>
                  </div>
                </li>
                )
        }
            {notificationsArr.length > 0 && notificationsArr.map((notif) => {
              let lotTitle = notif.lotTitle ? `${notif.lotTitle.split(' ').join('-').replace(/,/g, '')}-` : '';
              let pageType = notif.redirectType;
              let lotRef = notif.ref?.toLowerCase();

              if (pageType === 'lot') {
                pageType = 'auction-lot';
                lotTitle = `${urls.seoFriendlyUrl(notif.title)}-`;
              }

              if (pageType === 'messages') {
                pageType = 'my-account/message-center';
                lotRef = '';
                lotTitle = '';
              }

              const notificationURL = `/${pageType}/${lotTitle?.toLowerCase()}${lotRef}`;

              return (
                <li key={`${notif.notificationID}mob`} className="media row mt-3 pb-2 border-bottom">
                  <div className="notif-col">
                    {!notif.read
                && <span className="fa fa-circle red-font" />}
                  </div>
                  <div className="media-left col-2 zero-left-padding">
                    <a
                      href={notificationURL}
                      className=" mark-notif-read-link"
                      onClick={() => dispatch(markNotifRead({ notificationID: notif.notificationID, headers }))}
                    >
                      <img
                        className="media-object notif-img"
                        src={notif.thumbPhotoLink ? notif.thumbPhotoLink : '/img/invaluable/INVBadge_256x256.png'}
                        alt="..."
                      />
                    </a>
                  </div>
                  <div className="media-body row ">
                    <div
                      className="col-10"
                      onClick={() => dispatch(markNotifRead({
                        notificationID: notif.notificationID, headers, notificationURL
                      }))}
                      aria-hidden="true"
                    >
                      <h4 className="media-heading mark-notif-read-link ">{notif.title}</h4>
                      <span className="notif-span mark-notif-read-link">{notif.body}</span>
                      <div className="notif-date-text mark-notif-read-link">{formatNotifDate(notif.timeStamp)}</div>
                    </div>
                    <NotificationsDropDown notifData={notif} headers={headers} />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default NotificationsModal;
