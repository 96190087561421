import axios from 'axios';
import { get } from 'lodash';
import logger from 'routes/middleware/logging/logger';
import {
  FETCH_CATEGORIES_ERROR,
  FETCH_CATEGORIES_LOADING,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_FOLLOW_LOADING,
  FETCH_CATEGORY_FOLLOW_ERROR,
  FETCH_CATEGORY_FOLLOW_SUCCESS,
  FETCH_CATEGORY_UNFOLLOW_ERROR,
  FETCH_CATEGORY_UNFOLLOW_SUCCESS,
} from './constants';

// Action creators for follow category
export const fetchCategoryFollowLoading = () => ({
  type: FETCH_CATEGORY_FOLLOW_LOADING,
});

export const fetchCategoryFollowSuccess = payload => ({
  type: FETCH_CATEGORY_FOLLOW_SUCCESS,
  payload: {
    ...payload,
    isLoadingFollowCategory: false,
    isFollowingSuccess: true,
    isUnfollowingSuccess: false,
  },
});

export const fetchCategoryFollowError = payload => ({
  type: FETCH_CATEGORY_FOLLOW_ERROR,
  payload: {
    ...payload,
    isLoadingFollowCategory: false,
    isFollowingSuccess: false,
  },
});

export const fetchCategoryFollow = ({ categoryRef, cookie, sessionInfo }) => async (dispatch) => {
  dispatch(fetchCategoryFollowLoading());

  // If the user is not following categories already... set them to following categories first
  try {
    if (!get(sessionInfo, 'emailPreferences.emailPrefs.sendCategoryAlerts')) {
      const requestBody = sessionInfo.emailPreferences;
      requestBody.emailPrefs.sendCategoryAlerts = true;

      await axios({
        method: 'POST',
        url: '/app/v2/user/submitEditEmailPrefs',
        data: requestBody,
        headers: { cookie },
      });
    }
  } catch (err) {
    logger.error(`Error setting category alerts email preferences [categoryRef=${categoryRef}]`, err);
    dispatch(fetchCategoryFollowError());
    return;
  }

  try {
    const url = `https://${sessionInfo.reqHostname}/api/users/categories/${categoryRef}/`;
    const headers = {
      'Content-Type': 'application/json',
      channel: 'invaluable',
      'x-auth-token': sessionInfo.token,
    };

    await axios({
      method: 'POST',
      url,
      headers,
    });
  } catch (err) {
    logger.error(`Error following category [categoryRef=${categoryRef}]`, err);
    dispatch(fetchCategoryFollowError());
    return;
  }

  // handle successful follow heap event
  try {
    // eslint-disable-next-line no-undef
    heap.track('Category Alert - Sign-up - Success Event');
  } catch (error) {
    logger.warn('Category alert heap tracking error', error);
  }

  dispatch(fetchCategoryFollowSuccess());
};

// Action creators for Unfollow category
export const fetchCategoryUnfollowSuccess = payload => ({
  type: FETCH_CATEGORY_UNFOLLOW_SUCCESS,
  payload: {
    ...payload,
    isFollowingSuccess: false,
    isUnfollowingSuccess: true,
  },
});

export const fetchCategoryUnfollowError = payload => ({
  type: FETCH_CATEGORY_UNFOLLOW_ERROR,
  payload: {
    ...payload,
    isUnfollowingSuccess: false,
  },
});

export const fetchCategoryUnfollow = ({ categoryRef, sessionInfo }) => async (dispatch) => {
  try {
    dispatch(fetchCategoryFollowLoading());
    const url = `https://${sessionInfo.reqHostname}/api/users/categories/${categoryRef}/`;
    const headers = {
      'Content-Type': 'application/json',
      channel: 'invaluable',
      'x-auth-token': sessionInfo.token,
    };

    await axios({
      method: 'DELETE',
      url,
      headers,
    });

    dispatch(fetchCategoryUnfollowSuccess());
  } catch (err) {
    logger.error(`Error unfollowing category [categoryRef=${categoryRef}]`, err);
    dispatch(fetchCategoryUnfollowError());
  }
};

// Action creators for categories page
export const fetchCategoriesLoading = () => ({
  type: FETCH_CATEGORIES_LOADING,
});

export const fetchCategoriesSuccess = payload => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload,
});

export const fetchCategoriesError = error => ({
  type: FETCH_CATEGORIES_ERROR,
  payload: error,
});

export const fetchCategories = ({ categoryRef, categoryType, cookie, reqHostname }) => async (dispatch, getStore) => {
  dispatch(fetchCategoriesLoading());

  try {
    const sessionInfo = get(getStore(), 'invReactCommon.sessionInfo', {});
    const { memberId, oasHeaders, isLoggedIn } = sessionInfo;
    const xAuthToken = oasHeaders?.['x-auth-token'] || '';

    let headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      channel: 'invaluable',
    };
    const numItems = 24;

    const isSubcategory = categoryType === 'sc';

    const categoriesContentUrl = `https://${reqHostname}/api/categories/${categoryRef}/pageContent`;
    const recommendedItemsUrl = `https://${reqHostname}/api/search/?category=${categoryRef}&sort=totalBids,desc&size=${numItems}&filter=(buyItNow:0)&filter=(hasImage:1)`;
    const recommendedItemsBuyNowUrl = `https://${reqHostname}/api/search/?category=${categoryRef}&sort=totalBids,desc&size=${numItems}&filter=(buyItNow:1)&filter=(hasImage:1)`;

    // Add member-id to headers for remaining requests
    headers = {
      ...headers,
      'member-id': memberId,
    };

    // 2) We need information from categoryContentResponse API for the categories URL
    const categoryContentResponse = await axios(categoriesContentUrl, { headers });

    /**
     * For subcategory pages - we want to get the child categories of the parent category -
     * aka the sibling subcategories.
     *
     * For super and parent categories, we want to get the default child categories.
     */
    const childCategoryRef = isSubcategory ? categoryContentResponse.data.parentCategory.ref : categoryRef;
    const categoriesUrl = `https://${reqHostname}/app/subcategories/${childCategoryRef}?excludeGeneral=true`;

    // 3) If user is logged in - get their email preferences for following category / keyword alerts
    const emailPreferences = { emailPrefs: {}, userPrefs: {} };
    if (isLoggedIn) {
      const emailPrefsUrl = `https://${reqHostname}/app/v2/user/emailPreferences`;
      try {
        const emailPreferenceResponse = await axios(emailPrefsUrl, { headers: { cookie }, withCredentials: true });
        emailPreferences.emailPrefs = emailPreferenceResponse.data.data.emailPrefs;
        emailPreferences.userPrefs = emailPreferenceResponse.data.data.userPrefs;
      } catch (err) {
        logger.warn('Could not fetch email preferences from Invaluable API', err);
      }
    }

    // 4) Make our remaining requests in parallel
    const [
      categoryResponse,
      recommendedItemsResponse,
      recommendedItemsBuyNowResponse,
    ] = await Promise.all([
      axios(categoriesUrl, { headers }),
      axios(recommendedItemsUrl, { headers }),
      axios(recommendedItemsBuyNowUrl, { headers }),
    ]);

    const payload = {
      categoryContent: categoryContentResponse.data,
      facetCounts: recommendedItemsResponse.data.facetCounts,
      recommendedItems: recommendedItemsResponse.data.itemViewList,
      recommendedItemsBuyNow: recommendedItemsBuyNowResponse.data.itemViewList,
      subCategories: categoryResponse.data.data.subcategories,
      sessionInfo: {
        emailPreferences,
        isLoggedIn,
        reqHostname,
        token: xAuthToken,
        userID: memberId
      },
    };

    dispatch(fetchCategoriesSuccess(payload));
  } catch (err) {
    logger.error(`Error fetching categories [categoryRef=${categoryRef}]`, err);
    dispatch(fetchCategoriesError(err));
  }
};
