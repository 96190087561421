/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { saveRecentItem, sendABToHeap } from '../../config';
import TrendUp from './icons/TrendUp';

function PopularItems({ hits }) {
  return (
    <Fragment>
      <h4>
        <TrendUp />
        Popular
      </h4>
      {
    !hits.length ? <p>No popular keywords</p> : null
  }
      <div className="ais-Hits">
        <ul
          className="ais-Hits-list"
          role="listbox"
        >
          {
        hits.map(hit => (
          <li
            role="option"
            aria-selected="false"
            aria-label={hit.query}
            className="text-truncate"
            key={hit.query.replace(' ', '-')}
            onClick={() => {
              saveRecentItem(hit.query);
              sendABToHeap('Search Type Ahead', { clickEvent: 'Popular Searches' }, 0);
            }}
          ><a href={`/search?keyword=${hit.query}`}>{hit.query}</a>
          </li>
        ))
      }
        </ul>
      </div>
    </Fragment>
  );
}

export default connectAutoComplete(PopularItems);
