import React, { Fragment } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { saveRecentItem, sendABToHeap } from '../../config';

function PopularItems({ hits }) {
  return (
    <Fragment>
      {
    hits.length ? (
      <div className="ais-Hits">
        <ul
          className="ais-Hits-list"
          role="listbox"
        >
          {
          hits.map(hit => (
            <li
              role="option"
              aria-selected="false"
              aria-label={hit.query}
              className="text-truncate"
              onClick={() => {
                saveRecentItem(hit.query);
                sendABToHeap('Search Type Ahead', { clickEvent: 'Popular Searches' }, 0);
              }}
            >
              <a href={`/search?query=${hit.query}&keyword=${hit.query}`}>{hit.query}</a>
            </li>
          ))
        }
        </ul>
      </div>
    ) : <span className="empty-results">No popular keywords</span>
  }
    </Fragment>
  );
}

export default connectAutoComplete(PopularItems);
