/* eslint-disable no-console */
import DOMPurify from 'isomorphic-dompurify';
import React, { Fragment, useEffect } from 'react';

function Reviews({ yotpoReviews, ssrReviews }) {
  // hide elements overlapping because of our custom styles

  const writeAReviewClick = () => {
    document.querySelector('.yotpo-bottomline').style.display = 'none';
    // we do not want to have the blue focus of the unfilled stars after clicking write a review btn
    setTimeout(() => {
      document.querySelector('.write-review-button').focus();
    }, 500);
  };

  const checkYotpoExists = () => {
    if (document.body.contains(document.querySelector('#yotpo-testimonials > div.yotpo-modal-dialog > div.yotpo-modal-content > div.yotpo-popup-box-small.yotpo-nav.yotpo-nav-primary > ul > li.yotpo-nav-tab > div > span'))) {
      document.querySelector('#yotpo-testimonials > div.yotpo-modal-dialog > div.yotpo-modal-content > div.yotpo-popup-box-small.yotpo-nav.yotpo-nav-primary > ul > li.yotpo-nav-tab > div > span').click();
      document.querySelector('.yotpo-nav-primary').style.display = 'none';

      document.querySelector('h2.modal-title').remove();

      // add event listener to button to hide reviews element that overlaps when clicking
      // native yotpo write a review button
      const writeReviewBtn = document.querySelector('.write-review-button');
      if (writeReviewBtn) {
        writeReviewBtn.addEventListener('click', writeAReviewClick);
      }
    } else {
      setTimeout(() => {
        checkYotpoExists();
      }, 500);
    }
  };

  useEffect(() => {
    checkYotpoExists();
  }, []);

  return (
    <Fragment>
      <div className="mt-4 container-fluid">
        <script
          type="application/ld+json"
          id="yotpo-reviews-jsonld"
        // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(JSON.stringify(yotpoReviews)) }}
        />

        <h1>Invaluable Reviews and Ratings</h1>
        <div className="row mt-2">
          <div className="col-md-9">
            <h2 className="reviews-title">Customer Reviews for Invaluable.com</h2>
          </div>
        </div>
        <hr />
        <div className="row mb-2">
          <div className="col-md-9">
            <p className="mb-4">
              Invaluable is the world&#39;s leading online marketplace for fine and decorative arts, collectibles, and estate auctions.
              Invaluable enables more than 4 million collectors around the globe to discover and buy the objects they love, with confidence and convenience,
              from over 5,000 of the world&#39;s premier traditional auction houses.
            </p>
            <p>
              If you&#39;re looking for Invaluable.com reviews you have come to the right place.
              We understand that our bidders put a lot of trust in us when they choose to bid within our marketplace.
            </p>
          </div>
        </div>
        <div className="yotpo-testimonials-custom-tab-container">
          <div id="yotpo-testimonials-custom-tab" dangerouslySetInnerHTML={{ __html: ssrReviews }} style={{ opacity: Number(!ssrReviews) }} />
        </div>
      </div>
      <div className="clearfix pb-5" />
    </Fragment>
  );
}

Reviews.defaultProps = {
};

Reviews.propTypes = {
};

export default Reviews;
