import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { urls } from 'invaluable-solitaire';

import { ARTISTS_INDEX,
  CATEGORIES_INDEX,
  HOUSE_INDEX,
  saveRecentItem,
  sendABToHeap,
  UPCOMING_LOTS_INDEX,
  UPCOMING_LOTS_SUGGESTIONS_INDEX } from '../../config';
import HighlightedText from './HighlightText';
import Auctions from './auctions';

function SuggestionSearch({ value, hits: hit, catalogDetails }) {
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  const houseIndex = isProd ? HOUSE_INDEX.prod : HOUSE_INDEX.dev;
  const artistIndex = isProd ? ARTISTS_INDEX.prod : ARTISTS_INDEX.dev;
  const upcomingLotsSuggestionsIndex = isProd ? UPCOMING_LOTS_SUGGESTIONS_INDEX.prod
    : UPCOMING_LOTS_SUGGESTIONS_INDEX.dev;

  const getLink = (index, val) => {
    if (index === UPCOMING_LOTS_INDEX) {
      return `/search?keyword=${val}`;
    }
    if (index === houseIndex) {
      return `/auction-house/${val}`;
    }
    if (index === CATEGORIES_INDEX) {
      return val;
    }
    if (index === artistIndex) {
      return `/artist/${val}`;
    }
  };

  const handleLastElement = (pIndex) => {
    const position = {
      0: UPCOMING_LOTS_INDEX,
      1: artistIndex,
      2: houseIndex,
      3: upcomingLotsSuggestionsIndex,
      4: CATEGORIES_INDEX,
    };
    const check = [];
    for (let i = pIndex + 1; i < 5; i += 1) {
      const firstIndex = position[i];
      hit.forEach(({ index, hits }) => {
        if (index === firstIndex) {
          if (hits.length) {
            check.push(false);
          } else {
            check.push(true);
          }
        }
      });
    }
    if (!check.every(v => v === true)) {
      return <hr className="divider" />;
    }
    return null;
  };

  const onClickTag = (saveValue) => {
    if (saveValue) {
      saveRecentItem(saveValue);
    }
  };

  const trackEvent = (index) => {
    if (index === UPCOMING_LOTS_INDEX) {
      sendABToHeap('Search Type Ahead', { clickEvent: 'Suggested Searches' }, 0);
    }
    if (index === houseIndex) {
      sendABToHeap('Search Type Ahead', { clickEvent: 'Auction Houses' }, 0);
    }
    if (index === CATEGORIES_INDEX) {
      sendABToHeap('Search Type Ahead', { clickEvent: 'Suggested Categories' }, 0);
    }
    if (index === artistIndex) {
      sendABToHeap('Search Type Ahead', { clickEvent: 'Artists' }, 0);
    }
  };

  return (
    <>
      {hit.map(({ index, hits }) => {
        if (index === UPCOMING_LOTS_INDEX) {
          return (hits.length
            ? (
              <Fragment>
                <h4>Suggested Searches</h4>
                <div
                  role="listbox"
                  className="searches-block"
                >
                  {hits.map(({ query, objectID }) => (
                    <HighlightedText
                      linkTo={getLink(index, objectID)}
                      key={`${objectID}-suggested`}
                      text={query}
                      searchString={value}
                      onClick={() => {
                        onClickTag(query);
                        trackEvent(UPCOMING_LOTS_INDEX);
                      }}
                    />
                  ))}
                </div>
                {handleLastElement(0)}
              </Fragment>
            ) : null
          );
        }
        if (index === artistIndex) {
          return (hits.length
            ? (
              <>
                <h4>Artists</h4>
                <div className="searches-block">
                  {hits.map(({ firstName, lastName, artistRef }) => (
                    <HighlightedText
                      linkTo={
                        getLink(
                          index,
                          `${urls.seoFriendlyUrl(`${firstName} ${lastName}`)}-${urls.seoFriendlyUrl(artistRef)}`
                        )
                      }
                      key={`${artistRef}-house`}
                      text={`${firstName} ${lastName}`}
                      searchString={value}
                      onClick={() => trackEvent(ARTISTS_INDEX)}
                    />
                  ))}
                </div>
                {handleLastElement(1)}
              </>
            ) : null
          );
        }
        if (index === houseIndex) {
          return (hits.length
            ? (
              <>
                <h4>Auction Houses</h4>
                <div className="searches-block">
                  {hits.map(({ houseName, houseRef }) => (
                    <HighlightedText
                      linkTo={getLink(index, `${urls.seoFriendlyUrl(houseName)}-${urls.seoFriendlyUrl(houseRef)}`)}
                      key={`${houseRef}-house`}
                      text={houseName}
                      searchString={value}
                      onClick={() => trackEvent(HOUSE_INDEX)}
                    />
                  ))}
                </div>
                {handleLastElement(2)}
              </>
            ) : null
          );
        }
        if (index === upcomingLotsSuggestionsIndex) {
          return (catalogDetails.length
            ? (
              <>
                <h4>Auctions</h4>
                <Auctions
                  catalogDetails={catalogDetails}
                  value={value}
                />
                {handleLastElement(3)}
              </>
            ) : null
          );
        }
        return null;
      })}
    </>
  );
}

export default SuggestionSearch;
