import { useState } from 'react';

const usePDPModal = (isOpen = false) => {
  const [isOpenModal, setIsOpenModal] = useState(isOpen);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);
  const toggleModal = () => setIsOpenModal(!isOpenModal);

  return [isOpenModal, openModal, closeModal, toggleModal];
};

export default usePDPModal;
