import {
  FETCH_RFA_PAGE_DATA_SUCCESS,
  FETCH_RFA_PAGE_DATA_ERROR,
  FETCH_RFA_USER_DATA_SUCCESS,
  FETCH_RFA_USER_DATA_ERROR,
  SET_SMS_BANNED_STATUS,
} from '../actions/constants';

const initialState = {
  page: {
    data: [],
    error: false,
  },
  user: {
    data: [],
    error: false,
  },
  isSmsBanned: false,
};

function rfa(state = initialState, action) {
  switch (action.type) {
    case FETCH_RFA_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        page: {
          data: action.payload,
          error: false,
        }
      };
    }

    case FETCH_RFA_PAGE_DATA_ERROR:
      return {
        ...state,
        page: {
          data: [],
          error: true,
        }
      };

    case FETCH_RFA_USER_DATA_SUCCESS: {
      return {
        ...state,
        user: {
          data: action.payload,
          error: false,
        }
      };
    }

    case FETCH_RFA_USER_DATA_ERROR:
      return {
        ...state,
        user: {
          data: [],
          error: true,
        }
      };

    case SET_SMS_BANNED_STATUS:
      return {
        ...state,
        isSmsBanned: action.payload,
      };

    default:
      return state;
  }
}

export default rfa;
