import React from 'react';

function Search() {
  return (
    <svg
      style={{ marginRight: '6px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M13 13L10.1 10.1M11.6667 6.33333C11.6667 9.27885 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27885 1 6.33333C1 3.38781 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38781 11.6667 6.33333Z"
        stroke="#222222"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Search;
