import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { MOBILE_MEDIA_QUERY } from 'src/components/common/constants';
import { useMedia } from 'src/hooks';
import { selectIsMobile } from 'src/selectors/inv-common';

import { MobileFilterResultsView } from './MobileFilterResultsView';
import { WebFilterResultsView } from './WebFilterResultsView';

export function ResponsiveFiltersAndResults({
  searchState,
  onSearchStateChange,
}) {
  const isMobileScreen = useSelector(selectIsMobile);

  const isMobile = useMedia(MOBILE_MEDIA_QUERY) || isMobileScreen;

  return (
    <div className="artist-columns-container">
      {isMobile
        ? (
          <MobileFilterResultsView
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
          />
        )
        : (
          <WebFilterResultsView
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
          />
        )}
    </div>
  );
}

ResponsiveFiltersAndResults.propTypes = {
  searchState: PropTypes.objectOf(PropTypes.any).isRequired,
  onSearchStateChange: PropTypes.func.isRequired,
};
