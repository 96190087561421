import React, { Element } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import { ARTIST_DETAIL_INFO_ELEMENT_ID } from 'src/components/artist-detail/constants';
import { getFormattedProfessionsAndEra } from 'src/components/artist-detail/helpers';
import { SocialShare } from 'src/components/artist-detail/social-share/SocialShare';
import { FollowUnfollowArtistButton } from 'src/components/common/follow-unfollow-artist-button';
import { selectArtistBioInfo } from 'src/selectors/artist-detail';
import { useSelectedArtistDetailTab } from 'src/hooks';
import { getArtistHeading, getArtistSeoFriendlyPath } from 'src/utils/artist-detail';

function ArtistOverview({ followArtistBtnRef }) {
  const { artistRef, bio, birthAndDeathDates, professions, displayName, firstName, lastName } = useSelector(selectArtistBioInfo);
  const selectedTab = useSelectedArtistDetailTab();

  const artistHeading = getArtistHeading(selectedTab, displayName);
  const professionsAndEra = getFormattedProfessionsAndEra(professions, birthAndDeathDates);
  const parsedBiography = ReactHtmlParser(bio);

  const artistDetailAndBioText = bio ? 'Read Full Artist Biography' : 'See Artist Details';

  const artistDetailUrl = getArtistSeoFriendlyPath({ firstName, lastName, artistRef });
  const windowOptions = 'noopener,noreferrer';

  return (
    <div className="artist-overview">
      <div className="artist-overview-head">
        <div className="name-professions">
          <h1>{artistHeading}</h1>
        </div>
        <div
          className="follow-and-social-btns"
          ref={followArtistBtnRef}
        >
          <FollowUnfollowArtistButton artistRef={artistRef} />
          <SocialShare />
        </div>
      </div>
      <h4 className="profession-names">{professionsAndEra}</h4>
      {
        bio && (
          <p className="text-ellipsis">{parsedBiography}</p>
        )
      }
      <a
        href={`#${ARTIST_DETAIL_INFO_ELEMENT_ID}`}
        {...(selectedTab
          && { onClick: () => {
            window.open(`${artistDetailUrl}#${ARTIST_DETAIL_INFO_ELEMENT_ID}`, '_blank', windowOptions);
          }
          })}
      >
        {artistDetailAndBioText}
      </a>
    </div>
  );
}

ArtistOverview.propTypes = {
  followArtistBtnRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

export default ArtistOverview;
