export const URL_UPDATE_DELAY_MS = 700;

export const SORT_BY_FILTER_OPTIONS = {
  PAST: [
    { value: 'archive_prod', label: 'Auction Date: Soonest' },
    { value: 'archive_dateTime_asc_prod', label: 'Auction Date: Furthest' },
    { value: 'archive_priceResult_desc_prod', label: 'Price: High to Low' },
    { value: 'archive_priceResult_asc_prod', label: 'Price: Low to High' },
  ],
  UPCOMING_STAGE: [
    { value: 'upcoming_lots_dateTimeUTCUnix_asc_stage', label: 'Ending Soonest' },
    { value: 'upcoming_lots_postDateTime_desc_stage', label: 'Newly Listed Items' },
    { value: 'upcoming_lots_currentBid_asc_stage', label: 'Price: Low to High' },
    { value: 'upcoming_lots_currentBid_desc_stage', label: 'Price: High to Low' },
    { value: 'upcoming_lots_bidCount_asc_stage', label: 'Number of Bids: Low to High' },
    { value: 'upcoming_lots_bidCount_desc_stage', label: 'Number of Bids: High to Low' },
  ],
  UPCOMING_PROD: [
    { value: 'upcoming_lots_dateTimeUTCUnix_asc_prod', label: 'Ending Soonest' },
    { value: 'upcoming_lots_postDateTime_desc_prod', label: 'Newly Listed Items' },
    { value: 'upcoming_lots_currentBid_asc_prod', label: 'Price: Low to High' },
    { value: 'upcoming_lots_currentBid_desc_prod', label: 'Price: High to Low' },
    { value: 'upcoming_lots_bidCount_asc_prod', label: 'Number of Bids: Low to High' },
    { value: 'upcoming_lots_bidCount_desc_prod', label: 'Number of Bids: High to Low' },
  ],
};
export const DEFAULT_SORT_BY_OPTION = {
  PAST: 'archive_prod',
  UPCOMING_STAGE: 'upcoming_lots_dateTimeUTCUnix_asc_stage',
  UPCOMING_PROD: 'upcoming_lots_dateTimeUTCUnix_asc_prod',
};

export const SORT_BY_FILTER_OPTION_VALUE_KEY = 'value';
export const SORT_BY_FILTER_OPTION_LABEL_KEY = 'label';

export const ARTIST_LOT_ATTRS_TO_RETRIEVE = [
  'bidCount',
  'currencyCode',
  'currencySymbol',
  'currentBid',
  'dateTimeLocal',
  'dateTimeUTCUnix',
  'endTimeUTCUnix',
  'estimateHigh',
  'estimateLow',
  'houseName',
  'isParity',
  'lotDescription',
  'lotNumber',
  'lotRef',
  'lotTitle',
  'photoPath',
  'priceResult',
  'saleType'
];
