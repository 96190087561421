import React from 'react';

function Clock() {
  return (
    <svg
      style={{ marginRight: '6px' }}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="14px"
      height="14px"
      viewBox="0 0 128.000000 128.000000"
      preserveAspectRatio="xMidYMid meet"
    >

      <g
        transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M501 1265 c-110 -24 -226 -89 -311 -175 -250 -250 -252 -649 -5 -895
   122 -121 256 -183 419 -192 292 -16 560 170 649 452 31 99 31 271 0 370 -101
   318 -428 510 -752 440z m245 -76 c98 -17 210 -78 288 -155 219 -220 219 -569
   -1 -789 -219 -219 -568 -218 -788 3 -270 269 -194 722 148 891 118 58 225 73
   353 50z"
        />
        <path d="M600 832 l0 -207 125 -125 125 -125 27 28 28 27 -113 113 -112 112 0
   193 0 192 -40 0 -40 0 0 -208z"
        />
      </g>
    </svg>
  );
}

export default Clock;
