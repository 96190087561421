/* eslint-disable no-undef */
import { routes } from 'config';
import axios from 'axios';
import { fullPageLoadingSpinnerToggle } from 'src/utils/common/helpers';
import { getOasHeaders } from 'src/actions/inv-common.actions';

const syncLoginStatus = async (authData) => {
  try {
  // sync session with node app
    await axios.post(routes.api.BOULDER_LOGIN, authData);
    // update terms and conditions
    const res = await axios.post(routes.api.UPDATE_TERMS, authData);
    // Calculate expiration date in the future
    const exdate = new Date();
    const exdays = 10000;
    exdate.setDate(exdate.getDate() + exdays);
    const exdateString = exdate.toUTCString();

    document.cookie = `privacyVersion=${res.data.data.memberCurrentUserTermAgreement};
   expires="${exdateString}"; path=/;'`;

    window.heap.addEventProperties({ authState: 'authenticated' });

    fullPageLoadingSpinnerToggle(false);
    window.location.reload();
  } catch (error) {
    console.log('syncloginstatus error', error);
  }
};

export const googleClientLoginCall = async (token) => {
  fullPageLoadingSpinnerToggle(true);

  const googleAuth = {
    companyID: 1,
    authIdToken: token,
    deviceType: 'web',
    accountCreationSource: 'Google Sign In',
  };

  try {
    const res = await axios.post(routes.api.GOOGLE_CLIENT_LOGIN, googleAuth);
    const oasHeaders = getOasHeaders(res);
    const isSignUp = res.data.data.accountCreated;

    const authData = {
      authorization: res.headers.authorization,
      userName: res.data.userName,
      firstName: res.data.data.firstName,
      lastName: res.data.data.lastName,
      email: res.data.userName,
      keepLoggedIn: res.data.keepLoggedIn,
      memberID: res.data.data.memberID,
      memberRef: res.data.data.memberRef,
      userID: res.data.data.memberID,
      suggestUpdatePassword: res.data.data.suggestUpdatePassword,
      accountCreated: res.data.accountCreated,
      'x-auth-token': res.headers['x-auth-token'],
      ...oasHeaders
    };

    if (isSignUp) {
      sessionStorage.setItem('isFirstTimeLogin', true);
      try {
        const signUpDiv = document.getElementById('signUpModal');
        if (signUpDiv) {
          window.heap.track('SIGNUP-GOOGLE-SSO');
        } else {
          window.heap.track('SIGNUP-GOOGLE-ONETAP');
        }
        window.heap.track('Sitewide - Account Create - Success Event');
      } catch (error) {
        console.log('google sign up heap track error', error);
      }
    } else {
      try {
        const loginModalDiv = document.getElementById('signInModal');
        if (loginModalDiv) {
          window.heap.track('LOGIN-GOOGLE-SSO');
        } else {
          window.heap.track('LOGIN-GOOGLE-ONETAP');
        }
        window.heap.track('Sitewide - Login - Success Event');
      } catch (error) {
        console.log('google sign in heap track error', error);
      }
      sessionStorage.removeItem('isFirstTimeLogin');
    }

    syncLoginStatus(authData);
  } catch (error) {
    console.error('google sign in error', error);
  }
};

export const handleGoogleCredentialResponse = (response) => {
  googleClientLoginCall(response.credential, window.location);
};

export const initGoogleSso = (isProd, isLoggedin) => {
  try {
    window.onload = function () {
      // TODO: Maybe move these to a static Ids file
      const prodClientId = '359458960265-uankr96dhtnbqn9putsim3sdu7u81jfd.apps.googleusercontent.com';
      const stageClientId = '766696693350-jmfff4ibm6r0ruiehvd4c0kk0vfn0h46.apps.googleusercontent.com';

      const cliendId = isProd ? prodClientId : stageClientId;

      google.accounts.id.initialize({
        client_id: cliendId,
        callback: handleGoogleCredentialResponse,
        ux_mode: 'popup',
        auto_prompt: false,
        prompt_parent_id: 'oneTapParentContainer',
        use_fedcm_for_prompt: true,
      });

      // Display onetap if not loggedin
      if (!isLoggedin) { google.accounts.id.prompt(); }
    };
  } catch (error) {
    console.error('There was an error intializing Google Sign In!', error);
  }
};
