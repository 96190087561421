// (dyouberg) Needed for async/await
import 'babel-polyfill';

import React from 'react';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { render, hydrate } from 'react-dom';
import bugsnagReact from '@bugsnag/plugin-react';
import { Footer } from 'inv-common-components';
import loadable, { loadableReady } from '@loadable/component';
import ModalManager from './components/header/modals/ModalManager';
import './loadjquery';

import bugsnagClient from './bugsnagClient';

import reducers from './reducers';
import { routes } from '../config';
import { NotificationsPermissionModal } from './components/NotificationsPermissionModal';
import SSRApp from './SSRApp';
import Header from './containers/Header/Header';

const Artmyn = loadable(() => import(
  /* webpackChunkName: "Artmyn" */
  './components/shell-pages/Artmyn'
));
const DiscoverArtists = loadable(() => import(
  /* webpackChunkName: "DiscoverArtists" */
  './components/shell-pages/DiscoverArtists'
));
const DiscoverCampaigns = loadable(() => import(
  /* webpackChunkName: "DiscoverCampaigns" */
  './components/shell-pages/DiscoverCampaigns'
));
const DownloadApp = loadable(() => import(
  /* webpackChunkName: "DownloadApp" */
  './components/shell-pages/DownloadApp'
));
const FeaturedTimedSales = loadable(() => import(
  /* webpackChunkName: "FeaturedTimedSales" */
  './components/shell-pages/FeaturedTimedSales'
));
const Landing = loadable(() => import(
  /* webpackChunkName: "Landing" */
  './components/shell-pages/Landing'
));
const Lapada = loadable(() => import(
  /* webpackChunkName: "Lapada" */
  './components/shell-pages/Lapada'
));
const Parity = loadable(() => import(
  /* webpackChunkName: "Parity" */
  './components/shell-pages/Parity'
));
const Press = loadable(() => import(
  /* webpackChunkName: "Press" */
  './components/shell-pages/Press'
));

const Search = loadable(
  () => import(
  /* webpackChunkName: "Search" */
    './containers/new-search/Search'
  ),
  { ssr: false, }
);

// (bnikom) sending up server store client to not make extra API calls
const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const __INITIAL_STATE__ = window.__APP_INITIAL_STATE__;
delete window.__INITIAL_STATE__;

const isShowPPRView = window.__isShowPPRView__;
delete window.__isShowPPRView__;

// Configure Middleware
// (bnikom) no preloaded State for Search, Landing, Artmyn, Parity page
const store = (preloadedState)
  ? createStore(reducers, preloadedState, composeWithDevTools(applyMiddleware(thunk)))
  : createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

export default store;

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

const context = {};

const isMarkupPresent = document.getElementById('mainContentContainer').hasChildNodes();

loadableReady(() => {
  // SSR pages
  if (isMarkupPresent) {
    hydrate(<ErrorBoundary>
      <Provider store={store}>
        <Router context={context}>
          <SSRApp initialState={{ isShowPPRView, ...__INITIAL_STATE__ }} />
        </Router>
        <ModalManager />
        <NotificationsPermissionModal />
        {window.location.pathname !== routes.REQUEST_FOR_APPROVAL && <Footer />}
      </Provider>
    </ErrorBoundary>, document.getElementById('mainContentContainer'));
  } else {
    // These are the client side rendered pages
    render(<ErrorBoundary>
      <Provider store={store}>
        <Router context={context}>
          <Switch>
            <Route
              path={routes.DOWNLOAD_APP}
              component={DownloadApp}
              exact
            />
            <Route
              path={routes.DISCOVER_ARTISTS}
              component={DiscoverArtists}
            />
            <Route
              path={routes.DISCOVER_CAMPAIGNS}
              component={DiscoverCampaigns}
            />
            <Route
              path={routes.ARTMYN}
              component={Artmyn}
            />
            <Route
              path={routes.FEATUREDTIMEDSALES}
              component={FeaturedTimedSales}
            />
            <Route
              path={routes.LANDING}
              component={Landing}
            />
            <Route
              path={routes.LAPADA}
              component={Lapada}
            />
            <Route
              path={routes.PRESS}
              component={Press}
            />
            <Route
              path={routes.PARITY}
              component={Parity}
            />
            <Route
              path={routes.SEARCH}
              component={props => (
                <>
                  <Header />
                  <Search
                    {...props}
                    {...__INITIAL_STATE__}
                  />
                </>
              )}
            />
          </Switch>
        </Router>
        <ModalManager />
        <NotificationsPermissionModal />
        <Footer />
      </Provider>
    </ErrorBoundary>, document.getElementById('mainContentContainer'));
  }
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // TODO We have used fully qualified URL for service worker here
    // This is because right now static files are resolved in INV-Node
    // Once Inv-Node is deprecated we should use relative path
    // so that we do not have to specify scope of service worker manually
    navigator.serviceWorker.register('/search/static/dist/service-worker.js', { scope: '/' })
      .catch(registrationError => {
        console.error('SW registration failed: ', registrationError);
      });
  });
}
