import {
  FETCH_AUCTIONS_ERROR,
  FETCH_AUCTIONS_LOADING,
  FETCH_AUCTIONS_SUCCESS,
} from '../actions/constants';

const initialState = {
  auctions: [],
  isLoading: false,
  type: null,
};

function auctions(state = initialState, action) {
  switch (action.type) {
    case FETCH_AUCTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        memberId: action.payload.memberId,
        type: action.type,
        auctions: action.payload.auctions,
        facets: action.payload.facets,
        pagination: action.payload.pagination,
        sort: action.payload.sort,
        totalElements: action.payload.totalElements,
      };
    }

    case FETCH_AUCTIONS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
        type: action.type,
      };

    case FETCH_AUCTIONS_LOADING:
      return {
        ...state,
        isLoading: true,
        type: action.type,
      };

    default:
      return state;
  }
}

export default auctions;
