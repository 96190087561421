import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';
import { createArtistDetailURLForPastUpcomingPages } from '../helpers';

import { IS_UPCOMING_TOOLTIP_CLOSED_KEY } from './constants';

/**
 * Sets upcoming tooltip closed state in local storage
 * @param isClosed - Boolean if tooltip is closed
 */
export const setIsUpcomingTooltipClosed = (isClosed) => {
  localStorage.setItem(IS_UPCOMING_TOOLTIP_CLOSED_KEY, isClosed);
};

/**
 * Gets upcoming tooltip closed state from local storage
 * @returns {boolean} state if tooltip closed
 */
export const getIsUpcomingTooltipClosed = () => Boolean(JSON.parse(localStorage.getItem(IS_UPCOMING_TOOLTIP_CLOSED_KEY)));

/**
 * Gets the metadata for artist detail tabs
 * @param artist - artist info
 * @param hostName - name of the current host
 * @returns Object -  Meta data of the artist tabs
 */
export const getArtistDetailLotsTabsMeta = (artist, hostName) => ([
  {
    id: ARTIST_LOTS_TABS.UPCOMING,
    title: 'Upcoming Lots',
    href: createArtistDetailURLForPastUpcomingPages(artist, hostName, true),
  },
  {
    id: ARTIST_LOTS_TABS.PAST,
    title: 'Past Lots',
    href: createArtistDetailURLForPastUpcomingPages(artist, hostName, false),
  },
]);
