/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
export const errorMessages = {
  loginIncorrect: 'Your email address or password is incorrect.',
  emailRequired: 'Email address is required.',
  pleaseEnterFirstName: 'Please enter a first name.',
  pleaseEnterLastName: 'Please enter a last name.',
  emailInvalid: 'Your email format is invalid.',
  pleaseAcceptTerms: 'Please accept the terms and conditions in order to continue.',
  validEmailAccount: 'Your email address is already in use by another account.',
  passwordsDoNotMatch: 'Passwords do not match.',
  passwordCharacterValidate: 'Password must be between 10-50 characters.',
};

/**
* Checks if input from create password and confirm password fields match
* @param {string} pw - The 'create password' field value
* @param {string} pwConfirm - The 'confirm password' field value
*/

const doPasswordsMatch = (pw, pwConfirm) => pw === pwConfirm;

export const getCreatedPasswordErrors = (createdPassword, confirmedPassword) => {
  const errors = [];

  if (!doPasswordsMatch(createdPassword, confirmedPassword)) {
    errors.push('passwords-dont-match');
  }

  return errors;
};

export const getloginErrors = (emailValue, passwordValue) => {
  const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailIsValid = emailValue !== '' ? validEmailRegex.test(emailValue) : true;
  const passwordIsValid = passwordValue.length > 0;
  const errors = [];

  if (!emailIsValid) {
    errors.push({
      fieldName: 'invalid-email',
    });
  }

  if (!passwordIsValid) {
    errors.push({
      fieldName: 'invalid-password',
    });
  }

  return errors;
};

export const getEmailErrors = (emailValue) => {
  const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emailIsValid = emailValue !== '' ? validEmailRegex.test(emailValue) : true;
  return emailIsValid;
};
