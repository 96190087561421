import {
  FETCH_UPCOMING_AUCTIONS_ERROR,
  FETCH_UPCOMING_AUCTIONS_LOADING,
  FETCH_UPCOMING_AUCTIONS_SUCCESS,
  FETCH_MY_AUCTIONS_SUCCESS,
  FETCH_MY_AUCTIONS_ERROR,
} from '../actions/constants';

const initialState = {
  isLoadingUpcomingAuctions: false,
  upcomingAuctions: null,
  type: null,
};

function homepage(state = initialState, action) {
  switch (action.type) {
    case FETCH_UPCOMING_AUCTIONS_SUCCESS: {
      return {
        ...state,
        isLoadingUpcomingAuctions: false,
        upcomingAuctions: action.payload.content,
        memberID: action.memberID,
        isProd: action.isProd,
        type: action.type,
      };
    }

    case FETCH_UPCOMING_AUCTIONS_ERROR:
      return {
        ...state,
        upcomingAuctions: null,
        type: action.type,
        errorMessage: action.payload,
        isLoadingUpcomingAuctions: false,
      };

    case FETCH_UPCOMING_AUCTIONS_LOADING:
      return {
        ...state,
        type: action.type,
        isLoadingUpcomingAuctions: true,
        upcomingAuctions: null,
      };

    case FETCH_MY_AUCTIONS_SUCCESS: {
      return {
        ...state,
        myAuctions: action.payload,
        type: action.type,
      };
    }

    case FETCH_MY_AUCTIONS_ERROR:
      return {
        ...state,
        type: action.type,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
}

export default homepage;
