import { useEffect, useState } from 'react';
import { get } from 'lodash';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { UPCOMING_LOTS_SUGGESTIONS_INDEX } from '../config';

const auctionsCache = {};

export default function useFetchAuctions(hits) {
  const [auctions, setAuctions] = useState([]);
  const [isFetchingAuctions, setIsFetchingAuctions] = useState();
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  const xAuthToken = useSelector((state) => get(state, 'invReactCommon.sessionInfo.authToken', false));
  const upcomingLotsSuggestionsIndex = isProd ? UPCOMING_LOTS_SUGGESTIONS_INDEX.prod
    : UPCOMING_LOTS_SUGGESTIONS_INDEX.dev;
  let cancelToken;

  function getLotsRefs() {
    const refs = [];
    const algoliaCatalogData = {};
    const upcomingLotsHits = hits?.find(hit => hit.index === upcomingLotsSuggestionsIndex);

    if (upcomingLotsHits) {
      upcomingLotsHits.hits.forEach((hit) => {
        if (!refs.includes(hit.catalogRef)) {
          refs.push(hit.catalogRef);
          algoliaCatalogData[hit.catalogRef] = {
            dateTimeUTCUnix: hit.dateTimeUTCUnix,
            dateTimeLocal: hit.dateTimeLocal,
          };
        }
      });
    }
    return [refs.slice(0, 3).join(','), algoliaCatalogData];
  }

  useEffect(() => {
    async function fetchAuctions(refs, algoliaCatalogData) {
      if (!refs) {
        setAuctions([]);
        return;
      }
      if (cancelToken) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      setIsFetchingAuctions(true);
      cancelToken = axios.CancelToken.source();
      axios({
        method: 'GET',
        url: `/api/auctions/catalog/catalogdata?catalogRefs=${refs}`,
        headers: { 'x-auth-token': xAuthToken, cancelToken: cancelToken.token },
        cancelToken: cancelToken.token,
      })
        .then((response) => {
          const catalogData = [];
          response.data.forEach((catalog) => {
            if (catalog.upcoming) {
              catalogData.push({
                ...algoliaCatalogData[catalog.catalogRef],
                ...catalog,
              });
            }
          });
          auctionsCache[refs] = catalogData;
          setAuctions(auctionsCache[refs]);
          setIsFetchingAuctions(false);
        }).catch(() => {
          setAuctions([]);
          setIsFetchingAuctions(false);
        });
    }
    const [catalogRefs, catalogData] = getLotsRefs();
    if (!catalogRefs) {
      setAuctions([]);
    } else if (auctionsCache[catalogRefs]) {
      setAuctions(auctionsCache[catalogRefs]);
    } else {
      fetchAuctions(catalogRefs, catalogData);
    }
  }, [hits]);
  return [auctions, isFetchingAuctions];
}
