import React from 'react';
import { useSelector } from 'react-redux';

import { selectArtistBioInfo } from 'src/selectors/artist-detail';
import { FollowUnfollowArtistButton } from 'src/components/common/follow-unfollow-artist-button';

function NoUpcomingLotsBanner() {
  const { artistRef } = useSelector(selectArtistBioInfo);

  return (
    <div className="no-lots-banner">
      <div className="inner-container">
        <h3>There are no lots by this artist that are for sale at auction.</h3>
        <FollowUnfollowArtistButton artistRef={artistRef} />
        <p>We’ll notify you when works of theirs become available.</p>
      </div>
    </div>
  );
}

export default NoUpcomingLotsBanner;
