/**
 * Takes the auctionHouses array and divides into sub-arrays for column presentation.
 * @param {array} auctionHouses - An array of objects representing auction houses.
 * @param {number} houseCount - The total number of auction houses in the auctionHouses array.
 * @param {number} noOfColumns - The desired number of columns to split the auction houses into.
 * @returns {array} - returns a new array that contains noOfColumns sub-arrays.
 */
export const splitHousesIntoNoOfColumns = (auctionHouses, houseCount, noOfColumns) => {
  const sortedAuctionHouses = auctionHouses.sort((a, b) => a.houseName.localeCompare(b.houseName));
  const columnCount = Math.min(houseCount, noOfColumns);
  const baseChunkSize = Math.floor(houseCount / columnCount);
  const remainingHouses = houseCount % columnCount;

  return Array.from({ length: columnCount }, (_, index) => {
    const chunkSize = baseChunkSize + (index < remainingHouses ? 1 : 0);
    const startIndex = index * baseChunkSize + Math.min(index, remainingHouses);
    return sortedAuctionHouses.slice(startIndex, startIndex + chunkSize);
  });
};
