const { createLogger, transports, format } = require('winston');

const {
  combine,
  timestamp,
  errors,
  simple,
  json,
  colorize,
} = format;

const formatArgs = [
  timestamp({ format: 'YYYY-MM-DDTHH:mm:ss.SSS Z' }),
  errors({ stack: true }),
];

// Order matters, so "colorize" has to go before json/simple
if (process.env.NODE_ENV === 'dev') {
  formatArgs.push(colorize(), simple());
} else {
  formatArgs.push(json());
}

const logger = createLogger({
  format: combine(...formatArgs),
  level: process.env.LOG_LEVEL || process.env.NODE_ENV === 'dev' ? 'debug' : 'info',
  // For Docker deploys, we want everything logged to stdout, which also goes to /var/log/node.
  transports: [
    new transports.Console(),
  ],
  exceptionHandlers: [
    new transports.Console(),
  ],
  exitOnError: false,
});

module.exports = logger;
