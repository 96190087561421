import axios from 'axios';

import logger from 'routes/middleware/logging/logger';
import {
  FETCH_CATALOG_ERROR,
  FETCH_CATALOG_LOADING,
  FETCH_CATALOG_SUCCESS,
  FETCH_CURRENCY_ARR,
  UPDATE_TAB_VISIBILITY,
  SYNC_LOT_VISIBILTY,
  UPDATE_LOT_INFO_PROPERTIES,
} from './constants';

// Action creators

export const fetchCatalogLoading = () => ({
  type: FETCH_CATALOG_LOADING,
});

export const fetchCatalogSuccess = (results, memberID) => ({
  type: FETCH_CATALOG_SUCCESS,
  payload: results,
  memberID,
});

export const fetchCatalogError = error => ({
  type: FETCH_CATALOG_ERROR,
  payload: error,
});

export const updateLotInfoPropeties = ({ lotInfo }) => ({
  type: UPDATE_LOT_INFO_PROPERTIES,
  payload: lotInfo,
});

export const updateTabVisibilty = isVisible => ({
  type: UPDATE_TAB_VISIBILITY,
  payload: isVisible,
});

export const syncLotVisibility = (visibleLots) => ({
  type: SYNC_LOT_VISIBILTY,
  payload: visibleLots,
});

export const updateFeaturedLotVisibility = ({ visibleLots, isVisible }) => async (dispatch, getState) => {
  const currentVisibleLots = getState().catalog.visibleLots;
  let newVisibleLots = [...currentVisibleLots];

  visibleLots.forEach(lotRef => {
    if (isVisible) {
      newVisibleLots = [...newVisibleLots, lotRef];
    } else if (!isVisible) {
      const index = newVisibleLots.indexOf(lotRef);
      if (index !== -1) {
        newVisibleLots.splice(index, 1);
      }
    }
  });
  dispatch(syncLotVisibility(newVisibleLots));
};

function getOccurrence(array, value) {
  let count = 0;
  array.forEach((v) => (v === value && count++));
  return count;
}

export const updateLotVisibility = ({ lotRef, isVisible, isFeatured }) => async (dispatch, getState) => {
  const currentVisibleLots = getState().catalog.visibleLots;
  let newVisibleLots = [...currentVisibleLots];

  if (isVisible) {
    newVisibleLots = [...newVisibleLots, lotRef];
  } else if (!isVisible) {
    const index = newVisibleLots.indexOf(lotRef);
    if (index !== -1) {
      newVisibleLots.splice(index, 1);
    }
  }

  /* This is a bug fix for the case where a featured lot is added to the visible lots array
   * twice on pageload because of algolia rerendering issues INV-11128
  */
  if (isFeatured) {
    const count = getOccurrence(newVisibleLots, lotRef);
    if (count >= 2) {
      const index = newVisibleLots.indexOf(lotRef);
      if (index !== -1) {
        newVisibleLots.splice(index, 1);
      }
    }
  }

  dispatch(syncLotVisibility(newVisibleLots));
};

export const fetchCurrencyConversion = currencyConversion => ({
  type: FETCH_CURRENCY_ARR,
  payload: currencyConversion,
});

export const fetchCatalogInformation = ({ sessionInfo, reqHostname, cookie, catalogId }) => async (dispatch) => {
  dispatch(fetchCatalogLoading());
  const { memberId: memberID, isLoggedIn, oasHeaders } = sessionInfo;
  const xAuthToken = oasHeaders?.['x-auth-token'] || '';

  const headers = {
    'Content-Type': 'application/json',
    channel: 'invaluable',
    'x-auth-token': xAuthToken,
    'member-id': memberID,
  };
  let catalogRes;

  try {
    catalogRes = await axios.get(
      `https://${reqHostname}/api/catalog/${catalogId}`,
      { headers }
    );
  } catch (error) {
    logger.error(`Error fetching catalog from Invaluable API [catalogId=${catalogId}]`, error);
    dispatch(fetchCatalogError(error));
    return;
  }

  if (catalogRes.status !== 200) {
    const errmsg = 'Error fetching catalog from Invaluable API';
    logger.error(`${errmsg} [catalogId=${catalogId}]`, { catalogRes });
    dispatch(
      fetchCatalogError(
        new Error(`${errmsg} [statusCode=${catalogRes.status}]`)
      )
    );
    return;
  }

  dispatch(fetchCatalogSuccess({ catalogRes: catalogRes.data, cookie, memberID, xAuthToken, isLoggedIn }));
};
