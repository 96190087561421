import { useState, useEffect } from 'react';
import { format } from 'date-fns-tz';

const useTimeZoneLocal = (eventDateTime, isList, isActive) => {
  const [state, setState] = useState({ convertedDate: '', userTimeZone: '' });

  useEffect(() => {
    try {
      /* We use undefined in this as a param so that the browser can detect the correct location
    * and we do not define it incorrectly, this was causing locations like great britain, australia
    * etc to appear as GBT+1 and such
    */
      const timeArr = new Date().toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ');
      // check if the date is reading right to left for locales that do not use a language right to left
      // if the last item of this array is a string and its length is less than or equal to 5 I have found this to be true
      const lastItemTimeArr = timeArr[timeArr.length - 1];
      const lastItemTypeString = typeof lastItemTimeArr === 'string';
      const lastItemLength = lastItemTimeArr.length <= 5;
      const zoneAbbreviation = (lastItemTypeString && lastItemLength) ? lastItemTimeArr : timeArr[0];
      const date = new Date(eventDateTime);
      const today = new Date();
      const isPast = (date < today) && !isActive;

      let eventDate;
      // Abbreviate month if the timezone is in a "list" page
      if (isList) {
        eventDate = isPast ? format(date, 'MMM  d, yyyy h:mm a') : format(date, 'MMM  d, h:mm a');
      } else {
        eventDate = isPast ? format(date, 'MMMM  d, yyyy h:mm a') : format(date, 'MMMM  d, h:mm a');
      }
      setState({ convertedDate: eventDate, userTimeZone: zoneAbbreviation });
    } catch (error) {
      setState({ convertedDate: false, userTimeZone: false });
    }
  }, [eventDateTime]);

  return state;
};

export default useTimeZoneLocal;
