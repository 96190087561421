import { useEffect, useState } from 'react';
import { getAlerts, getAlertsObj, getEmail } from 'src/components/header/modals/emailOnlyUtils';

const useEmailOnlyData = () => {
  const [email, setEmail] = useState('');
  const [alerts, setAlerts] = useState(getAlertsObj);

  useEffect(() => {
    const handleStorageChange = () => {
      setEmail(getEmail());
      setAlerts(getAlerts());
    };

    window.addEventListener('storage', handleStorageChange);

    handleStorageChange();

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return {
    email,
    alerts
  };
};

export default useEmailOnlyData;
