import { get } from 'lodash';
import {
  AH_FETCH_FOLLOW_COUNT_SUCCESS,
  AH_FETCH_UPCOMING_AUCTIONS_SUCCESS,
  AH_FOLLOW_UNFOLLOW_ERROR,
  AH_FOLLOW_UNFOLLOW_SUCCESS,
  FETCH_AH_IS_SELLER_FOLLOWED,
  FETCH_AUCTION_HOUSE_DETAIL_ERROR,
  FETCH_AUCTION_HOUSE_DETAIL_SUCCESS,
  FETCH_AUCTION_HOUSE_PAST_AUCTIONS_SUCCESS,
  FETCH_AUCTION_HOUSE_PAST_AUCTIONS_ERROR,
  FETCH_AUCTION_HOUSE_PAST_AUCTIONS_LOADING,
  FETCH_AUCTION_HOUSE_POPULAR_ITEMS_LOADING,
  FETCH_AUCTION_HOUSE_POPULAR_ITEMS_SUCCESS,
  FETCH_AUCTION_HOUSE_POPULAR_ITEMS_ERROR,
  FETCH_AUCTION_HOUSE_CONTACT_INFO_SUCCESS,
  SET_AUCTION_HOUSE_NOTABLE_LOTS,
  SET_AUCTION_HOUSE_NOTABLE_LOTS_LOADING,
  SET_AUCTION_HOUSE_ARTISTS,
  SET_AUCTION_HOUSE_ARTISTS_LOADING,
  SET_CF_IP_COUNTRY,
  SEND_SELLER_MESSAGE_ERROR,
  SEND_SELLER_MESSAGE_LOADING,
  SEND_SELLER_MESSAGE_SUCCESS,
  FETCH_VIP_STATUS_ERROR,
  FETCH_VIP_STATUS_SUCCESS,
  FETCH_SUBSCRIBED_USER_STATUS_SUCCESS,
  FETCH_AUCTION_HOUSE_CATEGORIES_SUCCESS,
  FETCH_AUCTION_HOUSE_CATEGORIES_LOADING,
  FETCH_AUCTION_HOUSE_CATEGORIES_ERROR,
  FETCH_AGGREGATED_RATING_SUCCESS,
  REFETCH_UPCOMING_LOTS_SUCCESS,
  LOAD_MORE_UPCOMING_LOTS_SUCCESS
} from '../actions/constants';

const initialState = {
  isLoadingAuctionHouse: false,
  auctionHouse: null,
  auctionHouseStatus: null,
  isSellerFollowed: null,
  followCount: 0,
  upcomingAuctions: null,
  auctionHouseContactInfo: null,
  sessionInfo: {
    oasHeaders: null,
    memberId: 0,
    authToken: null,
    isLoggedIn: false,
  },
  pastAuctions: {
    isLoading: false,
  },
  popularItems: {
    data: [],
    isLoading: false,
  },
  auctionHouseNotableLots: {
    data: [],
    isLoading: false,
  },
  auctionHouseArtists: {
    data: [],
    isLoading: false,
  },
  errors: {
    sessionCallFailed: false,
    followUnfollowCallFailed: false,
    pastAuctionsError: false,
    popularItemsError: false,
  },
  cfIpCountry: null,
  message: {
    isLoading: false,
    isError: false
  },
  categories: {
    data: null,
    isLoading: false,
    isError: false
  },
  aggregateRating: {
    ratingValue: 0,
    reviewCount: 0
  },
  isUserVip: false,
};

function auctionHouse(state = initialState, action) {
  switch (action.type) {
    case FETCH_AUCTION_HOUSE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: action.payload,
          isLoading: false,
          isError: false
        }
      };
    case FETCH_AUCTION_HOUSE_CATEGORIES_LOADING:
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: action.payload,
        }
      };
    case FETCH_AUCTION_HOUSE_CATEGORIES_ERROR:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: [],
          isError: true,
        }
      };
    case FETCH_VIP_STATUS_SUCCESS:
      return {
        ...state,
        isUserVip: action.payload,
      };
    case FETCH_SUBSCRIBED_USER_STATUS_SUCCESS:
      return {
        ...state,
        isSubscribedUser: action.payload,
      };
    case FETCH_VIP_STATUS_ERROR:
      return {
        ...state,
        isUserVip: false
      };
    case SEND_SELLER_MESSAGE_LOADING:
      return {
        ...state,
        message: {
          isLoading: true,
          isError: false
        }
      };
    case SEND_SELLER_MESSAGE_SUCCESS:
      return {
        ...state,
        message: {
          isLoading: false,
          isError: false
        }
      };
    case SEND_SELLER_MESSAGE_ERROR:
      return {
        ...state,
        message: {
          isLoading: false,
          isError: true
        }
      };
    case SET_AUCTION_HOUSE_ARTISTS:
      return {
        ...state,
        auctionHouseArtists: {
          data: action.payload,
          isLoading: false
        }
      };
    case SET_AUCTION_HOUSE_ARTISTS_LOADING:
      return {
        ...state,
        auctionHouseArtists: {
          ...state.auctionHouseArtists,
          isLoading: action.payload
        }
      };
    case SET_AUCTION_HOUSE_NOTABLE_LOTS:
      return {
        ...state,
        auctionHouseNotableLots: {
          data: action.payload,
          isLoading: false
        }
      };
    case SET_AUCTION_HOUSE_NOTABLE_LOTS_LOADING:
      return {
        ...state,
        auctionHouseNotableLots: {
          ...state.auctionHouseNotableLots,
          isLoading: action.payload
        }
      };
    case FETCH_AUCTION_HOUSE_POPULAR_ITEMS_SUCCESS:
      return {
        ...state,
        popularItems: {
          data: action.payload,
          isLoading: false,
        },
        errors: {
          ...state.errors,
          popularItensError: false
        }
      };

    case FETCH_AUCTION_HOUSE_POPULAR_ITEMS_LOADING:
      return {
        ...state,
        popularItems: {
          data: state.popularItems,
          isLoading: true,
        },
        errors: {
          ...state.errors,
          popularItemsError: false
        }
      };

    case FETCH_AUCTION_HOUSE_POPULAR_ITEMS_ERROR:
      return {
        ...state,
        popularItems: {
          isLoading: false,
        },
        errors: {
          ...state.errors,
          popularItemsError: true
        }
      };

    case FETCH_AUCTION_HOUSE_PAST_AUCTIONS_SUCCESS:
      return {
        ...state,
        pastAuctions: {
          ...action.payload,
          isLoading: false,
        },
        errors: {
          ...state.errors,
          pastAuctionsError: false
        }
      };

    case FETCH_AUCTION_HOUSE_PAST_AUCTIONS_LOADING:
      return {
        ...state,
        pastAuctions: {
          ...state.pastAuctions,
          isLoading: true,
        },
        errors: {
          ...state.errors,
          pastAuctionsError: false
        }
      };

    case FETCH_AUCTION_HOUSE_PAST_AUCTIONS_ERROR:
      return {
        ...state,
        pastAuctions: {
          isLoading: false,
        },
        errors: {
          ...state.errors,
          pastAuctionsError: true
        }
      };

    case FETCH_AUCTION_HOUSE_DETAIL_SUCCESS: {
      return {
        ...state,
        auctionHouse: action.payload,
        isLoadingAuctionHouse: false,
        auctionHouseStatus: get(action, 'payload.response.status', 200)
      };
    }

    case FETCH_AUCTION_HOUSE_DETAIL_ERROR:
      return {
        ...state,
        auctionHouse: null,
        isLoadingAuctionHouse: false,
        auctionHouseStatus: get(action, 'payload.response.status', 500)
      };

    case AH_FETCH_UPCOMING_AUCTIONS_SUCCESS:
      return {
        ...state,
        upcomingAuctions: action.payload
      };

    case REFETCH_UPCOMING_LOTS_SUCCESS:
      return {
        ...state,
        upcomingAuctions: {
          ...state.upcomingAuctions,
          content: state.upcomingAuctions.content.map((auction) => {
            const isAuction = action.payload.find((item) => item.catalogId === auction.catalogId);
            return {
              ...auction,
              isViewOnly: isAuction.isViewOnly || auction.isViewOnly,
              bidderCatalogStatus: isAuction.bidderCatalogStatus || auction.bidderCatalogStatus
            };
          })
        }
      };

    case LOAD_MORE_UPCOMING_LOTS_SUCCESS:
      return {
        ...state,
        upcomingAuctions: {
          ...action.payload,
          content: [...state.upcomingAuctions.content, ...action.payload.content]
        }
      };

    case FETCH_AH_IS_SELLER_FOLLOWED:
      return {
        ...state,
        isSellerFollowed: action.payload
      };

    case AH_FOLLOW_UNFOLLOW_SUCCESS:
      return {
        ...state,
        isSellerFollowed: action.payload,
        followCount: action.payload ? state.followCount + 1 : state.followCount - 1
      };

    case AH_FOLLOW_UNFOLLOW_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          followUnfollowCallFailed: true
        }
      };

    case AH_FETCH_FOLLOW_COUNT_SUCCESS:
      return {
        ...state,
        followCount: action.payload
      };

    case FETCH_AUCTION_HOUSE_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        auctionHouseContactInfo: action.payload
      };

    case SET_CF_IP_COUNTRY:
      return {
        ...state,
        cfIpCountry: action.payload
      };

    case FETCH_AGGREGATED_RATING_SUCCESS:
      return {
        ...state,
        aggregateRating: action.payload
      };

    default:
      return state;
  }
}

export default auctionHouse;
