import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { setArtistPriceRangeFilter } from 'src/actions';
import MenuSelect from 'src/components/common/algolia/menu-select';
import RangeInput from 'src/components/common/algolia/range-input';
import { selectArtistPriceRangeFilter } from 'src/selectors/artist-detail';
import { useSelectedArtistDetailTab } from 'src/hooks';
import { PRICE_RANGE_ATTR } from 'src/utils/artist-detail/constants';

import { FILTERS_META } from './constants';

export const CurrencyAndPriceRangeFilter = memo(() => {
  const dispatch = useDispatch();
  const priceRange = useSelector(selectArtistPriceRangeFilter);
  const selectedTab = useSelectedArtistDetailTab();

  const { PRICE_RANGE: PRICE_RANGE_META } = FILTERS_META;

  const setRangeInput = (value) => {
    if (!isEmpty(value)) dispatch(setArtistPriceRangeFilter(value));
  };

  return (
    <div className="algolia">
      <MenuSelect
        attribute={PRICE_RANGE_META.ATTR}
        title={PRICE_RANGE_META.TITLE}
        label={PRICE_RANGE_META.LABEL}
      />
      <RangeInput
        attribute={PRICE_RANGE_ATTR[selectedTab]}
        rangeInput={priceRange}
        setRangeInput={setRangeInput}
      />
    </div>
  );
});
