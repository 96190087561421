// This is a static list of currencies/countries that we use to convert to a local currency in list

const countryCurrencies = {
  // Australia
  AU: 'AUD',
  // Brazil
  BR: 'BRL',
  // Canada
  CA: 'CAD',
  // Switzerland
  CH: 'CHF',
  // China
  CN: 'CNY',
  // Columbia
  CO: 'COP',
  // Czech Republic
  CZ: 'CZK',
  // Denmark
  DK: 'DKK',
  // Begin Euro countries
  // Austria
  AT: 'EUR',
  // Belgium
  BE: 'EUR',
  // Cypress
  CY: 'EUR',
  // Estonia
  EE: 'EUR',
  // Finland
  FI: 'EUR',
  // France
  FR: 'EUR',
  // Germnay
  DE: 'EUR',
  // Greece
  GR: 'EUR',
  // Ireland
  IE: 'EUR',
  // Italy
  IT: 'EUR',
  // Latvia
  LV: 'EUR',
  // Lithuania
  LT: 'EUR',
  // Luxembourg
  LU: 'EUR',
  // Malta
  MT: 'EUR',
  // Netherlands
  NL: 'EUR',
  // Portugal
  PT: 'EUR',
  // Slovakia
  SK: 'EUR',
  // Slovania
  SI: 'EUR',
  // Spain
  ES: 'EUR',
  //  End Euro countries
  // Great Britain
  GB: 'GBP',
  // Hong Kong
  HK: 'HKD',
  // Hungary
  HU: 'HUF',
  // Indonedia
  ID: 'IDR',
  // India
  IN: 'INR',
  // Japan
  JP: 'JPY',
  // Kenya
  KE: 'KES',
  // South Korea
  KR: 'KRW',
  // Marocco
  MA: 'MAD',
  // Malaysia
  MY: 'MYR',
  // Nigeria
  NG: 'NGN',
  // Norway
  NO: 'NOK',
  // New Zealand
  NZ: 'NZD',
  // Phillipines
  PH: 'PHP',
  // Poland
  PL: 'PLN',
  // Russia
  RU: 'RUB',
  // Sweden
  SE: 'SEK',
  // Singapore
  SG: 'SGD',
  // Thailand
  TH: 'THB',
  // Turkey
  TR: 'TRY',
  // Taiwan
  TW: 'TWD',
  // United States
  US: 'USD',
  // South Africa
  ZA: 'ZAR',
};

export default countryCurrencies;
