import React, { Fragment, useEffect, useState } from 'react';
import Clock from './icons/Clock';

function RecentlyViewed() {
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);

  const checkCampaignExists = () => {
    const campaignContainerId = document.getElementById('recently-viewed-items');
    if (campaignContainerId) {
      setIsCampaignLoaded(true);
    } else {
      setTimeout(() => {
        checkCampaignExists();
      }, 100);
    }
  };

  useEffect(() => {
    checkCampaignExists();
  }, []);

  return (
    <Fragment>
      <h4>
        <Clock /> Recently Viewed
      </h4>
      <div
        id="egageRecentlyViewed"
        className="recently-viewed"
      />
      {
      !isCampaignLoaded && <p>No recently viewed items</p>
    }
    </Fragment>
  );
}

export default RecentlyViewed;
