import { addDays, subDays, subMonths, addMonths } from 'date-fns';

import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';

export const FILTERS_META = {
  LOTS_WITH_IMG: {
    ATTR: 'hasImage',
    LABEL: 'Lots with images only',
    VALUE_WHEN_SELECTED: true,
  },
  PRICE_RANGE: {
    ATTR: 'currencyCode',
    TITLE: 'Price Range',
    LABEL: 'All currencies',
  },
  SELLER_META: {
    TITLE: 'Seller',
    ATTR: 'houseName',
    PLACE_HOLDER: 'Search seller name',
  },
  SELLER_LOCATION_META: {
    TITLE: 'Seller Location',
    ATTR: 'countryName',
  },
  AUCTION_DATE: {
    ATTR: 'dateTimeUTCUnix',
    TITLE: 'Auction Date',
  },
};

export const DEFAULT_DATE_FILTER_OPTION = 'All dates';
export const FILTER_REFINEMENT_LIST_LIMIT = 50;

const PAST_DATE_FILTER_OPTIONS = [
  { label: 'All dates', start: undefined, end: undefined },
  { label: 'Past 30 days', start: subDays(new Date(), 30), end: new Date() },
  { label: 'Past 3 months', start: subMonths(new Date(), 3), end: new Date() },
  { label: 'Past 6 months', start: subMonths(new Date(), 6), end: new Date() },
  { label: 'Custom', start: undefined, end: undefined },
];

const UPCOMING_DATE_FILTER_OPTIONS = [
  { label: 'All dates', start: undefined, end: undefined },
  { label: 'Next 7 days', start: new Date(), end: addDays(new Date(), 7) },
  { label: 'Next 30 days', start: new Date(), end: addDays(new Date(), 30) },
  { label: 'Next 3 months', start: new Date(), end: addMonths(new Date(), 3) },
  { label: 'Custom', start: undefined, end: undefined },
];

export const AUCTION_DATE_FILTER_OPTIONS = {
  [ARTIST_LOTS_TABS.UPCOMING]: UPCOMING_DATE_FILTER_OPTIONS,
  [ARTIST_LOTS_TABS.PAST]: PAST_DATE_FILTER_OPTIONS,
};

export const PAGE_SIZE_OPTIONS = [
  { value: 24, label: '24' },
  { value: 48, label: '48' },
  { value: 72, label: '72' },
  { value: 96, label: '96' },
  { value: 144, label: '144' },
  { value: 192, label: '192' },
];
