import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { deleteNotification, markNotifRead } from 'src/actions/inv-common.actions';

function NotificationsDropDown({ notifData, headers }) {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className={`col-2 notif-col ${dropdownOpen ? 'show' : ''}`}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
        <DropdownToggle
          caret
          className="fa fa-ellipsis-h"
          tag="div"
        />
        <DropdownMenu
          className="notif-gray dropleft main-dropdown"
        >
          <DropdownItem
            tag="a"
            className="mark-notif-read"
            onClick={() => dispatch(markNotifRead({ notificationID: notifData.notificationID, headers }))}
          >
            Mark as read
          </DropdownItem>
          <DropdownItem
            tag="a"
            className="delete-notif"
            onClick={() => dispatch(deleteNotification({ notificationID: notifData.notificationID, headers }))}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default NotificationsDropDown;
