import React, { Element } from 'react';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FollowUnfollowArtistButton } from 'src/components/common/follow-unfollow-artist-button';
import { MOBILE_MEDIA_QUERY } from 'src/components/common/constants';
import { useIsElementOnScreen, useMedia } from 'src/hooks';
import { selectArtistBioInfo } from 'src/selectors/artist-detail';
import { selectIsFollowedArtist } from 'src/selectors/inv-common';

function StickyFooter({ followArtistBtnRef }) {
  const isMobileView = useMedia(MOBILE_MEDIA_QUERY);
  const { isElementOnScreen } = useIsElementOnScreen({
    elementToObserveRef: followArtistBtnRef,
  });

  const { artistRef, displayName } = useSelector(selectArtistBioInfo);
  const isArtistFollowed = useSelector(state => selectIsFollowedArtist(state, artistRef));

  const isShowStickyFooter = !(isArtistFollowed || isElementOnScreen);

  if (!isShowStickyFooter) return null;

  return (
    <div className="artist-sticky-footer">
      <Container fluid>
        <div className="artist-sticky-footer-inner">
          <div className="content">
            { !isMobileView && <strong>Want to be notified when new upcoming works by {displayName} are available?</strong> }
            <p>
              {
                isMobileView
                  ? `Get notified when new works by ${displayName} become available!`
                  : 'Follow now to be notified when new works of theirs are available at auction.'
              }
            </p>
          </div>
          <FollowUnfollowArtistButton artistRef={artistRef} />
        </div>
      </Container>
    </div>
  );
}

StickyFooter.propTypes = {
  followArtistBtnRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired
};

export default StickyFooter;
