import { useLayoutEffect, } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

function getDeviceType() {
  if (isAndroid) { return 'android'; }
  if (isIOS) { return 'ios'; }

  return 'other';
}

const URL_REDIRECTS = {
  android: 'https://play.google.com/store/apps/details?id=com.invaluable.invaluable&referrer=utm_source%3Dsms%26utm_medium%3Dreferral%26utm_campaign%3Dbidmodal',
  ios: 'https://itunes.apple.com/app/apple-store/id944415329?pt=303628&ct=bid_modal_sms&mt',
  other: '/',
};

function DownloadApp() {
  useLayoutEffect(() => {
    console.log(getDeviceType());
    window.location.href = URL_REDIRECTS[getDeviceType()];
  }, []);

  return null;
}

export default DownloadApp;
