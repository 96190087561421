import { useHistory } from 'react-router-dom';

import { ARTIST_LOTS_PATH } from 'src/utils/artist-detail/constants';

/**
 * Custom Hook to get the selected tab based on the current URL path.
 *
 * @returns {string} The selected tab obtained from the current URL path.
 */
function useSelectedArtistDetailTab() {
  const history = useHistory();
  const { pathname } = history.location;

  return Object.keys(ARTIST_LOTS_PATH).find(
    tab => pathname.includes(ARTIST_LOTS_PATH[tab])
  );
}

export default useSelectedArtistDetailTab;
