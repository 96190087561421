import React from 'react';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'config';
import Header from './containers/Header/Header';
import Reviews from './components/reviews/Reviews';
// These Artist routes are not codesplit with loadable because
// utilities in the way they are built are both client and server side and
// the loadable component does not work with them in their current state.
import ArtistDetail from './containers/artist-detail/ArtistDetail';
import ArtistPastLots from './containers/artist-past-lots/ArtistPastLots';
import ArtistUpcomingLots from './containers/artist-upcoming-lots/ArtistUpcomingLots';

import DownloadApp from './components/shell-pages/DownloadApp';

const ArtistDirectoryPage = loadable(() => import(
  /* webpackChunkName: "ArtistDirectoryPage" */
  './containers/artist-directory-page/ArtistDirectoryPage'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: true artist detail - Loading...</span>,
});

const Categories = loadable(() => import(
  /* webpackChunkName: "Categories" */
  './containers/categories/Categories'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: categories - Loading...</span>,
});

const Homepage = loadable(() => import(
  /* webpackChunkName: "HomePage" */
  './containers/homepage/Homepage'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: true homepage - Loading...</span>,
});

const CatalogContainer = loadable(() => import(
  /* webpackChunkName: "CatalogContainer" */
  './components/catalog/CatalogContainer'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: true catalog page - Loading...</span>,
});

const Rfa = loadable(() => import(
  /* webpackChunkName: "Rfa" */
  './containers/rfa/Rfa'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: true rfa page - Loading...</span>,
});

const AuctionHouse = loadable(() => import(
  /* webpackChunkName: "AuctionHouse" */
  './containers/auction-house/AuctionHouse'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: true auction house - Loading...</span>,
});

const PDP = loadable(() => import(
  /* webpackChunkName: "PDP" */
  './containers/pdp/Pdp'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: PDP - Loading...</span>,
});

const PastPDP = loadable(() => import(
  /* webpackChunkName: "PastPDP" */
  './containers/pdp/PastPdp'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: PastPDP - Loading...</span>,
});

const AuctionHouseDetails = loadable(() => import(
  /* webpackChunkName: "AuctionHouseDetails" */
  './containers/auction-house-details/AuctionHouseDetails'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: true auction house Details - Loading...</span>,
});

const Auctions = loadable(() => import(
  /* webpackChunkName: "Auctions" */
  './containers/auctions/Auctions'
), {
  ssr: true,
  fallback: <span className="loading-state">ssr: true auction house Details - Loading...</span>,
});

function SSRApp({ initialState }) {
  const __INITIAL_STATE__ = initialState;

  return (
    <Switch>
      <Route
        path={`${routes.ARTIST}/:id${routes.SOLDATAUCTION}`}
        component={props => (
          <>
            <Header />
            <ArtistPastLots
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={`${routes.ARTIST}/:id${routes.LOTSATAUCTION}`}
        component={props => (
          <>
            <Header />
            <ArtistUpcomingLots
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={routes.AUCTION_HOUSE}
        exact
        component={props => (
          <>
            <Header />
            <AuctionHouse
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={routes.AUCTION_HOUSE_DETAILS}
        exact
        component={props => (
          <>
            <Header />
            <AuctionHouseDetails
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={routes.REQUEST_FOR_APPROVAL}
        exact
        component={props => (
          <Rfa
            {...props}
            {...__INITIAL_STATE__}
          />
        )}
      />

      <Route
        path={routes.DOWNLOAD_APP}
        component={props => (
          <>
            <Header />
            <DownloadApp
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
        exact
      />
      <Route
        path={routes.ARTIST}
        component={props => (
          <>
            <Header />
            <ArtistDetail
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        exact
        path={routes.ARTIST_DIRECTORY}
        component={() => (
          <>
            <Header />
            <ArtistDirectoryPage
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={routes.UPCOMING_AUCTIONS}
        component={props => (
          <>
            <Header />
            <Auctions
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={routes.PDP_V1_PAGE}
        component={(props) => (
          <>
            <Header />
            {__INITIAL_STATE__.isShowPPRView ? <PastPDP {...props} /> : <PDP {...props} />}
          </>
        )}
      />
      <Route
        path={routes.PDP_V2_PAGE}
        component={(props) => (
          <>
            <Header />
            <PDP
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={routes.CATEGORIES}
        component={props => (
          <>
            <Header />
            <Categories {...props} />
          </>
        )}
      />
      <Route
        exact
        path={routes.HOMEPAGE}
        component={props => (
          <>
            <Header />
            <Homepage
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={routes.REVIEWS}
        component={props => (
          <>
            <Header />
            <Reviews
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />
      <Route
        path={routes.CATALOG}
        component={props => (
          <>
            <Header />
            <CatalogContainer
              {...props}
              {...__INITIAL_STATE__}
            />
          </>
        )}
      />

    </Switch>
  );
}

export default SSRApp;
