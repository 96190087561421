import { TABLET_MEDIA_QUERY, MOBILE_MEDIA_QUERY } from '../components/common/constants';
import useMedia from './useMedia';

/**
 * Returns the viewport configuration based on the current device's screen size.
 * @param config parameter should be an object that contains the desired configuration properties
 * for different viewport sizes, in the following format:
 * {
     DESKTOP: [desired value for desktop],
     TABLET: [desired value for tablet],
     MOBILE: [desired value for mobile]
   }
 * @returns viewport configuration based on the current device's screen size.
 */
const useGetViewPortConfig = (config) => {
  const isTablet = useMedia(TABLET_MEDIA_QUERY);
  const isMobile = useMedia(MOBILE_MEDIA_QUERY);

  if (isMobile) return config.MOBILE;

  if (isTablet) return config.TABLET;

  return config.DESKTOP;
};

export default useGetViewPortConfig;
