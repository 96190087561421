import React from 'react';
import classNames from 'classnames';
import { NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';

export function TabItem({ isSelected, tabMeta, onClickHandler }) {
  const { id: tabId, title, href } = tabMeta;
  const linkProps = {
    className: classNames({ active: isSelected }),
    ...(href ? { href, } : { onClick: () => onClickHandler(tabId) })
  };

  return (
    <NavItem id={tabId}>
      <NavLink
        {...linkProps}
      >
        <h2>{title}</h2>
      </NavLink>
    </NavItem>
  );
}

TabItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func,
  tabMeta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    href: PropTypes.string,
  }).isRequired,
};

TabItem.defaultProps = {
  onTabClick: undefined,
};
