import React from 'react';
import { string } from 'prop-types';
import { JSONLD, Generic, GenericCollection } from 'react-structured-data';

import { INVALUABLE_BASEURL_PROD } from 'src/components/common/constants';
import { ARTIST_LOTS_TABS, ARTIST_LOTS_PATH } from 'src/utils/artist-detail/constants';

function BreadcrumbListSchema({ artistName, pathName }) {
  const [artist, pageType] = pathName.split('/').splice(3);
  const pageTypeItemName = pageType && pageType === ARTIST_LOTS_PATH[ARTIST_LOTS_TABS.UPCOMING]
    ? 'For Sale at Auction'
    : 'Sold at Auction Prices';

  const artistDirectoryLink = '/artists/';
  const artistLink = `/artist/${artist}/`;
  const pageTypeLink = `/artist/${artist}/${pageType}/`;

  return (
    <JSONLD dangerouslyExposeHtml>
      <Generic
        type="breadcrumblist"
        jsonldtype="BreadcrumbList"
      >
        <GenericCollection type="itemListElement">
          <Generic
            schema={{
              type: 'listitem',
              position: 1,
              item: {
                '@id': `${INVALUABLE_BASEURL_PROD}`,
                name: 'Home',
              },
            }}
          />
          <Generic
            schema={{
              type: 'listitem',
              position: 2,
              item: {
                '@id': `${INVALUABLE_BASEURL_PROD}${artistDirectoryLink}`,
                name: 'Artists',
              },
            }}
          />
          <Generic
            schema={{
              type: 'listitem',
              position: 3,
              item: {
                '@id': `${INVALUABLE_BASEURL_PROD}${artistLink}`,
                name: artistName,
              },
            }}
          />
          {pageType
            && (
            <Generic
              schema={{
                type: 'listitem',
                position: 4,
                item: {
                  '@id': `${INVALUABLE_BASEURL_PROD}${pageTypeLink}`,
                  name: pageTypeItemName,
                },
              }}
            />
            )}
        </GenericCollection>
      </Generic>
    </JSONLD>
  );
}

BreadcrumbListSchema.propTypes = {
  artistName: string.isRequired,
  pathName: string.isRequired,
};

export default BreadcrumbListSchema;
