import React, { Fragment } from 'react';
import { objectOf, string } from 'prop-types';

import { ARTIST_LOTS_TABS, ARTIST_LOTS_PATH } from 'src/utils/artist-detail/constants';
import { DEFAULT_PAGINATION_PAGE_NUMBER } from 'src/utils/constants';
import { getPageTitle, getArtistSeoFriendlyPath } from 'src/utils/artist-detail';

function ArtistDetailSeo({ page, hostName, artist, selectedTab }) {
  const { artistRef, displayName, lastName, firstName } = artist;

  const pageTitle = selectedTab ? getPageTitle(page, displayName, selectedTab) : getPageTitle(page, displayName);
  const pageNumber = page && page > DEFAULT_PAGINATION_PAGE_NUMBER ? `?page=${page}` : '';
  const selectedTabRoute = selectedTab ? `${ARTIST_LOTS_PATH[selectedTab]}/${pageNumber}` : '';
  const seoArtist = getArtistSeoFriendlyPath({ firstName, lastName, artistRef });
  const pageURL = `https://${hostName}${seoArtist}${selectedTabRoute}`;

  const detailPageDescription = `View ${displayName}'s biographical information, upcoming artworks at auction, and sale prices from our price archives. Click to learn more.`;
  const upcomingPageDescription = `Discover ${displayName}'s latest artworks for sale at auction. Check out the artist's biography and current auction listings on Invaluable.com.`;
  const pastPageDescription = `Explore ${displayName}'s past auction results and sold artwork prices. Research and compare historical data while shopping upcoming ${displayName}'s sales on Invaluable.com.`;
  const selectedTabDescription = selectedTab === ARTIST_LOTS_TABS.UPCOMING ? upcomingPageDescription : pastPageDescription;
  const descriptionToShow = selectedTab ? selectedTabDescription : detailPageDescription;

  return (
    <Fragment>
      <title>{pageTitle}</title>
      <meta
        name="description"
        content={descriptionToShow}
      />
      <meta
        property="og:title"
        content={pageTitle}
      />
      <meta
        property="og:description"
        content={descriptionToShow}
      />
      <link
        rel="canonical"
        href={pageURL}
      />
      <meta
        property="og:url"
        content={pageURL}
      />
      <meta name="robots" content="index, follow" />
    </Fragment>
  );
}

ArtistDetailSeo.propTypes = {
  page: string,
  hostName: string.isRequired,
  artist: objectOf({}).isRequired,
  selectedTab: string,
};

ArtistDetailSeo.defaultProps = {
  page: '',
  selectedTab: '',
};

export default ArtistDetailSeo;
