import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { MODAL_TRANSITION_TIME_OUT_MS } from './constants';

function ErrorModal({ errorMessage, isShowErrorModal, toggleModal }) {
  const handleToggleErrorModal = () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={isShowErrorModal}
      className="custom-modals-global"
      toggle={handleToggleErrorModal}
      modalTransition={{ timeout: MODAL_TRANSITION_TIME_OUT_MS }}
      size="md"
      centered
      fade
    >
      <ModalHeader
        size="lg"
        toggle={handleToggleErrorModal}
      />
      <ModalBody>
        <div>
          {errorMessage}
        </div>
      </ModalBody>
    </Modal>
  );
}

ErrorModal.propTypes = {
  errorMessage: PropTypes.node.isRequired,
  isShowErrorModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ErrorModal;
