import React from 'react';
import { useSelector } from 'react-redux';

import { selectArtistDisplayName } from 'src/selectors/artist-detail';
import { selectUserSimilarArtists } from 'src/selectors/inv-common';
import ArtistCard from '../common/ArtistCard';
import { Carousel } from '../common/carousel';

function SimilarArtists() {
  const artistName = useSelector(selectArtistDisplayName);
  const userSimilarArtists = useSelector(selectUserSimilarArtists);

  const hasSimilarArtists = !!userSimilarArtists.length;

  if (!hasSimilarArtists) return null;

  return (
    <div className="artist-carousel-container">
      <h2>{`Similar Artists to ${artistName}`}</h2>
      <Carousel>
        {
          userSimilarArtists.map(({
            artistId, artistRef, displayName, upcomingCount: upcomingItems, imageURL, redirectUrl
          }) => (
            <ArtistCard
              key={artistId}
              name={displayName}
              imageURL={imageURL}
              artistRef={artistRef}
              redirectURL={redirectUrl}
              userArtistData={{ upcomingItems }}
            />
          ))
        }
      </Carousel>
    </div>
  );
}

export default SimilarArtists;
