import axios from 'axios';
import { get } from 'lodash';
import logger from 'routes/middleware/logging/logger';
import { fetchAuctionsWithFeaturedLots } from 'src/utils/featuredLots';
import {
  FETCH_UPCOMING_AUCTIONS_ERROR,
  FETCH_UPCOMING_AUCTIONS_SUCCESS,
  FETCH_UPCOMING_AUCTIONS_LOADING,
  FETCH_MY_AUCTIONS_SUCCESS,
  FETCH_MY_AUCTIONS_ERROR,
} from './constants';

// Action creators
export const fetchUpcomingAuctionsLoading = () => ({
  type: FETCH_UPCOMING_AUCTIONS_LOADING,
});

export const fetchUpcomingAuctionsSuccess = (results, memberID, isProd) => ({
  type: FETCH_UPCOMING_AUCTIONS_SUCCESS,
  payload: results,
  memberID,
  isProd,
});

export const fetchUpcomingAuctionsError = error => ({
  type: FETCH_UPCOMING_AUCTIONS_ERROR,
  payload: error,
});

export const fetchMyAuctionsSuccess = results => ({
  type: FETCH_MY_AUCTIONS_SUCCESS,
  payload: results,
});

export const fetchMyAuctionsError = error => ({
  type: FETCH_MY_AUCTIONS_ERROR,
  payload: error,
});

export const fetchUpcomingAuctions = ({ reqHostname, sessionInfo }) => async (dispatch) => {
  const memberID = get(sessionInfo, 'memberId', 0);
  const xAuthToken = get(sessionInfo, 'oasHeaders.x-auth-token', '');
  const isProd = (reqHostname === 'www.invaluable.com' && process.env.NODE_ENV === 'production');

  try {
    const payload = await axios.get(
      `https://${reqHostname}/api/catalog/upcoming?size=10&onlineOnly=false&loadParityBannerInfo=true`,
      {
        headers: {
          'Content-Type': 'application/json',
          'member-id': memberID,
          channel: 'invaluable',
        },
      }
    );

    const updatedResponse = await fetchAuctionsWithFeaturedLots({ auctionResponse: payload, reqHostname });

    dispatch(fetchUpcomingAuctionsSuccess({ ...payload.data, content: updatedResponse }, memberID, isProd));
  } catch (error) {
    logger.error(`Error fetching upcoming auctions from Invaluable API [memberID=${memberID}]`, error);
    dispatch(fetchUpcomingAuctionsError(error));
  }

  if (memberID !== 0) {
    try {
      const auctions = await axios.get(
        `https://${reqHostname}/api/users/auctions?upcoming=true&page=0&size=50`,
        {
          headers: {
            'Content-Type': 'application/json',
            'member-id': memberID,
            'x-auth-token': xAuthToken,
            channel: 'invaluable',
          },
        }
      );

      dispatch(fetchMyAuctionsSuccess(auctions.data));
    } catch (error) {
      logger.error(`Error fetching member's auctions from Invaluable API [memberID=${memberID}]`, error);
      dispatch(fetchMyAuctionsError(error));
    }
  }
};
