import get from 'lodash/get';
import map from 'lodash/map';

export const selectSessionInfo = state => get(state, 'invReactCommon.sessionInfo');

export const selectMemberId = state => get(state, 'invReactCommon.sessionInfo.memberId');

export const selectAuthToken = state => get(state, 'invReactCommon.sessionInfo.authToken');

export const selectIsFollowedArtist = (state, artistRef) => {
  const isFollowedArtists = get(state, 'invReactCommon.followedUnfollowedArtistsStatus', {});

  return artistRef && isFollowedArtists[artistRef];
};

export const selectArtistFollowers = (state, artistRef) => (
  artistRef && get(state, `invReactCommon.artistFollowers[${artistRef}]`, 0)
);

export const selectUserPopularArtistsData = state => get(state, 'invReactCommon.userPopularArtistsData', {});

export const selectUserSimilarArtists = state => get(state, 'invReactCommon.userSimilarArtists', []);

export const selectIsProd = state => get(state, 'invReactCommon.isProd', false);

export const selectHostname = state => get(state, 'invReactCommon.hostname', '');

export const selectUserCountryCode = state => get(state, 'invReactCommon.userCountryCode', '');

export const selectCurrencyConversionRates = state => get(state, 'invReactCommon.currencyConversionRates', {});

export const selectSimilarArtistsRefs = state => {
  const similarArtists = get(state, 'invReactCommon.userSimilarArtists', []);

  return map(similarArtists, 'artistRef');
};

export const selectIsMobile = state => get(state, 'invReactCommon.isMobile');

export const selectModalStatus = (state, name) => get(state, `invReactCommon.modals.${name}`);
export const selectNotificationAction = state => get(state, 'invReactCommon.loadingNotificationAction');
