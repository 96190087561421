import React from 'react';

import LotsWithImage from 'src/components/common/algolia/toggle-refinment';
import DynamicRefinmentList from 'src/components/common/algolia/dynamic-refinment';
import RefinementList from 'src/components/common/algolia/refinement-list';

import {
  FILTER_REFINEMENT_LIST_LIMIT,
  FILTERS_META,
} from './constants';
import { CurrencyAndPriceRangeFilter } from './PriceRangeFilter';
import { AuctionDateFilter } from './AuctionDateFilter';

function Filters() {
  const {
    LOTS_WITH_IMG: LOTS_WITH_IMG_META,
    SELLER_LOCATION_META,
    SELLER_META
  } = FILTERS_META;

  return (
    <div className="aside-filter-container">
      <LotsWithImage
        attribute={LOTS_WITH_IMG_META.ATTR}
        label={LOTS_WITH_IMG_META.LABEL}
        value={LOTS_WITH_IMG_META.VALUE_WHEN_SELECTED}
      />
      <div className="filter-categories-list">
        <DynamicRefinmentList
          attribute="supercategoryName"
          title="Categories"
        />
      </div>
      <AuctionDateFilter />
      <RefinementList
        searchable
        facetOrdering
        title={SELLER_META.TITLE}
        attribute={SELLER_META.ATTR}
        limit={FILTER_REFINEMENT_LIST_LIMIT}
        translations={{
          placeholder: SELLER_META.PLACE_HOLDER,
        }}
      />
      <RefinementList
        attribute={SELLER_LOCATION_META.ATTR}
        title={SELLER_LOCATION_META.TITLE}
        limit={FILTER_REFINEMENT_LIST_LIMIT}
      />
      <CurrencyAndPriceRangeFilter />
    </div>
  );
}

export default Filters;
