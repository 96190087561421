import React from 'react';

function Recent() {
  return (
    <svg
      style={{ marginRight: '6px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >

      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594
        -662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31
        -565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220
        412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 276 45
        498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132
        296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297 -180
        476 -132 269 -269 460 -489 681 -221 220 -412 357 -681 489 -246 121 -474 193
        -740 235 -147 23 -475 34 -615 20z m550 -226 c339 -49 662 -168 950 -352 253
        -161 541 -449 702 -702 144 -226 262 -507 317 -757 41 -185 53 -302 53 -513 0
        -275 -29 -467 -108 -713 -120 -371 -300 -663 -579 -942 -279 -279 -571 -459
        -942 -579 -246 -79 -438 -108 -713 -108 -275 0 -467 29 -713 108 -371 120
        -663 300 -942 579 -374 373 -589 803 -670 1340 -23 151 -23 479 0 630 54 355
        169 667 353 955 161 253 449 541 702 702 475 303 1045 429 1590 352z"
        />
        <path d="M2515 4466 c-63 -28 -60 20 -63 -894 l-2 -834 -31 -25 c-75 -63 -92
        -186 -37 -266 43 -64 93 -92 167 -94 l64 -1 426 -426 c435 -434 433 -433 501
        -427 32 3 78 49 81 81 6 68 7 66 -428 502 l-427 427 3 50 c3 58 -23 115 -68
        154 l-31 25 -2 834 c-3 786 -4 835 -21 860 -17 24 -63 49 -91 48 -6 -1 -24 -7
        -41 -14z"
        />
      </g>
    </svg>
  );
}

export default Recent;
