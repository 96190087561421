import { RESTFulService } from '../rest-client/restful-service';
import { routes } from '../../../config';

class ArtistDetailService extends RESTFulService {
  getArtistBioInfo = async (artistRef, hostname) => this.get(`https://${hostname}${routes.api.ARTIST_INFO}?artistRef=${artistRef}`);

  getArtistNotableSoldLots = async (artistRef, hostname) => this.get(`https://${hostname}${routes.api.ARTIST_NOTABLE_SOLD_LOTS}?artistRef=${artistRef}`);
}

export default new ArtistDetailService();
