/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FullScreenDialog } from 'inv-common-components';
import { get } from 'lodash';

// Configs
const STORAGE_ITEM = 'oe_dismissed';
const USER_HAS_ALERTS_ENDPOINT = '/api/members/hasAlertFollows';

function OnboardingEyebrow() {
  const [isEyebrowVisible, setIsEyebrowVisible] = useState(false);
  const [isOnboardingVisible, setIsOnboardingVisible] = useState(false);
  const [dismissed, setDismissed] = useState(() => JSON.parse(localStorage.getItem(STORAGE_ITEM)));
  const [onboardingSize, setOnboardingSize] = useState({ width: 0, height: 0 });
  const targetRef = useRef(null);
  const headers = useSelector((state) => get(state, 'invReactCommon.reqHeaders', {}));
  const xAuthToken = get(headers, 'x-auth-token', '');

  const callUserHasAlerts = async () => {
    // No user logged in
    if (!xAuthToken) {
      setIsEyebrowVisible(false);
      setIsOnboardingVisible(false);
      return;
    }

    const { data } = await axios.get(USER_HAS_ALERTS_ENDPOINT, {
      headers: {
        'x-auth-token': xAuthToken,
      },
    });

    setIsEyebrowVisible(!data);
  };

  const closeEyebrow = () => {
    setIsEyebrowVisible(false);
    setDismissed(true);

    window.localStorage.setItem(STORAGE_ITEM, JSON.stringify(true));
  };

  const renderOnboarding = () => {
    setIsOnboardingVisible(true);

    closeEyebrow();
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setOnboardingSize({ width, height });
      }
    });

    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        resizeObserver.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    callUserHasAlerts();
  }, [onboardingSize]);

  return (
    <div>
      <div
        onClick={renderOnboarding}
        role="button"
        tabIndex={0}
        id="onboarding-eyebrow"
        className={`${(isEyebrowVisible && !dismissed) ? '' : 'd-none'} onboarding-eyebrow container-fluid`}
        aria-hidden="true"
      >
        <span>
          Complete your account! Follow your favorite artists and collectibles to get notified when new items are available
          <i
            className="fa fa-chevron-right onboarding-eyebrow__icon"
            aria-hidden="true"
          />

        </span>

      </div>
      <div ref={targetRef}>
        {(isOnboardingVisible && headers) && (
        <FullScreenDialog
          openModal={get(window, 'sessionStorage.isFirstTimeLogin', 'false')}
          memberID={get(headers, 'member-id', '')}
          xAuthToken={xAuthToken}
        />
        )}
      </div>
    </div>
  );
}

export default OnboardingEyebrow;
