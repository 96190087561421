import { useEffect, useState } from 'react';

import { DEFAULT_OBSRV_ELEM_THRESHOLD } from './constants';

/**
  * this is hook to check if an element is in viewport
  @param threshold which could be either a single number or an array of numbers which indicate at
  what percentage of the target's visibility the observer's callback should be executed. If you only want to
  detect when visibility passes the 50% mark,you can use a value of 0.5. If you want the callback to run every
  time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1]. The default is 0
  (meaning as soon as even one pixel is visible, the callback will be run). A value of 1.0 means that the
  threshold isn't considered passed until every pixel is visible.
  @param elementToObserveRef reference of the element to be observed
  @returns isElementOnScreen flag, true if the element is in the viewport, false otherwise.
*/
const useIsElementOnScreen = ({ threshold = DEFAULT_OBSRV_ELEM_THRESHOLD, elementToObserveRef }) => {
  const [isElementOnScreen, setIsElementOnScreen] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsElementOnScreen(entry.isIntersecting);
    }, { threshold });

    elementToObserveRef.current && observer.observe(elementToObserveRef.current);

    return () => {
      elementToObserveRef.current && observer.unobserve(elementToObserveRef.current);
    };
  }, [elementToObserveRef]);

  return { isElementOnScreen };
};

export default useIsElementOnScreen;
