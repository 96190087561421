/* eslint-disable no-shadow */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { urls } from 'invaluable-solitaire';

import { CATEGORIES_INDEX, HOUSE_INDEX, NO_OF_RESULTS,
  NO_OF_RESULTS_MOBILE, saveRecentItem, UPCOMING_LOTS_INDEX, sendABToHeap } from '../../config';
import HighlightedText from './HightlightText';

function SuggestionSearch({ value, hits }) {
  const isProd = useSelector((state) => get(state, 'invReactCommon.isProd', false));
  const isMobile = useSelector((state) => get(state, 'invReactCommon.isMobile', false));
  const suggestedLength = isMobile ? NO_OF_RESULTS_MOBILE : NO_OF_RESULTS;
  const houseIndex = isProd ? HOUSE_INDEX.prod : HOUSE_INDEX.dev;

  const getLink = (index, val) => {
    if (index === UPCOMING_LOTS_INDEX) {
      return `/search?keyword=${val}&query=${val}`;
    }
    if (index === houseIndex) {
      return `/auction-house/${urls.seoFriendlyUrl(
        val
      )}-${urls.seoFriendlyUrl(val)}`;
    }
    if (index === CATEGORIES_INDEX) {
      return val;
    }
  };

  const onClickTag = (saveValue, type) => {
    if (saveValue) {
      saveRecentItem(saveValue);
      if (type === 'searches') {
        sendABToHeap('Search Type Ahead', { clickEvent: 'Suggested Searches' }, 0);
      } else {
        sendABToHeap('Search Type Ahead', { clickEvent: 'Suggested Categories' }, 0);
      }
    }
  };

  return (
    <Fragment>
      <h4>Suggested Searches</h4>
      {hits.slice(0, suggestedLength).map(({ index, hits }) => {
        if (index === UPCOMING_LOTS_INDEX) {
          return (
            <div className="searches-block">
              {hits.length ? hits.map(({ query, objectID }) => (
                <HighlightedText
                  linkTo={getLink(index, objectID)}
                  key={`${objectID}-suggested`}
                  text={query}
                  searchString={value}
                  onClick={() => onClickTag(query, 'searches')}
                />
              )) : <span className="none-hover">No relevant keywords found</span>}
            </div>
          );
        }
        return null;
      })}
    </Fragment>
  );
}

export default SuggestionSearch;
