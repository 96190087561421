import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import RecentSearches from './components/RecentSearches';
import SuggestionSearch from './components/SuggestionSearch';
import PopularSearches from './components/PopularSearches';
import RecentlyViewed from './components/RecentlyViewed';
import { SuggestionDropdown, SuggestionDropdownMobile } from './styles';

const SuggestionsDropdownV2 = ({ value, hits, catalogDetails, isMobile }) => {
  const mainContainer = isMobile ? document.getElementById('mobileSearchContainer')
    : document.getElementById('searchBarContainer');
  const Dropdown = isMobile ? SuggestionDropdownMobile : SuggestionDropdown;
  const [isEmpty, setEmpty] = useState(false);

  useEffect(() => {
    if (hits.length > 0) {
      const check = [];
      hits.forEach(({ hits: hit }, idx) => {
        if (hit.length) {
          check[idx] = false;
        } else {
          check[idx] = true;
        }
      });
      setEmpty(check.every(v => v === true));
    } else {
      setEmpty(true);
    }
  }, [hits, value]);

  return createPortal(
    <Dropdown
      id="suggestionDropdown"
      role="listbox"
      className={`suggestions-dropdown-v2 ${(value && isEmpty && Boolean(catalogDetails)) ? 'd-none hide' : ''}`}
    >
      {value ? (
        <SuggestionSearch
          value={value}
          hits={hits}
          catalogDetails={catalogDetails}
        />
      ) : null}
      {!value ? <RecentSearches /> : null}
      {!value ? <PopularSearches /> : null}
      {!value
        ? <RecentlyViewed /> : null}
    </Dropdown>,
    mainContainer.getElementsByClassName('suggestion-container')[0]
  );
};

export default SuggestionsDropdownV2;
