export const POST_LOGIN_ACTION_TYPE = {
  FOLLOW_SELLER: 'followSeller',
  FOLLOW_ARTIST: 'followArtist',
  PLACE_BID: 'placeBid',
  NOTIFY_ME: 'notifyMe',
  ADD_TO_WATCHED_LOTS: 'addToWatchedLots',
  REMOVE_FROM_WATCHED_LOTS: 'removeFromWatchedLots',
  OPEN_CONTACT_MODAL: 'openContactModal',
  OPEN_CONSIGN_MODAL: 'openConsignModal',
  FOLLOW_CATEGORY: 'followCategory',
  SAVE_ITEM: 'saveItem'
};

/**
 * A helper method to get post login action if available in session storage
 * @returns {object} post login action
 */
export const fetchPostLoginAction = () => {
  const postLoginAction = sessionStorage?.getItem('postLoginAction');
  return { hasPostLoginAction: !!postLoginAction, postLoginAction };
};

/**
 * A helper method to add post login action with payload in session storage
 * @param {string} type - post login action type
 * @param {string} payload - payload data
 */
export const addPostLoginAction = ({ type, payload }) => {
  sessionStorage.setItem('postLoginAction', `${type}, ${payload}`);
};

/**
 * A helper method to remove post login action from session storage
 */
export const removePostLoginAction = () => {
  sessionStorage.removeItem('postLoginAction');
};

/**
 * A helper method to check if the given payload value and payload action type exist in the post-login action.
 * @param {string} payloadValue - The payload value to check.
 * @param {string} payloadActionType - The payload action type to check.
 *
 * @returns {boolean} - Returns `true` if the given payload value and payload action type exist in the post-login action,
 * `false` otherwise.
 */
export const getIsPostLoginExistsForPayload = (payloadValue, payloadActionType) => {
  const { hasPostLoginAction, postLoginAction } = fetchPostLoginAction();

  const hasPostLoginActionForPayload = hasPostLoginAction && [payloadValue, payloadActionType]
    .every(item => postLoginAction.includes(item));

  return hasPostLoginActionForPayload;
};
