import React, { Fragment, useEffect, useState } from 'react';
import Recent from './icons/Recent';

function RecentlyViewed({ isMobile }) {
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);

  const checkCampaignExists = () => {
    const campaignContainerId = document.getElementById('recently-viewed-items');
    if (campaignContainerId) {
      setIsCampaignLoaded(true);
    } else {
      setTimeout(() => {
        checkCampaignExists();
      }, 100);
    }
  };

  useEffect(() => {
    checkCampaignExists();
  }, []);

  return (
    <Fragment>
      <h4>
        <Recent /> Recently Viewed
      </h4>
      <div
        id={isMobile ? 'searchRecentItemMobile' : 'searchRecentItem'}
        className="recently-viewed"
      />
      {
      !isCampaignLoaded && <p className="empty-results">No recently viewed items</p>
    }
    </Fragment>
  );
}

export default RecentlyViewed;
