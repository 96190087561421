import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectSessionInfo } from 'src/selectors/inv-common';
import { POST_LOGIN_ACTION_TYPE, fetchPostLoginAction, removePostLoginAction } from 'src/utils/post-login';
import usePDPModal from '../usePDPModal';

const usePDPContactSellerPostLogin = () => {
  const { isLoggedIn } = useSelector(selectSessionInfo);
  const [
    isOpenContactSellerModal,
    openContactSellerModal,
    closeContactSellerModal,
  ] = usePDPModal(false);

  useEffect(() => {
    if (isLoggedIn) {
      const { hasPostLoginAction, postLoginAction } = fetchPostLoginAction();
      if (hasPostLoginAction && postLoginAction.includes(POST_LOGIN_ACTION_TYPE.OPEN_CONTACT_MODAL)) {
        openContactSellerModal();
        removePostLoginAction();
      }
    }
  }, [isLoggedIn]);

  return { isOpenContactSellerModal, openContactSellerModal, closeContactSellerModal };
};

export default usePDPContactSellerPostLogin;
