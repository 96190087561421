import React from 'react';
import SavedItem from './savedItem';
import { trackClick } from '../utils';

function BidsAndFavorites({ savedItems }) {
  return (
    <div className="col-md-12 col-lg-12 col-xl-4 side-border">
      <div className="my-invaluable-section-header">
        <a
          className="my-bids-fav-tag"
          href="/my-account/bids"
          onClick={() => trackClick('Bids And Favorites')}
        >My Bids & Favorites <i className="fa fa-chevron-right" />
        </a>
      </div>
      {
        savedItems.length > 0 ? savedItems.map(item => <SavedItem item={item} key={item.ref} />)
          : <p>You do not have any bids or favorites saved.</p>
      }
    </div>
  );
}

export default BidsAndFavorites;
