import React from 'react';
import PropTypes from 'prop-types';

import RightArrowIcon from './RightArrowIcon';

function ViewAllLotCard({ pastUpcomingPageRedirectURL }) {
  return (
    <div className="artist-card view-all-card">
      <a
        href={pastUpcomingPageRedirectURL}
        target="_blank"
        rel="noopener noreferrer"
        className="view-all-btn"
      >
        <div className="icon-holder">
          <RightArrowIcon />
        </div>
        <span>View All</span>
      </a>
    </div>
  );
}

ViewAllLotCard.propTypes = {
  pastUpcomingPageRedirectURL: PropTypes.string.isRequired,
};

export default ViewAllLotCard;
