import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function NoSSR({ children }) {
  const [isMounted, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment>{isMounted ? children : null}</Fragment>;
}

NoSSR.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NoSSR;
