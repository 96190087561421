import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import loadable from '@loadable/component';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { setModalStatus, signupUser } from 'src/actions/inv-common.actions';
import { selectModalStatus } from 'src/selectors/inv-common';
import { MODAL_NAMES } from 'src/utils/constants';
import {
  SSOContainer
} from '../../../containers/Header/Header.styled';
import { errorMessages, getCreatedPasswordErrors } from './errorUtils';
import { followItem, getEmail } from './emailOnlyUtils';

const PasswordMeter = loadable(() => import(
  /* webpackChunkName: "PasswordMeter" */
  './PasswordMeter'
));

function SignUpModal() {
  const dispatch = useDispatch();

  const { isOpen: isModalOpen, email: preloadedEmail } = useSelector(state => selectModalStatus(state, MODAL_NAMES.signup));
  const signUpErrors = useSelector((state) => get(state, 'invReactCommon.errors.signupErrors', false));

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassowrd] = useState('');
  const [emailMeChecked, setEmailMeChecked] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [clientValidationErrors, setClientValdationErrors] = useState([]);
  const [passwordError, setPasswordError] = useState(false);

  const closeModal = () => dispatch(setModalStatus({
    name: MODAL_NAMES.signup,
    isOpen: false
  }));

  const setLoginModalOpen = () => dispatch(setModalStatus({
    name: MODAL_NAMES.login,
    isOpen: true
  }));

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailAddressChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassowrd(e.target.value);
  };

  const openLoginModal = () => {
    closeModal();
    setLoginModalOpen();
  };

  const openEmailOnlyModal = () => {
    closeModal();
    dispatch(setModalStatus({
      name: MODAL_NAMES.emailOnly,
      isOpen: true
    }));
  };

  const handleSubmitCreateAcct = () => {
    const errors = getCreatedPasswordErrors(password, confirmPassword);
    if (errors.length > 0) {
      setClientValdationErrors(errors);
    } else {
      const emailOnlyData = getEmail();

      const userData = {
        firstName,
        lastName,
        email: email || preloadedEmail || emailOnlyData,
        password,
        userPrefs: {
          sendNewsLetter: emailMeChecked,
        },
        emailOnly: !!emailOnlyData
      };

      dispatch(signupUser(userData));
    }
  };

  const handlePasswordMeter = (error) => {
    if (error === 'Good' || error === 'Excellent' || password.length >= 10) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const createEmailOnlyAccount = async () => {
    await followItem({
      email: preloadedEmail,
      signup: true
    });
    closeModal();
  };

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        window.google.accounts.id.renderButton(
          document.getElementById('ssoGoogleButtonSignUp'),
          { theme: 'outline', size: 'large', text: 'continue_with', logo_alignment: 'center', width: '270px' } // customization attributes
        );
      }, 1);
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      className="custom-modals-global modal-dialog modal-dialog-centered"
      id="signUpModal"
      size="md"
      fade
    >
      <div className="modal-header login-modal-header">
        <button type="button" className="close" data-dismiss="modal" onClick={() => closeModal()}>
          <span>×</span><span className="sr-only">Close</span>
        </button>
      </div>
      {preloadedEmail && (
        <button type="button" className="modal-back-btn btn btn-link text-left" data-dismiss="modal" onClick={openEmailOnlyModal}>
          <span><i className="fa fa-angle-left fa-2x mr-1" /></span><span className="sr-only">Previous</span>
        </button>
      )}
      <div className="modal-title modal-title-text text-overflow text-center px-5">{preloadedEmail ? `Welcome ${preloadedEmail}!` : 'Get Started'}</div>
      <div className="text-center px-2">{preloadedEmail ? 'You\'ve started your Invaluable account. To complete your account and begin bidding, please provide the additional information below.' : 'Create an account to make bidding fast and easy!.'}</div>
      <ModalBody>
        <Form className="form-group new-account-form" onSubmit={(e) => e.preventDefault()}>
          <fieldset className="login-inputs" id="loginFields">
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    maxLength={50}
                    value={firstName}
                    onChange={(e) => handleFirstNameChange(e)}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6}>
                <FormGroup>
                  <Input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    maxLength={50}
                    value={lastName}
                    onChange={(e) => handleLastNameChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            {!preloadedEmail && (
              <FormGroup>
                <Input
                  type="text"
                  name="email"
                  placeholder="Email"
                  maxLength={100}
                  value={email}
                  onChange={(e) => handleEmailAddressChange(e)}
                />
                <div className="control-label">
                  {clientValidationErrors.emailInvalid
                    && errorMessages.emailInvalid}
                </div>
              </FormGroup>
            )}
            <FormGroup>
              <Input
                type="password"
                name="password"
                id="passwordLoginModal"
                placeholder="Password (must be 10-50 characters)"
                maxLength={50}
                minLength={8}
                value={password}
                onChange={(e) => handlePasswordChange(e)}
              />
              {clientValidationErrors.includes('invalid-email') && (
              <div className="login-error-message control-label loginError" id="loginError">
                {errorMessages.passwordCharacterValidate}
              </div>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                name="password"
                id="passwordLoginModal"
                placeholder="Confirm Password"
                maxLength={50}
                minLength={8}
                value={confirmPassword}
                onChange={(e) => handleConfirmPasswordChange(e)}
              />
              {clientValidationErrors.includes('passwords-dont-match') && (
              <div className="login-error-message control-label loginError" id="loginError">
                {errorMessages.passwordsDoNotMatch}
              </div>
              )}
            </FormGroup>
            {password
              ? (
                <PasswordMeter
                  password={password}
                  onError={handlePasswordMeter}
                />
              )
              : null}
            <label
              className="checkbox-inline ml-3 mt-3 mb-3"
              htmlFor="rememberMe"
              onClick={() => setEmailMeChecked(!emailMeChecked)}
            >
              <Input type="checkbox" checked={`${emailMeChecked ? 'checked' : ''}`} id="rememberMe" />
              <span>
                Email me new arrivals and personalized recommendations from premier auction houses.
              </span>
            </label>
            {signUpErrors && (

            <div className="login-error-message control-label loginError" id="loginError">
              There was an error creating your account. Please try again.
            </div>
            )}
            <label
              className="checkbox-inline ml-3 mb-3"
              htmlFor="rememberMe"
              onClick={() => setTermsChecked(!termsChecked)}
            >
              <Input type="checkbox" checked={`${termsChecked ? 'checked' : ''}`} id="rememberMe" />
              <span>I have read and accept Invaluable's&nbsp;
                <a href="/agreements/userTerms.cfm" target="_blank">
                  terms of service
                </a> and <a href="/agreements/privacy.cfm" target="_blank">privacy policy</a>.
              </span>
            </label>

            <Button
              role="button"
              className="btn btn-login-modal modal-login-btn log-in-memory mt-2"
              onClick={handleSubmitCreateAcct}
              disabled={
                (!password.length
                  || !(preloadedEmail || email).length
                  || !firstName.length
                  || !lastName.length
                  || !termsChecked
                  || !confirmPassword.length
                  || !password.length
                  || passwordError
                )
              }
            >Complete Account
            </Button>
          </fieldset>
          <div className="ssoContainer">
            <div className="social-separator">
              <div className="social-line" />
              {!preloadedEmail && <span>OR</span>}
              <div className="social-line" />
            </div>
            {preloadedEmail ? (
              <div className="text-center">
                <a
                  role="button"
                  tabIndex="0"
                  className="close-login text-dark text-bold"
                  onClick={createEmailOnlyAccount}
                >Skip this step for now
                </a>
              </div>
            ) : (
              <SSOContainer>
                <div id="ssoGoogleButtonSignUp" allow="identity-credentials-get" className="space-bottom col-sm-6" />
              </SSOContainer>
            )}
          </div>
        </Form>
        <div className="text-center">
          <span className="dark-gray-text">Already have an account?&nbsp;</span>
          <a
            role="button"
            tabIndex="0"
            className="toggle-login"
            onClick={openLoginModal}
          >Sign in
          </a>
        </div>
      </ModalBody>
      <ModalFooter>
        {!preloadedEmail && <span className="dark-gray-text small">By continuing with Google, you agree to Invaluable's <a href="/agreements/userTerms.cfm" target="_blank">terms of service</a> and <a href="/agreements/privacy.cfm" target="_blank">privacy policy</a>. Invaluable may send you communications; you can set your preferences in your account.</span>}
      </ModalFooter>
    </Modal>
  );
}

export default SignUpModal;
