import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { arrayOf, number, shape, string } from 'prop-types';

import { selectArtistDisplayName } from 'src/selectors/artist-detail';
import { useGetViewPortConfig } from 'src/hooks';
import { getAuctionHouseSeoFriendlyURL } from 'src/components/artist-detail/helpers';

import { COLS_TO_DISPLAY } from './constants';
import { splitHousesIntoNoOfColumns } from './helpers';

function HousesSellingWorkByArtist({ houses }) {
  const artistDisplayName = useSelector(selectArtistDisplayName);

  const colsToDisplayAsPerViewPort = useGetViewPortConfig(COLS_TO_DISPLAY);
  const houseColsToDisplay = splitHousesIntoNoOfColumns(houses, houses.length, colsToDisplayAsPerViewPort);

  const renderHouseInfoLinkCol = (col) => (
    col.length ? (
      <Col key={col[0].houseRef}>
        <ul className="list-unstyled">
          {col.map(({ houseName, houseRef, upcomingLotsCount }) => (
            <li key={houseRef} className="my-2">
              <a href={getAuctionHouseSeoFriendlyURL(houseName, houseRef)}>
                {houseName} ({upcomingLotsCount})
              </a>
            </li>
          ))}
        </ul>
      </Col>
    ) : null
  );

  return (
    <div className="auction-houses-selling-list">
      <h3>Auction Houses Selling Works by {artistDisplayName}</h3>
      <Row>
        { houseColsToDisplay.map(renderHouseInfoLinkCol)}
      </Row>
    </div>
  );
}

HousesSellingWorkByArtist.propTypes = {
  houses: arrayOf(shape({
    houseName: string,
    houseRef: string,
    upcomingLotsCount: number,
  }).isRequired).isRequired
};

export default HousesSellingWorkByArtist;
