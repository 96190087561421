import React from 'react';
import PropTypes from 'prop-types';
import { connectToggleRefinement } from 'react-instantsearch-dom';
import { formatCurrency } from '../../../../utils/algolia-helper';

function ToggleRefinement({
  currentRefinement,
  label,
  count,
  refine,
}) {
  return (
    <div className="filter-wrapper">
      <div className="toggle-refinement">
        <input
          type="checkbox"
          className="form-check-input"
          value={currentRefinement}
          checked={currentRefinement}
          onChange={() => refine(!currentRefinement)}
        />
        <label
          className="form-check-label"
          htmlFor="toggle-refinmet-label"
        >
          <span
            role="presentation"
            id="toggle-refinmet-label"
            onClick={() => refine(!currentRefinement)}
          >{label}
          </span>
          <span className="count">{ formatCurrency(count.unchecked) }</span>
        </label>
      </div>
    </div>
  );
}

ToggleRefinement.propTypes = {
  currentRefinement: PropTypes.objectOf({}).isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.objectOf({
    checked: PropTypes.number.isRequired,
    unchecked: PropTypes.number.isRequired,
  }).isRequired,
  refine: PropTypes.func.isRequired,
};

export default connectToggleRefinement(ToggleRefinement);
