/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { connectRange } from 'react-instantsearch-dom';
import { FormGroup, Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { dateFromUnixTime, endOfDay, unixTimeFromDate } from 'src/utils/date-time';
import { convertTimeStampToDate } from '../../../../utils/algolia-helper';

function CustomDateRange({ filterOptions, currentRefinement, max, min, refine, dateFilter, setDateFilter, upcoming, title }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const minDate = dateFromUnixTime(min);
  const maxDate = dateFromUnixTime(max);

  const location = useLocation();
  const { dateType, dateTimeUTCUnix } = qs.parse(location.search.slice(1), { delimiter: /[&&,?]/ });

  function populateCustomDates() {
    const { from, to } = convertTimeStampToDate(currentRefinement);
    if (dateTimeUTCUnix && dateTimeUTCUnix.min) setStartDate(from);
    if (dateTimeUTCUnix && dateTimeUTCUnix.max) setEndDate(to);
  }

  function deSelectDate() {
    setStartDate(null);
    setEndDate(null);
  }

  useEffect(() => {
    if (dateType && dateType !== 'Custom') {
      deSelectDate();
    }
    if (dateType) {
      setDateFilter(decodeURIComponent(dateType));
    } else {
      setDateFilter('All dates');
      deSelectDate();
    }
  }, [dateType]);

  useEffect(() => {
    if (dateType === 'Custom') {
      populateCustomDates();
    }
  }, [currentRefinement]);

  function handleRefine(item) {
    const newMin = item.start ? unixTimeFromDate(item.start) : undefined;
    const newMax = item.end ? unixTimeFromDate(endOfDay(item.end)) : undefined;

    const refined = {
      min: newMin && Math.max(newMin, min),
      max: newMax && Math.min(newMax, max),
    };

    refine(refined);
  }

  function handleSelectOption(item) {
    setDateFilter(item.label);
    handleRefine(item);
  }

  function handleDateChange(type, date) {
    setDateFilter('Custom');
    if (type === 'startDate') {
      setStartDate(date);
      handleRefine({ start: date, end: endDate });
    }

    if (type === 'endDate') {
      setEndDate(date);
      handleRefine({ start: startDate, end: date });
    }
  }

  return (
    <Fragment>
      <h3>{ title }</h3>
      <div className="filter-wrapper custom-date-range">
        <FormGroup tag="fieldset">
          {filterOptions.map((item) => {
            const end = item.end && unixTimeFromDate(item.end);
            const start = item.start && unixTimeFromDate(item.start);
            const disabled = upcoming ? currentRefinement.min > end : currentRefinement.max < start;

            return (
              <Fragment>
                <FormGroup
                  check
                  className="mt-2 ml-0"
                  key={item.label}
                >
                  <Label check>
                    <Input
                      checked={item.label === dateFilter}
                      type="radio"
                      name="startDate"
                      placeholderText="MM/DD/YYYY"
                      disabled={disabled}
                      onChange={() => handleSelectOption(item)}
                    />{' '}
                    {item.label}
                  </Label>
                </FormGroup>
                {item.label === 'Custom'
                  && (
                  <span
                    className="datepicker-holder mt-2"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      className="datepicker"
                      style={{ width: '45%' }}
                    >
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        name="endDate"
                        minDate={minDate}
                        maxDate={maxDate}
                        placeholderText="MM/DD/YY"
                        onChange={date => handleDateChange('startDate', date)}
                      />
                    </div>
                    <div
                      className="datepicker"
                      style={{ width: '45%' }}
                    >
                      <DatePicker
                        className="form-control"
                        selected={endDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        placeholderText="MM/DD/YY"
                        onChange={date => handleDateChange('endDate', date)}
                      />
                    </div>
                  </span>
                  )}
              </Fragment>
            );
          })}
        </FormGroup>
      </div>
    </Fragment>
  );
}

CustomDateRange.propTypes = {
  currentRefinement: PropTypes.objectOf({ min: PropTypes.number, max: PropTypes.number }).isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  refine: PropTypes.func.isRequired,
  dateFilter: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setDateFilter: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf({}).isRequired,
  upcoming: PropTypes.bool.isRequired,

};

export default connectRange(CustomDateRange);
