import { routes } from 'config';
import React, { useEffect, useState } from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ModalBody, ModalFooter, ModalHeader, Modal as ReactstrapModal } from 'reactstrap';
import { allowNotifications, handleBlockNotifications, initFirebase, registerNotifications } from 'src/firebase';
import { selectHostname, selectMemberId } from 'src/selectors/inv-common';
import { getIsProdEnv } from 'src/utils/common/helpers';

export function NotificationsPermissionModal() {
  const memberId = useSelector(selectMemberId);
  const hostname = useSelector(selectHostname);
  const isProd = getIsProdEnv(hostname);
  const [show, setShow] = useState(false);
  const v3Routes = [
    routes.ARTMYN,
    routes.DISCOVER_CAMPAIGNS,
    routes.FEATUREDTIMEDSALES,
    routes.LANDING, routes.LAPADA,
    routes.PARITY, routes.PRESS];
  const currentPath = window.location.pathname;

  const getIsV3Page = () => {
    // This check is implemented because because artists and auction pages url patterns
    // also match categories page url pattern.
    if (currentPath.includes('artist') || currentPath.includes('auction-house')) return false;
    return v3Routes.includes(currentPath);
  };

  const handleClose = () => {
    setShow(false);
    handleBlockNotifications();
  };

  const handleAllow = () => {
    setShow(false);
    allowNotifications(memberId);
  };

  useEffect(() => {
    if (memberId) {
      initFirebase(isProd);
      registerNotifications(setShow, memberId);
    }
  }, [memberId]);

  if (!memberId) return null;

  const getModalContent = () => (
    <>
      <ModalHeader>
        <ModalTitle className="v4-modal-title">
          Invaluable.com wants to:
          <button type="button" className="close notificationBlockBtn" onClick={handleClose}>
            <span>&times;</span><span className="sr-only">Close</span>
          </button>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p>
          <strong>Show Notifications:</strong> Don&apos;t miss out on your next treasure!
          Enable Notifications to increase your chances of winning.
        </p>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-primary" onClick={handleAllow}>Allow</button>&nbsp;
        <button type="button" className="btn btn-link notificationBlockBtn" onClick={handleClose}>Block</button>
      </ModalFooter>
    </>
  );

  return (
    getIsV3Page() ? (
      <Modal
        className="custom-modals-global"
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        fade
      >
        {getModalContent()}
      </Modal>
    ) : (
      <ReactstrapModal
        className="custom-modals-global v4-notification-permission-modal"
        isOpen={show}
        toggle={handleClose}
      >
        {getModalContent()}
      </ReactstrapModal>
    )
  );
}
