import React, { memo, useCallback } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../utils/algolia-helper';

function RefinementListItem({
  isFromSearch,
  item,
  onRefineItem,
}) {
  const handleRefineItem = useCallback(() => {
    onRefineItem(item.value);
  }, [item.value, onRefineItem]);

  return (
    <li className="ais-RefinementList-item ais-RefinementList-item--selected">
      <label
        className="form-check-label ais-RefinementList-label"
        htmlFor="form-check-input"
      >
        <input
          type="checkbox"
          className="form-check-input"
          checked={item.isRefined}
          onChange={handleRefineItem}
        />
        <span
          role="presentation"
          onClick={handleRefineItem}
        >
          {isFromSearch ? (
            <Highlight attribute="label" hit={item} />
          ) : (
            item.label
          )}{' '}
        </span>
        <span className="ais-RefinementList-count">
          {formatCurrency(item.count)}
        </span>
      </label>
    </li>
  );
}

RefinementListItem.propTypes = {
  isFromSearch: PropTypes.bool.isRequired,
  item: PropTypes.shape({}).isRequired,
  onRefineItem: PropTypes.func.isRequired,
};

export default memo(RefinementListItem);
