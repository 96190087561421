import classNames from 'classnames';
import { urls as solitaireUrls } from 'invaluable-solitaire';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import WatchUnwatchLot from 'src/components/common/WatchUnwatchLot';
import { useLocalisedAmount, useTimedCountdown } from 'src/hooks';
import { selectLotsDisplayMode } from 'src/selectors/artist-detail';
import { selectIsProd, selectSessionInfo } from 'src/selectors/inv-common';
import { formatAmountAndAppendSymbol } from 'src/utils/artist-detail';
import { LOT_HIT_DATE_FORMAT, LOTS_DISPLAY_MODES } from 'src/utils/artist-detail/constants';
import { getImageBaseDomain } from 'src/utils/common/helpers';
import useTimeZoneLocal from 'src/hooks/useTimezoneLocal';

import LotStatusAndPrice from './LotStatusAndPrice';

function LotHit({
  hit,
  isUpcoming,
  hideDescription = false,
  showFormattedDate = false,
  hideEstimates = false,
  hideHouseName = false,
  onLotClick = undefined,
}) {
  const {
    lotTitle,
    photoPath,
    houseName,
    currentBid,
    estimateLow,
    estimateHigh,
    bidCount = 0,
    dateTimeLocal,
    dateTimeUTCUnix,
    currencySymbol,
    lotDescription,
    currencyCode,
    lotNumber,
    lotRef,
    userItemProperties,
    priceResult,
    isParity,
  } = hit;

  const lotsDisplayMode = useSelector(selectLotsDisplayMode);
  const { isLoggedIn } = useSelector(selectSessionInfo);
  const isProd = useSelector(selectIsProd);
  const currentAmount = showFormattedDate && !currentBid ? estimateLow : currentBid;
  const localizedBidAmount = useLocalisedAmount({ amount: currentAmount, currencyCode });
  const lotCountDownStatus = useTimedCountdown(hit);

  const lotPhotoUrl = `${getImageBaseDomain(isProd)}/housePhotos/${photoPath}`;
  const [lotImageSrc, setLotImageSrc] = useState(lotPhotoUrl);

  const isListView = lotsDisplayMode === LOTS_DISPLAY_MODES.LIST;
  const isGridView = lotsDisplayMode === LOTS_DISPLAY_MODES.GRID;
  const isShowLocalisedBid = localizedBidAmount && (currentBid || showFormattedDate) && isUpcoming;
  const formattedEstLow = formatAmountAndAppendSymbol(estimateLow, currencySymbol);
  const formattedEstHigh = formatAmountAndAppendSymbol(estimateHigh, currencySymbol);
  const isShowAuctionDate = !isUpcoming && dateTimeLocal;
  const formattedAuctionDate = isShowAuctionDate && format(new Date(dateTimeLocal), LOT_HIT_DATE_FORMAT);
  const { convertedDate, userTimeZone } = useTimeZoneLocal(dateTimeUTCUnix * 1000, false, true);

  const formattedBidAmount = formatAmountAndAppendSymbol(currentAmount, currencySymbol);
  const bidCountText = `${bidCount} bid${bidCount === 1 ? '' : 's'}`;
  const seoFriendlyLotTitle = solitaireUrls.seoFriendlyUrl(lotTitle);
  const seoFriendlyLotNumber = solitaireUrls.seoFriendlyUrl(lotNumber);
  const seoFriendlyLotRef = solitaireUrls.seoFriendlyUrl(lotRef);
  const auctionLotUrl = `/auction-lot/${seoFriendlyLotTitle}-${seoFriendlyLotNumber}-c-${seoFriendlyLotRef}`;
  const { isWatched } = userItemProperties || {};
  const noImageURL = `${getImageBaseDomain(isProd)}/static/noimage_noborder.png`;

  const liveLotCountDownStatus = showFormattedDate && !lotCountDownStatus
    ? `${convertedDate} ${userTimeZone}` : lotCountDownStatus || dateTimeLocal;

  const lotDateStatusClassName = classNames(
    'auction-date',
    { 'live-status': !!lotCountDownStatus }
  );

  const thumbnailLotStatus = priceResult === 0 ? 'passed' : 'sold';

  const renderBidAmountAndBidCount = () => {
    const isShowBidCount = bidCount > 0;
    return (
      <div className="bid-status">
        <strong>{formattedBidAmount}</strong>
        {isShowLocalisedBid
          && <strong className="localised-bid">({localizedBidAmount})</strong>}
        {
          isShowBidCount && (
            <span>{isShowLocalisedBid ? bidCountText : `(${bidCountText})`}</span>
          )
        }
      </div>
    );
  };

  const onImageError = () => {
    setLotImageSrc(noImageURL);
  };

  return (
    <div className={classNames('lot-card-container', { 'parity-badge': isParity })}>
      <div className="thumbnail-container">
        {
          isParity
          && (
            <img
              className="bp-parity-badge"
              src={`${getImageBaseDomain(isProd)}/static/home/BP_Badge.png`}
              alt="BP Parity Badge"
            />
          )
        }
        <WatchUnwatchLot key={isWatched} lotRef={lotRef} isWatched={!!isWatched} />
        <a target="_blank" rel="noreferrer" href={auctionLotUrl} onClick={() => onLotClick?.()}>
          <img
            alt={lotTitle}
            src={lotImageSrc}
            onError={onImageError}
          />
        </a>
        {!isUpcoming && isLoggedIn && <span className="thumbnail-pill">{thumbnailLotStatus}</span>}
      </div>
      <div className="lot-detail">
        {isUpcoming && <span className={lotDateStatusClassName}>{liveLotCountDownStatus}</span>}
        {isShowAuctionDate && (
          <span className="auction-date">{formattedAuctionDate}</span>
        )}
        <h4 className="text-ellipsis">
          <a target="_blank" rel="noreferrer" href={auctionLotUrl}>{lotTitle}</a>
        </h4>
        {
          !hideHouseName && (isGridView || hideDescription) && <span className="auction-name">{houseName}</span>
        }
        {!hideEstimates && (
          <span className="estimated-bid">
            {`Est: ${formattedEstLow} - ${formattedEstHigh}`}
          </span>
        )}
        {!isUpcoming && <LotStatusAndPrice hit={hit} />}
        {isUpcoming && renderBidAmountAndBidCount()}
        {
          isListView && !hideDescription && (
            <Fragment>
              <p className="text-ellipsis">{lotDescription}</p>
              <span className="auction-name">{houseName}</span>
            </Fragment>
          )
        }
      </div>
    </div>
  );
}

LotHit.propTypes = {
  hit: PropTypes.shape(PropTypes.any).isRequired,
  isUpcoming: PropTypes.bool.isRequired,
  hideDescription: PropTypes.bool,
  hideHouseName: PropTypes.bool,
  showFormattedDate: PropTypes.bool,
  onLotClick: PropTypes.func,
  hideEstimates: PropTypes.bool
};

export default LotHit;
