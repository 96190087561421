import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Hits, Stats } from 'react-instantsearch-dom';

import { LOTS_DISPLAY_MODES, ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';
import HitsPerPage from 'src/components/common/algolia/hit-per-page';
import Pagination from 'src/components/common/algolia/pagination';
import SearchResults from 'src/components/common/algolia/state-results';
import { selectLotsDisplayMode, selectNumberOfLotsPerPage } from 'src/selectors/artist-detail';
import { setNumberOfLotsPerPage, setFilteredLotsCount } from 'src/actions';
import { useSelectedArtistDetailTab } from 'src/hooks';
import LotHit from '../../common/lot-hit/LotHit';

import { PAGE_SIZE_OPTIONS } from './constants';

function FilteredLotsView() {
  const dispatch = useDispatch();
  const lotsDisplayMode = useSelector(selectLotsDisplayMode);
  const numberOfLotsPerPage = useSelector(selectNumberOfLotsPerPage);
  const selectedTab = useSelectedArtistDetailTab();

  const isUpcoming = selectedTab === ARTIST_LOTS_TABS.UPCOMING;

  const lotsContainerClassName = classNames(
    'lot-views-holder',
    {
      'lots-list-view': lotsDisplayMode === LOTS_DISPLAY_MODES.LIST,
      'lots-grid-view': lotsDisplayMode === LOTS_DISPLAY_MODES.GRID
    }
  );

  const setLotsCountAndDisplayNoLotsMessage = totalHits => {
    dispatch(setFilteredLotsCount(totalHits));
  };

  const setPageSize = (pageSize) => {
    dispatch(setNumberOfLotsPerPage(pageSize));
  };

  return (
    <div className={lotsContainerClassName}>
      <Stats translations={{ stats: setLotsCountAndDisplayNoLotsMessage }} />
      <SearchResults>
        <Hits hitComponent={({ hit }) => <LotHit hit={hit} isUpcoming={isUpcoming} />} />
        <div className="pagination-and-pagesize-container">
          <Pagination pageSize={numberOfLotsPerPage} showLessItems />
          <div className="pagesize-dropdowns algolia">
            <span>Lots Per Page:</span>
            <HitsPerPage
              defaultRefinement={numberOfLotsPerPage}
              currentRefinement={numberOfLotsPerPage}
              items={PAGE_SIZE_OPTIONS}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </SearchResults>
    </div>
  );
}

export default FilteredLotsView;
