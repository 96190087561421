import React from 'react';
import { useSelector } from 'react-redux';

import { selectArtistBioInfo } from 'src/selectors/artist-detail';

function Aliases() {
  const { aliases } = useSelector(selectArtistBioInfo);

  const formattedAliases = aliases && aliases.join(', ');
  const headingForAliasesSection = formattedAliases && aliases.length > 1 ? 'Aliases' : 'Alias';

  return (
    formattedAliases && (
      <div className="detailed-info-widget">
        <h3>{ headingForAliasesSection }</h3>
        <p>{ formattedAliases }</p>
      </div>
    )
  );
}

export default Aliases;
