import React, { Fragment } from 'react';
import { number, func, string, oneOfType, bool } from 'prop-types';
import { connectPagination } from 'react-instantsearch-dom';
import RCPagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { formatCurrency } from '../../../../utils/algolia-helper';

function CustomPagination({ currentRefinement, nbPages, refine, createURL, handleScrollIntoViewWhenPageChange, pageSize, showLessItems }) {
  const paginationDefaultProps = {
    total: (nbPages * pageSize),
    pageSize,
    current: currentRefinement,
    onChange: (e) => {
      refine(e);
      handleScrollIntoViewWhenPageChange && handleScrollIntoViewWhenPageChange();
    },
    // Only difference from AZ --> these are fa not fas
    prevIcon: () => <i className="fa fa-chevron-left" />,
    nextIcon: () => <i className="fa fa-chevron-right" />,
    jumpPrevIcon: () => <span>...</span>,
    jumpNextIcon: () => <span>...</span>,
    locale: localeInfo,
    hideOnSinglePage: true,
  };

  const itemRender = (current, type, element) => {
    if (type === 'page') {
      return (
        <a
          href={createURL(current)}
          onClick={(event) => {
            event.preventDefault();
            refine(current);
          }}
        >{formatCurrency(current)}
        </a>
      );
    }
    return element;
  };

  return (
    <Fragment>
      {/* pagination for mobile devices */}
      <RCPagination
        {...paginationDefaultProps}
        showLessItems
        itemRender={itemRender}
        className="mobile d-md-none pl-0 mb-0"
      />
      {/* pagination for desktop */}
      <RCPagination
        {...paginationDefaultProps}
        itemRender={itemRender}
        showLessItems={showLessItems}
        className="d-none d-md-flex pl-0 mb-0"
      />
    </Fragment>
  );
}

CustomPagination.defaultProps = {
  showLessItems: false
};

CustomPagination.propTypes = {
  currentRefinement: number.isRequired,
  nbPages: number.isRequired,
  refine: func.isRequired,
  createURL: func.isRequired,
  pageSize: oneOfType([number, string]).isRequired,
  handleScrollIntoViewWhenPageChange: func.isRequired,
  showLessItems: bool,
};

export default connectPagination(CustomPagination);
