import React from 'react';
import { Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import { TabItem } from './TabItem';

/**
 * tabsMeta: list of tabs to show. it is a list of object
 * [
 *   {
 *     title: string, the title of the tab to show on the page
 *     id: string, unique id of the tab to identify
 *     href: string, link to open. NOTE: if passed onTabClick will be ignored
 *   },
 *   ...
 * ]
 */
export function InvTabs({ tabsMeta, selectedTabId, onTabClick, className }) {
  return (
    <div className={`inv-tabs-container ${className}`}>
      <Nav tabs>
        {tabsMeta.map((tab) => (
          <TabItem
            key={`${tab.id}-${tab.title}`}
            isSelected={tab.id === selectedTabId}
            tabMeta={tab}
            onClickHandler={onTabClick}
          />
        ))}
      </Nav>
      <hr />
    </div>
  );
}

InvTabs.propTypes = {
  selectedTabId: PropTypes.string.isRequired,
  onTabClick: PropTypes.func,
  tabsMeta: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    href: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
};

InvTabs.defaultProps = {
  className: '',
  onTabClick: undefined,
};
