import { getImageEnv } from '../../../config';

export const ASYNC_ACTION_STATUS_TYPE = {
  IDLE: 'idle',
  LOADING: 'loading',
  COMPLETE: 'complete',
  FAILED: 'failed',
};

export const ASYNC_ACTION = {
  CONTACT_SELLER_STATUS: 'contactSellerStatus',
  FOLLOW_SELLER_STATUS: 'followSellerStatus',
  ADD_TO_WATCHLIST_STATUS: 'addToWatchlistStatus',
  REMOVE_TO_WATCHLIST_STATUS: 'removeToWatchlistStatus',
};

export const APPROVAL_STATUSES_MAPPING = {
  p: 'Pending Approval',
  a: 'Approved to bid',
};

export const APPROVAL_STATUSES = {
  PENDING_APPROVAL: 'p',
  APPROVED_TO_BID: 'a',
};

export const MAX_INCREMENTED_BIDS_COUNT = 500;

export const AUCTION_DATE_FORMATS = {
  DATE: 'MMMM DD, YYYY',
  DATE_TIME: 'MMMM DD, YYYY, hh:mm A',
};

export const AUCTION_TYPE = {
  LIVE: 'Live Auction',
  TIMED: 'Timed Auction',
  VIEW_ONLY: 'View only auction',
};

export const PLACE_BID_NESTED_FORMS = {
  SMS_OPT_IN: 'smsPhone',
  SHIPPING_ADDRESS: 'shippingAddress',
  BILLING_ADDRESS: 'billingAddress',
  CREDIT_CARD: 'creditCard',
};

export const SESSION_STORAGE_KEY_TO_HIDE_NOTIFICATION_POPUP = 'hideNotificationPopUp';

export const REGEX_TO_REMOVE_ANY_LINKS_WITH_ANCHOR_TAG = /<a(\s[^>]*)?>.*?<\/a>/g;
export const REGEX_TO_REMOVE_ANY_URL_WITH_HTTP_OR_HTTPS = /(?:https?):\/\/[\n\S]+/g;
export const REGEX_TO_REMOVE_ANY_EXTRA_SPACE = /\s+/g;
export const PDP_GA_DATA_ELEMENT_ID = 'pdp-data';
export const PDP_GA_DATA_ATTRIBUTE_ID = 'data-ga-object';

export const ALGOLIA_METHODS = {
  OBJECT_IDS_AFTER_SEARCH: 'convertedObjectIDsAfterSearch',
  INIT: 'init',
  SET_USER_TOKEN: 'setUserToken',
};
export const ALGOLIA_EVENTS = {
  BID_SUBMIT_SUCCESS: 'Submit_Bid',
  CLICK_TO_BID: 'click_to_bid',
};

export const BID_SUBMIT_FE_ERROR_MSG = {
  INCREASE_BID: 'Please increase your bid and try again.',
  BIDDING_CLOSED: 'Your bid could not be placed. This lot has ended and is now closed.',
  UNEXPECTED_ERROR: 'Unexpected error while submitting the bid',
};

export const FIXED_DECIMAL_PLACES_CONVERSION = 2;

export const ARTMYN_THUMBNAIL_URL = `https://${getImageEnv()}.invaluable.com/static/invaluable/pdp/ARTMYN_logo_85x20.svg`;

export const ARTMYN_ASSET_DEFAULT_ATTRBS = {
  ref: 'artmyn',
  large: ARTMYN_THUMBNAIL_URL,
  medium: ARTMYN_THUMBNAIL_URL,
  isArtmyn: true,
};
