import axios from 'axios';
import logger from 'routes/middleware/logging/logger';

export const fetchFeaturedLots = async (catalogRefList, hostname) => {
  if (!catalogRefList.length) return [];

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  try {
    const response = await axios.post(`https://${hostname}/api/auctions/catalog/featuredLots`, catalogRefList, options);
    return response.data.sort((a, b) => a.lotNumber - b.lotNumber);
  } catch (error) {
    logger.error('Error fetching featured lots from Invaluable API', error);
    return [];
  }
};

export const fetchAuctionsWithFeaturedLots = async ({ auctionResponse, reqHostname }) => {
  try {
    const featuredLotsAuctions = auctionResponse.data.content
      .flatMap(({ hasFeaturedLots = false, ref }) => (hasFeaturedLots ? ref : []));

    const featuredLots = await fetchFeaturedLots(featuredLotsAuctions, reqHostname);

    const refsObjMap = featuredLots.reduce((accumulator, currentObj) => {
      if (!accumulator[currentObj.catalogRef]) {
        accumulator[currentObj.catalogRef] = [];
      }
      if (accumulator[currentObj.catalogRef].length < 5) {
        accumulator[currentObj.catalogRef].push(currentObj);
      }
      return accumulator;
    }, {});

    const updatedAuctions = auctionResponse.data.content.map(obj1 => {
      const matchingCatalogObjects = refsObjMap[obj1.ref];
      return { ...obj1, featuredLots: matchingCatalogObjects };
    });

    return updatedAuctions;
  } catch (err) {
    logger.error('Error fetching auctions ', err);
  }
};
