import React, { Fragment } from 'react';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { UPCOMING_LOTS_INDEX, algoliaClient } from '../../config';
import PopularItems from './PopularItems';

function PopularSearches() {
  return (
    <Fragment>
      <hr className="divider" />
      <InstantSearch
        indexName={UPCOMING_LOTS_INDEX}
        searchClient={algoliaClient}
        attributesToRetrieve={['query']}
      >
        <Configure
          hitsPerPage={4}
        />
        <PopularItems />
      </InstantSearch>
      <hr className="divider" />
    </Fragment>
  );
}

export default PopularSearches;
