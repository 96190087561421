import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { setModalStatus } from 'src/actions';
import { MODAL_NAMES } from 'src/utils/constants';

import {
  selectSessionInfo,
  selectIsFollowedArtist,
  selectHostname,
} from '../../../selectors/inv-common';
import { setFollowThisArtistStatus } from '../../../thunks/inv-common';
import {
  POST_LOGIN_ACTION_TYPE,
  addPostLoginAction,
  removePostLoginAction,
  getIsPostLoginExistsForPayload,
} from '../../../utils/post-login';
import {
  FOLLOW_UNFOLLOW_ARTIST_BTN_TEXT,
  FOLLOW_UNFOLLOW_ARTIST_BTN_ICON,
} from './constants';

function FollowUnfollowArtistButton({ artistRef, isSecondaryBtn }) {
  const dispatch = useDispatch();
  const hostName = useSelector(selectHostname);
  const { isLoggedIn } = useSelector(selectSessionInfo);
  const isFollowed = useSelector(state => selectIsFollowedArtist(state, artistRef));

  const followUnfollowBtnIconClass = classNames('fa', isFollowed
    ? FOLLOW_UNFOLLOW_ARTIST_BTN_ICON.CHECK : FOLLOW_UNFOLLOW_ARTIST_BTN_ICON.PLUS);
  const followUnfollowButtonText = isSecondaryBtn ? FOLLOW_UNFOLLOW_ARTIST_BTN_TEXT.SECONDARY : FOLLOW_UNFOLLOW_ARTIST_BTN_TEXT.PRIMARY;
  const followUnfollowArtistBtnText = isFollowed
    ? followUnfollowButtonText.FOLLOWING
    : followUnfollowButtonText.FOLLOW;

  const handleFollowUnfollowArtist = () => {
    if (isLoggedIn) {
      dispatch(setFollowThisArtistStatus(artistRef, hostName));
    } else {
      addPostLoginAction({
        type: POST_LOGIN_ACTION_TYPE.FOLLOW_ARTIST,
        payload: artistRef,
      });
      dispatch(setModalStatus({ name: MODAL_NAMES.login, isOpen: true }));
    }
  };

  useEffect(() => {
    if (!isLoggedIn) return;

    if (getIsPostLoginExistsForPayload(artistRef, POST_LOGIN_ACTION_TYPE.FOLLOW_ARTIST)) {
      !isFollowed && dispatch(setFollowThisArtistStatus(artistRef, hostName));
      removePostLoginAction();
    }
  }, [isLoggedIn]);

  return (
    <Button
      className="follow-this-artist-btn"
      color="primary"
      outline={isSecondaryBtn}
      onClick={handleFollowUnfollowArtist}
    >
      <span>
        <i className={followUnfollowBtnIconClass} />
        {followUnfollowArtistBtnText}
      </span>
    </Button>
  );
}

FollowUnfollowArtistButton.defaultProps = {
  isSecondaryBtn: false,
};

FollowUnfollowArtistButton.propTypes = {
  artistRef: PropTypes.string.isRequired,
  isSecondaryBtn: PropTypes.bool,
};

export default FollowUnfollowArtistButton;
