import React, { useState } from 'react';

import MobileFiltersModal from 'src/components/artist-detail/MobileFiltersModal';
import { MOBILE_MEDIA_QUERY } from 'src/components/common/constants';
import { useMedia } from 'src/hooks';

import MobileFilterIcon from './MobileFilterIcon';

export function MobileFilterIconAndModal() {
  const [isMobileFilterModalOpen, setIsMobileFilterModalOpen] = useState(false);
  const isMobile = useMedia(MOBILE_MEDIA_QUERY);

  return isMobile ? (
    <div className="filter-btn-mobile-view">
      <button className="btn border-btn" onClick={() => setIsMobileFilterModalOpen(true)}>
        <MobileFilterIcon />
      </button>
      <MobileFiltersModal
        isModalOpen={isMobileFilterModalOpen}
        closeModal={() => setIsMobileFilterModalOpen(false)}
      />
    </div>
  ) : null;
}
