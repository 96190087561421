import { general as solitaireGeneral } from 'invaluable-solitaire';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { selectSessionInfo } from 'src/selectors/inv-common';
import { formatAmountAndAppendSymbol } from 'src/utils/artist-detail';
import { constants } from '../../../../config';

function LotStatusAndPrice({ hit }) {
  const { isLoggedIn } = useSelector(selectSessionInfo);

  const {
    userItemProperties,
    priceResult,
    currencySymbol,
  } = hit;
  const { displayPrices } = userItemProperties || {};

  const isLotPassed = priceResult === 0;
  const lotStatusToShow = isLotPassed ? 'Passed' : 'Sold: ';
  const shouldDisplayPrice = isLoggedIn && !isLotPassed && displayPrices;
  const soldPrice = formatAmountAndAppendSymbol(priceResult, currencySymbol);

  const renderSubscriptionLink = () => (
    (!displayPrices && !isLotPassed) && (
      <a
        href={constants.PURCHASE_SUBSCRIPTIONS_PATH}
        className="past-link unbold"
      >
        Upgrade to view prices
      </a>
    )
  );

  return (
    <div className="lot-status-and-price-with-link">
      { isLoggedIn
        ? (
          <Fragment>
            <b>{lotStatusToShow}</b>
            { shouldDisplayPrice ? soldPrice : renderSubscriptionLink() }
          </Fragment>
        )
        : (
          <button
            tabIndex={0}
            onClick={() => solitaireGeneral.promptLogin()}
            className="btn btn-link"
          >
            View sold prices
          </button>
        ) }
    </div>
  );
}

LotStatusAndPrice.propTypes = {
  hit: PropTypes.shape(PropTypes.any).isRequired,
};

export default LotStatusAndPrice;
