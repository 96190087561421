import React from 'react';

function RightArrowIcon() {
  return (
    <svg
      width="50"
      height="42"
      viewBox="0 0 50 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.9112 23.5036L31.0441 41.015C30.3741 41.6717 29.4807 42 28.5874 42C27.5824 42 26.689 41.6717 26.019 41.015C24.5673 39.7016 24.5673 37.4033 26.019 36.0899L37.7443 24.4886H3.57342C1.56337 24.4886 0 22.9564 0 20.9863C0 19.1257 1.56337 17.484 3.57342 17.484H37.7443L26.019 5.99218C24.5673 4.67883 24.5673 2.38046 26.019 1.0671C27.359 -0.3557 29.7041 -0.3557 31.0441 1.0671L48.9112 18.5785C50.3629 19.8919 50.3629 22.1902 48.9112 23.5036Z"
        fill="#222222"
      />
    </svg>
  );
}

export default RightArrowIcon;
