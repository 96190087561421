import React from 'react';
import { fetchConsoleLink, openOnNewWindow } from 'inv-common-components';
import { get } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useTimeZoneLocal from 'src/hooks/useTimezoneLocal';
import { pluralizeText } from 'src/utils/common/helpers';
import { AddToCalendarMenuPopover, StatusWrapper } from '../styles';
import { getCalendarLinks, trackClick } from '../utils';

function MyAuction({ auction, notFirst }) {
  const {
    catalogDateUTC,
    catalogStatus,
    userStatus,
    ref,
    catalogTitle,
    houseName,
    catalogType,
  } = auction;
  const inProgress = catalogStatus === 'ACTIVE';
  const isApproved = userStatus === 'APPROVED';
  const isUpcoming = catalogStatus === 'UPCOMING';
  const xAuthToken = useSelector((state) => get(state, 'invReactCommon.sessionInfo.authToken', ''));
  const hostname = useSelector((state) => get(state, 'invReactCommon.hostname', ''));
  const { convertedDate, userTimeZone } = useTimeZoneLocal(catalogDateUTC, false, inProgress);
  const event = {
    title: `${catalogTitle} by ${houseName}`,
    start: catalogDateUTC,
    duration: [4, 'hours'],
    url: `https://${hostname}/catalog/${ref}`,
    description: 'Invaluable auction',
    location: `https://${hostname}/catalog/${ref}`,
  };

  const liveConsoleLink = async () => {
    const channelID = 1;

    try {
      const consoleUrlResponse = await fetchConsoleLink({ catalogRef: ref, xAuthToken, channelID });
      openOnNewWindow(consoleUrlResponse.data.consoleUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const getDateTimeSection = () => {
    const currentDate = moment.utc(new Date());
    const eventDate = moment(catalogDateUTC);
    const daysRemaing = eventDate.diff(currentDate, 'days');
    if (daysRemaing === 0) {
      return (
        <span className="auctioneer auction-status">
          Live Today
        </span>
      );
    }
    if (daysRemaing < 7) {
      return (
        <span className="auctioneer auction-status">
          {daysRemaing} {pluralizeText(daysRemaing, 'Day')} left
        </span>
      );
    }
    return (
      <span className="auctioneer">
        {convertedDate} {userTimeZone}
      </span>
    );
  };

  const getAuctionButton = () => {
    if (catalogType === 'LIVE') {
      return (
        <button
          className="btn btn-live full-width my-auction-tag"
          type="button"
          onClick={liveConsoleLink}
        >Enter Live Auction
        </button>
      );
    }
    return (
      <a href={`/catalog/${ref}`} className="btn btn-live full-width">
        Happening Now
      </a>
    );
  };

  return (
    <div className={`my-auctions ${notFirst ? 'space-top' : ''}`}>
      <div>
        {auction.inProgress ? <p className="auction-status">Live Now</p> : getDateTimeSection()}
        {isApproved ? (
          <StatusWrapper className="approval-status">
            <i className="fa fa-check" />
            Approved
          </StatusWrapper>
        ) : (
          <StatusWrapper className="approval-status pending">
            <i className="fa fa-refresh" />
            Pending
          </StatusWrapper>
        )}
      </div>
      <a href={`/catalog/${ref}`} onClick={() => trackClick('My Auction')} className="title my-auction-tag">{catalogTitle}</a>
      <p className="auctioneer">by {houseName}</p>
      {inProgress && getAuctionButton()}
      {isUpcoming && (
      <>
        <button
          className="calendar-links-btn btn btn-link"
          type="button"
          id={`AUCTION-CALENDER-TOOLTIP-${ref}`}
        >
          <i className="fa fa-calendar" /> Add to calendar
        </button>
        <AddToCalendarMenuPopover
          placement="bottom"
          target={`AUCTION-CALENDER-TOOLTIP-${ref}`}
          trigger="focus hover"
          className="showPopoverV3 popover fade bottom in"
        >
          <ul>
            {
                getCalendarLinks(event, ref).map(({ calendarURL, calendarType }) => (
                  <a
                    href={calendarURL}
                    target="_blank"
                    rel="noreferrer"
                    key={`event-${calendarType}`}
                  >
                    {calendarType}
                  </a>
                ))
            }
          </ul>
        </AddToCalendarMenuPopover>
      </>
      )}
      {!isApproved && <p className="call">*Call to get approved: +1 (740) 362 4771</p>}
    </div>
  );
}

export default MyAuction;
