import qs from 'qs';
import get from 'lodash/get';
import { urls as solitaireUrls } from 'invaluable-solitaire';

import { PRICE_RANGE_ATTR } from 'src/utils/artist-detail/constants';
import { DEFAULT_PAGINATION_PAGE_NUMBER } from 'src/utils/constants';
import { routes } from '../../../config';

import {
  ARTIST_LOTS_TABS,
  DEFAULT_NUM_OF_LOTS_PER_PAGE,
  SORT_BY_QUERY_PARAM_TO_INDEX,
  SORT_BY_INDEX_TO_QUERY_PARAM,
} from './constants';

/**
 * Retrieves the price range for a given display mode.
 * @param {object} state - The current search state.
 * @returns {object} The price range for the given display mode.
 */
export const getPriceRangeBySelectedTab = (state, selectedTab) => {
  const priceRangeAttribute = PRICE_RANGE_ATTR[selectedTab];
  const priceRange = get(state, `range.${priceRangeAttribute}`);
  return priceRange;
};

/**
 * returns updated queryparameters based on refinement list
 * @param {object} refinementList algolia refinementList
 * @returns {object} updated query params based on refinement list
 */
const getRifinementsQueryParams = (refinementList) => {
  const queryParameters = {};
  if (refinementList) {
    Object.keys(refinementList).forEach((key) => {
      if (refinementList[key]) {
        queryParameters[key] = Array.isArray(refinementList[key])
          ? refinementList[key].map(encodeURIComponent)
          : [refinementList[key]].filter(Boolean);
      }
    });
  }

  return queryParameters;
};

/**
 * returns updated queryparameters with dateTimeUTCUnix and dateType strings
 * @param {object} range algoli range from which to get date range
 * @param {string} dateTypeParam to get selected date filter option
 * @returns {object} updated query params
 */
const getDateFilterQueryParams = (range, dateTypeParam) => {
  const queryParameters = {};
  if (range && range.dateTimeUTCUnix) {
    queryParameters.dateTimeUTCUnix = range.dateTimeUTCUnix;
  }

  if (dateTypeParam) {
    if (range && range.dateTimeUTCUnix) {
      queryParameters.dateType = encodeURIComponent(dateTypeParam);
      const { min, max } = range.dateTimeUTCUnix;
      if (!min && !max) {
        queryParameters.dateType = undefined;
      }
    }
  }

  return queryParameters;
};

/**
 * Returns value for sort query param against the selected sorter
 * @param {string} sortByIndex
 * @param {boolean} isUpcoming
 * @param {boolean} isProd
 * @returns string, value of sort query param
 */
const getSortByQueryParamFromIndex = (sortByIndex, isUpcoming, isProd) => {
  const {
    PAST: PAST_QUERY_PARAMS,
    UPCOMING_PROD: UPCOMING_PROD_QUERY_PARAMS,
    UPCOMING_STAGE: UPCOMING_STAGE_QUERY_PARAMS,
  } = SORT_BY_QUERY_PARAM_TO_INDEX;

  if (isUpcoming) return isProd ? UPCOMING_PROD_QUERY_PARAMS[sortByIndex] : UPCOMING_STAGE_QUERY_PARAMS[sortByIndex];

  return PAST_QUERY_PARAMS[sortByIndex];
};

/**
 * Return the algolia index against the given value of sort query param
 * @param {string} sortByParam
 * @param {boolean} isUpcoming
 * @param {boolean} isProd
 * @returns string, sort by index for the sort query param
 */
const getSortByIndexFromQueryParam = (sortByParam, isUpcoming, isProd) => {
  const {
    PAST: PAST_INDEXES,
    UPCOMING_PROD: UPCOMING_PROD_INDEXES,
    UPCOMING_STAGE: UPCOMING_STAGE_INDEXES,
  } = SORT_BY_INDEX_TO_QUERY_PARAM;

  if (isUpcoming) return isProd ? UPCOMING_PROD_INDEXES[sortByParam] : UPCOMING_STAGE_INDEXES[sortByParam];

  return PAST_INDEXES[sortByParam];
};

export const createArtistDetailURLFromSearchState = (
  state,
  pathname,
  location,
  isProd,
  isShowPageNumInURL,
  selectedTab
) => {
  const { page, refinementList, range, dateType, sortBy } = state;
  let queryParameters = {};

  if (isShowPageNumInURL && page > DEFAULT_PAGINATION_PAGE_NUMBER) {
    queryParameters.page = page;
  }

  if (sortBy) {
    const isUpcoming = selectedTab === ARTIST_LOTS_TABS.UPCOMING;

    queryParameters.sort = getSortByQueryParamFromIndex(sortBy, isUpcoming, isProd);
  }

  if (state.toggle && state.toggle.hasImage) {
    queryParameters.hasImage = state.toggle.hasImage;
  }

  if (state.menu && state.menu.currencyCode) {
    queryParameters.currencyCode = state.menu.currencyCode;
  }

  const priceRange = getPriceRangeBySelectedTab(state, selectedTab);

  if (priceRange) {
    queryParameters.priceRange = priceRange;
  }

  queryParameters = {
    ...queryParameters,
    ...getRifinementsQueryParams(refinementList),
    ...getDateFilterQueryParams(range, dateType),
  };

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  const { hostname } = location;
  const domain = `https://${hostname}`;

  return `${domain}${pathname}${queryString}`;
};

export const artistURLToSearchState = (search, isProd, selectedTab) => {
  const queryParameters = (typeof search === 'object') ? search : qs.parse(search.slice(1), { delimiter: /[&&,?]/ });
  const {
    page,
    sort,
    hasImage = false,
    currencyCode = '',
    priceRange = {},
    supercategoryName = [],
    dateType = '',
    dateTimeUTCUnix = {},
    houseName = [],
    countryName = [],
    ...rest
  } = queryParameters;

  const superCategories = Array.isArray(supercategoryName) ? supercategoryName : [supercategoryName].filter(Boolean);
  const key = Object.keys(rest);
  const innerCategories = Array.isArray(rest[key]) ? rest[key] : [rest[key]].filter(Boolean);
  const houseNames = Array.isArray(houseName) ? houseName : [houseName].filter(Boolean);
  const countryNames = Array.isArray(countryName) ? countryName : [countryName].filter(Boolean);
  const isUpcoming = selectedTab === ARTIST_LOTS_TABS.UPCOMING;
  const priceRangeAttribute = PRICE_RANGE_ATTR[selectedTab];

  return {
    ...(page ? { page } : {}),
    ...(hasImage && { toggle: { hasImage } }),
    ...(sort && { sortBy: getSortByIndexFromQueryParam(sort, isUpcoming, isProd) }),
    refinementList: {
      supercategoryName: superCategories.map(decodeURIComponent),
      houseName: houseNames.map(decodeURIComponent),
      countryName: countryNames.map(decodeURIComponent),
      ...([key] ? { [key]: innerCategories.map(decodeURIComponent) } : {}),
    },
    dateType: decodeURIComponent(dateType),
    range: {
      dateTimeUTCUnix,
      [priceRangeAttribute]: priceRange
    },
    menu: { currencyCode },
  };
};

export const searchStateToArtistDetailUrl = (searchState, pathname, location, isProd, isShowPageNumInURL, selectedTab) => {
  if (!searchState) return '';

  const path = createArtistDetailURLFromSearchState(searchState, pathname, location, isProd, isShowPageNumInURL, selectedTab);
  const url = new URL(path);

  return `${url.pathname}${url.search}`;
};

export const formatAmountAndAppendSymbol = (amount, currencySymbol) => {
  if (!amount) return '';

  return currencySymbol ? `${currencySymbol}${amount.toLocaleString()}` : amount.toLocaleString();
};

/**
 * return lots count per page for a given display mode
 * @param {string} lotsDisplayMode display mode
 * @returns {number} return lots count per page
 */
export const getDefaultNumberOfLotsPerPage = (lotsDisplayMode) => DEFAULT_NUM_OF_LOTS_PER_PAGE[lotsDisplayMode];

/**
 * Gets the page title based on the current page number, artist display name and selectedTab
 * @param {string|number} page - The current page number
 * @param {string} artistDispalyName - The current artist display name
 * @param {string} selectedTab - The current selected tab. The selected tab arg if present can be upcoming/past
 * @returns {string} The page title to display in the title tag
 */
export const getPageTitle = (page, artistDisplayName, selectedTab) => {
  const pageNumber = page && page > DEFAULT_PAGINATION_PAGE_NUMBER ? `Page ${page}: ` : '';
  const selectedTabTitle = selectedTab === ARTIST_LOTS_TABS.UPCOMING
    ? `${pageNumber}${artistDisplayName} Art for Sale at Auction`
    : `${pageNumber}${artistDisplayName} Paintings & Artwork for Sale | ${artistDisplayName} Art Value Price Guide`;

  const pageTitle = selectedTab
    ? selectedTabTitle
    : `${artistDisplayName} Artwork for Sale at Online Auction | ${artistDisplayName} Biography & Info`;

  return pageTitle;
};

/**
 * this function returns artist detail page path
 * @param {string} firstName - First name
 * @param {string} lastName - Last name
 * @param {string} artistRef - Artist reference
 * @returns {string} seo friendly artist detail path
 */
export const getArtistSeoFriendlyPath = ({ firstName = '', lastName = '', artistRef }) => {
  const firstNamePart = firstName && `${solitaireUrls.seoFriendlyUrl(firstName)}-`;
  const lastNamePart = lastName && `${solitaireUrls.seoFriendlyUrl(lastName)}-`;

  let artistUrl = `${routes.ARTIST}/${lastNamePart}${firstNamePart}${solitaireUrls.seoFriendlyUrl(artistRef)}/`;
  artistUrl = artistUrl.replace(/--+/g, '-');

  return artistUrl;
};

/**
 * return page heading based on page loaded
 * @param {string} selectedTab - selected tab(upcoming, past)
 * @param {string} artistName - Name of the artist
 * @returns {string} page heading
 */
export const getArtistHeading = (selectedTab, artistName) => {
  let headingText = `${artistName} Art for Sale and Sold Prices`;

  if (selectedTab) {
    headingText = `${artistName} ${selectedTab === ARTIST_LOTS_TABS.UPCOMING ? 'Art for Sale at Auction' : 'Sold at Auction Prices'}`;
  }

  return headingText;
};

/**
 * return array of past and upcoming data with required order
 * @param {Object} upcomingData contains lotsToDisplay and totalHits
 * @param {Object} pastData contains lotsToDisplay and totalHits
 * @returns {Array} return array of past and upcoming data
 */

export const getPastAndUpcomingLotsCarouselsOrder = (upcomingData, pastData) => {
  const pastLotsProps = { key: 'past', carouselData: pastData, isUpcoming: false };
  const upcomingLotsProps = { key: 'upcoming', carouselData: upcomingData, isUpcoming: true };
  if (upcomingData.totalHits === 0 && pastData.totalHits !== 0) {
    return [pastLotsProps, upcomingLotsProps];
  }
  return [upcomingLotsProps, pastLotsProps];
};
