import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import loadable from '@loadable/component';

import { setArtistDateFilter } from 'src/actions';
import { selectArtistDateFilter } from 'src/selectors/artist-detail';
import { useSelectedArtistDetailTab } from 'src/hooks';
import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';

import AuctionDateRange from 'src/components/common/algolia/date-range';
import { AUCTION_DATE_FILTER_OPTIONS, FILTERS_META } from './constants';
// const AuctionDateRange = loadable(() => import(
//   /* webpackChunkName: "AuctionDateRange" */
//   'src/components/common/algolia/date-range'
// ));

export const AuctionDateFilter = memo(() => {
  const dispatch = useDispatch();
  const selectedTab = useSelectedArtistDetailTab();
  const dateFilter = useSelector(selectArtistDateFilter);

  const { AUCTION_DATE: AUCTION_DATE_META } = FILTERS_META;
  const isUpcoming = selectedTab === ARTIST_LOTS_TABS.UPCOMING;

  const handleDateFilterChange = (value) => {
    dispatch(setArtistDateFilter({ date: value }));
  };

  return (
    <AuctionDateRange
      attribute={AUCTION_DATE_META.ATTR}
      title={AUCTION_DATE_META.TITLE}
      upcoming={isUpcoming}
      filterOptions={AUCTION_DATE_FILTER_OPTIONS[selectedTab]}
      dateFilter={dateFilter}
      setDateFilter={handleDateFilterChange}
    />
  );
});
