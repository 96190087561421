/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';

import { REST_RESPONSE_STATUS_CODES } from './constants';

function isResponseErroneous(response) {
  return response instanceof Error;
}

export const prepareGenericResponseForRESTClient = (response) => {
  const isErroneous = isResponseErroneous(response);

  if (!isErroneous && response.status === REST_RESPONSE_STATUS_CODES.SUCCESS) {
    return {
      data: response.data,
      statusCode: REST_RESPONSE_STATUS_CODES.SUCCESS,
      statusMessage: 'Request processed successfully',
      success: true,
    };
  }

  return {
    data: get(response, 'response.data', {}),
    statusCode: get(response, 'response.status', REST_RESPONSE_STATUS_CODES.FAIL),
    statusMessage: get(response, 'message', ''),
    success: false,
  };
};
