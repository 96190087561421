import React, { Fragment, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import { JSONLD, Generic } from 'react-structured-data';

import {
  SimilarArtists,
  ArtistOverview,
  HousesSellingWorkByArtist,
} from 'src/components/artist-detail';
import StickyFooter from 'src/components/artist-detail/StickyFooter';
import PopularArtists from 'src/components/common/PopularArtists';
import { useSelectedArtistDetailTab } from 'src/hooks';
import {
  selectArtistBioInfo,
  selectCurrentPageNumber,
  selectHousesSellingWorkByArtist,
} from 'src/selectors/artist-detail';
import { getPageTitle } from 'src/utils/artist-detail';

function ArtistDetailWrapper({ children }) {
  const { bio, displayName } = useSelector(selectArtistBioInfo);
  const pageNumber = useSelector(selectCurrentPageNumber);
  const housesSellingWorkByArtist = useSelector(selectHousesSellingWorkByArtist);
  const selectedTab = useSelectedArtistDetailTab();

  const followArtistBtnRef = useRef(null);

  const locationParams = typeof window !== 'undefined' ? window.location.search : '';

  useEffect(() => {
    const updatedPageNumber = locationParams.includes('page') ? pageNumber : '';
    document.title = getPageTitle(updatedPageNumber, displayName, selectedTab);
  }, [pageNumber, selectedTab, locationParams]);

  return (
    <Fragment>
      <JSONLD
        dangerouslyExposeHtml
      >
        <Generic
          type="person"
          jsonldtype="Person"
          schema={{
            name: displayName,
            description: bio,
          }}
        />
      </JSONLD>
      <div className="artist-page-holder">
        <Container fluid>
          <ArtistOverview followArtistBtnRef={followArtistBtnRef} />
          { children }
          <SimilarArtists />
          <PopularArtists />
          { housesSellingWorkByArtist.length > 0 && <HousesSellingWorkByArtist houses={housesSellingWorkByArtist} /> }
        </Container>
      </div>
      <StickyFooter followArtistBtnRef={followArtistBtnRef} />
    </Fragment>
  );
}

ArtistDetailWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ArtistDetailWrapper;
