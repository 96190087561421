import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { UncontrolledPopover } from 'reactstrap';
import { urls as solitaireUrls } from 'invaluable-solitaire';

function CategoryPopover({ targetId, linkData, viewAllTitle, titleHref }) {
  const windowWidth = useSelector((state) => get(state, 'invReactCommon.windowWidth', 1326));
  return (
    <UncontrolledPopover
      placement="bottom"
      target={targetId}
      trigger="focus hover"
      popperClassName={`nav-popover ${(linkData.length > 8 && targetId !== 'superCategory') ? 'double-list' : ''}`}
      innerClassName="popover-body clearfix"
    /*
    * This is a hack to get the popover to show up on the bottom of the container
    known bug with reactstrap https://github.com/reactstrap/reactstrap/issues/1613
    */
      flip={false}
      boundariesElement="window"
    >
      <ul className="list-unstyled">
        {viewAllTitle && (
        <li className="view-all" key={viewAllTitle}>
          <a
            href={titleHref}
          >
            View All {viewAllTitle}
          </a>
        </li>
        )}
        {linkData.map((link, index) => {
          if (index < 8 && targetId !== 'superCategory') {
            return (
              <li key={link.ref}>
                <a
                  href={
                `/${solitaireUrls.seoFriendlyUrl(link.categoryName)}${link.isParent ? '/pc' : '/cc'}-${link.categoryRef}/`
            }
                >{link.categoryName}
                </a>
              </li>
            );
          } if (targetId === 'superCategory') {
            if (
              (link.categoryName === 'Asian Art & Antiques' && windowWidth > 1325)
              || (link.categoryName === 'Collectibles' && windowWidth > 1140)
              || (link.categoryName === 'Decorative Art' && windowWidth > 820)
              || (link.categoryName === 'Furniture' && windowWidth > 1200)
              || (link.categoryName === 'Jewelry' && windowWidth > 1035)
              || (link.categoryName === 'Fine Art')
            ) {
              return;
            }
            return (
              <li key={link.ref}>
                <a
                  href={
                `/${solitaireUrls.seoFriendlyUrl(link.categoryName)}${link.isParent ? '/pc' : '/cc'}-${link.categoryRef}/`
            }
                >{link.categoryName}
                </a>
              </li>
            );
          }
          return;
        })}
        {targetId === 'superCategory' && <li className="view-all"><a href="/inv/how-to-bid/">Learn How to Bid</a></li>}
      </ul>
      {(linkData.length > 8 && targetId !== 'superCategory') && (
      <ul className="list-unstyled">
        {linkData.map((link, index) => {
          if (index >= 8) {
            return (
              <li key={link.ref}>
                <a
                  href={
                `/${solitaireUrls.seoFriendlyUrl(link.categoryName)}${link.isParent ? '/pc' : '/cc'}-${link.categoryRef}/`
            }
                >{link.categoryName}
                </a>
              </li>
            );
          }
          return;
        })}
      </ul>
      )}
    </UncontrolledPopover>
  );
}

export default CategoryPopover;
