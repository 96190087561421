import React from 'react';
import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-dom';

function StateResults({ searchResults, children }) {
  const hasResults = searchResults && !!searchResults.index && searchResults.nbHits === 0;

  return !hasResults ? children
    : <div className="search-results">We couldn’t find any results for those parameters. Please try removing some filters.</div>;
}

StateResults.propTypes = {
  searchResults: PropTypes.shape({ nbHits: PropTypes.number.isRequired }).isRequired,
  children: PropTypes.node.isRequired,
};

export default connectStateResults(StateResults);
