import get from 'lodash/get';

import { ASYNC_ACTION_STATUS_TYPE, ASYNC_ACTION, PLACE_BID_NESTED_FORMS } from '../../utils/pdp/constants';
import { getAuctionHouseURL } from '../../utils/pdp/helpers';

import { getSortedCountries } from './helpers';

export const selectIsPDPDataLoaded = state => !!state.pdp.lotData
  && !!state.pdp.catalogData && !!state.pdp.auctionHouseData;

export const selectIsProd = state => get(state, 'pdp.isProd');

export const selectArtistInfo = state => get(state, 'pdp.artistInfo');

export const selectLotTitle = state => get(state, 'pdp.lotData.lotTitle');

export const selectLotNumber = state => get(state, 'pdp.lotData.lotNumber');

export const selectLotRef = state => get(state, 'pdp.lotData.lotRef');

export const selectIsReserveMet = state => get(state, 'pdp.lotData.isReserveMet', undefined);

export const selectHasReserve = state => get(state, 'pdp.lotData.hasReserve', false);

export const selectIsLotNotified = state => get(state, 'pdp.lotData.isLotNotified', false);

export const selectAddToWatchlistStatus = state => get(state, `pdp.asyncStatus.${ASYNC_ACTION.ADD_TO_WATCHLIST_STATUS}`, ASYNC_ACTION_STATUS_TYPE.IDLE);

export const selectRemoveToWatchlistStatus = state => get(state, `pdp.asyncStatus.${ASYNC_ACTION.REMOVE_TO_WATCHLIST_STATUS}`, ASYNC_ACTION_STATUS_TYPE.IDLE);

export const selectFollowSellerStatus = state => get(state, `pdp.asyncStatus.${ASYNC_ACTION.FOLLOW_SELLER_STATUS}`, ASYNC_ACTION_STATUS_TYPE.IDLE);

export const selectContactSellerStatus = state => get(state, `pdp.asyncStatus.${ASYNC_ACTION.CONTACT_SELLER_STATUS}`, ASYNC_ACTION_STATUS_TYPE.IDLE);

export const selectIsFollowingSeller = state => get(state, 'pdp.isFollowingSeller');

export const selectPdpBidderApprovalStatus = state => get(state, 'pdp.bidderStatus.approvalStatus', '');

export const selectLotLiveInfo = state => get(state, 'pdp.lotLiveInfo');

export const selectAuctionHouseData = state => get(state, 'pdp.auctionHouseData');

export const selectSellerFollowCount = state => get(state, 'pdp.sellerFollowCount', 0);

export const selectLotId = state => get(state, 'pdp.lotData.lotId');

export const selectIsUserVIP = state => get(state, 'pdp.isUserVip');

export const selectBreadCrumbs = state => state.pdp.breadCrumbs;

export const selectCatalogRef = state => state.pdp.catalogRef;

export const selectLotPhotos = state => get(state, 'pdp.lotData.photos', []);

export const selectLotData = state => state.pdp.lotData;

export const selectLiveWatchersCount = state => state.pdp.catalogData.liveWatchersCount;

export const selectBidData = state => state.pdp.bidData;

export const selectSellerInfo = state => state.pdp.sellerInfo;

export const selectPDPIsLoading = state => state.pdp.isLoading;

export const selectSelectedBidIncrement = state => state.pdp.selectedBidIncrement;

export const selectSelectedBidAmount = state => state.pdp.selectedBidAmount;

export const selectBidInfo = state => state.pdp.bidInfo;

export const selectBidSubmitResponse = state => state.pdp.bidSubmitResponse;

export const selectAuctionData = (state) => {
  const catalogData = get(state, 'pdp.catalogData', {});
  const auctionHouseData = get(state, 'pdp.auctionHouseData', {});
  const isTimedLotClosed = get(state, 'pdp.lotData.isLotClosed', false);
  return {
    catalogId: catalogData.id,
    catalogTitle: catalogData.title,
    date: catalogData.date,
    eventDate: catalogData.eventDate,
    timeZoneRegion: catalogData.timeZoneRegion,
    timeZone: catalogData.timeZone,
    isLive: catalogData.isLive,
    isTimed: catalogData.isTimed,
    isUpcoming: catalogData.isTimed && isTimedLotClosed ? false : catalogData.isUpcoming,
    isViewOnly: catalogData.isViewOnly,
    isAuctionInProgress: catalogData.isAuctionInProgress,
    house: {
      name: auctionHouseData.name,
      address: auctionHouseData.address,
      ref: auctionHouseData.ref,
    },
  };
};

export const selectPaymentAndTermsDetail = state => state.pdp.paymentAndTermsDetail;

export const selectIsPaymentAndTermsDetailDataAvailable = state => get(state, 'pdp.paymentAndTermsDetail.isDataAvailable', false);

export const selectDisplayParityBanner = state => get(state, 'pdp.catalogData.displayParityBanner', false);

export const selectHouseExtensionTime = state => get(state, 'pdp.catalogTermsData.houseExtensionTime', '');

export const selectPayableBuyersPremium = state => get(state, 'pdp.catalogTermsData.payableBP', '');

export const selectIsCatalogTermsDataAvailable = state => !!get(state, 'pdp.catalogTermsData.payableBP', '') && !!get(state, 'pdp.catalogTermsData.houseExtensionTime', '');

export const selectStaticBreadCrumbs = (state) => {
  const { catalogData, auctionHouseData: sellerData } = state.pdp;
  return catalogData && sellerData && [
    { name: 'Auction Houses', URL: 'auction-house' },
    { name: sellerData.name, URL: getAuctionHouseURL(sellerData.name, sellerData.ref) },
    { name: catalogData.title, URL: `catalog/${catalogData.ref.toLowerCase()}` },
  ];
};

export const selectShippingAddress = state => get(state, 'pdp.contactInfo.shippingAddress', {});

export const selectBillingAddress = state => get(state, 'pdp.contactInfo.billingAddress', {});

export const selectSortedCountries = state => getSortedCountries(get(state, 'pdp.contactInfo.countries'));

export const selectSmsOptIn = state => get(state, `pdp.contactInfo.${PLACE_BID_NESTED_FORMS.SMS_OPT_IN}`);

export const selectFormErrors = state => get(state, 'pdp.contactInfo.formErrors');

export const selectUserContactInfo = state => get(state, 'pdp.contactInfo');

export const selectShowRFA = state => get(state, 'pdp.approvalInfo.showRFA');

export const selectCreditCardsInfo = state => get(state, 'pdp.ccInfo');

export const selectUserCreditCards = state => get(state, 'pdp.ccInfo.userCreditCards');

export const selectAreBillingShippingAddressSame = state => get(state, 'pdp.areBillingShippingAddressSame');

export const selectAvailableCreditCardTypes = state => get(state, 'pdp.ccInfo.availableCreditCardTypes');

export const selectShowCreditCardSection = (state) => {
  const isShowCreditCardSection = get(state, 'pdp.ccInfo.showCreditCardSection');
  const hasPaymentProcessing = get(state, 'pdp.ccInfo.hasPaymentProcessing');
  return isShowCreditCardSection || hasPaymentProcessing;
};

export const selectWatchListCallFailed = (state) => {
  const { addToWatchListCallFailed, removeToWatchListCallFailed } = state.pdp.errors;
  return addToWatchListCallFailed || removeToWatchListCallFailed;
};

export const selectFollowUnfollowSellerCallFailed = state => state.pdp.errors.followUnFollowSellerCallFailed;

export const selectFetchCountryStatesCallFailed = state => state.pdp.errors.fetchCountryStatesCallFailed;

export const selectPlaceBidCallsFailed = (state) => {
  const { contactInfoCallFailed, requestForApprovalInitCallFailed, updateBidTotalCallFailed } = state.pdp.errors;
  return contactInfoCallFailed || requestForApprovalInitCallFailed || updateBidTotalCallFailed;
};

export const selectSubscriptionInfo = state => get(state, 'pdp.subscriptionInfo');

export const selectAlgoliaParams = state => get(state, 'pdp.algoliaParams', {});

export const selectCatalogData = state => get(state, 'pdp.catalogData', {});

export const selectConciergeStatus = state => get(state, 'pdp.conciergeStatus', false);

export const selectConciergeModalProps = state => get(state, 'pdp.conciergeModalProps', {});

export const selectStatusCode = state => get(state, 'pdp.statusCode', 200);
