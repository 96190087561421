export const ALGOLIA_INDEX = {
  UPCOMING_PROD: 'upcoming_lots_prod',
  UPCOMING_NON_PROD: 'upcoming_lots_stage',
  PAST_PROD: 'archive_prod',
};

export const ARTISTS_DEFAULT_INDEX = {
  PROD: 'artists_default_prod',
  STAGE: 'artists_default_stage',
};
