import React, { useState, useEffect } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import Cross from './components/icons/Cross';
import ClickOutside from './components/clickOutside';
import SuggestionsDropdownV2 from './suggestionsDropdownV2';
import { saveRecentItem } from '../config';
import useFetchAuctions from '../hooks/useFetchAuctions';

function AutoCompleteSearchBar({ currentRefinement, refine, hits, isMobile }) {
  const [auctions] = useFetchAuctions(hits);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(currentRefinement);

  const onChange = (event) => {
    const val = event.currentTarget.value;
    setValue(val);
    refine(val);
  };

  const onOpen = (event) => {
    const val = event.currentTarget.value;
    if (val) {
      setValue(val);
      refine(val);
    }
    setIsOpen(true);
  };

  const onClick = () => {
    saveRecentItem(value);
    window.location.href = `/search?query=${value}&keyword=${value}`;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  const onClickClose = () => {
    setValue('');
    refine('');
    setIsOpen(false);
  };

  useEffect(() => {
    const queryString = window.location.search && new URLSearchParams(window.location.search);
    let keyword = queryString && queryString.get('keyword');
    if (keyword === null) {
      keyword = '';
    }

    setValue(decodeURIComponent(keyword));
  }, []);

  return (
    <ClickOutside onClickOutside={() => setIsOpen(false)}>
      <div
        id="search-container"
        role="combobox"
        aria-haspopup="listbox"
        aria-owns="suggestionDropdown"
        aria-controls="suggestionDropdown"
        aria-expanded="true"
        className="search-container-v2"
      >
        <a
          href="/catalog/advancedSearch.cfm?srchScope=u"
          className={`notranslate ${isOpen ? 'advanced-link-margin' : ''}`}
        >Advanced
        </a>
        <div className="input-group-wrapper">
          <div className="search-input">
            <i className="fa fa-search" />
            <input
              aria-label="Search by item, artists, sellers, or category"
              id={(isMobile) ? 'searchItemsNew_m' : 'searchItemsNew'}
              onChange={onChange}
              onClick={onOpen}
              onFocus={onOpen}
              type="text"
              value={value}
              placeholder="Search by item, artists, sellers, or category"
              onKeyDown={handleKeyDown}
              autoComplete="off"
              aria-autocomplete="list"
              aria-controls="suggestionDropdown"
            />
            {
            isOpen && (
            <button
              onClick={onClick}
              tabIndex="-1"
              type="button"
            >
              <i className="fa fa-arrow-right" />
            </button>
            )
          }
          </div>
          <div className="suggestion-container" />
          {isOpen ? (
            <button
              aria-label="Close suggestion dropdown"
              className="close-btn"
              type="button"
              onClick={onClickClose}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setIsOpen(false);
                }
              }}
            >
              <Cross />
            </button>
          ) : null}
          {isOpen && (
          <SuggestionsDropdownV2
            value={value}
            hits={hits}
            catalogDetails={auctions}
            isMobile={isMobile}
          />
          )}
        </div>
      </div>
    </ClickOutside>
  );
}

export default connectAutoComplete(AutoCompleteSearchBar);
