import axios from 'axios';
import { stringify } from 'query-string';

import { prepareGenericResponseForRESTClient } from './helpers';

// eslint-disable-next-line import/prefer-default-export
export class RESTFulService {
  /**
   * A method that returns the API header information
   * @param options other metadata associated with the API request
   * @returns returns the API header information
   */
  static getHeaders = (options = {}) => {
    let headers = {
      'Content-Type': 'application/json',
    };

    if (options.headers) {
      // customise headers
      const { headers: customHeaders } = options;
      headers = { ...headers, ...customHeaders };
    }

    return headers;
  };

  /**
   * Sends a get request to the provided url using axios.get
   * @param url rest endpoint url
   * @param queryParams request payload
   * @param options other metadata associated with the API request
   * @returns returns the API response from backend
   */
  get = async (url, params = {}) => {
    const { queryParams, options } = params;
    const headers = RESTFulService.getHeaders(options);

    try {
      const response = await axios.get(url, {
        params: queryParams,
        paramsSerializer: param => stringify(param),
        headers,
      });
      return prepareGenericResponseForRESTClient(response);
    } catch (error) {
      return prepareGenericResponseForRESTClient(error);
    }
  };

  /**
   * Sends a POST request to provided url using axios.post
   * @param url rest endpoint url
   * @param data data request payload
   * @param options other metadata associated with the API request
   * @returns returns the API response from backend
   */
  post = async (url, params = {}) => {
    const { data, options } = params;
    const headers = RESTFulService.getHeaders(options);

    try {
      const response = await axios.post(url, data, { headers });
      return prepareGenericResponseForRESTClient(response);
    } catch (error) {
      return prepareGenericResponseForRESTClient(error);
    }
  };
}
