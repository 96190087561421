import logger from 'routes/middleware/logging/logger';
import axios from 'axios';
import get from 'lodash/get';
import { fullPageLoadingSpinnerToggle } from 'src/utils/common/helpers';
import { MODAL_NAMES } from 'src/utils/constants';
import { routes } from '../../config';
import {
  FETCH_SESSION_INFO_SUCCESS,
  FETCH_SESSION_INFO_ERROR,
  FETCH_FOLLOW_THIS_ARTIST_SUCCESS,
  FETCH_FOLLOW_THIS_ARTIST_ERROR,
  SET_FOLLOW_THIS_ARTIST_SUCCESS,
  SET_FOLLOW_THIS_ARTIST_ERROR,
  SET_USER_POPULAR_ARTISTS_DATA,
  SET_IS_PROD_ENV,
  SET_HOST_NAME,
  SET_USER_COUNTRY_CODE,
  ADD_CONVERSION_RATE,
  FETCH_SIMILAR_ARTISTS_DATA_SUCCESS,
  FETCH_SIMILAR_ARTISTS_DATA_ERROR,
  FETCH_USER_ARTIST_FOLLOW_STATUS_SUCCESS,
  FETCH_USER_ARTIST_FOLLOW_STATUS_ERROR,
  SET_IS_MOBILE_USER_AGENT,
  FETCH_HEADER_LOGIN_SUCCESS,
  FETCH_HEADER_LOGIN_ERROR,
  FETCH_HEADER_LOGIN_LOADING,
  FETCH_HEADER_SIGNUP_SUCCESS,
  FETCH_HEADER_SIGNUP_ERROR,
  FETCH_HEADER_SIGNUP_LOADING,
  FETCH_IS_VIP_STATUS_SUCCESS,
  FETCH_IS_VIP_STATUS_ERROR,
  FETCH_USER_COUNTS_SUCCESS,
  FETCH_USER_COUNTS_ERROR,
  SET_SEARCH_TYPE_AHEAD_COOKIE,
  SET_MY_INVALUABLE_COOKIE,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  SET_MODAL_STATUS,
  SET_POST_LOGIN_REDIRECT_URL,
  SET_WINDOW_WIDTH,
} from './constants';

export const setModalStatus = (payload) => ({
  type: SET_MODAL_STATUS,
  payload,
});

export const fetchSessionInfoSuccess = sessionInfo => ({
  type: FETCH_SESSION_INFO_SUCCESS,
  payload: sessionInfo,
});

export const fetchSessionInfoError = {
  type: FETCH_SESSION_INFO_ERROR,
};

export const fetchFollowThisArtistSuccess = followThisArtistStatus => ({
  type: FETCH_FOLLOW_THIS_ARTIST_SUCCESS,
  payload: followThisArtistStatus,
});

export const setFollowThisArtistSuccess = followThisArtistStatus => ({
  type: SET_FOLLOW_THIS_ARTIST_SUCCESS,
  payload: followThisArtistStatus,
});

export const fetchFollowThisArtistError = {
  type: FETCH_FOLLOW_THIS_ARTIST_ERROR,
};

export const setFollowThisArtistError = {
  type: SET_FOLLOW_THIS_ARTIST_ERROR,
};

export const setUserPopularArtistsData = popularArtists => ({
  type: SET_USER_POPULAR_ARTISTS_DATA,
  payload: popularArtists,
});

export const setIsProdEnv = isProd => ({
  type: SET_IS_PROD_ENV,
  payload: isProd,
});

export const setWindowWidth = windowSize => ({
  type: SET_WINDOW_WIDTH,
  payload: windowSize,
});

export const setHostName = hostname => ({
  type: SET_HOST_NAME,
  payload: hostname,
});

export const setUserCountryCode = userCountryCode => ({
  type: SET_USER_COUNTRY_CODE,
  payload: userCountryCode,
});

export const addConversionRate = conversionRate => ({
  type: ADD_CONVERSION_RATE,
  payload: conversionRate,
});

export const fetchUserSimilarArtistsDataSuccess = similarArtists => ({
  type: FETCH_SIMILAR_ARTISTS_DATA_SUCCESS,
  payload: similarArtists,
});

export const fetchUserSimilarArtistsDataError = {
  type: FETCH_SIMILAR_ARTISTS_DATA_ERROR,
};

export const fetchUserArtistFollowStatusSuccess = userArtistFollowStatus => ({
  type: FETCH_USER_ARTIST_FOLLOW_STATUS_SUCCESS,
  payload: { userArtistFollowStatus },
});

export const fetchUserArtistFollowStatusError = {
  type: FETCH_USER_ARTIST_FOLLOW_STATUS_ERROR,
};

export const setIsMobileUserAgent = isMobile => ({
  type: SET_IS_MOBILE_USER_AGENT,
  payload: isMobile,
});

export const fetchVipStatusSuccess = vipStatus => ({
  type: FETCH_IS_VIP_STATUS_SUCCESS,
  payload: vipStatus,
});

export const fetchVipStatusError = error => ({
  type: FETCH_IS_VIP_STATUS_ERROR,
  payload: error,
});

export const fetchLoginHeaderError = {
  type: FETCH_HEADER_LOGIN_ERROR,
};

export const fetchVipStatus = ({ headers }) => async (dispatch) => {
  try {
    const vipRes = await axios.get(`${routes.api.PDP_GET_VIP_STATUS}`, { headers });
    const vipStatus = vipRes.data.vip;
    dispatch(fetchVipStatusSuccess(vipStatus));
  } catch (error) {
    fetchVipStatusError(error);
  }
};

export const fetchNotificationsSuccess = notifications => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const fetchNotificationsError = error => ({
  type: FETCH_NOTIFICATIONS_ERROR,
  payload: error,
});

const fetchUserCountsSuccess = userCounts => ({
  type: FETCH_USER_COUNTS_SUCCESS,
  payload: userCounts,
});

const fetchUserCountsError = error => ({
  type: FETCH_USER_COUNTS_ERROR,
  payload: error,
});

const requestPrefix = '/api/users/counts/';
const requestUrls = [
  { requestUrl: `${requestPrefix}registeredAuctions` },
  { requestUrl: `${requestPrefix}activeBids` },
  { requestUrl: `${requestPrefix}watchedLots` },
  { requestUrl: `${requestPrefix}wonLots` },
  { requestUrl: `${requestPrefix}keywordArtistAlertsCount` },
  { requestUrl: `${requestPrefix}artistAlerts` },
  { requestUrl: `${requestPrefix}categoryAlerts` },
  { requestUrl: `${requestPrefix}sellerAlerts` },
  { requestUrl: `${requestPrefix}unreadMessagesCount` },
];

const userCounts = {
  ARTIST_ALERTS: 0,
  REGISTERED_AUCTIONS: 0,
  ACTIVE_BIDS: 0,
  CATEGORY_ALERTS: 0,
  KEYWORD_ARTIST_ALERTS: 0,
  KEYWORD_ALERTS: 0,
  UNREAD_MESSAGES_COUNT: 0,
  FOLLOWING_SELLERS_COUNT: 0,
  WATCHED_LOTS: 0,
  WON_LOTS: 0,
};

export const fetchUserCounts = ({ headers }) => async (dispatch) => {
  try {
    const cachedUserCounts = JSON.parse(window.sessionStorage.getItem('myUserCounts'));
    const counts = get(cachedUserCounts, 'counts', {});

    // If we have already fetched user counts in the past 15 minutes, don't do it again
    const hasUserCounts = new Date().getTime() < get(cachedUserCounts, 'expiresAt', 0);
    if (hasUserCounts) {
      dispatch(fetchUserCountsSuccess(counts));
      return;
    }

    const promises = requestUrls.map(url => axios.get(url.requestUrl, { headers }));

    const countResults = await Promise.all(promises);
    const newUserCounts = userCounts;
    countResults.forEach((countProperty) => {
      newUserCounts[countProperty.data.type] = countProperty.data.count;
    });
    const keyWordCount = await axios.get('/api/users/keywords', { headers });
    newUserCounts.KEYWORD_ALERTS = keyWordCount.data.totalElements;

    /*
     * Cache the counts in sessionStorage for the next 15 minutes since these requests are expensive
     * and made on every page.
    */
    const expiresAt = new Date();
    expiresAt.setMinutes(expiresAt.getMinutes() + 15);
    const sessionObj = { counts: newUserCounts, expiresAt: expiresAt.getTime() };
    window.sessionStorage.setItem('myUserCounts', JSON.stringify(sessionObj));

    dispatch(fetchUserCountsSuccess(newUserCounts));
  } catch (error) {
    dispatch(fetchUserCountsError(error));
    console.error(error);
  }
};

export const setSearchTypeAheadCookie = cookie => ({
  type: SET_SEARCH_TYPE_AHEAD_COOKIE,
  payload: cookie
});

export const setmyInvaluableCookie = cookie => ({
  type: SET_MY_INVALUABLE_COOKIE,
  payload: cookie
});

export const setPostLoginRedirectUrl = payload => ({
  type: SET_POST_LOGIN_REDIRECT_URL,
  payload
});

export const syncLoginStatus = async (authData, redirectUrl) => {
  try {
  // sync session with node app
    await axios.post(routes.api.BOULDER_LOGIN, authData);
    // update terms and conditions
    const res = await axios.post(routes.api.UPDATE_TERMS, authData);
    // Calculate expiration date in the future
    const exdate = new Date();
    const exdays = 10000;
    exdate.setDate(exdate.getDate() + exdays);
    const exdateString = exdate.toUTCString();

    document.cookie = `privacyVersion=${res.data.data.memberCurrentUserTermAgreement};
   expires="${exdateString}"; path=/;'`;
    window.heap.addEventProperties({ authState: 'authenticated' });
    if (redirectUrl) {
      window.location = redirectUrl;
    } else {
      window.location.reload();
    }
  } catch (error) {
    console.error('syncloginstatus error', error);
    fullPageLoadingSpinnerToggle(false);
  }
};

export const handlePostLoginClient = (authData) => {
  // Handle cookies that trigger actions attempted before promptLogin
  if (document.cookie.includes('preLoginAction')) {
    // Get preLoginCookie value by parsing the cookie string, finding it and extracting its value
    const getPreLoginCookie = document.cookie.split(';')
      .find(cookie => cookie.includes('preLoginAction'))
      .split('=')[1];

    // Set new postLoginAction cookie that shuold only exist on login success
    document.cookie = `postLoginAction=${getPreLoginCookie}`;

    // Delete pre-login cookie by setting expiration date to now
    document.cookie = `preLoginAction=; expires=${new Date()}`;
  }

  // save the value for the next page to show the stale password alert wherever that page may be
  window.sessionStorage.setItem('suggestPasswordUpdate', authData.suggestUpdatePassword);

  if (authData.accountCreated) {
    // Set a flag in session storge if user is currently signing up in both v4/v3 signup.js
    sessionStorage.setItem('isFirstTimeLogin', true);
  }

  // Track the login event
  global.dataLayer.push({
    event: authData.accountCreated ? 'CreateAccountRFA' : 'UserLogin',
    CustomerRef: authData.memberRef,
    CustomerID: authData.memberID,
    bn_email: authData.userName,
    type: 'Invaluable',
  });
};

export const getOasHeaders = (res) => {
  const headers = {};
  // go through all the response headers and add any header
  // that starts with oastoken to the post request to boulder to save in session
  Object.keys(res.headers).forEach((k) => {
    if (k.match(/oastoken/i)) headers[k] = res.headers[k];
  });
  return headers;
};

export const loginUser = (userData, isSignUp = false) => async (dispatch, getStore) => {
  fullPageLoadingSpinnerToggle(true);
  if (!isSignUp) {
    dispatch({ type: FETCH_HEADER_LOGIN_LOADING });
  }
  try {
    const res = await axios.post(routes.api.LOGIN, userData);
    const oasHeaders = getOasHeaders(res);
    const authData = {
      authorization: res.headers.authorization,
      userName: userData.userName,
      firstName: res.data.data.firstName,
      lastName: res.data.data.lastName,
      email: userData.userName,
      keepLoggedIn: userData.keepLoggedIn,
      memberID: res.data.data.memberID,
      memberRef: res.data.data.memberRef,
      userID: res.data.data.memberID,
      suggestUpdatePassword: res.data.data.suggestUpdatePassword,
      'x-auth-token': res.headers['x-auth-token'],
      ...oasHeaders
    };
    const redirectUrl = get(getStore(), 'invReactCommon.postLoginRedirectUrl', document.referrer);

    if (isSignUp) {
      // Set a flag in session storge if user is currently signing up in both v4/v3 signup.js
      sessionStorage.setItem('isFirstTimeLogin', true);
    } else {
      // Remove flag in session storge if user already signed up in both v4/v3 signup.js
      sessionStorage.removeItem('isFirstTimeLogin');
    }

    handlePostLoginClient(authData);

    syncLoginStatus(authData, redirectUrl);

    dispatch(setModalStatus({ name: isSignUp ? MODAL_NAMES.signup : MODAL_NAMES.login, isOpen: false }));

    dispatch({ type: isSignUp ? FETCH_HEADER_SIGNUP_SUCCESS : FETCH_HEADER_LOGIN_SUCCESS });
  } catch (error) {
    logger.error('unable to login user', error);
    const errorStatus = error?.response?.data?.httpStatus;
    if (errorStatus === 422) {
      dispatch({ type: FETCH_HEADER_LOGIN_ERROR });
      fullPageLoadingSpinnerToggle(false);
      return;
    }
    fullPageLoadingSpinnerToggle(false);
  }
};

export const signupUser = (userData) => async (dispatch) => {
  dispatch({ type: FETCH_HEADER_SIGNUP_LOADING });
  userData.keepLoggedIn = true;
  userData.accountCreationSource = 'Email';

  try {
    const res = await axios.post(routes.api.SUBMIT_CREATE_ACCOUNT, userData);

    // Track the create account event
    global.dataLayer.push({
      event: 'CreateAccountRFA',
      CustomerRef: res.data.memberRef,
      CustomerID: res.data.memberID,
      bn_email: userData.email,
    });

    try {
      // Track heap signup event
      window.heap.track('SIGNUP-EMAIL');
      window.heap.track('Sitewide - Account Create - Success Event');
    } catch (error) {
      logger.error('signup heap track error', error);
    }

    dispatch(loginUser({
      userName: userData.email,
      password: userData.password,
      keepLoggedIn: true
    }, true));

    dispatch(setModalStatus({
      name: MODAL_NAMES.signup,
      isOpen: false
    }));
  } catch (error) {
    const errorStatus = error?.response?.data?.httpStatus;
    if (errorStatus === 422
        && error?.response?.data?.pageAccessException?.type === 'LOGGEDIN') {
      window.location = error.response.data.pageAccessException.redirectURL;
      return;
    }
    if (errorStatus === 422 && error.response.data.errors.length) {
      const firstError = error.response?.data?.errors[0]?.validationErrors?.email;
      logger.error(firstError, error);
      dispatch({ type: FETCH_HEADER_SIGNUP_ERROR, payload: [{ error: firstError }] });
      return;
    }

    logger.error('unable to create new user', error);
    dispatch({ type: FETCH_HEADER_SIGNUP_ERROR,
      payload: [{
        error: 'Something went wrong while signing up! Please try again.'
      }]
    });
  }
};

export const fetchNotifications = ({ headers }) => async (dispatch) => {
  try {
    const memberID = headers['member-id'] || '0';
    const notifPopUpData = await axios.get(`/push/notificationHistory?memberID=${memberID}&size=50`, { headers });
    let notifCount = 0;
    notifPopUpData.data.forEach((notif) => {
      if (notif.read === false) notifCount += 1;
    });
    dispatch(fetchNotificationsSuccess({ notifications: notifPopUpData.data, notificationsCount: notifCount }));
  } catch (error) {
    console.error('fetch notifications error', error);
    dispatch(fetchNotificationsError(error));
  }
};

export const deleteNotification = ({ notificationID, headers }) => async (dispatch) => {
  const memberID = headers['member-id'] || '0';
  try {
    await axios.delete(`/push/notificationHistory?memberID=${memberID}`, { data: { notificationIDs: [notificationID] } });
    dispatch(fetchNotifications({ headers }));
  } catch (err) {
    console.error('deleting notification error', err);
  }
};

export const deleteAllNotifications = ({ headers }) => async (dispatch) => {
  const memberID = headers['member-id'] || '0';
  try {
    await axios.delete(`/push/notificationHistory?memberID=${memberID}`);
    dispatch(fetchNotifications({ headers }));
  } catch (err) {
    console.error('delete all notifications error', err);
  }
};

export const markNotifRead = ({ notificationID, headers, notificationURL }) => async (dispatch) => {
  const notifData = { notificationIDs: [notificationID] };

  try {
    await axios.post('/push/notificationReadStatus', notifData, {
      headers
    });
    dispatch(fetchNotifications({ headers }));
  } catch (error) {
    console.error('mark notifications read error', error);
  }

  if (notificationURL) {
    window.location = notificationURL;
  }
};

export const markAllNotifsRead = ({ headers }) => async (dispatch) => {
  const notifData = { notificationIDs: [] };
  const memberID = headers['member-id'] || '0';

  try {
    await axios.post(`/push/markAllNotificationsAsRead?memberID=${memberID}`, notifData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch(fetchNotifications({ headers }));
  } catch (error) {
    console.error('mark all notifications read error', error);
  }
};
