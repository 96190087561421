import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import TrendUp from './icons/TrendUp';
import { NO_OF_RESULTS_LONG, NO_OF_RESULTS_MOBILE, UPCOMING_LOTS_INDEX, algoliaClient } from '../../config';
import PopularItems from './popularItems';

function PopularSearches() {
  const isMobile = useSelector((state) => get(state, 'invReactCommon.isMobile', false));
  const length = isMobile ? NO_OF_RESULTS_MOBILE : NO_OF_RESULTS_LONG;
  return (
    <Fragment>
      <h4>
        <TrendUp />
        Popular
      </h4>
      <InstantSearch
        indexName={UPCOMING_LOTS_INDEX}
        searchClient={algoliaClient}
        attributesToRetrieve={['query']}
      >
        <Configure
          hitsPerPage={length}
        />
        <PopularItems />
      </InstantSearch>
    </Fragment>
  );
}

export default PopularSearches;
