export const PAGE_LAYOUTS = {
  V4: 'v4',
  V3: 'v3',
};

export const NODE_ENVS = {
  PROD: 'production',
  DEV: 'dev',
};

export const HTTP_STATUS_CODES = {
  OK: 200,
  INTERNAL_SERVER_ERROR: 500,
  BAD_REQUEST: 400,
};

export const PAGE_ERRORS = {
  ARTIST_DIRECTORY: 'Unable to fetch V2 artists directory page',
  ARTIST_DETAIL: 'Unable to fetch V2 artist detail page',
  PDP: 'Unable to fetch V2 PDP',
  ARTIST_PAST_LOTS: 'Unable to fetch artist past lots page',
  ARTIST_UPCOMING_LOTS: 'Unable to fetch artist upcoming lots page',
};

export const ALGOLIA_SECRETS = {
  AWS_REGION: 'us-east-2',
  SECRET_ID_PROD: '/backend/inv-node_prod',
  SECRET_ID_STAGE: '/backend/inv-node_stage',
};

export const ALGOLIA_STYLE_SHEETS = `  
      <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css"
          integrity="sha256-t2ATOGCtAIZNnzER679jwcFcKYfLlw01gli6F6oszk8="
          crossOrigin="anonymous"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.4.5/themes/satellite-min.css"
        integrity="sha256-TehzF/2QvNKhGQrrNpoOb2Ck4iGZ1J/DI4pkd2oUsBc="
        crossOrigin="anonymous"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/@algolia/autocomplete-theme-classic"
      />
    `;

export const REACT_SLICK_STYLES = `
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
`;

export const POPULAR_ARTISTS_ALGOLIA_ATTRIBUTES_TO_RETRIVE = ['artistRef', 'upcomingCount', 'followerCount'];

export const fiveMinMillis = 300000;

export const ALGOLIA_HIT_BID_FIELDS_INDEXES = {
  memberId: 0,
  orderBidStat: 1,
  maxBidAmount: 2,
  memberRef: 3,
  recordStat: 4,
};

export const MAX_COOKIE_SIZE = 4 * 1024; // 4KB
