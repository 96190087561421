import get from 'lodash/get';

import { DEFAULT_DATE_FILTER_OPTION } from 'src/components/artist-detail/filters-and-lots-view/constants';
import { DEFAULT_PAGINATION_PAGE_NUMBER, DEFAULT_PRICE_RANGE_FILTER } from 'src/utils/constants';

export const selectArtistRef = state => get(state, 'artistDetail.artistBio.artistRef', '');

export const selectArtistDisplayName = state => get(state, 'artistDetail.artistBio.displayName', '');

export const selectArtistBioInfo = state => get(state, 'artistDetail.artistBio', {});

export const selectArtistGenres = state => get(state, 'artistDetail.artistBio.genres', []);

export const selectArtistNotableSoldLots = state => get(state, 'artistDetail.artistNotableSoldLots', []);

export const selectArtistDateFilter = state => get(state, 'artistDetail.filters.date', DEFAULT_DATE_FILTER_OPTION);

export const selectArtistPriceRangeFilter = state => get(
  state,
  'artistDetail.filters.priceRange',
  DEFAULT_PRICE_RANGE_FILTER
);

export const selectTotalFilteredLots = state => get(state, 'artistDetail.totalFilteredLots', 0);

export const selectIsShowPageNumInURL = (state) => {
  const totalFilteredLots = get(state, 'artistDetail.totalFilteredLots', 0);
  const numberOfLotsPerPage = get(state, 'artistDetail.numberOfLotsPerPage', 0);

  return Boolean(totalFilteredLots && numberOfLotsPerPage && totalFilteredLots > numberOfLotsPerPage);
};

export const selectCurrentPageNumber = state => get(state, 'artistDetail.page', DEFAULT_PAGINATION_PAGE_NUMBER);

export const selectLotsDisplayMode = state => get(state, 'artistDetail.lotsDisplayMode');

export const selectNumberOfLotsPerPage = state => get(state, 'artistDetail.numberOfLotsPerPage');

export const selectHousesSellingWorkByArtist = state => get(state, 'artistDetail.housesSellingWorkByArtist');
