import React from 'react';

function TrendUp() {
  return (
    <svg
      style={{ marginRight: '6px' }}
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 0.666656L9.42095 5.91241C9.15694 6.17642 9.02494 6.30843 8.87272 6.35789C8.73882 6.40139 8.59459 6.40139 8.4607 6.35789C8.30848 6.30843 8.17647 6.17642 7.91246 5.91241L6.08762 4.08757C5.82361 3.82356 5.6916 3.69155 5.53939 3.64209C5.40549 3.59859 5.26126 3.59859 5.12736 3.64209C4.97514 3.69155 4.84314 3.82356 4.57913 4.08757L1.33337 7.33332M14.6667 0.666656H10M14.6667 0.666656V5.33332"
        stroke="#222222"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default TrendUp;
