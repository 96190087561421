import {
  fetchArtistBioInfoSuccess,
  fetchArtistBioInfoError,
  fetchArtistNotableSoldLotsSuccess,
  fetchArtistNotableSoldLotsError,
  setAuctionHousesSellingWorkByArtist,
} from 'src/actions';
import { algoliaSearchClient } from 'src/utils/algolia-helper';
import { ALGOLIA_INDEX } from 'src/utils/algolia-helper/constants';
import { getIsProductionEnv } from 'src/utils/common/helpers';

import logger from 'routes/middleware/logging/logger';
import ArtistDetailService from '../services/artist-detail';

export const fetchArtistBioInfo = (artistRef, hostname) => async (dispatch) => {
  try {
    const { success, data: artistBio } = await ArtistDetailService.getArtistBioInfo(artistRef, hostname);
    if (success) {
      dispatch(fetchArtistBioInfoSuccess(artistBio));
    }
    dispatch(fetchArtistBioInfoError);
  } catch (error) {
    dispatch(fetchArtistBioInfoError);
  }
};

export const fetchArtistNotableSoldLots = (artistRef, hostname) => async (dispatch) => {
  try {
    const { success, data: notableSoldLots } = await ArtistDetailService.getArtistNotableSoldLots(artistRef, hostname);

    if (success) {
      dispatch(fetchArtistNotableSoldLotsSuccess(notableSoldLots));
    } else {
      dispatch(fetchArtistNotableSoldLotsError);
    }
  } catch (error) {
    dispatch(fetchArtistNotableSoldLotsError);
  }
};

/**
 * Fetches set of house associated with each upcoming lot. Traverses through that set, creates
 * a map with keys as each unique house alongside counting the lots for that house. Returns
 * values of this map as an array.
 * @param {string} artistRef
 * @returns Array of auction houses, [{houseRef,houseName,upcomingLotsCount},]
 */
export const fetchFrmtAndSetHousesSellingArtistWork = (artistRef) => async (dispatch) => {
  try {
    const housesSellingWorkByArtistConfig = {
      indexName: getIsProductionEnv() ? ALGOLIA_INDEX.UPCOMING_PROD : ALGOLIA_INDEX.UPCOMING_NON_PROD,
      params: {
        attributesToRetrieve: ['houseName', 'houseRef'],
        hitsPerPage: 1000, // TODO: INV-9737 fetch all results regardless of pagination
        filters: `artistRef:${artistRef}`,
      }
    };
    const algoliaResults = await algoliaSearchClient.search([housesSellingWorkByArtistConfig]);

    const auctionHouses = algoliaResults.results[0].hits.reduce((houses, currentHouse) => {
      const { houseRef, houseName } = currentHouse;

      if (houseRef in houses) {
        houses[houseRef].upcomingLotsCount += 1;
      } else {
        houses[houseRef] = {
          houseRef,
          houseName,
          upcomingLotsCount: 1, // As this is first occurrence of this house
        };
      }

      return houses;
    }, {});

    dispatch(setAuctionHousesSellingWorkByArtist(Object.values(auctionHouses)));
  } catch (error) {
    logger.error('ARTIST DETAILS: Algolia SSR fetch error for [fetchFrmtAndSetHousesSellingArtistWork]', error);

    dispatch(setAuctionHousesSellingWorkByArtist([]));
  }
};
