import {
  SET_ARTIST_DATE_FILTER,
  FETCH_ARTIST_BIO_INFO_SUCCESS,
  FETCH_ARTIST_BIO_INFO_ERROR,
  FETCH_ARTIST_NOTABLE_SOLD_LOTS_SUCCESS,
  FETCH_ARTIST_NOTABLE_SOLD_LOTS_ERROR,
  SET_FILTERED_LOTS_COUNT,
  SET_LOTS_DISPLAY_MODE,
  SET_NUMBER_OF_LOTS_PER_PAGE,
  SET_CURRENT_PAGE_NUMBER,
  SET_ARTIST_PRICE_RANGE_FILTER,
  SET_HOUSES_SELLING_WORK_BY_ARTIST_SUCCESS,
} from 'src/actions/constants';
import { LOTS_DISPLAY_MODES } from 'src/utils/artist-detail/constants';
import { DEFAULT_DATE_FILTER_OPTION } from 'src/components/artist-detail/filters-and-lots-view/constants';
import { DEFAULT_PAGINATION_PAGE_NUMBER, DEFAULT_PRICE_RANGE_FILTER } from 'src/utils/constants';
import logger from 'routes/middleware/logging/logger';

const initialState = {
  artistBio: undefined,
  artistNotableSoldLots: undefined,
  filters: {
    date: DEFAULT_DATE_FILTER_OPTION,
    priceRange: DEFAULT_PRICE_RANGE_FILTER
  },
  lotsDisplayMode: LOTS_DISPLAY_MODES.LIST,
  numberOfLotsPerPage: undefined, // default is set while server side rendering
  totalFilteredLots: undefined,
  page: DEFAULT_PAGINATION_PAGE_NUMBER,
  housesSellingWorkByArtist: [],
  errors: {
    artistBioInfoCallFailed: false,
    artistNotableSoldLotsCallFailed: false,
    isFollowArtistCallFailed: false,
    isSessionCallFailed: false,
  },
};

function artistDetail(state = initialState, action) {
  try {
    switch (action.type) {
      case FETCH_ARTIST_BIO_INFO_SUCCESS: {
        const {
          artistRef,
          bio,
          displayName,
          dates: birthAndDeathDates,
          profession: professions,
          alias: aliases,
          genres,
          lastName,
          foreName: firstName,
        } = action.payload;

        return {
          ...state,
          artistBio: {
            artistRef,
            aliases,
            bio,
            birthAndDeathDates,
            displayName,
            professions,
            genres,
            lastName,
            firstName
          },
        };
      }

      case FETCH_ARTIST_NOTABLE_SOLD_LOTS_SUCCESS: {
        const notableSoldLots = action.payload;

        return {
          ...state,
          artistNotableSoldLots: notableSoldLots
        };
      }

      case SET_ARTIST_DATE_FILTER: {
        const { date } = action.payload;
        return {
          ...state,
          filters: {
            ...state.filters,
            date,
          },
        };
      }

      case SET_ARTIST_PRICE_RANGE_FILTER: {
        const priceRange = action.payload;

        return {
          ...state,
          filters: {
            ...state.filters,
            priceRange,
          },
        };
      }

      case SET_FILTERED_LOTS_COUNT: {
        const totalNumberOfLots = action.payload;
        return {
          ...state,
          totalFilteredLots: totalNumberOfLots,
        };
      }

      case SET_LOTS_DISPLAY_MODE: {
        return {
          ...state,
          lotsDisplayMode: action.payload
        };
      }

      case SET_NUMBER_OF_LOTS_PER_PAGE: {
        return {
          ...state,
          numberOfLotsPerPage: action.payload
        };
      }

      case SET_CURRENT_PAGE_NUMBER: {
        const page = action.payload;
        return {
          ...state,
          page,
        };
      }

      case FETCH_ARTIST_BIO_INFO_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            artistBioInfoCallFailed: true,
          },
        };
      }

      case FETCH_ARTIST_NOTABLE_SOLD_LOTS_ERROR: {
        return {
          ...state,
          errors: {
            ...state.errors,
            artistNotableSoldLotsCallFailed: true,
          },
        };
      }

      case SET_HOUSES_SELLING_WORK_BY_ARTIST_SUCCESS: {
        return {
          ...state,
          housesSellingWorkByArtist: action.payload,
        };
      }

      default:
        return state;
    }
  } catch (error) {
    logger.error('Error in the artist detail Reducer', error);
    return state;
  }
}

export default artistDetail;
