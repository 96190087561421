import {
  FETCH_CATEGORIES_ERROR,
  FETCH_CATEGORIES_LOADING,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_FOLLOW_LOADING,
  FETCH_CATEGORY_FOLLOW_ERROR,
  FETCH_CATEGORY_FOLLOW_SUCCESS,
  FETCH_CATEGORY_UNFOLLOW_ERROR,
  FETCH_CATEGORY_UNFOLLOW_SUCCESS,
} from '../actions/constants';

const initialState = {
  isFollowingSuccess: false,
  isLoadingCategories: false,
  isLoadingFollowCategory: false,
  isUnfollowingSuccess: false,
  sessionInfo: {},
  subCategories: null,
  type: null,
};

function categories(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORY_FOLLOW_LOADING:
      return {
        ...state,
        isLoadingFollowCategory: true,
      };

    case FETCH_CATEGORY_FOLLOW_SUCCESS:
    case FETCH_CATEGORY_FOLLOW_ERROR:
    case FETCH_CATEGORY_UNFOLLOW_SUCCESS:
    case FETCH_CATEGORY_UNFOLLOW_ERROR:
      return {
        ...state,
        isLoadingFollowCategory: false,
        isFollowingSuccess: action.payload.isFollowingSuccess,
        isUnfollowingSuccess: action.payload.isUnfollowingSuccess,
      };

    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoryContent: action.payload.categoryContent,
        facetCounts: action.payload.facetCounts,
        isLoadingCategories: false,
        recommendedItems: action.payload.recommendedItems,
        recommendedItemsBuyNow: action.payload.recommendedItemsBuyNow,
        sessionInfo: action.payload.sessionInfo,
        subCategories: action.payload.subCategories,
        type: action.type,
      };

    case FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        categoryContent: null,
        facetCounts: null,
        errorMessage: action.payload,
        isLoadingCategories: false,
        recommendedItems: null,
        recommendedItemsBuyNow: null,
        subCategories: null,
        type: action.type,
        sessionInfo: null,
      };

    case FETCH_CATEGORIES_LOADING:
      return {
        ...state,
        categoryContent: null,
        facetCounts: null,
        isLoadingCategories: true,
        recommendedItems: null,
        recommendedItemsBuyNow: null,
        sessionInfo: null,
        subCategories: null,
        type: action.type,
      };

    default:
      return state;
  }
}

export default categories;
