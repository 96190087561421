import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { selectIsProd } from 'src/selectors/inv-common';
import GridViewListViewControls from 'src/components/artist-detail/works-by-artist-and-sort-by-filters/GridViewListViewControls';
import { useSelectedArtistDetailTab } from 'src/hooks';
import SortByFilter from 'src/components/artist-detail/works-by-artist-and-sort-by-filters/SortByFilter';
import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';

import {
  SORT_BY_FILTER_OPTION_VALUE_KEY,
  SORT_BY_FILTER_OPTION_LABEL_KEY,
} from './constants';
import { getSortOptions, getDefaultSortByOption } from './helpers';

export function SortAndViewControls() {
  const isProd = useSelector(selectIsProd);
  const selectedTab = useSelectedArtistDetailTab();

  const isUpcoming = selectedTab === ARTIST_LOTS_TABS.UPCOMING;

  return (
    <Fragment>
      <SortByFilter
        items={getSortOptions(isProd, isUpcoming)}
        defaultRefinement={getDefaultSortByOption(isProd, isUpcoming)}
        optionValueKey={SORT_BY_FILTER_OPTION_VALUE_KEY}
        optionDisplayKey={SORT_BY_FILTER_OPTION_LABEL_KEY}
      />
      <GridViewListViewControls />
    </Fragment>
  );
}
