import { useSelector } from 'react-redux';
import moment from 'moment';

import { selectArtistInfo, selectAuctionData, selectBreadCrumbs, selectLotData } from '../selectors/pdp';

/**
 * A util to return google analytics ecommerce detail object used by evergage
 * @returns {object} returns ecommerce detail object
 */
// eslint-disable-next-line import/prefer-default-export
export const gaEcommerceDetail = () => {
  const { house: { ref: sellerRef }, isUpcoming, isViewOnly, isTimed } = useSelector(selectAuctionData);
  const breadcrumbs = useSelector(selectBreadCrumbs);
  const productCategory = breadcrumbs.map(breadcrumb => breadcrumb.name).join(' | ');
  const productCategoryRef = breadcrumbs.map(breadcrumb => breadcrumb.ref).join(' | ');
  const {
    lotRef,
    lotTitle,
    currency,
    currencySymbol,
    isLotPassed,
    currentBid,
    isLotSold,
    postedDate,
    artistRef,
  } = useSelector(selectLotData);

  const { fullName: artistName } = useSelector(selectArtistInfo);

  const price = isLotPassed ? 'Passed' : `${currencySymbol || currency}${currentBid}`;
  const timedType = isTimed ? ' timed' : '';
  const itemType = isViewOnly ? 'view only' : 'lot';
  const itemStatus = (isLotSold || !isUpcoming || isViewOnly) ? 'past ' : 'active ';

  const pageTypeString = `${itemStatus}${itemType}${timedType} details`;
  const variant = `${itemStatus}${itemType}${timedType}`;

  return {
    ecommerce: {
      detail: {
        products: [
          {
            brand: sellerRef,
            category: productCategory,
            id: lotRef,
            name: lotTitle,
            variant,
            currency,
            publishDate: moment(postedDate).format('MM-DD-YYYY'),
            price,
          },
        ],
      },
    },
    pageType: pageTypeString,
    ArtistName: artistName || null,
    ArtistRef: artistRef || null,
    CategoryRef: productCategoryRef,
    CategoryName: productCategory,
  };
};
