import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import logger from 'routes/middleware/logging/logger';
import {
  FETCH_SEARCH_RESULTS_ERROR,
  FETCH_SEARCH_RESULTS_LOADING,
  FETCH_SEARCH_RESULTS_SUCCESS,
  TOGGLE_SAVED_SEARCH_KEYWORD,
  FETCH_KEYWORD_FOLLOWED_SUCCESS,
  FETCH_KEYWORD_FOLLOWED_ERROR,
  FETCH_CURRENCY_CODE,
} from './constants';

// Action creators
export const fetchSearchResultsLoading = () => ({
  type: FETCH_SEARCH_RESULTS_LOADING,
});

export const fetchSearchResultsSuccess = SearchResults => ({
  type: FETCH_SEARCH_RESULTS_SUCCESS,
  payload: SearchResults,
});

export const fetchSearchResultsError = error => ({
  type: FETCH_SEARCH_RESULTS_ERROR,
  payload: error,
});

export const fetchCurrencyCode = countryCode => ({
  type: FETCH_CURRENCY_CODE,
  payload: countryCode,
});

export const toggleSavedSearchKeyword = keywordObj => ({
  type: TOGGLE_SAVED_SEARCH_KEYWORD,
  payload: keywordObj,
});

export const fetchKeywordFollowedSuccess = keywordObj => ({
  type: FETCH_KEYWORD_FOLLOWED_SUCCESS,
  payload: keywordObj,
});

export const fetchKeywordFollowedError = error => ({
  type: FETCH_KEYWORD_FOLLOWED_ERROR,
  payload: error,
});

const isKeywordFollowed = async (keyword, xAuthToken) => {
  const uri = '/api/users/isKeywordFollowed';
  const headers = {
    Accept: 'application/json',
    'Access-Control-Allow-Headers': '*',
    'x-auth-token': xAuthToken,
    'Content-Type': 'application/json; charset=utf-8',
    'Inv-Request-Id': uuidv4(),
  };

  const data = {
    withAll: keyword,
    notWith: '',
    withOne: '',
    withPhrase: '',
  };

  const response = await axios.post(uri, data, { headers });
  const keyWordData = response.data;
  return !!keyWordData;
};

export const addKeyword = (xAuthToken, keyword) => async () => {
  const { data } = await axios.get('/api/users/hasKeywordAlertEmail', {
    headers: {
      'x-auth-token': xAuthToken,
    },
  });

  const isFollowed = await isKeywordFollowed(keyword, xAuthToken);

  if (!isFollowed && !data) {
    const uri = `/api/users/autoSearchKeyword?keyword=${keyword}`;
    const headers = {
      Accept: 'application/json',
      'Access-Control-Allow-Headers': '*',
      'x-auth-token': xAuthToken,
      'Content-Type': 'application/json; charset=utf-8',
    };
    await axios.post(uri, data, { headers });
  }
};

export const fetchKeywordId = (keyword, xAuthToken) => {
  const newkeyword = keyword;

  return async (dispatch) => {
    const uri = '/api/users/isKeywordFollowed';
    const headers = {
      Accept: 'application/json',
      'Access-Control-Allow-Headers': '*',
      'x-auth-token': xAuthToken,
      'Content-Type': 'application/json; charset=utf-8',
      'Inv-Request-Id': uuidv4(),
    };

    const data = {
      withAll: newkeyword,
      notWith: '',
      withOne: '',
      withPhrase: '',
    };
    try {
      const isKwFollowed = await axios.post(uri, data, { headers });
      dispatch(fetchKeywordFollowedSuccess(isKwFollowed.data));
    } catch (error) {
      logger.error(`Error fetching keyword followed state [keyword=${keyword}]`, error);
      dispatch(fetchKeywordFollowedError(error));
    }
  };
};

export const fetchSearchResults = hostname => async (dispatch) => {
  dispatch(fetchSearchResultsLoading());
  try {
    const boulderResponse = await axios(`https://${hostname}/boulder/session-info`, { headers: {} });
    dispatch(fetchSearchResultsSuccess({ sessionInfo: boulderResponse.data }));
  } catch (error) {
    logger.error('Error fetching session info in fetchSearchResults()', error);
    dispatch(fetchSearchResultsError(error));
  }
};
