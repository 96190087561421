import axios from 'axios';
import { get } from 'lodash';
import React, { useState } from 'react';
import { trackClick } from '../utils';

function SavedItem({ item }) {
  const {
    img,
    isFav,
    ref,
    showDate,
    date,
    linkTo,
    title,
    price,
    isOutBid,
    pendingBid,
    highBidder,
    hasBid
  } = item;
  const [isFavorite, setIsFavorite] = useState(isFav);
  const handleLike = async () => {
    const action = isFavorite ? 'unwatch-lot' : 'watch-lot';
    try {
      const response = await axios
        .post(`/app/v2/member/${action}/${ref}`);
      if (get(response, 'data.httpStatus') === 200) {
        setIsFavorite(!isFavorite);
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(`We are unable to ${isFavorite ? 'remove' : 'save'} this item at this time.`
              + 'Please try again later or contact Customer Care any time at 617-746-9800');
    }
  };
  return (
    <div className="bids-fav">
      <div className="details">
        <div className="img-block">
          <button className={`fav-button ${isFavorite ? 'fav' : ''}`} type="button" onClick={handleLike}>
            <i id={ref} className={`fa ${isFavorite ? 'fa-heart' : 'fa-heart-o'}`} />
          </button>
          <img src={img} alt="bid-fav" />
        </div>
        <div className="detail-block">
          {
            date && (
            <span className={`time-left ${showDate ? 'show-date' : ''}`}>
              {date}
            </span>
            )
        }
          <a href={linkTo} onClick={() => trackClick('Bids And Favorites')} className="title my-bids-fav-tag">
            {title}
          </a>
          <div className="price-status-block">
            <span className="price">
              {price}
            </span>
            {
                isOutBid && (
                <span className="pill outbid">
                  outbid
                </span>
                )
            }
            {
                pendingBid && (
                <span className="pill pending">
                  pending
                </span>
                )
            }
            {
                highBidder && (
                <span className="pill high-bid">
                  high bidder
                </span>
                )
            }
          </div>
        </div>
      </div>
      {pendingBid && <span className="call">*Call to get approved: +1 (740) 362 4771</span>}
      {!hasBid && isFavorite && <a href={linkTo} className="bids-btn inverse-btn">Place Bid</a>}
      {hasBid && isOutBid && <a href={linkTo} className="bids-btn">increase bid</a>}
    </div>
  );
}

export default SavedItem;
