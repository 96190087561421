import React, { memo, useEffect, useState, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { setArtistPriceRangeFilter, setCurrentPageNumber } from 'src/actions';
import { AlgoliaProvider } from 'src/context/algolia-context';
import {
  selectArtistDateFilter,
  selectCurrentPageNumber,
  selectIsShowPageNumInURL,
} from 'src/selectors/artist-detail';
import { selectIsProd } from 'src/selectors/inv-common';
import {
  artistURLToSearchState,
  createArtistDetailURLFromSearchState,
  getPriceRangeBySelectedTab,
  searchStateToArtistDetailUrl,
} from 'src/utils/artist-detail';
import { useSelectedArtistDetailTab } from 'src/hooks';
import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';

import NoUpcomingLotsBanner from '../NoUpcomingLotsBanner';
import { NO_LOTS_TO_DISPLAY_MESSAGE } from '../constants';

import { SortAndViewControls } from './SortAndViewControls';
import { MobileFilterIconAndModal } from './MobileFilterIconAndModal';
import { ResponsiveFiltersAndResults } from './ResponsiveFiltersAndResults';
import { FilteredLotsCount } from './FilteredLotsCount';
import { ARTIST_LOT_ATTRS_TO_RETRIEVE } from './constants';
import { getFilterStr } from './helpers';

function FiltersAndResultsWrapper({
  location,
  artistRef,
  searchClient,
  widgetsCollector,
  resultsState,
  algoliaSearchState,
  indexName,
  updateUrl,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedTab = useSelectedArtistDetailTab();
  const [searchState, setSearchState] = useState(algoliaSearchState);
  const isProd = useSelector(selectIsProd);
  const dateFilter = useSelector(selectArtistDateFilter);
  const page = useSelector(selectCurrentPageNumber);
  const isShowPageNumInURL = useSelector(selectIsShowPageNumInURL);

  const { pathname } = history?.location || {};

  const filtersConfig = useMemo(() => getFilterStr(artistRef, selectedTab), [selectedTab]);

  const isUpcoming = selectedTab === ARTIST_LOTS_TABS.UPCOMING;
  const areNoLotsAvailable = resultsState?.rawResults[0]?.hits.length === 0;

  useEffect(() => {
    if (process.browser) {
      window.onpopstate = async (event) => {
        event.preventDefault();

        const updatedSearchState = artistURLToSearchState(event.target.location.search, isProd, selectedTab);
        setSearchState(updatedSearchState);
      };
    }
  }, []);

  const dispatchArtistPriceRangeFilter = (updatedSearchState) => {
    const priceRange = getPriceRangeBySelectedTab(updatedSearchState, selectedTab);

    if (!isEmpty(priceRange)) dispatch(setArtistPriceRangeFilter(priceRange));
  };

  const onSearchStateChange = (updatedSearchState) => {
    const updatedPage = updatedSearchState.page || page;
    const updatedState = { ...updatedSearchState, dateType: dateFilter, page: updatedPage };
    const url = searchStateToArtistDetailUrl(updatedState, pathname, location, isProd, isShowPageNumInURL, selectedTab);

    updateUrl(url, updatedState);
    setSearchState(updatedState);
    dispatchArtistPriceRangeFilter(updatedSearchState);
    dispatch(setCurrentPageNumber(updatedPage));
  };

  useEffect(() => {
    const updatedState = { ...searchState, dateType: dateFilter };
    const url = searchStateToArtistDetailUrl(updatedState, pathname, location, isProd, isShowPageNumInURL, selectedTab);
    updateUrl(url, searchState);
  }, [dateFilter]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>
      {
        areNoLotsAvailable ? (
          <Fragment>
            <div className="artist-sort-filter-holder">
              <FilteredLotsCount />
            </div>
            { isUpcoming ? <NoUpcomingLotsBanner /> : <strong>{NO_LOTS_TO_DISPLAY_MESSAGE[ARTIST_LOTS_TABS.PAST]}</strong>}
          </Fragment>
        ) : (
          <InstantSearch
            {...{
              indexName,
              searchState,
              ...(isEmpty(resultsState) ? {} : { resultsState }),
              searchClient,
              widgetsCollector,
              onSearchStateChange
            }}
            key={selectedTab}
            createURL={state => {
              createArtistDetailURLFromSearchState(state, pathname, location, isProd, isShowPageNumInURL, selectedTab);
            }}
          >
            <AlgoliaProvider>
              <Configure
                filters={filtersConfig}
                attributesToRetrieve={ARTIST_LOT_ATTRS_TO_RETRIEVE}
              />
              <div className="artist-sort-filter-holder">
                <FilteredLotsCount />
                <SortAndViewControls />
                <MobileFilterIconAndModal />
              </div>
              <ResponsiveFiltersAndResults
                searchState={searchState}
                onSearchStateChange={onSearchStateChange}
              />
            </AlgoliaProvider>
          </InstantSearch>
        )
      }
    </Fragment>
  );
}

FiltersAndResultsWrapper.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  searchClient: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetsCollector: PropTypes.func,
  artistRef: PropTypes.string.isRequired,
  algoliaSearchState: PropTypes.objectOf(PropTypes.any),
  resultsState: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  updateUrl: PropTypes.func,
};

FiltersAndResultsWrapper.defaultProps = {
  algoliaSearchState: {},
  resultsState: new Proxy({}, { get: () => [] }),
  widgetsCollector: () => {},
};

export default memo(FiltersAndResultsWrapper);
