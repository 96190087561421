export const categoryData = [
  {
    targetId: 'fineArt',
    title: 'Fine Art',
    titleHref: '/fine-art/pc-SG2BIX3JPJ/',
    className: 'fine-art-link notranslate nav-link',
    linkData: [
      {
        categoryId: 52,
        categoryLevel: 1,
        categoryName: 'Drawings',
        categoryRef: 'X40D0LU64C',
        categoryShortName: 'Drawings',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 2338,
        popular: false
      },
      {
        categoryId: 54,
        categoryLevel: 1,
        categoryName: 'Mixed Media Art',
        categoryRef: 'Z3K5WYSB8R',
        categoryShortName: 'Mixed Media Art',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 811,
        popular: false
      },
      {
        categoryId: 55,
        categoryLevel: 1,
        categoryName: 'Paintings',
        categoryRef: 'AF14022FJQ',
        categoryShortName: 'Paintings',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 9524,
        popular: false
      },
      {
        categoryId: 56,
        categoryLevel: 1,
        categoryName: 'Photography',
        categoryRef: '5FM3GDY2EF',
        categoryShortName: 'Photography',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1099,
        popular: false
      },
      {
        categoryId: 57,
        categoryLevel: 1,
        categoryName: 'Posters',
        categoryRef: 'QB7AGYS9KO',
        categoryShortName: 'Posters',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1170,
        popular: false
      },
      {
        categoryId: 58,
        categoryLevel: 1,
        categoryName: 'Prints',
        categoryRef: 'SGERF8GX5G',
        categoryShortName: 'Prints',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 8621,
        popular: false
      },
      {
        categoryId: 59,
        categoryLevel: 1,
        categoryName: 'Sculptures',
        categoryRef: 'MH70PM0ARM',
        categoryShortName: 'Sculptures',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 2717,
        popular: false
      }
    ],
  }, {
    targetId: 'decorativeArt',
    title: 'Decorative Art',
    titleHref: '/decorative-art/pc-HV9SJ0PETO/',
    className: 'decorate-art-link notranslate nav-link',
    linkData: [{
      categoryId: 38,
      categoryLevel: 1,
      categoryName: 'American Indian Art',
      categoryRef: 'V1810UB55N',
      categoryShortName: 'American Indian Art',
      isParent: false,
      linkCategoryURL: '',
      lotCount: 782,
      popular: false
    },
    {
      categoryId: 40,
      categoryLevel: 1,
      categoryName: 'Ceramics & Pottery',
      categoryRef: 'W7JLTHSDLU',
      categoryShortName: 'Ceramics & Pottery',
      isParent: false,
      linkCategoryURL: '',
      lotCount: 3053,
      popular: false
    },
    {
      categoryId: 41,
      categoryLevel: 1,
      categoryName: 'Ethnographic & Indigenous Artifacts',
      categoryRef: 'SN06I92UXA',
      categoryShortName: 'Ethnographic & Indigenous Artifacts',
      isParent: false,
      linkCategoryURL: '',
      lotCount: 1488,
      popular: false
    },
    {
      categoryId: 43,
      categoryLevel: 1,
      categoryName: 'Glass',
      categoryRef: 'HVPO5ZEMD1',
      categoryShortName: 'Glass',
      isParent: false,
      linkCategoryURL: '',
      lotCount: 3149,
      popular: false
    },
    {
      categoryId: 39,
      categoryLevel: 1,
      categoryName: 'Greek, Roman & Egyptian Antiquities',
      categoryRef: 'YIWIKOSRZQ',
      categoryShortName: 'Greek, Roman & Egyptian Antiquities',
      isParent: false,
      linkCategoryURL: '',
      lotCount: 809,
      popular: false
    },
    {
      categoryId: 44,
      categoryLevel: 1,
      categoryName: 'Porcelain & China',
      categoryRef: 'G51MM3JCDR',
      categoryShortName: 'Porcelain & China',
      isParent: false,
      linkCategoryURL: '',
      lotCount: 1946,
      popular: false
    },
    {
      categoryId: 45,
      categoryLevel: 1,
      categoryName: 'Silver & Vertu',
      categoryRef: 'HVLGMC4OMS',
      categoryShortName: 'Silver & Vertu',
      isParent: false,
      linkCategoryURL: '',
      lotCount: 2219,
      popular: false
    }
    ],
  }, {
    targetId: 'jewelry',
    title: 'Jewelry',
    titleHref: '/jewelry/pc-YKGNZXS2MH/',
    className: 'jewelry-link notranslate nav-link',
    linkData: [
      {
        categoryId: 87,
        categoryLevel: 1,
        categoryName: 'Bracelets',
        categoryRef: 'SD21QYCFQN',
        categoryShortName: 'Bracelets',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1665,
        popular: false
      },
      {
        categoryId: 88,
        categoryLevel: 1,
        categoryName: 'Costume Jewelry',
        categoryRef: '9OACJQ50M0',
        categoryShortName: 'Costume Jewelry',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 174,
        popular: false
      },
      {
        categoryId: 89,
        categoryLevel: 1,
        categoryName: 'Earrings',
        categoryRef: '18N22YFW7F',
        categoryShortName: 'Earrings',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1938,
        popular: false
      },
      {
        categoryId: 91,
        categoryLevel: 1,
        categoryName: 'Loose Stones & Beads',
        categoryRef: 'OG1XEE04VI',
        categoryShortName: 'Loose Stones & Beads',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1385,
        popular: false
      },
      {
        categoryId: 92,
        categoryLevel: 1,
        categoryName: "Men's Jewelry",
        categoryRef: 'Q5BUT80T6S',
        categoryShortName: "Men's Jewelry",
        isParent: false,
        linkCategoryURL: '',
        lotCount: 284,
        popular: false
      },
      {
        categoryId: 98,
        categoryLevel: 1,
        categoryName: "Men's Watches",
        categoryRef: '7KBYXLPOCW',
        categoryShortName: "Men's Watches",
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1004,
        popular: false
      },
      {
        categoryId: 93,
        categoryLevel: 1,
        categoryName: 'Necklaces & Pendants',
        categoryRef: 'O62XLMG5L9',
        categoryShortName: 'Necklaces & Pendants',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 2991,
        popular: false
      },
      {
        categoryId: 94,
        categoryLevel: 1,
        categoryName: 'Pins & Brooches',
        categoryRef: 'TQ0X13A58C',
        categoryShortName: 'Pins & Brooches',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 788,
        popular: false
      },
      {
        categoryId: 97,
        categoryLevel: 1,
        categoryName: 'Pocket Watches & Accessories',
        categoryRef: 'OKRAO7JCZY',
        categoryShortName: 'Pocket Watches & Accessories',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 485,
        popular: false
      },
      {
        categoryId: 95,
        categoryLevel: 1,
        categoryName: 'Rings',
        categoryRef: 'QE9HEBWJZR',
        categoryShortName: 'Rings',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 9338,
        popular: false
      },
      {
        categoryId: 96,
        categoryLevel: 1,
        categoryName: 'Vintage Jewelry',
        categoryRef: 'K9OGPEH0Q1',
        categoryShortName: 'Vintage Jewelry',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1110,
        popular: false
      },
      {
        categoryId: 99,
        categoryLevel: 1,
        categoryName: "Women's Watches",
        categoryRef: 'WBQ0TKYDMB',
        categoryShortName: "Women's Watches",
        isParent: false,
        linkCategoryURL: '',
        lotCount: 517,
        popular: false
      }
    ],
  }, {
    targetId: 'collectibles',
    title: 'Collectibles',
    titleHref: '/collectibles/pc-BQWOG3FLWY/',
    className: 'collectibles-link notranslate nav-link d-md-none d-lg-inline-block',
    linkData: [
      {
        categoryId: 28,
        categoryLevel: 1,
        categoryName: 'Advertising, Paper & Ephemera',
        categoryRef: 'PCM15ZAEJA',
        categoryShortName: 'Advertising, Paper & Ephemera',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 2605,
        popular: false
      },
      {
        categoryId: 2417,
        categoryLevel: 1,
        categoryName: 'Animation Art',
        categoryRef: '5FFNCDPPYM',
        categoryShortName: 'Animation Art',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 77,
        popular: false
      },
      {
        categoryId: 2524,
        categoryLevel: 1,
        categoryName: 'Antiques',
        categoryRef: 'U6RB6GC1MM',
        categoryShortName: 'Antiques',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1,
        popular: false
      },
      {
        categoryId: 23,
        categoryLevel: 1,
        categoryName: 'Autographs',
        categoryRef: 'EOB7P0TJ34',
        categoryShortName: 'Autographs',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 4100,
        popular: false
      },
      {
        categoryId: 24,
        categoryLevel: 1,
        categoryName: 'Books, Maps & Manuscripts',
        categoryRef: 'NVAZ3UE29G',
        categoryShortName: 'Books, Maps & Manuscripts',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 10553,
        popular: false
      },
      {
        categoryId: 25,
        categoryLevel: 1,
        categoryName: 'Coins, Money & Stamps',
        categoryRef: 'SV4INCVA01',
        categoryShortName: 'Coins, Money & Stamps',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 11914,
        popular: false
      },
      {
        categoryId: 26,
        categoryLevel: 1,
        categoryName: 'Couture, Fashion & Accessories',
        categoryRef: 'OKYXW1QPNP',
        categoryShortName: 'Couture, Fashion & Accessories',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 2466,
        popular: false
      },
      {
        categoryId: 27,
        categoryLevel: 1,
        categoryName: 'Electronics Collectibles',
        categoryRef: 'WNDJ7L05CS',
        categoryShortName: 'Electronics Collectibles',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 627,
        popular: false
      },
      {
        categoryId: 30,
        categoryLevel: 1,
        categoryName: 'Historical, Political & Space Collectibles',
        categoryRef: 'WHUZCHPFAQ',
        categoryShortName: 'Historical, Political & Space Collectibles',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 257,
        popular: false
      },
      {
        categoryId: 31,
        categoryLevel: 1,
        categoryName: 'Memorabilia',
        categoryRef: 'WXMDZM23Q2',
        categoryShortName: 'Memorabilia',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 2878,
        popular: false
      },
      {
        categoryId: 32,
        categoryLevel: 1,
        categoryName: 'Military & Wartime Collectibles',
        categoryRef: '0EIGHLOFDZ',
        categoryShortName: 'Military & Wartime Collectibles',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 4598,
        popular: false
      },
      {
        categoryId: 33,
        categoryLevel: 1,
        categoryName: 'Musical Instruments & Equipment',
        categoryRef: '9RFC2VPWGO',
        categoryShortName: 'Musical Instruments & Equipment',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 195,
        popular: false
      },
      {
        categoryId: 34,
        categoryLevel: 1,
        categoryName: 'Natural History Collectibles, Fossils & Minerals',
        categoryRef: '4E71Y757DJ',
        categoryShortName: 'Natural History Collectibles, Fossils & Minerals',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1095,
        popular: false
      },
      {
        categoryId: 35,
        categoryLevel: 1,
        categoryName: 'Pens',
        categoryRef: '5ZZ5EOVHXB',
        categoryShortName: 'Pens',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 113,
        popular: false
      },
      {
        categoryId: 2522,
        categoryLevel: 1,
        categoryName: 'Police Collectibles',
        categoryRef: 'SYHOQE2P4A',
        categoryShortName: 'Police Collectibles',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 0,
        popular: false
      },
      {
        categoryId: 36,
        categoryLevel: 1,
        categoryName: 'Sporting, Fishing & Hunting Collectibles',
        categoryRef: 'HHO7L7CYWT',
        categoryShortName: 'Sporting, Fishing & Hunting Collectibles',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 560,
        popular: false
      },
      {
        categoryId: 37,
        categoryLevel: 1,
        categoryName: 'Tools',
        categoryRef: '159SUKPT23',
        categoryShortName: 'Tools',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1204,
        popular: false
      }
    ],
  }, {
    targetId: 'furniture',
    title: 'Furniture',
    titleHref: '/furniture/pc-66MNH3RVXU/',
    className: 'furniture-link d-md-none d-lg-inline-block notranslate nav-link',
    linkData: [
      {
        categoryId: 66,
        categoryLevel: 1,
        categoryName: 'Beds',
        categoryRef: 'Q1M0GV0I2V',
        categoryShortName: 'Beds',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 52,
        popular: false
      },
      {
        categoryId: 67,
        categoryLevel: 1,
        categoryName: 'Benches & Stools',
        categoryRef: 'OQO01MZVPQ',
        categoryShortName: 'Benches & Stools',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 143,
        popular: false
      },
      {
        categoryId: 68,
        categoryLevel: 1,
        categoryName: 'Cabinets, Armoires & Cupboards',
        categoryRef: '8RA3GB6FWF',
        categoryShortName: 'Cabinets, Armoires & Cupboards',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 694,
        popular: false
      },
      {
        categoryId: 69,
        categoryLevel: 1,
        categoryName: 'Chairs',
        categoryRef: 'GPI4WFSDTE',
        categoryShortName: 'Chairs',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 732,
        popular: false
      },
      {
        categoryId: 70,
        categoryLevel: 1,
        categoryName: 'Clocks',
        categoryRef: '77XL1D8362',
        categoryShortName: 'Clocks',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 496,
        popular: false
      },
      {
        categoryId: 71,
        categoryLevel: 1,
        categoryName: 'Decor & Accessories',
        categoryRef: 'M6LMPIAVCG',
        categoryShortName: 'Decor & Accessories',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 4813,
        popular: false
      },
      {
        categoryId: 72,
        categoryLevel: 1,
        categoryName: 'Dressers & Vanities',
        categoryRef: 'MIYYXXKHP7',
        categoryShortName: 'Dressers & Vanities',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 47,
        popular: false
      },
      {
        categoryId: 74,
        categoryLevel: 1,
        categoryName: 'Lamps & Lights',
        categoryRef: 'XGC3VSBGB1',
        categoryShortName: 'Lamps & Lights',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1076,
        popular: false
      },
      {
        categoryId: 75,
        categoryLevel: 1,
        categoryName: 'Mirrors',
        categoryRef: '1D7I67OQBU',
        categoryShortName: 'Mirrors',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 261,
        popular: false
      },
      {
        categoryId: 76,
        categoryLevel: 1,
        categoryName: 'Rugs & Carpets',
        categoryRef: '9CP1X2GQ1T',
        categoryShortName: 'Rugs & Carpets',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1138,
        popular: false
      },
      {
        categoryId: 77,
        categoryLevel: 1,
        categoryName: 'Shelves & Bookcases',
        categoryRef: 'ZG423REXF2',
        categoryShortName: 'Shelves & Bookcases',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 122,
        popular: false
      },
      {
        categoryId: 78,
        categoryLevel: 1,
        categoryName: 'Sofas, Couches & Chaises',
        categoryRef: 'FRT16H4VXB',
        categoryShortName: 'Sofas, Couches & Chaises',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 125,
        popular: false
      },
      {
        categoryId: 79,
        categoryLevel: 1,
        categoryName: 'Tables, Stands & Consoles',
        categoryRef: 'FKAJ7AFLJ9',
        categoryShortName: 'Tables, Stands & Consoles',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 1301,
        popular: false
      }
    ],
  }, {
    targetId: 'asianArt',
    title: 'Asian Art',
    titleHref: '/asian-art-antiques/pc-URF9IESAHL/',
    className: 'asian-art-link d-none d-xl-inline-block notranslate nav-link hidden-sm',
    linkData: [
      {
        categoryId: 13,
        categoryLevel: 1,
        categoryName: 'Chinese Art & Antiques',
        categoryRef: 'KEDZ10Y647',
        categoryShortName: 'Chinese Art & Antiques',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 9611,
        popular: false
      },
      {
        categoryId: 15,
        categoryLevel: 1,
        categoryName: 'Indian & South Asian Art & Antiques',
        categoryRef: 'I13Z7CQUX6',
        categoryShortName: 'Indian & South Asian Art & Antiques',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 362,
        popular: false
      },
      {
        categoryId: 16,
        categoryLevel: 1,
        categoryName: 'Japanese Art & Antiques',
        categoryRef: 'OY2OUC9LF6',
        categoryShortName: 'Japanese Art & Antiques',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 2071,
        popular: false
      },
      {
        categoryId: 17,
        categoryLevel: 1,
        categoryName: 'Korean Antiques & Art',
        categoryRef: 'GEKIBCU9K0',
        categoryShortName: 'Korean Antiques & Art',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 27,
        popular: false
      },
      {
        categoryId: 18,
        categoryLevel: 1,
        categoryName: 'Southeast Asian Art & Antiques',
        categoryRef: 'QC1A8YCACY',
        categoryShortName: 'Southeast Asian Art & Antiques',
        isParent: false,
        linkCategoryURL: '',
        lotCount: 434,
        popular: false
      }
    ]
  },
  {
    targetId: 'superCategory',
    linkData: [
      {
        categoryId: 1,
        categoryLevel: 0,
        categoryName: 'Asian Art & Antiques',
        categoryRef: 'URF9IESAHL',
        categoryShortName: 'Asian Art & Antiques',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 12775,
        popular: false
      },
      {
        categoryId: 2,
        categoryLevel: 0,
        categoryName: 'Automobiles, Boats & Airplanes',
        categoryRef: 'WIARM9WZTW',
        categoryShortName: 'Automobiles, Boats & Airplanes',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 408,
        popular: false
      },
      {
        categoryId: 3,
        categoryLevel: 0,
        categoryName: 'Collectibles',
        categoryRef: 'BQWOG3FLWY',
        categoryShortName: 'Collectibles',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 43526,
        popular: false
      },
      {
        categoryId: 4,
        categoryLevel: 0,
        categoryName: 'Decorative Art',
        categoryRef: 'HV9SJ0PETO',
        categoryShortName: 'Decorative Art',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 13475,
        popular: false
      },
      {
        categoryId: 5,
        categoryLevel: 0,
        categoryName: 'Dolls, Bears & Toys',
        categoryRef: '0HV8BV6K8Y',
        categoryShortName: 'Dolls, Bears & Toys',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 3959,
        popular: false
      },
      {
        categoryId: 9,
        categoryLevel: 0,
        categoryName: 'Estate & Storage',
        categoryRef: 'WXJEP9TUWL',
        categoryShortName: 'Estate & Storage',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 0,
        popular: false
      },
      {
        categoryId: 6,
        categoryLevel: 0,
        categoryName: 'Fine Art',
        categoryRef: 'SG2BIX3JPJ',
        categoryShortName: 'Fine Art',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 26650,
        popular: false
      },
      {
        categoryId: 8,
        categoryLevel: 0,
        categoryName: 'Furniture',
        categoryRef: '66MNH3RVXU',
        categoryShortName: 'Furniture',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 11195,
        popular: false
      },
      {
        categoryId: 7,
        categoryLevel: 0,
        categoryName: 'Guns & Firearms',
        categoryRef: 'TJYLLGLDKA',
        categoryShortName: 'Guns & Firearms',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 3271,
        popular: false
      },
      {
        categoryId: 10,
        categoryLevel: 0,
        categoryName: 'Jewelry',
        categoryRef: 'YKGNZXS2MH',
        categoryShortName: 'Jewelry',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 22666,
        popular: false
      },
      {
        categoryId: 11,
        categoryLevel: 0,
        categoryName: 'Real Estate',
        categoryRef: 'MU8Q47SBT3',
        categoryShortName: 'Real Estate',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 2,
        popular: false
      },
      {
        categoryId: 12,
        categoryLevel: 0,
        categoryName: 'Wines & Spirits',
        categoryRef: '5P1KWJO0ML',
        categoryShortName: 'Wines & Spirits',
        isParent: true,
        linkCategoryURL: '',
        lotCount: 1908,
        popular: false
      }
    ],
  },
];
