export const ARTIST_LOTS_TABS = {
  UPCOMING: 'upcoming',
  PAST: 'past',
};

export const ARTIST_LOTS_PATH = {
  [ARTIST_LOTS_TABS.UPCOMING]: 'lots-at-auction',
  [ARTIST_LOTS_TABS.PAST]: 'sold-at-auction-prices',
};

export const PRICE_RANGE_ATTR = {
  [ARTIST_LOTS_TABS.UPCOMING]: 'currentBid',
  [ARTIST_LOTS_TABS.PAST]: 'priceResult',
};

export const LOT_HIT_DATE_FORMAT = 'MMM. dd, yyyy';

export const LOT_HIT_DATE_TIME_FORMAT = 'MMM. dd, yyyy hh:mm:a';

export const LOTS_DISPLAY_MODES = {
  GRID: 'grid',
  LIST: 'list',
};

export const DEFAULT_DISPLAY_MODE = {
  [ARTIST_LOTS_TABS.UPCOMING]: LOTS_DISPLAY_MODES.GRID,
  [ARTIST_LOTS_TABS.PAST]: LOTS_DISPLAY_MODES.LIST,
};

export const DEFAULT_NUM_OF_LOTS_PER_PAGE = {
  [LOTS_DISPLAY_MODES.GRID]: 96,
  [LOTS_DISPLAY_MODES.LIST]: 48,
};

export const SORT_BY_QUERY_PARAM_TO_INDEX = {
  PAST: {
    archive_dateTime_desc_prod: 'auctionDateDesc',
    archive_dateTime_asc_prod: 'auctionDateAsc',
    archive_priceResult_desc_prod: 'priceDesc',
    archive_priceResult_asc_prod: 'priceAsc',
  },
  UPCOMING_STAGE: {
    upcoming_lots_postDateTime_desc_stage: 'postDateDesc',
    upcoming_lots_currentBid_asc_stage: 'currentBidAsc',
    upcoming_lots_currentBid_desc_stage: 'currentBidDesc',
    upcoming_lots_bidCount_asc_stage: 'totalBidsAsc',
    upcoming_lots_bidCount_desc_stage: 'totalBidsDesc',
  },
  UPCOMING_PROD: {
    upcoming_lots_postDateTime_desc_prod: 'postDateDesc',
    upcoming_lots_currentBid_asc_prod: 'currentBidAsc',
    upcoming_lots_currentBid_desc_prod: 'currentBidDesc',
    upcoming_lots_bidCount_asc_prod: 'totalBidsAsc',
    upcoming_lots_bidCount_desc_prod: 'totalBidsDesc',
  }
};

export const SORT_BY_INDEX_TO_QUERY_PARAM = {
  PAST: {
    auctionDateDesc: 'archive_dateTime_desc_prod',
    auctionDateAsc: 'archive_dateTime_asc_prod',
    priceDesc: 'archive_priceResult_desc_prod',
    priceAsc: 'archive_priceResult_asc_prod',
  },
  UPCOMING_STAGE: {
    postDateDesc: 'upcoming_lots_postDateTime_desc_stage',
    currentBidAsc: 'upcoming_lots_currentBid_asc_stage',
    currentBidDesc: 'upcoming_lots_currentBid_desc_stage',
    totalBidsAsc: 'upcoming_lots_bidCount_asc_stage',
    totalBidsDesc: 'upcoming_lots_bidCount_desc_stage',
  },
  UPCOMING_PROD: {
    postDateDesc: 'upcoming_lots_postDateTime_desc_prod',
    currentBidAsc: 'upcoming_lots_currentBid_asc_prod',
    currentBidDesc: 'upcoming_lots_currentBid_desc_prod',
    totalBidsAsc: 'upcoming_lots_bidCount_asc_prod',
    totalBidsDesc: 'upcoming_lots_bidCount_desc_prod',
  },
};
