export const DEFAULT_OBSRV_ELEM_THRESHOLD = 1;

export const SALE_TYPE = {
  LIVE: 'Live',
  TIMED: 'Timed'
};

export const LOT_COUNT_DOWN_STATUS = {
  LIVE_NOW: 'Live Now',
  LIVE_TODAY: 'Live Today',
  DAYS_LEFT: 'Days Left',
  DAY_LEFT: 'Day Left',
  HOURS_LEFT: 'Hours Left',
  HOUR_LEFT: 'Hour Left',
  CLOSING_NOW: 'Closing Now'
};
