import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
/**
 * A common select to be used across the inv-react, it is mainly created to customize
 * default browser styles for a dropdown according to the inv dropdowns style.
 * @param value, Value of the field
 * @param defaultOptionText, Option to be displayed at the top with empty value
 * @param className, Optional className
 * @param selectFirstOption, Optional flag to select first option
 * @param selectedItemCustomRender, Optional HTML for selected dropdown value
 * @param options, Options to render, list of objects e.g, [{countryKey: 'PK', countryName: 'Pakistan'},]
 * @param optionValueKey, Property of the options objects to be used as value  of select, 'countryKey' in the above example
 * @param optionDisplayKey, Property of the options objects to be displayed, 'countryName' in the above example
 * @param onChange, Optional onChange callback
 * @param onBlur, Optional onBlur callback
 * @returns Select jsx
 */
function Select(props) {
  const {
    name,
    value,
    defaultOptionText,
    className,
    selectFirstOption = true,
    selectedItemCustomRender,
    options,
    optionDisplayKey,
    optionValueKey,
    onChange,
    onBlur
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const selectMenuClasses = classNames([className, 'form-control',
    {
      'not-selected': !value,
    },
  ]);

  const setSelectedValue = (option) => {
    onChange && onChange(option[optionValueKey]);
  };

  const resetSelectedValue = () => onChange && onChange('');

  const selectedOption = value && options.find(option => value === option[optionValueKey]);
  const dropDownDisplayText = selectedOption ? selectedOption[optionDisplayKey] : defaultOptionText || options[0][optionDisplayKey];

  useEffect(() => {
    if (selectFirstOption && !defaultOptionText && !value && onChange) {
      onChange(options[0][optionValueKey]);
    }
  }, []);

  return (
    <div className="custom-select-menu">
      <Dropdown
        className="select-dropdown"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle
          className={selectMenuClasses}
          tag="div"
          id="select-dropdown-options"
          htmlFor="select-dropdown-options"
        >
          <span
            className="select-dropdown-label"
          >
            <span className="select-dropdown-value text-truncate">
              {selectedItemCustomRender ? selectedItemCustomRender() : dropDownDisplayText}
            </span>
            <span className="select-dropdown-caret fa fa-angle-down" />
          </span>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu select-dropdown-options"
          onBlur={() => { onBlur?.(); }}
        >
          {defaultOptionText && (
            <DropdownItem
              className="not-selected"
              onClick={() => resetSelectedValue()}
            >
              {defaultOptionText}
            </DropdownItem>
          )}
          {
        options.map(option => (
          <DropdownItem
            key={`${name}-${option[optionDisplayKey]}`}
            onClick={() => setSelectedValue(option)}
          >{option[optionDisplayKey]}
          </DropdownItem>
        ))
      }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  selectFirstOption: PropTypes.bool,
  defaultOptionText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionValueKey: PropTypes.string.isRequired,
  optionDisplayKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  selectedItemCustomRender: PropTypes.func
};

Select.defaultProps = {
  name: '',
  className: '',
  selectFirstOption: true,
  defaultOptionText: undefined,
  onChange: undefined,
  onBlur: undefined,
  selectedItemCustomRender: undefined
};

export default Select;
