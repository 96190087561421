import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectContactSellerStatus } from 'src/selectors/pdp';
import { ASYNC_ACTION_STATUS_TYPE } from 'src/components/common/constants';
import usePDPModal from '../usePDPModal';

const usePDPContactSellerModalDisplay = (closeContactSellerModal) => {
  const contactSellerStatus = useSelector(selectContactSellerStatus);

  const [
    isOpenContactSuccessModal,
    openContactSuccessModal, ,
    toggleContactSuccessModal,
  ] = usePDPModal(false);

  useEffect(() => {
    if (contactSellerStatus === ASYNC_ACTION_STATUS_TYPE.COMPLETE) {
      closeContactSellerModal();
      openContactSuccessModal();
    }
  }, [contactSellerStatus]);

  return { isOpenContactSuccessModal, toggleContactSuccessModal };
};

export default usePDPContactSellerModalDisplay;
