import axios from 'axios';
import { fullPageLoadingSpinnerToggle } from 'src/utils/common/helpers';

// Configs
export const STORAGE_PREFIX = 'eos_';
export const EMAIL_ONLY_FOLLOW_ENDPOINT = '/app/user/createEmailOnlyAccount';
export const GET_ACCOUNT_TYPE_ENDPOINT = '/app/user/getAccountType';
export const UPGRADE_EMAIL_ONLY_ACCOUNT_ENDPOINT = '/app/user/submitEditEmailOnlyAccount';

const storeValue = (name, obj = {}) => {
  const parsedObj = JSON.stringify(obj);
  sessionStorage.setItem(`${STORAGE_PREFIX}${name}`, parsedObj);

  // This is necessary to trigger changes on the same tab
  window.dispatchEvent(new Event('storage'));
};

const getValue = (name) => {
  const data = JSON.parse(sessionStorage.getItem(`${STORAGE_PREFIX}${name}`));
  return data;
};

export const getAlertsObj = () => ({
  keyword: [],
  artist: [],
  house: [],
  category: [],
});

export const getEmail = () => getValue('USER');
export const setEmail = (email) => { storeValue('USER', email); };

export const getAlerts = () => getValue('ALERTS') || getAlertsObj();
export const setAlerts = (alerts) => { storeValue('ALERTS', alerts); };

const alertTypeMap = {
  ARTIST: 'Artist',
  CATEGORY: 'Category',
  KEYWORD: 'Keyword',
  PAST_LOT: 'Past Lot',
  SELLER: 'House',
};

export const followItem = (
  {
    sendNewsLetter,
    email,
    alertType,
    refOrKeyword,
    lotId,
    unfollow = false,
    signup = false,
  }
) => {
  if (!email) return;

  fullPageLoadingSpinnerToggle(true);

  return new Promise((resolve, reject) => {
    axios
      .post(EMAIL_ONLY_FOLLOW_ENDPOINT, {
        user: {
          email,
          userPrefs: {
            sendNewsLetter,
            keywordAlerts: true,
            personalizedEmail: sendNewsLetter,
            marketingEmail: sendNewsLetter,
            auctionNewsletter: sendNewsLetter,
            blogContent: sendNewsLetter,
          },
        },
        alertType,
        refOrKeyword,
        lotId,
        unfollow,
      })
      .then(({ data }) => {
        // User already has a full account
        const { httpStatus, errors } = data;
        if (httpStatus === 422) {
          fullPageLoadingSpinnerToggle(false);
          const error = { ...errors[0], httpStatus: 422, email };
          return reject(error);
        }

        if (errors.length) {
          fullPageLoadingSpinnerToggle(false);
          return reject(errors);
        }

        const { alerts } = data.data;
        const alertsObj = getAlertsObj();

        Object.keys(alerts).forEach((ref) => {
          switch (alerts[ref]) {
            case 'k': {
              alertsObj.keyword.push(ref.toLowerCase());
              break;
            }
            case 's': {
              alertsObj.house.push(ref.toLowerCase()); break;
            }
            case 'a': {
              alertsObj.artist.push(ref.toLowerCase()); break;
            }
            case 'c': {
              alertsObj.category.push(ref.toLowerCase()); break;
            }
            default: {
              break;
            }
          }
        });

        if (signup) {
          window.heap?.track(`${alertTypeMap[alertType]} Alert - Sign-up - Success Event`);
        }

        setAlerts(alertsObj);
        setEmail(email);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => fullPageLoadingSpinnerToggle(false));
  });
};
