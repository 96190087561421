import { get } from 'lodash';
import {
  FETCH_ARTIST_BIO_ERROR,
  FETCH_ARTIST_BIO_SUCCESS,
  TOGGLE_SAVED_ARTIST_KEYWORD,
  FETCH_HOUSES_ERROR,
  FETCH_HOUSES_LOADING,
  FETCH_HOUSES_SUCCESS,
  FETCH_GENRES_ERROR,
  FETCH_GENRES_LOADING,
  FETCH_GENRES_SUCCESS,
  FETCH_FEATURED_LOTS_ERROR,
  FETCH_FEATURED_LOTS_LOADING,
  FETCH_FEATURED_LOTS_SUCCESS,
} from '../actions/constants';

// eslint-disable-next-line no-unused-vars
import { constants } from '../../config';

const initialState = {
  isLoadingArtistBio: false,
  artist: null,
  artistType: null,
  items: null,
  isLoadingSearchResults: false,
  searchType: null,
  followed: false,
  followType: null,
  soldSection: [],
  subType: null,
  facetCounts: {},
};

function artist(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTIST_BIO_SUCCESS: {
      return {
        ...state,
        artist: action.payload,
        artistType: action.type,
        isLoadingArtistBio: false,
        artistStatus: action.payload.status || 200,
      };
    }

    case FETCH_ARTIST_BIO_ERROR:
      return {
        ...state,
        artist: null,
        artistType: action.type,
        errorMessage: action.payload,
        isLoadingArtistBio: false,
        artistStatus: get(action, 'payload.response.status', 500),
      };

    case TOGGLE_SAVED_ARTIST_KEYWORD: {
      const followed = !state.followed;
      return {
        ...state,
        followed,
      };
    }

    case FETCH_HOUSES_LOADING:
    case FETCH_GENRES_LOADING:
    case FETCH_FEATURED_LOTS_LOADING: {
      return {
        ...state,
        actionType: action.type,
      };
    }

    case FETCH_HOUSES_ERROR:
    case FETCH_GENRES_ERROR:
    case FETCH_FEATURED_LOTS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        actionType: action.type,
      };
    }

    case FETCH_HOUSES_SUCCESS: {
      return {
        ...state,
        actionType: action.type,
        housesSection: action.payload || [],
      };
    }

    case FETCH_GENRES_SUCCESS: {
      return {
        ...state,
        actionType: action.type,
        genresSection: action.payload || [],
      };
    }

    case FETCH_FEATURED_LOTS_SUCCESS: {
      return {
        ...state,
        actionType: action.type,
        featuredLots: action.payload || [],
      };
    }

    default:
      return state;
  }
}

export default artist;
