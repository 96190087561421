import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Carousel, NON_CIRCULAR_CAROUSEL_CONFIG } from 'src/components/common/carousel';
import { selectArtistBioInfo, selectArtistDisplayName } from 'src/selectors/artist-detail';
import { selectHostname } from 'src/selectors/inv-common';
import { ARTIST_LOTS_TABS } from 'src/utils/artist-detail/constants';

import LotHit from '../../common/lot-hit/LotHit';
import NoUpcomingLotsBanner from '../NoUpcomingLotsBanner';
import { NO_LOTS_TO_DISPLAY_MESSAGE } from '../constants';

import ViewAllLotCard from './ViewAllLotCard';
import { MAX_NUM_OF_LOTS_TO_SHOW_VIEW_ALL_BTN } from './constants';
import { createArtistDetailURLForPastUpcomingPages } from '../helpers';

function PastUpcomingLotsCarousel({ lotsToDisplay, totalHits, isUpcoming }) {
  const currentArtistName = useSelector(selectArtistDisplayName);
  const hostname = useSelector(selectHostname);
  const artistBio = useSelector(selectArtistBioInfo);

  const isShowViewAllLinkCard = totalHits > MAX_NUM_OF_LOTS_TO_SHOW_VIEW_ALL_BTN;
  const sectionHeading = isUpcoming ? 'For Sale at Auction' : 'Sold at Auction Prices';

  const pastUpcomingPageRedirectURL = createArtistDetailURLForPastUpcomingPages(artistBio, hostname, isUpcoming);

  const renderLotsWithFallback = () => {
    if (!lotsToDisplay.length) {
      return isUpcoming ? (
        <NoUpcomingLotsBanner />)
        : <strong>{NO_LOTS_TO_DISPLAY_MESSAGE[ARTIST_LOTS_TABS.PAST]}</strong>;
    }

    return (
      <Carousel customConfig={NON_CIRCULAR_CAROUSEL_CONFIG}>
        {lotsToDisplay.map((hit) => (
          <LotHit
            hit={hit}
            key={hit.lotRef}
            isUpcoming={isUpcoming}
          />
        ))}
        {isShowViewAllLinkCard && (
        <ViewAllLotCard pastUpcomingPageRedirectURL={pastUpcomingPageRedirectURL} />
        )}
      </Carousel>
    );
  };

  return (
    <div className="artist-lots-carousel-container">
      <div className="carousel-header">
        <h2>
          {`${sectionHeading}: ${currentArtistName} (${totalHits.toLocaleString()})`}
        </h2>
        {lotsToDisplay.length ? (
          <a
            className="btn btn-outline-primary"
            target="_blank"
            rel="noopener noreferrer"
            href={pastUpcomingPageRedirectURL}
          >View All
          </a>
        ) : null}

      </div>

      {renderLotsWithFallback()}

    </div>
  );
}

PastUpcomingLotsCarousel.propTypes = {
  lotsToDisplay: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalHits: PropTypes.number.isRequired,
  isUpcoming: PropTypes.bool,

};

PastUpcomingLotsCarousel.defaultProps = {
  isUpcoming: false,
};

export default PastUpcomingLotsCarousel;
