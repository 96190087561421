/**
 * Moves [ 'US', 'UK', 'CA', 'AU' ] at the top of the list if present in the list
 * @param countries list of the countries
 * @returns list of the countries with the above mentioned countries at the top
 */
export const getSortedCountries = (countries) => {
  const countriesClone = [...countries];
  const mostSelectedCountryCodes = ['US', 'UK', 'CA', 'AU'];
  const mostSelectedCountries = [];

  mostSelectedCountryCodes.forEach((countryCode) => {
    const index = countriesClone.findIndex(country => country.countryCode === countryCode);
    const country = countriesClone.splice(index, 1)[0]; // remove country from the main list
    country && mostSelectedCountries.push(country);
  });

  return mostSelectedCountries.concat(countriesClone);
};
